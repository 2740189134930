import { flatten, isEmpty, join } from 'lodash';
import RequestFactory from './RequestFactory';

class BaseResource {
    _resourcePath;

    /**
     * The Resource's base path
     * @returns {string}
     */
    get resourcePath() {
        return this._resourcePath;
    }

    /**
     * The Resource's base path
     * @param value {string}
     */
    set resourcePath(value) {
        this._resourcePath = value;
    }

    /**
     * Get Single Event
     * @param id
     * @param filters
     * @param others
     * @returns RequestObject
     */
    getIndividual({ id, filters = {}, include = [], waitFor = [] } = {}) {
        const query = {
            filter: {
                status: 1,
                ...filters,
            },
        };

        if (!isEmpty(include)) {
            query.include = join(flatten([include]), ',');
        }

        return RequestFactory(`${this.resourcePath}/${id}`, 'view', {}, query, null, waitFor);
    }

    /**
     * Get Collection of Events
     * @param params
     * @returns RequestObject|void
     */
    getCollection({ limit = 50, offset = 0, sort = [], filters = {}, include = [], waitFor = [] } = {}) {
        const query = {
            filter: {
                status: 1,
                ...filters,
            },
            page: {
                limit,
                offset,
            },
        };

        if (!isEmpty(sort)) {
            query.sort = join(flatten([sort]), ',');
        }

        if (!isEmpty(include)) {
            query.include = join(flatten([include]), ',');
        }

        return RequestFactory(this.resourcePath, 'view', null, query, null, waitFor);
    }
}

export default BaseResource;
