<template>
    <list-with-filter
        class="career-jobs"
        taxonomy-type="content_type"
        :title="$t('jobs.jobs.title')"
        :filter-values="filterValues"
        :facets="facets"
        :search-bar="false"
        :total-count="parseInt(jobs.pager.count)"
        :items-per-page="parseInt(jobs.pager.items_per_page)"
        :page.sync="page"
        has-top-slot
        hide-filter-header
        has-box
        :intro="pageIntro"
        @filterUpdate="filterUpdateHandler"
    >
        <!-- Info box desktop -->
        <Container v-if="!isMobileSize" slot="filterBox" class="filterBox">
            <div class="filter__info">
                <div>
                    <div class="filter__text">{{ $t('jobs.jobs.info') }}</div>
                    <custom-link :to="$t('jobs.opportunities.url')" class="filter__link" underline primary>
                        {{ $t('jobs.jobs.txt') }}
                    </custom-link>
                </div>
            </div>
        </Container>

        <!-- Filter button -->
        <Container slot="filterButton" class="filterButton">
            <el-button class="reset__button link-button">
                {{ this.$t('filter.reinitialize') }}
            </el-button>
        </Container>

        <!-- Jobs -->
        <Container no-margin class="career__container">
            <loader-wrapper :loading="isUpdating">
                <section v-if="jobs.results.length > 0" class="career__section">
                    <article v-for="job in jobs.results" :key="job.entity_id" class="career__section__job">
                        <div v-if="job.pdf_url" class="job__title job__title-pdf">
                            <custom-link :to="job.pdf_url" info external hide-external>
                                {{ job.entity_title | decodehtml }}
                            </custom-link>
                            <img src="~assets/img/pdf_icone.png" alt="pdf" />
                        </div>
                        <div v-else class="job__title">
                            {{ job.entity_title | decodehtml }}
                        </div>
                        <div class="job__property">
                            <span class="bold">{{ $t('jobs.display') }} :</span>
                            <span> {{ job.display_period }}</span>
                        </div>
                        <div class="job__property">
                            <span class="bold">{{ $t('jobs.type') }} :</span>
                            <span> {{ ' ' + job.job_category }}</span>
                        </div>
                        <div class="job__property">
                            <span class="bold">{{ $t('jobs.number') }} :</span>
                            <span> {{ ' ' + job.competition_number }}</span>
                        </div>
                        <div class="separator"></div>
                    </article>
                </section>
                <section v-else class="career__section">
                    {{ $t('filter.noResult') }}
                </section>
            </loader-wrapper>
        </Container>

        <!-- Info box desktop -->
        <Container v-if="isMobileSize" class="filterBox">
            <div class="filter__info filter__info__mobile">
                <div>
                    <div class="filter__text">{{ $t('jobs.jobs.info') }}</div>
                    <custom-link :to="$t('jobs.opportunities.url')" class="filter__link" underline primary>
                        {{ $t('jobs.jobs.txt') }}
                    </custom-link>
                </div>
            </div>
        </Container>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container.vue';
import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        Container,
        CustomLink,
        ListWithFilter,
        LoaderWrapper,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            type: Array,
            default: () => [],
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);

        return {
            displayFilters: initData.displayFilters,
            facets: initData.facets,
            jobs: initData.jobs,
            isUpdating: false,
            isMobileSize: true,
            page: parseInt(this.$route.query.page) || 1,
            filterValues: {
                byType: [],
            },
        };
    },

    computed: {
        pageIntro() {
            return this.initData.item.body != null ? this.initData.item.body.processed : null;
        },
    },

    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        page() {
            this.updateJobs(true);
        },
    },

    async asyncData({ app, query, store }, item) {
        const path =
            process.env.CMS_API_ENDPOINT +
            '/' +
            app.i18n.locale +
            `/api/search-jobs?job_type=open_job_offer&page=${
                query.page && query.page > 0 ? query.page - 1 : 0
            }&items_per_page=10`;
        const results = await app.$client.execute('solrSearch', 'getDatas', {
            uri: path,
        });

        const facets = [];

        if (results.data.facets) {
            for (const [facetId, filters] of Object.entries(results.data.facets)) {
                const facet = { facetId, filters: [] };
                for (const [key, f] of Object.entries(filters)) {
                    facet.filters.push({
                        id: key,
                        value: f.values.value,
                        name: f.values.label,
                        active: f.values.active ? f.values.active === 'true' : false,
                    });
                }
                facets.push(facet);
            }
        }

        return {
            item,
            jobs: results.data,
            facets,
        };
    },

    mounted() {
        this.getWindowSize();
        window.addEventListener('resize', this.getWindowSize);
    },

    methods: {
        getWindowSize() {
            const windowWidth = window.innerWidth;
            windowWidth <= 768 ? (this.isMobileSize = true) : (this.isMobileSize = false);
        },
        async updateJobs(pageOnly = false) {
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;
            const path =
                process.env.CMS_API_ENDPOINT +
                '/' +
                this.$i18n.locale +
                `/api/search-jobs?job_type=open_job_offer&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`;

            const parameters = {
                facets: this.filterValues.byType,
            };

            const uri = this.$solrUrlBuilder.buildUri(path, parameters);

            const response = await this.$client.execute('solrSearch', 'getDatas', {
                uri,
            });

            if (response.data.facets) {
                const facets = [];
                for (const [facetId, filters] of Object.entries(response.data.facets)) {
                    const facet = { facetId, filters: [] };
                    for (const [key, f] of Object.entries(filters)) {
                        facet.filters.push({
                            id: key,
                            value: f.values.value,
                            name: f.values.label,
                            active: f.values.active ? f.values.active === 'true' : false,
                        });
                    }
                    facets.push(facet);
                }
                this.facets = facets;
            }

            this.jobs = response.data;
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updateJobs();
        },
    },
};
</script>

<style lang="scss" scoped>
.filter {
    &__info {
        display: flex;
        width: 100%;
        max-width: 284px;
        height: 203px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        font-size: rem(16px);
        padding: 40px 39px 36px 39px;
        text-align: center;
        align-items: center;
        flex-flow: row;
        margin-top: 8px;
        margin-bottom: 46px;

        &__mobile {
            max-height: 176px;
            margin-top: 0;
            margin-bottom: 18px;
        }
    }

    &__link {
        font-size: rem(16px);
        font-weight: 400;
        line-height: 1.5em;

        @include media(md) {
            font-size: rem(18px);
        }
    }

    &__text {
        font-size: rem(14px);
        margin-bottom: 20px;
        line-height: 1.5em;

        @include media(md) {
            font-size: rem(16px);
        }
    }
}
.career {
    &__container {
        margin-left: 0;
        margin-right: 0;

        @include media(sm) {
            padding-left: 5px;
        }

        @include media(lg) {
            margin-right: 117px;
            padding-left: 5px;
        }
    }

    &__form {
        display: flex;
        width: 100%;
        max-width: 974px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
        text-align: center;
        align-items: center;
        flex-flow: column;

        @include media(sm) {
            flex-flow: row;
            height: 108px;
        }

        &__text {
            max-width: 660px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 37px 30px 44px 30px;
            text-align: left;
            font-weight: 400;
            font-size: rem(14px);
            line-height: 1.3em;

            @include media(sm) {
                display: flex;
                align-items: center;
                padding: 30px 30px 30px 30px;
                font-size: rem(18px);
                line-height: 1.7em;
            }
        }

        &__link {
            height: 100%;
            width: 100%;
            float: right;
            background-color: $--color-footer;
            box-sizing: border-box;
            padding: 20px;
            font-size: rem(18px);

            @include media(sm) {
                width: 314px;
            }

            &-icone {
                margin-left: 16px;
            }
        }
    }

    &__section {
        align-items: flex-start;

        &__job {
            margin-bottom: 34px;
            width: 100%;

            @include media(sm) {
                margin-bottom: 36px;
            }
        }

        .job {
            &__title {
                color: $--color-info;
                font-size: rem(18px);
                font-weight: 700;
                margin-bottom: 22px;
                display: flex;
                flex-flow: column;

                @include media(sm) {
                    font-size: rem(18px);
                    white-space: nowrap;
                    flex-flow: row;
                    margin-bottom: 26px;
                }

                &-pdf {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }

            &__property {
                margin-bottom: 15px;
                display: flex;
                flex-flow: column;
                font-size: rem(14px);

                @include media(sm) {
                    flex-flow: row;
                    font-size: rem(16px);
                    margin-bottom: 0;
                }
            }
        }
    }
}

.separator {
    display: inline-flex;
    width: 100%;
    background-color: $--color-gray;
    height: 2px;
    align-self: center;
    margin-top: 20px;
}

.bold {
    font-weight: 500;
    margin-right: 5px;
    margin-bottom: 13px;
}
</style>

<style lang="scss">
.el-container {
    .el-main {
        .career-jobs > div > div {
            margin-left: 0;

            @include media(lg) {
                margin-left: -20px;
            }
        }
    }
}

.el-main {
    .career-jobs {
        margin-bottom: 33px;

        .title__htag--h1 {
            font-size: rem(25px);
            margin-top: 48px;
            margin-bottom: 17px;

            @include media(sm) {
                font-size: rem(42px);
                margin-top: 25px;
                margin-bottom: 21px;
            }
        }

        .el-collapse-item__header-desktop {
            margin-top: 0;
        }

        .filters {
            margin-top: 20px;
            margin-bottom: 24px;

            @include media(sm) {
                margin-top: 0;
            }
        }

        .filter-accordion-header {
            margin-bottom: 12px;
        }

        .filterButton .el-button {
            margin-left: 0px;
        }
    }
}
.career-jobs {
    .career__form {
        .link__external {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .career__container {
        .link__external {
            display: flex;
            flex-flow: column;

            @include media(sm) {
                flex-flow: row;
            }
        }
    }

    .list-with-filter {
        &__first-row {
            margin-bottom: 12px;

            @include media(sm) {
                margin-bottom: 80px;
            }
        }
        &__content {
            margin-top: 20px;

            @include media(sm) {
                margin-top: 0;
            }
        }
    }
}
</style>
