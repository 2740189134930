import BaseResource from '../BaseResource';

class InteractiveMaps extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/interactiveMaps';
    }
}

export default InteractiveMaps;
