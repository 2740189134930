<template>
    <div class="parag__reusable">
        <template v-for="block in paragraph.reusable_block">
            <component :is="blockType[block.meta.type]" :key="block.id" class="reusable__block" :block="block" />
        </template>
    </div>
</template>

<script>
import Accordions from '~/components/paragraphs/Accordions.vue';
export default {
    components: {
        // due to "unused import" rule and the component binding,
        // you must comment paragraph imports
        Accordions, // eslint-disable-line
    },
    props: {
        paragraph: {
            type: Object,
            default: null,
        },
    },

    computed: {
        blockType() {
            return {
                accordionsBlock: Accordions,
            };
        },
    },
};
</script>
<style lang="scss">
.parag__reusable {
    .reusable__block {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
</style>
