import BaseResource from '../BaseResource';
import RequestFactory from '../RequestFactory';

class WebformJsonSchema extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/webform_jsonschema';
    }

    saveEntry({ id, body, token }) {
        return RequestFactory(`${this.resourcePath}/${id}`, 'create', { 'X-CSRF-Token': token }, null, body);
    }

    getCollection() {
        throw new Error('Method not supported.');
    }
}

export default WebformJsonSchema;
