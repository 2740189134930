import BaseResource from '../BaseResource';

class Directions extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/directions';
    }
}

export default Directions;
