<template>
    <div
        class="c-block-accordions-wrapper"
        :class="[{ 'is-active': isOpen }, { 'is-scrollable': isScrollable }, modifier]"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <div class="c-block-accordions-wrapper_toggler menu--accordion-toggler">
            <slot :toggle="toggle" name="toggler"></slot>
        </div>
        <div
            :id="'accordion-content--' + getId()"
            ref="content"
            :aria-labelledby="'accordion--toggle--' + getId()"
            class="c-block-accordions-wrapper_content menu--accordion-content"
        >
            <ul ref="inner" class="c-block-accordions-wrapper_inner">
                <slot name="content"></slot>
            </ul>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';

import { mapState } from 'vuex';

export default {
    props: {
        modifier: {
            type: String,
            default: '',
        },
    },
    data: () => {
        return {
            isOpen: false,
            isScrollable: false,
        };
    },
    computed: {
        ...mapState('locomotive-global', ['metrics', 'breakpoints']),
    },
    created() {
        this.isAnimating = false;
    },
    methods: {
        toggle() {
            if (this.isAnimating) return;
            if (!this.isOpen) {
                this.open();
            } else {
                this.close();
            }
        },
        open() {
            if (this.isOpen) return;

            this.isOpen = true;
            this.isAnimating = true;
            this.$emit('onOpen', this._uid);

            const height = this.$refs.inner.offsetHeight;

            if (this.modifier === '-menu' && this.metrics.width > this.breakpoints.header) {
                document.documentElement.classList.add('has-menu-overlay');
            }

            if (this.modifier === '-menu') {
                document.documentElement.classList.add('has-accordions-open');
            }

            gsap.to(this.$refs.content, {
                height,
                opacity: 1,
                duration: 0.3,
                // delay: this.modifier === '-menu' ? 0.1 : 0,
                ease: 'loco',
                onComplete: () => {
                    this.isAnimating = false;
                    this.$refs.content.style.height = 'auto';
                    this.$emit('onOpened');

                    if (this.modifier === '-menu') {
                        setTimeout(() => {
                            this.isScrollable = true;
                            document.documentElement.classList.add('has-accordions-open');
                        }, 100);
                    }
                },
            });
        },
        close() {
            if (!this.isOpen) return;

            this.isOpen = false;
            this.isAnimating = true;
            this.$emit('onClose', this._uid);
            this.isScrollable = false;

            if (this.modifier === '-menu' && this.metrics.width > this.breakpoints.header) {
                document.documentElement.classList.remove('has-menu-overlay');
            }

            gsap.to(this.$refs.content, {
                height: 0,
                opacity: 0,
                duration: 0.3,
                ease: 'quart.out',
                onComplete: () => {
                    this.isAnimating = false;
                    this.$emit('onClosed');

                    if (this.modifier === '-menu') {
                        document.documentElement.classList.remove('has-accordions-open');
                    }
                },
            });
        },
        forceClose() {
            this.isOpen = false;
            this.isAnimating = false;
            this.isScrollable = false;
            this.$refs.content.style.height = '0px';
            this.$emit('onClose');
            this.$emit('onClosed');
        },
        onMouseEnter() {
            if (this.modifier !== '-menu' || this.metrics.width < this.breakpoints.header) return;
            this.open();
        },
        onMouseLeave() {
            if (this.modifier !== '-menu' || this.metrics.width < this.breakpoints.header) return;
            this.close();
        },
        getId() {
            return this._uid;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-accordions-wrapper {
    .c-ui-icon.-cross {
        transition: transform $loco-speed $loco-easing;
        transform-origin: top;
    }

    &.is-active {
        .c-ui-icon.-cross {
            transform: rotate(45deg) translate3d(0, -50%, 0);
        }

        & > .c-block-accordions-wrapper_content {
            visibility: visible;
        }
    }
}
.c-block-accordions-wrapper_content {
    visibility: collapse;
    height: 0;
    background-color: $loco-neutral-darker-hover;
    overflow: hidden;

    .c-block-accordions-wrapper.-menu > & {
        @media (min-width: $from-header) {
            max-height: calc((var(--vh, 1vh) * 100) - var(--header-height));
        }
    }
}

.c-block-accordions-wrapper_toggler {
    @media (max-width: $to-header) {
        & > .c-block-menu_item {
            height: auto !important;
        }
    }
}

.c-block-accordions-wrapper_inner {
    padding-bottom: loco-rem(36px);
}

.c-block-accordions-wrapper.-menu {
    &.is-scrollable {
        & > .c-block-accordions-wrapper_content {
            @media (min-width: $from-header) {
                overflow-y: auto;
            }
        }
    }
    & > .c-block-accordions-wrapper_content {
        background-color: $loco-neutral-darker;

        @media (max-width: $to-header) {
            .c-block-accordions_toggler {
                padding-left: loco-rem(20px);
                padding-right: loco-rem(48px);
            }

            & > .c-block-accordions-wrapper_inner {
                .c-block-accordions-wrapper_inner {
                    padding: loco-rem(16px) loco-rem(20px) loco-rem(32px);
                }
            }

            .c-ui-icon {
                right: loco-rem(20px);
            }
        }

        @media (min-width: $from-header) {
            position: absolute;
            left: 0;
            width: 100%;
            overflow-y: hidden;
            overflow-x: hidden;

            & > .c-block-accordions-wrapper_inner {
                padding: 0;

                .c-block-accordions-wrapper_inner {
                    padding: loco-rem(16px) loco-rem($loco-padding);
                }
            }

            .c-block-accordions_toggler {
                padding: loco-rem(16px) loco-rem($loco-padding);

                & > .c-ui-icon {
                    right: loco-rem($loco-padding);
                }
            }
        }
    }
}
</style>
