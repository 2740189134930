import * as Cookies from 'js-cookie';
import { forEach, isEmpty } from 'lodash';
import jsonapiParse from 'jsonapi-parse';
import qs from 'qs';

import HTTPError from './HTTPError';

export default class ApiClient {
    /**
     * @type {string}
     * @private
     */
    _languageCode = 'fr';

    /**
     * @type {string}
     * @private
     */
    _apiEndpoint;

    /**
     * @type {Object}
     * @private
     */
    _api;

    /**
     * @type {Object}
     * @private
     */
    _axios;

    /**
     * @type {Object}
     * @private
     */
    _ctx;

    /**
     * @type {string}
     * @private
     */
    _profileCookieName = 'Profile';

    /**
     * @type {Array}
     * @private
     */
    _fullUriRequestTypes = ['ByPath', 'SolrSearch'];

    constructor(ctx) {
        this._ctx = ctx;
        this._languageCode = ctx.app.i18n.locale;
        this._apiEndpoint = ctx.env.CMS_API_ENDPOINT;
        this._api = ctx.app.$api;
        this._axios = ctx.app.$axios;
        this._axios.defaults.withCredentials = true;
    }

    /**
     *
     * @returns {apiList}
     */
    get ctx() {
        return this._ctx;
    }

    /**
     *
     * @returns {apiList}
     */
    get api() {
        return this._api;
    }

    /**
     * @returns {string}
     */
    get apiEndpoint() {
        return this._apiEndpoint;
    }

    /**
     * @returns {string}
     */
    get languageCode() {
        return this._languageCode;
    }

    /**
     * @returns {string}
     */
    get profileCookieName() {
        return this._profileCookieName;
    }

    /**
     * @returns {string}
     */
    set languageCode(languageCode) {
        this._languageCode = languageCode;
    }

    /**
     * Start the login process
     */
    startAuthentication() {
        const currentUri = this.ctx.app.router.history.current.path.substr(1);
        window.location = `${this.apiEndpoint}/auth/connect/authorize?redirect=${currentUri}`;
    }

    getAuthenticationLink() {
        const currentUri = this.ctx.app.router.history.current.path.substr(1);
        return `${this.apiEndpoint}/auth/connect/authorize?redirect=${currentUri}`;
    }

    /**
     * Logs out the current user by emptying the store and redirecting to the api to delete the cookies.
     * @returns {Promise<*>}
     */
    async logout() {
        const response = await this._axios.get(`${this.apiEndpoint}/signout-oidc`);
        this.ctx.store.commit('authentication/setIsLoggedIn', false);
        this.ctx.store.commit('authentication/setProfile', null);
        return response;
    }

    /**
     * Validate Authentication.
     */
    validateAuthentication() {
        const profileCookie = Cookies.get(this.profileCookieName);

        if (profileCookie) {
            this.ctx.store.dispatch('authentication/fetchProfile', this);
        }
    }

    /**
     * Builds SubRequest objects.
     * @param id
     * @param requestObject {RequestObject}
     */
    buildSubRequestObjectFromRequest(id, requestObject) {
        if (requestObject._params && requestObject._params.filter === undefined) {
            requestObject._params.filter = {};
        }

        if (requestObject._hasLangcode) {
            requestObject._params.filter.langcode = this.languageCode;
        }
        let uri = `/${this.languageCode}/api${requestObject._uri}${this.paramsToQueryString(requestObject._params)}`;

        if (requestObject._type === 'TranslatePathRequest') {
            uri = `${requestObject._uri}${this.paramsToQueryString(requestObject._params)}`;
        }

        const subQuery = {
            requestId: id,
            action: requestObject._action,
            uri,
            headers: [
                {
                    Accept: 'application/vnd.application+json',
                },
            ],
        };
        if (!isEmpty(requestObject._waitFor)) {
            subQuery.waitFor = requestObject._waitFor;
        }

        return subQuery;
    }

    /**
     * Queries multiple requests.
     * @param requests
     * @returns {Promise<void>}
     */
    async subRequests(requests = {}) {
        const subRequestsBody = [];
        forEach(requests, (request, key) => {
            subRequestsBody.push(this.buildSubRequestObjectFromRequest(key, request));
        });

        const url = `${this.apiEndpoint}/${this.languageCode}/subrequests?_format=json`;

        const response = await this._axios.post(url, subRequestsBody);
        return this.parseResponse(response, 'subrequest');
    }

    /**
     * Converts param object to query string.
     * @param params
     * @returns {string}
     */
    paramsToQueryString(params) {
        return params && qs.stringify(params).length
            ? '?' + qs.stringify(params, { indices: true, encodeValuesOnly: true })
            : '';
    }

    /**
     * Executes a request to the API.
     * @param api {string}
     * @param endpoint {string}
     * @param params {Object}
     * @param payload {Object}
     * @param throwError {Boolean}
     * @returns {Promise<void>}
     */
    async execute(api, endpoint, params = {}, payload = null, throwError = true) {
        if (this.api[api] && this.api[api][endpoint]) {
            /**
             * @var request Request
             */
            const request = this.api[api][endpoint](params);

            let response;
            let uri = `${this.apiEndpoint}/${this.languageCode}/api${request._uri}`;
            switch (request._action) {
                case 'view': {
                    if (request._params && request._params.filter === undefined) {
                        request._params.filter = {};
                    }

                    // request._params.filter.langcode = this.languageCode;

                    const query = this.paramsToQueryString(request._params);

                    if (request._type === 'TranslatePathRequest') {
                        uri = `${this.apiEndpoint}/${request._uri}`;
                    } else if (this._fullUriRequestTypes.includes(request._type)) {
                        uri = request._uri;
                        response = await this.get(uri);
                        break;
                    } else if (request._type === 'ByPathRequest') {
                        uri = request._uri;
                    }

                    response = await this.get(uri, query, request._headers);

                    break;
                }

                case 'create':
                    response = await this.post(uri, request._body, request._headers);
            }

            this.validateResponse(response);
            if (response) return this.parseResponse(response, request._type);
        }

        throw new ReferenceError(`Endpoint ${endpoint} does not exist for api ${api}`);
    }

    /**
     * Parses the response according to the request type.
     * @param response
     * @param requestType
     */
    parseResponse(response, requestType = null) {
        const nonJsonApiRequests = ['TranslatePathRequest'];

        if (requestType === 'SolrSearch') {
            return response;
        }

        if (requestType === 'subrequest') {
            const parsedResults = {};
            forEach(response.data, (data, subRequestId) => {
                try {
                    const parsedBody = JSON.parse(data.body);
                    parsedResults[subRequestId] = parsedBody.data ? jsonapiParse.parse(parsedBody) : parsedBody;
                    parsedResults[subRequestId].status = data.headers.status.shift();
                } catch (e) {
                    console.error('JSON Parse Error', e, data);
                    this.ctx.$externalLog.captureException(e);
                }
            });

            response.data = parsedResults;
        } else if (requestType === 'SolrSearch') {
            response.data = jsonapiParse.parse(response);
        } else if (!nonJsonApiRequests.includes(requestType)) {
            response.data = jsonapiParse.parse(response.data);
        }

        return response.data;
    }

    validateResponse(response) {
        if (response && response.status >= 400) {
            throw new HTTPError(response.status, response.message);
        }
    }

    /**
     * Execute a GET request.
     * @param url
     * @param query
     * @returns {Promise<void>}
     */
    async get(url, query = '', headers = {}) {
        try {
            return await this._axios.get(`${url}${query}`, { headers });
        } catch (e) {
            return e.response;
        }
    }

    /**
     * Execute a POST request.
     * @param url
     * @param body
     * @returns {Promise<*>}
     */
    async post(url, body, headers = {}) {
        try {
            return await this._axios.post(url, body, { headers });
        } catch (e) {
            return e.response;
        }
    }
}
