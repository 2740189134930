<template>
    <div>
        <el-breadcrumb
            v-if="breadcrumb && breadcrumb.length > 1"
            :aria-label="$t('a11y.breadcrumb')"
            class="breadcrumb"
            separator="/"
        >
            <el-breadcrumb-item
                v-for="(item, index) in breadcrumb"
                :key="index"
                class="breadcrumb__item"
                :class="{ 'is-last': breadcrumb.length === index + 1 }"
                :to="{ path: `/${$i18n.locale}${item.attributes.path}` }"
            >
                <a
                    v-if="`/${$i18n.locale}${item.attributes.path}` !== currentRouteName"
                    :href="`/${$i18n.locale}${item.attributes.path}`"
                    >{{ dotify(item.attributes.title) }}</a
                >
                <span v-else>{{ dotify(item.attributes.title) }}</span>
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            breadcrumb: state => state.breadcrumb.breadcrumb,
        }),
        currentRouteName() {
            return this.$router.currentRoute.path;
        },
    },

    methods: {
        dotify(txt) {
            const maxCaracter = 22;
            return txt.length > maxCaracter ? txt.substr(0, maxCaracter) + '...' : txt;
        },
    },
};
</script>

<style lang="scss">
.breadcrumb {
    display: flex;
    align-items: center;

    @include media(xs) {
        display: block;
    }

    &__item {
        font-size: rem(15px);
        font-weight: 400;

        &:not(:last-child) {
            @include media(xs) {
                margin-bottom: 5px;
            }
        }

        &:first-letter {
            text-transform: capitalize;
        }

        &.is-last {
            pointer-events: none;

            .is-link {
                text-decoration: none;
            }
        }

        span.is-link a {
            color: $--color-primary;
            text-decoration: underline;
            font-weight: 400;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
