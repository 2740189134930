<template>
    <header class="c-block-header">
        <div ref="headerWrapper" class="c-block-header_wrapper">
            <div ref="headerInner" class="c-block-header_inner">
                <block-alerts-carousel
                    v-if="alerts && alerts.length && hasAlerts"
                    ref="alerts"
                    class="c-block-header_alerts"
                    :items="alerts"
                    :auto-play="true"
                    @onClose="onCloseAlerts"
                />
                <block-header-bar ref="headerBar" />
                <div class="c-block-header_menu">
                    <block-menu v-if="navigation.length" :navigation="navigation" />
                    <!-- Header mobile bottom -->
                    <div class="c-block-header_bottom hide-from-header">
                        <ui-cta
                            class="-inline"
                            :url="'/' + this.$i18n.locale + '/langue'"
                            :title="$t('header.language')"
                            type="internal"
                            :label="$t('header.language')"
                        />
                        <ui-cta
                            class="-inline"
                            :url="'/' + this.$i18n.locale + '/emplois'"
                            :title="$t('header.emplois')"
                            type="internal"
                            :label="$t('header.emplois')"
                        />
                        <ui-cta
                            type="line"
                            :title="$t('header.portailCitoyen')"
                            :label="$t('header.portailCitoyen')"
                            size="rounded"
                            :url="portalUrl"
                        />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { gsap } from 'gsap';
import { mapState } from 'vuex';

import BlockAlertsCarousel from '~/components/locomotive/block-carousel-alerts.vue';
import BlockHeaderBar from '~/components/locomotive/block-header-bar.vue';
import BlockMenu from '~/components/locomotive/block-menu.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';

export default {
    components: {
        BlockMenu,
        BlockHeaderBar,
        BlockAlertsCarousel,
        UiCta,
    },
    props: {
        alerts: {
            type: Array,
            default: () => [],
        },
        navigation: {
            type: Array,
            default: () => [],
        },
    },
    data: () => {
        return {
            hasAlerts: false,
            portalUrl: process.env.LONGUEUIL_PORTAL_DOMAIN,
        };
    },
    computed: {
        ...mapState('locomotive-global', ['metrics', 'breakpoints']),
    },
    created() {
        if (this.alerts?.length) {
            this.hasAlerts = true;
        }
    },
    methods: {
        onCloseAlerts() {
            if (this.alerts?.length && this.hasAlerts) {
                const alertBarHeight = this.$refs.alerts.$el.offsetHeight;
                let translateValue = alertBarHeight * -1;

                if (this.$refs.alerts.$el.offsetWidth < 1270) {
                    translateValue = translateValue * -1;
                }

                this.animeAlerts(this.$refs.alerts.$el, translateValue, () => {
                    this.hasAlerts = false;
                });
            }
        },

        animeAlerts($el, translateY, callback = () => {}) {
            gsap.to($el, {
                y: translateY,
                duration: 0.6,
                ease: 'quart.out',
                onComplete: callback,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-header {
    height: var(--header-height);

    &_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        height: calc((var(--vh, 1vh) * 100));
        color: $loco-tonic-dark;
        z-index: $loco-zindex-header;
        pointer-events: none;

        &:before {
            display: none;

            @media (max-width: $to-header) {
                display: block;
                content: '';
                @include u-stretch;
                background-color: $loco-neutral-darker;
                transform: translate3d(0, -100%, 0);
                transition: transform $loco-speed $loco-easing 0.1s;

                .is-menu-open & {
                    transform: translate3d(0, 0, 0);
                    transition: transform $loco-speed $loco-easing;
                }
            }
        }

        @media (min-width: $from-header) {
            &:after {
                content: '';
                @include u-stretch;
                min-height: 200vh;
                background-color: #000;
                opacity: 0;
                z-index: -1;
                transition: opacity $loco-speed $loco-easing;

                .has-menu-overlay & {
                    opacity: 0.7;
                }
            }
        }
    }
}

.c-block-header_bottom {
    @media (max-width: $to-header) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: loco-rem(10px);
        padding-right: loco-rem(5px);
        color: $loco-light;
    }
}

.c-block-header_inner {
    @media (max-width: $to-header) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }

    @media (min-width: $from-header) {
        pointer-events: auto;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.03);
    }
}

.c-block-header_menu {
    pointer-events: auto;

    @media (max-width: $to-header) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        opacity: 0;
        visibility: hidden;
        transition: opacity $loco-speed $loco-easing, visibility $loco-speed $loco-easing;

        .is-menu-open & {
            opacity: 1;
            visibility: visible;
            transition: opacity $loco-speed $loco-easing 0.1s, visibility $loco-speed $loco-easing 0.1s;
        }
    }
}

.c-block-header_alerts {
    position: absolute;
    width: 100%;
    left: 0;
    pointer-events: auto;

    @media (max-width: $to-header) {
        bottom: 0;
    }

    @media (min-width: $from-header) {
        top: var(--header-height);
        z-index: -1;
    }
}
</style>
