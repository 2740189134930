export default (ctx, inject) => {
    const solrUrlBuilder = {
        buildUri: (uri, filters = {}) => {
            let newUri = uri;
            try {
                newUri = filters.search ? newUri.concat(filters.search) : newUri;
                if (filters.dates && filters.dates.type) {
                    if (filters.dates.start !== null) {
                        newUri = newUri.concat('&' + filters.dates.type + '[min]=' + filters.dates.start);
                    }
                    if (filters.dates.end !== null) {
                        newUri = newUri.concat('&' + filters.dates.type + '[max]=' + filters.dates.end);
                    }
                }
                if (filters.facets) {
                    for (let i = 0; i < filters.facets.length; i++) {
                        const facet = '&f[' + i + ']=' + filters.facets[i];
                        newUri = newUri.concat(facet);
                    }
                }
            } catch (e) {
                ctx.$externalLog.captureException(e);
            }
            return newUri;
        },
    };

    inject('solrUrlBuilder', solrUrlBuilder);
};
