<template>
    <div>
        <div v-show="ready">
            <button
                ref="prevButton"
                class="caroussel-base__slider-arrow caroussel-base__slider-arrow--left"
                :class="[
                    `slider-arrow--left-${$slugify(name)}`,
                    slides.length > slidesPerView ? '' : 'caroussel-base__slider-arrow--hidden-desktop',
                    slides.length > 1 ? '' : 'caroussel-base__slider-arrow--hidden-mobile',
                ]"
                :style="arrowsHeight ? { top: `${arrowsHeight}px` } : {}"
            >
                <img class="caroussel-base__slider-arrow-img" src="~assets/img/arrow.svg" alt="arrow" />
            </button>

            <button
                ref="nextButton"
                class="caroussel-base__slider-arrow caroussel-base__slider-arrow--right"
                :class="[
                    `slider-arrow--right-${$slugify(name)}`,
                    slides.length > slidesPerView ? '' : 'caroussel-base__slider-arrow--hidden-desktop',
                    slides.length > 1 ? '' : 'caroussel-base__slider-arrow--hidden-mobile',
                ]"
                :style="arrowsHeight ? { top: `${arrowsHeight}px` } : {}"
            >
                <img
                    class="caroussel-base__slider-arrow-img caroussel-base__slider-arrow-img--right"
                    src="~assets/img/arrow.svg"
                    alt="arrow"
                />
            </button>
            <div
                v-swiper:mySwiper="swiperOption"
                class="caroussel-base__slider-container"
                role="complementary"
                @slideChangeTransitionEnd="onSlideChange"
            >
                <div class="caroussel-base__slides swiper-wrapper">
                    <div
                        v-for="(slide, index) in slides"
                        :key="index"
                        class="swiper-slide caroussel-base__slide"
                        tabindex="-1"
                    >
                        <div class="focusable-slide" tabindex="-1">
                            <slot name="slide" :slideNb="index"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!ready">
            <LoadingDots :height="450" />
        </div>
    </div>
</template>
<script>
import LoadingDots from '~/components/LoadingDots';
export default {
    components: {
        LoadingDots,
    },
    i18n: {
        messages: {
            fr: {
                prev_slide: `Image précédente {image} de {total}`,
                next_slide: `Image suivante {image} de {total}`,
                progress: 'La diapositive {min} de {max} est affichée',
            },
            en: {
                prev_slide: `Previous image {image} of {total}`,
                next_slide: `Next image {image} of {total}`,
                progress: 'Slide {min} of {max} is displayed',
            },
        },
    },

    props: {
        name: {
            type: String,
            default: '',
            required: true,
        },
        slides: {
            type: Array,
            default() {
                return [];
            },
        },
        arrowsHeight: {
            type: Number,
            default: null,
        },
        slidesPerView: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            ready: false,
            swiperOption: {
                loopedSlides: this.slides.length,
                keyboard: true,
                loop: this.slides.length > this.slidesPerView,
                slidesPerView: this.slidesPerView,
                setWrapperSize: true,
                navigation: {
                    nextEl: `.slider-arrow--right-${this.name}`,
                    prevEl: `.slider-arrow--left-${this.name}`,
                    hideOnClick: false,
                },
                a11y: {
                    enabled: false,
                },
                breakpoints: {
                    767: {
                        slidesPerView: 1,
                        loop: this.slides.length > 1,
                    },
                },
            },
        };
    },
    mounted() {
        this.mySwiper.autoplay.stop();

        setTimeout(() => {
            this.ready = true;
            setTimeout(() => {
                this.setSlidesAttributes();
                this.setButtonsAttributes();
            }, 50);
        }, 1000);
    },
    methods: {
        onSlideChange() {
            this.setSlidesAttributes();
            this.setButtonsAttributes();
            this.setLiveRegion(
                this.$t('progress', {
                    min: this.mySwiper.realIndex + 1,
                    max: this.slides.length,
                }),
            );
            const activeSlide = this.$el.querySelector('.swiper-slide-active .focusable-slide');
            activeSlide.focus({ preventScroll: true });
        },
        setSlidesAttributes() {
            for (let i = 0; i < this.mySwiper.slides.length; i++) {
                this.mySwiper.slides[i].setAttribute('aria-hidden', 'true');
                this.mySwiper.slides[i].setAttribute('tabindex', '-1');
                const tabElements = this.$tabbable(this.mySwiper.slides[i]);
                if (tabElements) {
                    tabElements.forEach(e => {
                        e.setAttribute('tabindex', '-1');
                    });
                }
            }
            const activeSlide = this.$el.querySelector('.swiper-slide-active .focusable-slide');
            if (activeSlide) {
                activeSlide.setAttribute('tabindex', '0');
                const activeTabElements = activeSlide.querySelectorAll('[tabindex="-1"]');
                if (activeTabElements) {
                    activeTabElements.forEach(e => {
                        e.removeAttribute('tabindex');
                    });
                }
            }
        },
        setButtonsAttributes() {
            const prevSlideIndex = this.mySwiper.realIndex - 1 < 0 ? this.slides.length : this.mySwiper.realIndex;
            const prevMsg = this.$t('prev_slide', {
                image: prevSlideIndex,
                total: this.slides.length,
            });
            this.$refs.prevButton.setAttribute('aria-label', prevMsg);

            const nextSlideIndex = this.mySwiper.realIndex + 1 === this.slides.length ? 1 : this.mySwiper.realIndex + 2;
            const nextMsg = this.$t('next_slide', {
                image: nextSlideIndex,
                total: this.slides.length,
            });
            this.$refs.nextButton.setAttribute('aria-label', nextMsg);
        },
        setLiveRegion(value) {
            this.$eventBus.$emit('setLiveRegion', value);
        },
    },
};
</script>
<style lang="scss" scoped>
.focusable-slide {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px;
    // overflow: hidden;
}

.caroussel-base {
    &__slide {
        height: auto;
    }

    &__slider-container {
        position: relative;
        display: flex;
        @include media(xs) {
            margin-top: 40px;
        }
    }

    &__slider-arrow {
        transform: translateY(-50%);
        position: absolute;
        top: calc(50% - 3rem);
        left: rem(-10px);
        cursor: pointer;
        transition: 0.3s all;
        transition-timing-function: ease-in-out;
        z-index: 2;
        border: none;
        box-shadow: $--block-shadow;
        height: rem(48px);
        width: rem(48px);
        padding: 0;
        background-color: $--color-white;

        &:hover {
            opacity: 0.9;
        }

        &--left {
            img {
                transform: rotate(180deg);
            }
            @include media(xs) {
                left: 0;
            }
            @include media(sm) {
                transform: translateY(-100%);
            }
        }

        &--right {
            @include media(xs) {
                left: initial;
                right: 0;
            }
            @include media(sm) {
                transform: translateY(5px);
            }
        }

        &--hidden-desktop {
            @include media(sm) {
                display: none;
            }
        }

        &--hidden-mobile {
            @include media(xs) {
                display: none;
            }
        }
    }
}
</style>
