import Router from 'vue-router';
import Vue from 'vue';

import CmsRouter from '~/pages/CmsRouter';

Vue.use(Router);

export function createRouter() {
    const router = new Router({
        mode: 'history',
        routes: [
            {
                path: '/fr/(.*)?',
                component: CmsRouter,
                name: 'cmsRouter___fr',
            },
            // {
            //     path: '/en/(.*)?',
            //     component: CmsRouter,
            //     name: 'cmsRouter___en',
            // },
            {
                path: '/en/(.*)?',
                redirect: to => '/fr',
                name: 'cmsRouter___en',
            },
            { path: '/fr/(.*)?', component: CmsRouter, alias: '/(.*)?' },
        ],
    });

    router.afterEach((to, from, next) => {
        try {
            window.scrollTo({ top: 0 });
        } catch (e) {}
    });
    return router;
}
