<template>
    <div>
        <Title title="Intérêts"></Title>
        <Row v-for="(item, index) in poll" :key="index" class="poll">
            <el-col span="10" class="poll__question">
                <p>
                    <b>{{ item.question }}</b>
                </p>
                <div v-for="(answer, answerIndex) in item.answers" :key="answerIndex">
                    <el-radio label="answerIndex">{{ answer }}</el-radio>
                </div>
            </el-col>
            <el-col span="10" :offset="4" class="poll__question">
                <p>
                    <b>{{ item.question }}</b>
                </p>
                <el-radio-group>
                    <div v-for="(answer, answerIndex) in item.answers" :key="answerIndex">
                        <el-radio label="answerIndex">{{ answer }}</el-radio>
                    </div>
                </el-radio-group>
            </el-col>
        </Row>
        <Row>
            <el-button round plain type="primary">Mettre à jour</el-button>
        </Row>
    </div>
</template>

<script>
import Row from '~/components/Row';

export default {
    components: { Row },

    props: {
        poll: {
            type: Array,
            default: null,
        },
    },
};
</script>
<style scoped>
.poll {
    margin-bottom: 5rem;
}
</style>
