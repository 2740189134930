<template>
    <ul
        class="socialNetwork"
        :style="`width:${width}; max-width:${maxWidth}; ${center ? 'margin-left: auto; margin-right: auto;' : ''}`"
        :class="{
            'is-vertical': vertical,
            'is-round': round,
            'is-full-name': fullName,
            'is-dark': dark,
        }"
    >
        <li v-for="social in socials" :key="social.abbr">
            <!-- <menu-dropdown id="notifications" :is-popup="true" :width="310"> -->
            <menu-dropdown
                v-if="Array.isArray(social)"
                :id="'socials-top-' + (dark ? 'dark-' : '') + id"
                :is-popup="true"
                :item-list="$i18n.locales"
                :has-arrow="false"
                :width="250"
                placement="bottom-start"
            >
                <span slot="button" class="el-dropdown-link">
                    <img
                        v-if="social[0].icon && social[0].icon.length"
                        class="socialNetwork__ico"
                        :src="social[0].icon"
                        :alt="social[0].title"
                    />
                </span>

                <Custom-link
                    v-for="link in social[1]"
                    slot="content"
                    :key="link.title"
                    :to="link.link"
                    class="socialNetwork__item"
                    custom-link-wrapper-tag="li"
                    primary
                    hide-external
                    external
                >
                    <span class="socialNetwork__txt">{{ link.title }}</span>
                </Custom-link>
            </menu-dropdown>

            <Custom-link
                v-else
                :to="social.link"
                class="socialNetwork__item"
                :hide-external="!!(social.icon && social.icon.length)"
                external
            >
                <img
                    v-if="social.icon && social.icon.length"
                    class="socialNetwork__ico"
                    :src="social.icon"
                    :alt="social.name"
                />
                <span v-if="!social.icon && !social.icon.length" class="socialNetwork__txt">{{
                    fullName ? social.name : social.abbr
                }}</span>
            </Custom-link>
        </li>
    </ul>
</template>

<script>
import { mapState } from 'vuex';
import CustomLink from '~/components/CustomLink.vue';
import MenuDropdown from '~/components/header/MenuDropdown';

export default {
    components: {
        CustomLink,
        MenuDropdown,
    },

    props: {
        id: {
            type: String,
            default: '',
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '100%',
        },
        itemWidth: {
            type: Number,
            default: 40,
        },
        maxWidth: {
            type: String,
            default: '100%',
        },
        center: {
            type: Boolean,
            default: false,
        },
        round: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        fullName: {
            type: Boolean,
            default: false,
        },
        flickr: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState({
            menu: state => state.menu.menu,
        }),
        darkImg() {
            return this.dark ? '-dark' : '';
        },
        socials() {
            const socialAbbr = {
                facebook: 'FB',
                twitter: 'TW',
                instagram: 'INS',
                flickr: 'FLKR',
                youtube: 'YT',
                linkedin: 'LKIN',
            };

            let socialNetworks = [];

            if (this.menu.socialNetworks[this.$i18n.locale]) {
                socialNetworks = this.menu.socialNetworks[this.$i18n.locale].map(sn => {
                    if (!sn.children) {
                        const social = {
                            name: sn.attributes.link.title,
                            link: sn.attributes.link.uri,
                            abbr: socialAbbr[sn.attributes.link.title.toLowerCase()],
                            icon:
                                '/img/social-network/' + sn.attributes.link.title.toLowerCase() + this.darkImg + '.svg',
                        };
                        return social;
                    } else {
                        const socials = [];
                        socials.push({
                            title: sn.attributes.link.title,
                            icon:
                                '/img/social-network/' + sn.attributes.link.title.toLowerCase() + this.darkImg + '.svg',
                        });
                        let links = [];
                        links = sn.children.map(child => {
                            return {
                                title: child.attributes.link.title,
                                link: child.attributes.link.uri,
                            };
                        });
                        socials.push(links);
                        return socials;
                    }
                });
            }

            if (!this.flickr) socialNetworks = socialNetworks.filter(social => social.name !== 'Flickr');

            return socialNetworks;
        },
    },
};
</script>

<style lang="scss">
body *[class*='dropdown__popup-socials'] {
    padding: 12px !important;
}

.socialNetwork {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: rem(14px);
    transition: 0.25s all;
    list-style: none;
    padding-left: 0;

    .el-popper {
        display: flex;
        flex-flow: column;
        overflow: hidden;

        .socialNetwork__txt {
            font-size: rem(14px);
            line-height: 1.4em;
            white-space: nowrap;
        }
    }

    .dropdown__button {
        padding: 0px;
    }

    .el-dropdown {
        &:hover {
            cursor: pointer;
        }
        .socialNetwork__ico {
            opacity: 0.75;
            transition: 0.35s all;
        }

        &:hover .socialNetwork__ico {
            opacity: 1;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
        text-align: left;
        text-decoration: none;
        margin-right: rem(4px);
        margin-bottom: 5px;

        &:last-of-type {
            margin-right: 0;
            margin-bottom: 0;
        }

        .socialNetwork__ico {
            opacity: 0.75;
            transition: 0.35s all;
        }

        &:hover .socialNetwork__ico {
            opacity: 1;
        }
    }

    &__txt {
        margin: auto;
    }

    &.is-vertical {
        flex-flow: column;
    }

    &.is-round {
        .socialNetwork {
            &__item {
                border-radius: 999px;
            }
        }
    }

    &.is-full-name {
        .socialNetwork {
            &__item {
                width: auto;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    &.is-dark {
        .socialNetwork__item {
            opacity: 1;

            &:hover {
                opacity: 0.95;
            }
        }
    }
}
</style>
