export const state = () => ({
    gobalStateExemple: null, // Assurez-vous que ça doit aller ici. Prenez exemple sur menu.js
});

export const actions = {
    async nuxtServerInit({ dispatch }, { app }) {
        await dispatch('menu/fetchMenu', app);
        await dispatch('alertes/fetchAlertes', app);
    },
};
