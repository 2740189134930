class SentryWrapper {
    _sentryContext;

    constructor(sentryContext) {
        this._sentryContext = sentryContext;
    }

    captureException(e, ctx) {
        this._sentryContext.captureException(e, ctx);
    }

    captureMessage(e, ctx) {
        this._sentryContext.captureMessage(e, ctx);
    }
}

export default (ctx, inject) => {
    inject('externalLog', new SentryWrapper(ctx.$sentry));
};
