/* eslint-disable */
export default class {
    constructor(options = {}) {
        // Default settings
        this.defaults = {
            el: document,
            name: 'scroll',
            class: 'is-inview',
            rootMargin: '-1px -1px -1px -1px',
            hasScrollEvent: false,
            scrollCallback: null,
        };
        Object.assign(this, this.defaults, options);

        // Global stuff
        this.listeners = {};
        this.namespace = 'mu';
        this.hasCallEventSet = false;

        // Observer config
        this.isFirstObserve = true;

        // ScrollTo config
        this.isAutoScrolling = false;

        // Scroll values
        this.scrollValue = 0;
        this.scrollDirection = 'down';

        // Binding
        this.checkEvent = this.checkEvent.bind(this);

        // Calls
        this.init();
    }

    /**
     * Initialize
     *
     */
    init() {
        // Query all `data-scroll` elements.
        const $els = this.el.querySelectorAll('[data-' + this.name + ']');

        // Set observer options
        const observerOptions = {
            rootMargin: this.rootMargin,
        };

        // Set observer callback
        const onIntersect = entries => {
            entries.forEach(entry => {
                // define inview options (call, repeat..)
                const options = {
                    repeat: typeof entry.target.dataset[this.name + 'Repeat'] != 'undefined' ? true : false,
                    call:
                        typeof entry.target.dataset[this.name + 'Call'] != 'undefined'
                            ? entry.target.dataset[this.name + 'Call']
                            : false,
                };

                // Then apply view methods
                if (entry.intersectionRatio > 0) {
                    this.setInview(entry, options);
                } else if (!this.isFirstObserve) {
                    this.setOutOfView(entry, options);
                }
            });

            // Define first observe flag
            this.isFirstObserve = false;
        };

        // Create a single IntersectionObserver(IO) that watches all elements
        this.observer = new IntersectionObserver(onIntersect, observerOptions);

        // Observe each elements
        $els.forEach($item => {
            this.observer.observe($item);
        });

        // Bind scroll event
        if (this.hasScrollEvent) {
            this.bindScrollEvent();
        }
    }

    /**
     * Set inview
     *
     * @param {object} :: IntersectionObserver entry | {object} :: Inview options
     */
    setInview(entry, options) {
        entry.target.classList.add(this.class);

        if (options.call) {
            this.dispatchCall(options.call, 'enter', entry);
        }

        // Unobserve entry once they were triggered once, unless it is supposed to repeat
        if (!options.repeat) this.observer.unobserve(entry.target);
    }

    /**
     * Set out of view
     *
     * @param {object} :: IntersectionObserver entry | {object} :: Inview options
     */
    setOutOfView(entry, options) {
        if (options.call && this.hasCallEventSet) {
            this.dispatchCall(options.call, 'exit', entry);
        }

        if (options.repeat) {
            entry.target.classList.remove(this.class);
        }
    }

    /**
     * Manage call
     *
     * @param {string} :: Function string | {object} :: IntersectionObserver entry
     */
    dispatchCall(call, way, entry) {
        this.callWay = way;
        this.callValue = call.split(',').map(item => item.trim());
        this.callObj = entry;

        if (this.callValue.length == 1) this.callValue = this.callValue[0];

        const callEvent = new Event(this.namespace + 'call');
        this.el.dispatchEvent(callEvent);
    }

    /**
     * ScrollTo
     *
     */
    scrollTo(target, options = {}) {
        if (this.isAutoScrolling) return;
        this.isAutoScrolling = true;

        const easeOutQuint = x => {
            return 1 - Math.pow(1 - x, 5);
        };

        ////////////////////
        // ScrollTo settings
        ///////////////////

        // Get scroll element
        const $scrollElement =
            window.document.scrollingElement || window.document.body || window.document.documentElement;

        // Get target
        let $target = null;

        if (typeof target === 'string') {
            const $targets = document.querySelectorAll(target);
            if (!$targets.length) return;
            $target = $targets[0];
        } else if (typeof target === 'object') {
            $target = target;
        }

        if (!$target) return;

        // Get target offset
        const $targetOffsetTop = $target.getBoundingClientRect().top;

        // Get options
        const defaultOptions = {
            offset: 0,
            duration: 2000,
            callback: () => {},
            disableLerp: false,
        };
        const targetOptions = { ...defaultOptions, ...options };

        // Anime Settings
        const startTime = performance.now();
        const from = $scrollElement.scrollTop;
        const to = $targetOffsetTop + parseInt(targetOptions.offset, 10);

        const update = () => {
            const raf = requestAnimationFrame(update);
            const dateNow = performance.now();
            const elapsed = dateNow - startTime;
            const progress = Math.min(elapsed / targetOptions.duration, 1);
            const animatedValue = targetOptions.disableLerp ? from + progress * to : from + easeOutQuint(progress) * to;

            $scrollElement.scrollTop = animatedValue;

            if (progress >= 1) {
                cancelAnimationFrame(raf);
                targetOptions.callback();
                this.isAutoScrolling = false;
            }
        };

        // Start animation
        update();
    }

    /**
     * Set events
     *
     * @param
     */
    setEvents(event, func) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }

        const list = this.listeners[event];
        list.push(func);

        if (list.length === 1) {
            this.el.addEventListener(this.namespace + event, this.checkEvent, false);
        }

        if (event === 'call') {
            this.hasCallEventSet = true;
        }
    }

    /**
     * Check events
     *
     * @param
     */
    checkEvent(event) {
        const name = event.type.replace(this.namespace, '');
        const list = this.listeners[name];

        if (!list || list.length === 0) return;

        list.forEach(func => {
            switch (name) {
                case 'scroll':
                    return func(this.instance);
                case 'call':
                    return func(this.callValue, this.callWay, this.callObj);
                default:
                    return func();
            }
        });
    }

    /**
     * Receive events to set
     *
     * @param
     */
    on(event, func) {
        this.setEvents(event, func);
    }

    /**
     * Receive events to kill
     *
     * @param
     */
    off(event, func) {
        this.unsetEvents(event, func);
    }

    /**
     * Bind scroll event
     *
     */
    bindScrollEvent() {
        this.onScroll = this.onScroll.bind(this);
        document.addEventListener('scroll', this.onScroll);
    }

    /**
     * Unbind scroll event
     *
     */
    unbindScrollEvent() {
        document.removeEventListener('scroll', this.onScroll);
    }

    /**
     * On scroll callback
     * @param
     */
    onScroll(event) {
        // Set direction
        const distance = window.scrollY - this.scrollValue;
        const direction = distance / Math.abs(distance) || 1;
        this.scrollDirection = direction > 0 ? 'down' : 'up';

        // Call callback
        this.scrollCallback && this.scrollCallback(event);

        // Set last scroll position
        this.scrollValue = window.scrollY;
    }

    /**
     * Destroy
     * Disconect observer and remove events!
     */
    destroy() {
        this.observer.disconnect();

        Object.keys(this.listeners).forEach(event => {
            this.el.removeEventListener(this.namespace + event, this.checkEvent, false);
        });
        this.listeners = {};

        this.unbindScrollEvent();
    }
}
