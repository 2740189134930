<template>
    <ui-cta-wrapper
        class="c-ui-cta"
        :type="type"
        :link-type="linkType"
        :url="url"
        :title="title"
        :class="[typeClass, sizeClass, colorClass, { '-icon': iconType }, { '-label': label }]"
        @onClick="onClick"
    >
        <span v-if="label" :class="[labelClass]" class="c-ui-cta_label || c-text">
            <span v-html="label"></span>
            <span v-if="type === 'button'" aria-hidden="true" v-html="label"></span>
        </span>
        <span v-if="iconType" ref="icon" class="c-ui-cta_icon">
            <ui-icon :type="iconType" />
        </span>
    </ui-cta-wrapper>
</template>

<script>
import UiCtaWrapper from '~/components/locomotive/ui-cta-wrapper.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';
export default {
    components: { UiIcon, UiCtaWrapper },
    props: {
        type: {
            type: String,
            required: true,
        },
        linkType: {
            type: String,
            default: '',
        },
        iconType: {
            type: String,
            default: '',
        },
        iconWidth: {
            type: Number,
            default: 0,
        },
        iconHeight: {
            type: Number,
            default: 0,
        },
        url: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: 'medium',
        },
        color: {
            type: String,
            default: '',
        },
    },
    data: () => {
        return {};
    },
    computed: {
        typeClass() {
            return this.type ? `-${this.type}` : '';
        },
        sizeClass() {
            return this.size ? `-${this.size}` : '';
        },
        colorClass() {
            return this.color ? `-${this.color}` : '';
        },
        labelClass() {
            switch (this.type) {
                case 'button':
                    switch (this.size) {
                        case 'medium':
                            return '-button';
                        case 'tiny':
                            return '-caption';
                        case 'small':
                            return '-button-small';
                        case 'rounded':
                            return '-button';
                        case 'wide':
                            return 'c-heading -h6';
                    }
                    break;
                case 'link':
                    switch (this.size) {
                        case 'medium':
                            return '-body2';
                        case 'tiny':
                            return '-caption';
                        case 'small':
                            return '-body1';
                        case 'large':
                            return '-button';
                        case 'wide':
                            return 'c-heading -h6';
                    }
                    break;
                case 'internal':
                    switch (this.size) {
                        case 'medium':
                            return '-body2';
                        case 'tiny':
                            return '-caption';
                        case 'small':
                            return '-body1';
                        case 'large':
                            return '-button';
                        case 'wide':
                            return 'c-heading -h6';
                    }
                    break;
                case 'external':
                    switch (this.size) {
                        case 'medium':
                            return '-body2';
                        case 'tiny':
                            return '-caption';
                        case 'small':
                            return '-body1';
                        case 'large':
                            return '-button';
                        case 'wide':
                            return 'c-heading -h6';
                    }
                    break;
            }
            return '';
        },
    },

    mounted() {
        if (this.iconWidth && this.iconHeight) {
            this.$refs.icon.style.width = `${this.iconWidth}px`;
            this.$refs.icon.style.height = `${this.iconHeight}px`;
        }
    },

    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-cta {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    .c-block-footer_section & {
        color: $loco-light;
    }

    &:not(.-label) {
        font-size: 0;
        line-height: 0;
    }

    &.-button {
        &.-label {
            border-radius: 8px 8px 8px 0px;
            overflow: hidden;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            &:after {
                transform: translate3d(0, 100%, 0);
                transition: transform $loco-speed $loco-easing;
            }

            &:hover {
                &:after {
                    transform: translate3d(0, 0, 0);
                }

                .c-ui-cta_label {
                    span {
                        transform: translate3d(0, -100%, 0);
                    }
                }

                .c-ui-cta_icon {
                    transform: translate3d(-25%, -50%, 0);
                }

                &.-neutralDark {
                    color: $loco-neutral-darker;
                    &.-label {
                        &:before {
                            background-color: $loco-neutral-medium;
                        }
                    }
                }
            }

            .c-ui-cta_icon {
                transition: transform $loco-speed $loco-easing;
            }

            // Sizes
            &.-small {
                padding: loco-rem(12px) loco-rem(14px);

                &.-icon {
                    padding-right: loco-rem(60px);
                }
            }

            &.-medium {
                padding: loco-rem(16px) loco-rem(24px);

                &.-icon {
                    padding-right: loco-rem(80px);
                }
            }

            &.-rounded {
                padding: loco-rem(8px) loco-rem(16px);
                border-radius: 100px;

                &.-icon {
                    padding-right: loco-rem(44px);
                }
            }

            // Colors
            &.-light {
                color: $loco-dark;
                &.-label {
                    &:before {
                        background-color: $loco-light;
                    }
                }
            }

            &.-tonicDark {
                color: $loco-light;
                &.-label {
                    &:before {
                        background-color: $loco-tonic-dark;
                    }
                }
            }

            &.-tonic {
                color: $loco-light;
                &.-label {
                    &:before {
                        background-color: $loco-tonic;
                    }
                }
            }

            &.-neutral {
                color: $loco-dark;
                &.-label {
                    &:before {
                        background-color: $loco-neutral;
                    }
                }
            }

            &.-neutralDark {
                color: $loco-neutral-medium;
                transition: color $loco-speed $loco-easing;
                &.-label {
                    &:before {
                        background-color: $loco-neutral-darker;
                        transition: background-color $loco-speed $loco-easing;
                    }
                }
            }
        }

        &:not(.-label) {
            // Colors
            &.-light {
                color: $loco-light;
            }

            &.-tonicDark {
                color: $loco-tonic-dark;
            }

            &.-tonic {
                color: $loco-tonic;
            }

            &.-neutral {
                color: $loco-neutral;
            }

            &.-neutralDark {
                color: $loco-neutral-medium;
            }
        }
    }

    &.-link,
    &.-external,
    &.-internal {
        position: relative;

        &.-label {
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: currentColor;
                transition: transform $loco-speed $loco-easing;
                transform-origin: right center;
                transform: scale3d(0, 1, 1);
            }

            &:hover {
                &::before {
                    transform-origin: left center;
                    transform: scale3d(1, 1, 1);
                }
            }

            // Sizes
            &.-small {
                &.-icon {
                    padding-right: loco-rem(24px);
                }
            }

            &.-medium {
                padding-bottom: loco-rem(4px);
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    opacity: 0.2;
                    background-color: $loco-grey-dark;
                    z-index: -1;
                }

                &.-icon {
                    padding-right: loco-rem(48px);
                }
            }
        }

        &.-light {
            color: $loco-light;

            &.-label {
                &:before {
                    background-color: $loco-light;
                }
            }
        }

        // Colors
        &.-tonicDark {
            color: $loco-tonic-dark;

            &.-label {
                &:before {
                    background-color: $loco-tonic-dark;
                }
            }
        }

        &.-tonic {
            color: $loco-tonic;

            &.-label {
                &:before {
                    background-color: $loco-tonic;
                }
            }
        }

        &.-neutral {
            color: $loco-neutral;

            &.-label {
                &:before {
                    background-color: $loco-neutral;
                }
            }
        }

        &.-neutralDark {
            color: $loco-neutral-medium;

            &.-label {
                &:before {
                    background-color: $loco-neutral-medium;
                }
            }
        }
    }

    &.-inline {
        padding-bottom: 0 !important;

        &::before,
        &::after {
            display: none;
        }

        .c-ui-cta_label {
            @include fontFamily($loco-font-bold, $loco-font-family-fallback);
            font-size: loco-rem(16px);
            line-height: 1.2;
            letter-spacing: -0.01em;
        }

        .c-ui-cta_icon + & {
            margin-left: rem(8px);
        }

        .-inline + & {
            margin-left: rem(18px);
        }
    }
}

.c-ui-cta_label {
    position: relative;
    overflow: hidden;
    z-index: 1;

    span {
        display: block;
        transition: transform $loco-speed $loco-easing;

        &:nth-of-type(2) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.c-ui-cta_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .c-ui-cta:not(.-label) & {
        width: loco-rem(24px);
        height: loco-rem(24px);
    }

    .c-ui-cta:not(.-label).-wide & {
        width: loco-rem($loco-margin-medium);
        height: loco-rem($loco-margin-medium);
    }

    .c-ui-cta.-label & {
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
        z-index: 1;
    }

    .c-ui-cta.-label.-small &,
    .c-ui-cta.-label.-rounded & {
        width: loco-rem(10px);
        height: loco-rem(10px);
    }

    .c-ui-cta.-label.-medium & {
        width: loco-rem(15px);
        height: loco-rem(15px);
    }

    .c-ui-cta.-label.-button.-small & {
        right: loco-rem(16px);
    }

    .c-ui-cta.-label.-button.-rounded & {
        right: loco-rem(14px);
    }

    .c-ui-cta.-label.-button.-medium & {
        right: loco-rem(28px);
    }
}
</style>
