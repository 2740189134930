<template>
    <footer class="c-block-footer || u-color-light">
        <div class="o-container">
            <div class="c-block-footer_section -top">
                <div class="o-layout -gutter-small">
                    <div class="o-layout_item u-5/12@from-medium">
                        <block-newsletter-push
                            v-if="pushNewsletter"
                            :category="pushNewsletter.category"
                            :title="pushNewsletter.title"
                            :link-label="pushNewsletter.linkLabel"
                            :link-url="pushNewsletter.linkUrl"
                            :link-type="pushNewsletter.linkType"
                            theme="light"
                        />
                    </div>
                    <div
                        v-for="(linksList, index) in linksLists"
                        :key="index"
                        :class="[{ 'u-1/4@from-medium': index % 2 === 1 }, { 'u-1/3@from-medium': index % 2 === 0 }]"
                        class="c-block-footer_nav_wrapper || o-layout_item"
                    >
                        <div class="c-block-footer_nav">
                            <h2 class="c-text -overline || u-color-white">
                                {{ linksList.title }}
                            </h2>
                            <nav :aria-label="$t('a11y.menu.footerMenu') + linksList.title">
                                <ul class="c-block-footer_nav_list || u-mt-5">
                                    <li v-for="(link, linkIndex) in linksList.links" :key="linkIndex">
                                        <ui-cta
                                            :url="link.url"
                                            :label="link.label"
                                            :title="link.label"
                                            :link-type="link.type"
                                            class="u-mt-2"
                                            color="light"
                                            type="link"
                                            size="small"
                                        />
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="c-block-footer_section -middle">
                <div class="o-layout -gutter-small">
                    <div class="c-block-cta_wrapper || o-layout_item u-1/2@from-small u-5/12@from-medium">
                        <block-cta
                            :icon-type="phoneCta.icon"
                            :description="phoneCta.description"
                            :links="phoneCta.links"
                        />
                    </div>
                    <div class="c-block-cta_wrapper || o-layout_item u-1/2@from-small u-1/3@from-medium">
                        <block-cta
                            :icon-type="addressCta.icon"
                            :description="addressCta.description"
                            :links="addressCta.links"
                        />
                    </div>
                    <div class="o-layout_item u-1/2@from-small u-1/4@from-medium">
                        <block-socials :links="socials" />
                    </div>
                </div>
            </div>
            <div class="c-block-footer_section -bottom">
                <div class="o-layout -gutter-small -middle">
                    <div class="o-layout_item u-5/12@from-medium"><!-- empty --></div>
                    <div class="o-layout_item u-1/3@from-medium">
                        <ul class="c-block-footer_legal_nav">
                            <li v-for="(link, linkIndex) in linksListBottom" :key="linkIndex">
                                <ui-cta
                                    :url="link.url"
                                    :label="link.label"
                                    :title="link.label"
                                    :type="link.type"
                                    class="u-mt-2"
                                    color="light"
                                    size="tiny"
                                    :data-cc="link.url==='#'? 'c-settings':''" aria-haspopup="dialog"
                                />
                            </li>
                        </ul>
                    </div>
                    <div class="o-layout_item u-1/4@from-medium">
                        <div class="c-block-footer_legal_content">
                            <p class="c-block-footer_legal_copyright || c-text -caption">
                                {{ copyright }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import BlockCta from '~/components/locomotive/block-cta.vue';
import BlockNewsletterPush from '~/components/locomotive/block-newsletter-push.vue';
import BlockSocials from '~/components/locomotive/block-socials.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';

export default {
    components: {
        BlockNewsletterPush,
        BlockSocials,
        BlockCta,
        UiCta,
    },
    props: {
        linksLists: {
            type: Array,
            default: () => [],
        },
        linksListBottom: {
            type: Array,
            default: () => [],
        },
        phoneCta: {
            type: Object,
            default: () => {},
        },
        addressCta: {
            type: Object,
            default: () => {},
        },
        socials: {
            type: Array,
            default: () => [],
        },
        copyright: {
            type: String,
            default: '',
        },
        pushNewsletter: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-footer {
    background-color: $loco-tonic-dark;
    color: $loco-light;

    @media (max-width: $to-small) {
        padding-top: loco-rem($loco-margin-wide-mobile);
    }

    @media (min-width: $from-small) {
        padding-top: loco-rem($loco-margin-wide);
    }
}

.c-block-footer_nav_wrapper {
    @media (max-width: $to-medium) {
        width: 50%;
    }
}

.c-block-cta_wrapper {
    & + .c-block-cta_wrapper {
        @media (max-width: $to-small) {
            padding-top: loco-rem(40px);
        }
    }
}

.c-block-footer_section {
    &.-middle {
        @media (max-width: $to-medium) {
            padding-top: loco-rem(68px);
            padding-bottom: loco-rem($loco-margin-medium-mobile);
        }

        @media (min-width: $from-medium) {
            padding-top: loco-rem($loco-margin-wide);
            padding-bottom: loco-rem($loco-margin-medium);
        }
    }

    &.-bottom {
        position: relative;
        padding-bottom: loco-rem(24px);
        border-top: 1px solid $loco-neutral;

        @media (max-width: $to-medium) {
            padding-top: loco-rem(22px);
        }

        @media (min-width: $from-medium) {
            padding-top: loco-rem($loco-margin-wide);
        }
    }
}

.c-block-footer_legal_nav {
    display: flex;
    font-size: 0;
    padding-bottom: loco-rem(20px);

    @media (max-width: $to-tiny) {
        justify-content: space-between;
    }
    @media (min-width: $from-tiny) and (max-width: $to-medium) {
        justify-content: center;
    }
    @media (min-width: $from-medium) {
        padding-bottom: loco-rem(2px);
    }

    li {
        margin-right: loco-rem(32px);

        &:last-child {
            margin-right: 0;
        }
    }
}

.c-block-footer_legal_content {
    display: flex;
    align-items: baseline;
    justify-content: center;

    @media (min-width: $from-medium) {
        &:before {
            content: '';
            display: block;
        }
    }
}

.c-block-footer_legal_credits {
    line-height: loco-rem(24px);

    @media (max-width: $to-tiny) {
        width: 50%;
    }
}

.c-block-footer_legal_copyright {
    @media (max-width: $to-tiny) {
        width: 50%;
    }

    @media (min-width: $from-medium) {
        position: absolute;
        bottom: loco-rem(25px);
        left: 0;
    }
}

.c-block-footer {
    .c-block-socials {
        @media (max-width: $to-medium) {
            padding-top: loco-rem($loco-margin-medium);
        }
    }
}
</style>
