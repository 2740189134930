<template>
    <content-details
        :title="item.title"
        :infos="item.infos"
        :type-tags="typeTags"
        :more-link="{ txt: $t('events.backToEvents'), url: $t('events.url') }"
        class="single-event"
    >
        <template>
            <!-- Social -->
            <Container max-width="650px" big-padding>
                <SocialMedias
                    :title="item.title"
                    :tag="item.typeTags"
                    :url="$nuxt.$route.path"
                    :description="
                        item.body.processed.length < 50 ? item.body.processed : item.body.processed.substr(50) + '...'
                    "
                ></SocialMedias>
            </Container>

            <!-- WYSIWYG (Contenu) -->
            <Container
                v-if="item.body && item.body.processed"
                class="item__wysiwyg item__wysiwyg--first"
                max-width="650px"
                big-padding
            >
                <wysiwyg :body="item.body.processed" />
            </Container>

            <!-- Info box -->
            <Container class="item__infos" max-width="840px">
                <div class="item__infos__box">
                    <Row gutter>
                        <el-col v-if="dates.dateStart && dates.dateEnd" :span="12" :sm="8">
                            <div class="card__item">
                                <img
                                    class="item__infos-icone"
                                    src="~assets/img/calendar.png"
                                    :alt="$t('a11y.alternative.date')"
                                />

                                <div class="card__item__content">
                                    <div v-if="isSingleDay(dates.dateStart, dates.dateEnd)">
                                        {{ this.$t('events.frequences.onThe') }}
                                        {{ $moment.tz(dates.dateStart, 'America/New_York').format('DD MMMM') }}
                                    </div>

                                    <div v-else>
                                        <span class="start">
                                            {{ this.$t('events.from') }}
                                            {{ $moment.tz(dates.dateStart, 'America/New_York').format('DD MMMM') }}
                                        </span>

                                        <span class="end">
                                            {{ this.$t('events.to') }}
                                            {{ $moment.tz(dates.dateEnd, 'America/New_York').format('DD MMMM') }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="card__item">
                                <img
                                    class="item__infos-icone flex-icone"
                                    src="~assets/img/clock.png"
                                    :alt="$t('a11y.alternative.schedule')"
                                />
                                <div class="card__item__content">
                                    <span class="time">
                                        {{ this.$t('events.starting') }}
                                        {{ $moment.tz(dates.dateStart, 'America/New_York').format('HH:mm') }}
                                        {{ this.$t('events.end') }}
                                        {{ $moment.tz(dates.dateEnd, 'America/New_York').format('HH:mm') }}
                                    </span>
                                    <span v-if="dates.rrule">{{ recurrence }}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" :sm="8">
                            <div class="card__item">
                                <img
                                    class="item__infos-icone"
                                    src="~assets/img/cost.png"
                                    :alt="$t('a11y.alternative.cost')"
                                />
                                <div class="card__item__content">
                                    {{ costs }}
                                </div>
                            </div>
                            <div class="card__item">
                                <img class="item__infos-icone" src="~assets/img/informations.png" alt="" />
                                <div v-if="item.link" class="card__item__content">
                                    <span class="card__more-informations"
                                        >{{ this.$t('events.sideInfos.moreInfos') }} :</span
                                    >
                                    <span>
                                        <custom-link :to="item.link.uri" external underline hide-external>{{
                                            item.link.uri
                                        }}</custom-link>
                                    </span>
                                </div>
                                <div v-else class="card__item__content">
                                    <span>{{ $t('events.sideInfos.noInfos') }}</span>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" :sm="8">
                            <div v-if="item.place" class="card__item flex">
                                <img
                                    class="item__infos-place"
                                    src="~assets/img/map-marker-info.svg"
                                    :alt="$t('a11y.alternative.address')"
                                />
                                <div class="card__item__content">
                                    <div v-if="item.place.title">
                                        {{ item.place.title }}
                                    </div>
                                    <div v-if="item.place.adresse.address_line1">
                                        {{ item.place.adresse.address_line1 }}
                                    </div>
                                    <div v-if="item.place.adresse.locality && item.place.adresse.administrative_area">
                                        {{ item.place.adresse.locality }},
                                        {{ item.place.adresse.administrative_area }}
                                    </div>
                                    <div v-if="item.place.adresse.postal_code">
                                        {{ item.place.adresse.postal_code }}
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </Row>
                </div>
            </Container>

            <!-- Image -->
            <Container v-if="item.media_image" max-width="840px" class="item__image-wrapper">
                <media-image
                    :image="item.media_image"
                    size="front_large_21_9"
                    fit="cover"
                    class="item__image"
                ></media-image>
            </Container>

            <!-- WYSIWYG (Details) -->
            <Container
                v-if="item.registration_details && item.registration_details.value"
                big-padding
                max-width="650px"
                class="item__wysiwyg"
            >
                <wysiwyg :body="item.registration_details.value" />
            </Container>

            <!-- Linked events -->
            <Container v-if="events.length > 0" class="item__events" max-width="979px">
                <ListCards
                    type="event"
                    :custom-gutter="63"
                    :items="events"
                    :title="`${$t('events.futureEvents')}`"
                    trait
                    :more-link="{
                        txt: $t('events.seeAllEvents'),
                        url: $t('events.url'),
                    }"
                />
            </Container>
        </template>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import CustomLink from '~/components/CustomLink';
import ListCards from '~/components/ListCards';
import MediaImage from '~/components/MediaImage';
import Row from '~/components/Row.vue';
import SocialMedias from '~/components/SocialMedias.vue';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        const scripts = [
            {
                // type: 'text/javascript', // Fix W3C stuff
                src: 'https://addevent.com/libs/atc/1.6.1/atc.min.js',
                async: true,
                defer: true,
            },
        ];
        const head = this.$metaTags.head(this.metaTags);
        head.script = scripts;
        return head;
    },

    components: {
        Container,
        ContentDetails,
        CustomLink,
        ListCards,
        MediaImage,
        Row,
        SocialMedias,
        Wysiwyg,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            image: initData.image,
            events: initData.events,
            dates: initData.dates,
        };
    },

    computed: {
        recurrence() {
            return this.$rruleTranslator.toText(this.dates.rrule);
        },
        costs() {
            return this.item.costs ? this.item.costs : this.$t('events.unknown');
        },
        typeTags() {
            return this.item.event_type
                ? this.item.event_type.map(tag => {
                      return { name: tag.name, to: '#' };
                  })
                : [];
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        await store.dispatch('taxonomy/fetchEvent', app);

        const moment = app.$moment;
        moment.locale(app.i18n.locale);

        const filters = {
            filterByServiceType: {
                condition: {
                    path: `event_type.id`,
                    operator: 'IN',
                    value: item.event_type.map(item => item.id),
                },
            },
            filterNotItSelf: {
                condition: {
                    path: `id`,
                    operator: '<>',
                    value: item.id,
                },
            },
            byRecurringDates: {
                condition: {
                    path: `recurring_dates.value`,
                    operator: '>=',
                    value: moment.tz('America/Toronto').format(),
                },
            },
        };

        // get sorted values by date
        const eventsResults = await app.$client.execute('events', 'getCollection', {
            filters,
            limit: 3,
            include: ['place', 'media_image'],
            sort: 'recurring_dates.value',
        });
        const events = app.$drupalComponentsAdapter.normalizeItems(eventsResults.data);

        const dates = {};

        if (item.recurring_dates && item.recurring_dates !== '') {
            dates.dateStart = item.recurring_dates.value;
            dates.dateEnd = item.recurring_dates.end_value;
            dates.rrule = item.recurring_dates.rrule;
        }

        return { item, events, dates };
    },

    methods: {
        isSingleDay(start, end) {
            const s = start.split('T')[0];
            const e = end.split('T')[0];
            return s === e;
        },
    },
};
</script>

<style lang="scss">
.single-event {
    .item {
        &__events {
            margin-top: 55px;
        }

        &__wysiwyg {
            margin-bottom: 32px;
            &--first {
                margin-bottom: 25px;
            }
        }

        &__related-cards-item {
            margin-bottom: 25px;
        }

        &__image {
            display: flex;
            min-height: 197px;
            height: 62vw;
            margin-left: -20px;
            width: calc(100% + 40px);
            margin-top: 20px;
            margin-bottom: -7px;

            @include media(sm) {
                margin-left: initial;
                width: 100%;
                height: 510px;
                margin-top: 4px;
                margin-bottom: -10px;
            }

            &-wrapper {
                margin-bottom: 16px;
            }
        }

        &__infos {
            margin-top: 10px;
            margin-bottom: 40px;

            @include media(sm) {
                margin-top: 0;
                margin-bottom: 2em;
            }

            &__box {
                box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
                box-sizing: border-box;
                padding: 60px 84px;

                @include media(xs) {
                    padding: 60px 30px 60px 30px;
                }
            }

            &-icone {
                width: 30px;
                max-height: 34px;
                margin-right: 14px;
                margin-bottom: 15px;
                vertical-align: middle;

                @include media(md) {
                    margin-bottom: 0;
                }
            }

            &-place {
                fill: $--color-info;
                color: $--color-info;
                width: 20px;
                max-height: 30px;
                margin-right: 14px;
                margin-bottom: 15px;

                @include media(md) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .item__events {
        margin-top: 66px;

        @include media(sm) {
            margin-top: 80px;
        }

        .items-list .items-list__title {
            font-size: rem(15px);
            font-weight: 500;
            margin-bottom: 4px;
            letter-spacing: 0.9px;
        }

        .el-image {
            @include media(sm) {
                min-height: 190px;
            }
        }
    }

    .container {
        .title.padding {
            @include media(md) {
                width: 100%;
                margin: auto !important;
                max-width: 650px;
            }

            &__sub {
                margin-bottom: 21px;

                & > * {
                    margin-bottom: 0;
                }
            }
        }

        &.no-margin {
            margin: auto !important;
            max-width: 860px;
        }
    }

    .items-list__more .link__txt {
        font-size: rem(15px);
    }
}
</style>

<style lang="scss" scoped>
.content {
    margin-bottom: 20px;

    @include media(sm) {
        margin-bottom: 40px;
    }
}

.item__infos__box {
    @include media(xs) {
        width: calc(100% + 20px);
        margin-left: -10px;
        padding: 40px 30px;
    }

    .card__item__content {
        @include media(xs) {
            font-size: rem(13);
            font-weight: 400;
        }
    }

    .flex {
        @include media(md) {
            align-items: flex-start;
        }
    }

    .flex-icone {
        margin-top: 4px;
    }
}

.card {
    &__item {
        margin-bottom: 30px;
        font-size: rem(14px);
        font-weight: 500;
        max-width: 194px;
        display: flex;
        align-items: center;
        flex-flow: column;
        word-break: break-word;
        // white-space: nowrap;

        &:last-child {
            margin-bottom: 0;
        }

        @include media(xs) {
            &:last-child {
                margin-bottom: 30px;
            }
        }

        @include media(md) {
            flex-flow: row;
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            .end {
                display: block;
            }
        }
    }

    &__more-informations {
        font-weight: bold;
        white-space: nowrap;
    }
}
</style>
