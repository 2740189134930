<template>
    <div ref="desktopMain" class="list-with-filter">
        <Container v-if="showTitle" padding>
            <Title :title="title" />
            <div v-if="resultWord" class="list-with-filter__search-results">
                {{ $tc('search.resultsFor', resultNumber) }} :
                <b>« {{ resultWord }} »</b>
            </div>
        </Container>

        <Container v-if="intro" class="list-with-filter__intro" big-padding padding>
            <wysiwyg class="item__wysiwyg item__wysiwyg--bigger" :body="intro" />
        </Container>

        <Container v-if="hasTopSlot" class="list-with-filter__first-row" padding>
            <Row gutter>
                <el-col v-if="hasTopSlot" :span="24" class="list-with-filter__top-slot">
                    <slot name="top" />
                </el-col>
            </Row>
        </Container>

        <Container padding>
            <Row gutter>
                <el-col v-if="hasFilters" :span="24" :sm="7">
                    <div class="filters-container">
                        <filters-accordion :hide-filter-header="hideFilterHeader">
                            <div slot="content">
                                <div class="filters filters-form">
                                    <div
                                        v-if="searchBar"
                                        class="filters__category"
                                        :class="{ 'no-separator': searchBar }"
                                    >
                                        <search-bar
                                            id="filter-form-search-bar"
                                            v-model="titleFilter"
                                            :placeholder="searchBarPlaceholder"
                                            :bar-label="$t('a11y.searchBars.ariaLabel')"
                                            @search="onSearch"
                                        ></search-bar>
                                    </div>
                                    <div v-if="yearFilter">
                                        <label
                                            class="filters__label"
                                            :class="{
                                                'no-top-margin': !searchBar,
                                            }"
                                            >{{ $t('filter.year') }}</label
                                        >
                                        <el-select v-model="chosenYear" @change="e => filterUpdateEvent('date', e)">
                                            <el-option v-for="year in years" :key="year" :label="year" :value="year">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div v-else-if="hasDate" class="filters__category">
                                        <label
                                            class="filters__label"
                                            :class="{
                                                'no-top-margin': !searchBar,
                                            }"
                                            >Dates</label
                                        >
                                        <el-row>
                                            <el-radio-group
                                                v-model="dateFilter"
                                                @change="e => filterUpdateEvent('date', e)"
                                            >
                                                <el-radio v-for="range in dateFilters" :key="range" :label="range">
                                                    <template v-if="defaultDateLabel && range === 'default'">
                                                        {{ defaultDateLabel }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t(`filter.${range}`) }}
                                                    </template>
                                                </el-radio>
                                            </el-radio-group>
                                        </el-row>
                                        <el-row v-if="dateFilter === 'custom'">
                                            <div class="el-input-label-wrapper">
                                                <label for="start-date">{{ $t('a11y.filter.startDate') }}</label>
                                                <el-date-picker
                                                    id="start-date"
                                                    v-model="customDateRangeFilterFrom"
                                                    type="date"
                                                    value-format="timestamp"
                                                    clearable
                                                    @change="e => filterUpdateEvent('customDate', e)"
                                                />
                                            </div>

                                            <div class="el-input-label-wrapper">
                                                <label for="end-date">{{ $t('a11y.filter.endDate') }}</label>
                                                <el-date-picker
                                                    id="end-date"
                                                    v-model="customDateRangeFilterTo"
                                                    type="date"
                                                    value-format="timestamp"
                                                    clearable
                                                    @change="e => filterUpdateEvent('customDate', e)"
                                                />
                                            </div>
                                        </el-row>
                                    </div>
                                    <div
                                        v-for="(sortedFacet, index) in sortedFacets"
                                        :key="sortedFacet.facetId"
                                        class="filters__category"
                                    >
                                        <label
                                            class="filters__label"
                                            :class="{
                                                'no-top-margin': index === 0 && !searchBar && dateFilter === null,
                                            }"
                                            >{{ facetKeys[sortedFacet.facetId] }}</label
                                        >
                                        <el-checkbox-group
                                            v-model="typeFilter"
                                            @change="e => filterUpdateEvent('filter', e)"
                                        >
                                            <el-checkbox
                                                v-for="filter in sortedFacet.filters"
                                                :key="filter.id"
                                                :label="filter.value"
                                                >{{ filter.name }}</el-checkbox
                                            >
                                        </el-checkbox-group>
                                    </div>
                                </div>
                                <div v-if="hasFilterableOptions" class="filters-container__reset">
                                    <Container slot="filterButton" class="filterButton">
                                        <el-button class="reset__button" @click="resetHandler">{{
                                            $t('filter.reinitialize')
                                        }}</el-button>
                                    </Container>
                                </div>
                            </div>
                        </filters-accordion>
                        <div v-if="hasBox" class="filtersBottom">
                            <slot name="filterBox" />
                        </div>
                    </div>
                </el-col>

                <el-col
                    ref="mobileMain"
                    :span="24"
                    :sm="hasFilters ? 17 : 24"
                    class="list-with-filter__content"
                    aria-live="polite"
                    aria-labelledby="list-with-filter__results"
                >
                    <h2 id="list-with-filter__results" class="visuallyhidden">{{ $t('a11y.resultsList') }}</h2>
                    <slot />
                    <pagination
                        v-if="page !== null"
                        :value="page"
                        :page-count="Math.ceil(totalCount / itemsPerPage)"
                        @input="pageChangeHandler"
                    ></pagination>
                </el-col>
            </Row>
        </Container>
    </div>
</template>

<script>
import { cloneDeep, sortBy } from 'lodash';

import Container from '~/components/Container.vue';
import FiltersAccordion from '~/components/FiltersAccordion.vue';
import Pagination from '~/components/Pagination';
import Row from '~/components/Row.vue';
import SearchBar from '~/components/SearchBar';
import Title from '~/components/Title.vue';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    components: {
        Container,
        FiltersAccordion,
        Pagination,
        Row,
        Title,
        SearchBar,
        Wysiwyg,
    },

    props: {
        /**
         * Look and presentation
         */
        headerImg: {
            type: Object,
            default: null,
        },
        hasBox: {
            type: Boolean,
            default: false,
        },
        hasDate: {
            type: Boolean,
            default: false,
        },
        yearFilter: {
            type: Boolean,
            default: false,
        },
        dateFilters: {
            type: Array,
            default: () => ['default', 'today', 'week', 'month', 'custom'],
        },
        defaultDateLabel: {
            type: String,
            default: null,
        },
        hasFilters: {
            type: Boolean,
            default: true,
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: null,
        },
        intro: {
            type: String,
            default: null,
        },

        /**
         * Data related
         */
        facets: {
            type: Array,
            default: () => [],
        },
        facetsSort: {
            type: Array,
            default: () => null,
        },
        taxonomyType: {
            type: String,
            default: null,
        },
        filterValues: {
            type: Object,
            default: () => {
                return {
                    byTitle: '',
                    byDate: null,
                    byDateRange: null,
                    byCustomDateRangeFrom: null,
                    byCustomDateRangeTo: null,
                    byType: [],
                };
            },
        },

        /**
         * Pagination
         */
        page: {
            type: Number,
            default: 1,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        totalCount: {
            type: Number,
            default: 2,
        },
        /**
         * Header search results stats
         */
        resultNumber: {
            type: Number,
            default: 0,
        },
        resultWord: {
            type: String,
            default: null,
        },
        /**
         * Search Filter params
         */
        searchBar: {
            type: Boolean,
            default: true,
        },
        searchBarPlaceholder: {
            type: String,
            default: null,
        },
        searchBarAriaCustomLabel: {
            type: String,
            default: null,
        },
        hideFilterHeader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const initFilters = cloneDeep(this.filterValues);
        return {
            scrollDelay: false,
            titleFilter: initFilters.byTitle,
            dateFilter: initFilters.byDate,
            dateRangeFilter: initFilters.byDateRange,
            customDateRangeFilterFrom: initFilters.byCustomDateRangeFrom,
            customDateRangeFilterTo: initFilters.byCustomDateRangeTo,
            typeFilter: initFilters.byType,
            chosenYear: this.$moment().format('YYYY'),
        };
    },

    computed: {
        years() {
            const years = [];
            for (let i = 0; i < 10; i++) {
                years.push(
                    this.$moment()
                        .subtract(i, 'years')
                        .format('YYYY'),
                );
            }
            return years;
        },
        hasTopSlot() {
            return !!this.$slots.top;
        },
        hasFilterableOptions() {
            return this.hasFilters && (this.facets.length > 0 || this.hasDate || this.searchBar);
        },
        facetKeys() {
            return {
                content_types: this.$t('filter.content_type'),
                content_type: this.$t('filter.content_type'),
                types_services: this.$t('filter.types_service'),
                types_service: this.$t('filter.types_service'),
                borough: this.$t('filter.borough'),
                news_types: this.$t('filter.news_types'),
                event_type: this.$t('filter.event_type'),
                job_categories: this.$t('filter.job_categories'),
                job_sectors: this.$t('filter.job_sectors'),
                borough_place: this.$t('filter.borough'),
                place_type: this.$t('filter.place_type'),
            };
        },
        sortedFacets() {
            const self = this;
            const typeList = cloneDeep(this.facets);
            typeList.forEach(facetType => {
                facetType.filters.forEach(f => {
                    const newName = this.$utils.cleanify(f.name);
                    f.sortedName = newName;
                });
                this.$utils.sortBy(facetType.filters, 'sortedName');
            });
            if (this.facetsSort !== null && this.facetsSort.length > 0) {
                return sortBy(typeList, function(item) {
                    return self.facetsSort.indexOf(item.facetId);
                });
            }
            return typeList;
        },
    },

    watch: {
        filterValues: {
            deep: true,
            handler(val, oldVal) {
                this.titleFilter = val.byTitle;
                this.dateFilter = val.byDate;
                this.dateRangeFilter = val.byDateRange;
                this.customDateRangeFilterFrom = val.byCustomDateRangeFrom;
                this.customDateRangeFilterTo = val.byCustomDateRangeTo;
                this.typeFilter = val.byType;
            },
        },
    },

    mounted() {
        this.removeAriaHidden();
    },

    methods: {
        pageChangeHandler(val) {
            this.$emit('update:page', val);
            window.scrollTo({ top: 0 });
        },
        resetHandler() {
            this.chosenYear = this.$moment().format('YYYY');
            this.titleFilter = '';
            this.dateFilter = 'default';
            this.dateRangeFilter = [];
            this.customDateRangeFilterFrom = null;
            this.customDateRangeFilterTo = null;
            this.typeFilter = [];
            this.$emit('filterUpdate', {
                byTitle: '',
                byDate: 'default',
                byDateRange: [],
                byCustomDateRangeFrom: null,
                byCustomDateRangeTo: null,
                byType: [],
            });
        },
        filterUpdateEvent(source, value) {
            this.setDateRange();
            this.$emit('filterUpdate', {
                byTitle: this.titleFilter,
                byDate: this.dateFilter,
                byDateRange: this.dateRangeFilter,
                byCustomDateRangeFrom: this.customDateRangeFilterFrom,
                byCustomDateRangeTo: this.customDateRangeFilterTo,
                byType: this.typeFilter,
            });
        },
        setDateRange() {
            if (this.yearFilter) {
                this.dateRangeFilter[0] = this.$moment()
                    .set('year', parseInt(this.chosenYear))
                    .startOf('year')
                    .format('YYYY-MM-DD HH:mm:ss');
                this.dateRangeFilter[1] = this.$moment()
                    .set('year', parseInt(this.chosenYear))
                    .endOf('year')
                    .format('YYYY-MM-DD HH:mm:ss');
            } else {
                if (this.dateFilter === 'today') {
                    this.dateRangeFilter[0] = this.$moment()
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss');
                    this.dateRangeFilter[1] = this.$moment()
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss');
                }

                if (this.dateFilter === 'week') {
                    this.dateRangeFilter[0] = this.$moment()
                        .startOf('week')
                        .format('YYYY-MM-DD HH:mm:ss');
                    this.dateRangeFilter[1] = this.$moment()
                        .endOf('week')
                        .format('YYYY-MM-DD HH:mm:ss');
                }

                if (this.dateFilter === 'month') {
                    this.dateRangeFilter[0] = this.$moment()
                        .startOf('month')
                        .format('YYYY-MM-DD HH:mm:ss');
                    this.dateRangeFilter[1] = this.$moment()
                        .endOf('month')
                        .format('YYYY-MM-DD HH:mm:ss');
                }

                if (this.dateFilter === 'custom') {
                    this.dateRangeFilter[0] = this.customDateRangeFilterFrom
                        ? this.$moment(this.customDateRangeFilterFrom).format('YYYY-MM-DD HH:mm:ss')
                        : this.$moment('2000-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                    this.dateRangeFilter[1] = this.customDateRangeFilterTo
                        ? this.$moment(this.customDateRangeFilterTo).format('YYYY-MM-DD HH:mm:ss')
                        : this.$moment('2049-12-31 23:59:59', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                }

                if (this.dateFilter === 'default') {
                    this.dateRangeFilter[0] = this.$moment('2000-01-01 00:00:00', 'YYYY-MM-DD HH:mm:ss').format(
                        'YYYY-MM-DD HH:mm:ss',
                    );
                    this.dateRangeFilter[1] = this.$moment('2049-12-31 23:59:59', 'YYYY-MM-DD HH:mm:ss').format(
                        'YYYY-MM-DD HH:mm:ss',
                    );
                }
            }
        },
        onSearch(value) {
            this.titleFilter = value;
            this.filterUpdateEvent('search', value);
        },
        removeAriaHidden() {
            const radioInput = this.$el.querySelectorAll('.el-radio__original');
            radioInput.forEach(element => {
                const uniqueId = element.getAttribute('value');
                const elLabel = element.parentNode.parentNode;
                element.removeAttribute('aria-hidden');
                element.removeAttribute('tabindex');
                element.setAttribute('id', uniqueId);
                elLabel.setAttribute('for', uniqueId);
            });
        },
    },
};
</script>

<style lang="scss">
.list-with-filter {
    margin-bottom: 40px;
    &__intro {
        margin-bottom: 26px;

        @include media(sm) {
            margin-bottom: 34px;
        }
    }

    .filters-container {
        margin-bottom: 4rem;
        @include media(sm) {
            margin-bottom: 0;
            max-width: 284px;
        }
        &__reset {
            margin-top: 22px;
        }
        .filters {
            &__label {
                display: block;
                font-size: rem(15px);
                font-weight: 500;
                line-height: 1em;
                letter-spacing: rem(1.5px);
                text-transform: uppercase;
                margin-bottom: rem(24px);
                margin-top: rem(32px);
                &.no-top-margin {
                    margin-top: 0;
                }
            }
            &__category {
                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $--color-gray;
                }

                &.no-separator {
                    &:after {
                        display: none;
                    }
                }
            }

            .dummy {
                visibility: hidden;
            }

            .el-radio,
            .el-checkbox {
                display: block;
            }

            .el-radio {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &__label {
                    font-size: rem(14px);
                    font-weight: 400;
                }
            }

            .el-checkbox {
                display: flex;
                margin-bottom: 8px;

                &__input {
                    margin-top: 1px;
                }

                &__label {
                    white-space: break-spaces;
                    font-size: rem(14px);
                    font-weight: 400;
                }

                &-group {
                    &:last-child {
                        margin-bottom: 26px;
                    }
                }
            }

            .el-row {
                &:last-child {
                    margin-bottom: 28px;
                }
            }

            .el-input-label-wrapper {
                display: block;
                margin: 10px 0 10px 30px;

                label {
                    font-size: rem(12px);
                    display: block;
                    margin-bottom: 8px;
                }
            }

            .el-date-editor {
                margin-top: 0;
                width: 100%;

                .el-input__inner {
                    &::placeholder {
                        color: $--color-black;
                    }
                }

                .el-input__icon {
                    line-height: unset;

                    &::before {
                        color: $--color-black;
                    }
                }

                @include media(md) {
                    font-size: rem(12px);
                }
            }
        }
    }

    @include media(sm) {
        margin-bottom: 80px;
    }

    @include media(md) {
        margin-bottom: 100px;
    }

    @include media(lg) {
        margin-bottom: 144px;
    }

    &__search-results {
        color: $--color-primary;
        font-size: rem(13px);
        font-weight: 400;
        margin-bottom: 32px;

        @include media(sm) {
            font-size: rem(18px);
            font-weight: 500;
            margin-bottom: 42px;
        }
    }

    &__first-row {
        margin-bottom: 20px;
    }

    &__pagination {
        text-align: center;
        margin-right: 1em;
        align-self: flex-end;
        margin: 0;
        width: 100%;
        margin-top: auto;
    }

    &__hideFilter-container {
        display: flex;
        align-items: flex-end;
    }

    &__content {
        display: flex;
        flex-flow: column;
        margin-bottom: 20px;
    }

    .el-collapse-item {
        &__header {
            font-size: rem(13px);
            text-transform: uppercase;
            color: $--color-black;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
            border: 0px solid transparent !important;
            margin-top: 20px;
            margin-bottom: 20px;

            @include media(sm) {
                display: none !important;
            }
        }

        &__header-desktop {
            border: 0px solid transparent !important;
            border-bottom: 2px solid #e3e3e3 !important;
            padding-left: 0;
            border-radius: 0;
            text-transform: uppercase;
            font-size: rem(18px);
            color: $--color-green;
            font-weight: bold;
            padding-bottom: 16px;
            //margin-bottom: 20px;
            margin-top: 22px;

            @include media(xs) {
                display: none !important;
            }
        }

        &__wrap {
            padding: 0;
            margin: 0;
            background-color: transparent;

            @include media(sm) {
                display: block !important;
            }
        }
    }

    .filtersBottom {
        margin-top: 28px;

        &:before {
            content: '';
            display: block;
            width: 100%;
            border-top: rem(1px) solid $--color-gray;
            padding-bottom: rem(25px);
        }
    }
}
</style>
