<template>
    <div class="c-block-menu">
        <nav class="main-menu" :aria-label="$t('a11y.menu.mainMenu')">
            <ul class="c-block-menu_nav">
                <li v-for="(navigationItem, index) in navigation" :key="index" class="c-block-menu_nav_item">
                    <template v-if="navigationItem.items.length">
                        <menu-block-accordions-wrapper
                            ref="accordions"
                            modifier="-menu"
                            @onOpen="onAccordionParentOpen"
                            @onClose="onAccordionParentClose"
                        >
                            <template v-slot:toggler="props">
                                <button
                                    :id="'accordion--toggle--' + getId()"
                                    class="c-block-menu_item -link"
                                    :aria-controls="'accordion-content--' + getId()"
                                    @click="props.toggle"
                                >
                                    <span>
                                        {{ navigationItem.label }}
                                    </span>
                                    <span class="visually-hidden"> {{ $t('a11y.menu.expandContent') }}</span>
                                    <ui-icon class="hide-from-tablet" type="cross" />
                                    <ui-icon class="show-from-tablet" type="chevronSelect" />
                                </button>
                            </template>
                            <template v-slot:content>
                                <template v-for="(subNavigationItem, sIndex) in navigationItem.items">
                                    <template v-if="subNavigationItem.items.length">
                                        <menu-block-accordions
                                            ref="accordionsItem"
                                            :key="sIndex"
                                            :label="subNavigationItem.label"
                                            @onOpen="onAccordionChildOpen"
                                        >
                                            <ul class="c-block-menu_subnav">
                                                <li
                                                    v-for="(item, itemIndex) in subNavigationItem.items"
                                                    :key="itemIndex"
                                                    class="c-block-menu_subnav_item"
                                                >
                                                    <ui-cta
                                                        :title="item.label"
                                                        :label="item.label"
                                                        :url="item.url"
                                                        :link-type="item.type"
                                                        type="button"
                                                        color="neutralDark"
                                                        size="rounded"
                                                        icon-type="arrowRight"
                                                    />
                                                </li>
                                            </ul>
                                        </menu-block-accordions>
                                    </template>
                                    <template v-else-if="subNavigationItem.type === 'internal'">
                                        <NuxtLink
                                            :key="sIndex"
                                            :to="subNavigationItem.url"
                                            class="c-block-menu_item -subnav-link"
                                        >
                                            {{ subNavigationItem.label }}
                                        </NuxtLink>
                                    </template>
                                    <template v-else>
                                        <a
                                            :key="sIndex"
                                            :href="subNavigationItem.url"
                                            class="c-block-menu_item -subnav-link"
                                        >
                                            <span>
                                                {{ subNavigationItem.label }}
                                            </span>
                                            <ui-icon :key="sIndex" type="external" />
                                        </a>
                                    </template>
                                </template>
                            </template>
                        </menu-block-accordions-wrapper>
                    </template>
                    <template v-else-if="navigationItem.type === 'internal'">
                        <NuxtLink :to="navigationItem.url" class="c-block-menu_item -link -internal">
                            {{ navigationItem.label }}
                        </NuxtLink>
                    </template>
                    <template v-else>
                        <a :href="navigationItem.url" class="c-block-menu_item -link">
                            <span>
                                {{ navigationItem.label }}
                            </span>
                            <span class="visuallyhidden">{{ $t('a11y.externalLink') }}</span>
                            <ui-icon class="hide-from-tablet" type="external" />
                            <ui-icon class="show-from-tablet" type="externalMin" />
                        </a>
                    </template>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import UiCta from '~/components/locomotive/ui-cta.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';

import BlockAccordions from '~/components/locomotive/block-accordions.vue';
import BlockAccordionsWrapper from '~/components/locomotive/block-accordions-wrapper.vue';
import MenuBlockAccordions from '~/components/locomotive/menu-block-accordions';
import MenuBlockAccordionsWrapper from '~/components/locomotive/menu-block-accordions-wrapper';

export default {
    components: {
        MenuBlockAccordions,
        MenuBlockAccordionsWrapper,
        UiCta,
        UiIcon,
        BlockAccordions,
        BlockAccordionsWrapper,
    },
    props: {
        navigation: {
            type: Array,
            required: true,
        },
    },

    data: () => {
        return {
            isActive: false,
        };
    },

    computed: {
        ...mapState('locomotive-global', ['metrics', 'isMenuOpen', 'breakpoints']),
    },
    watch: {
        isMenuOpen: 'toggle',
        metrics: 'onResize',
    },
    created() {
        this.onClickBind = this.onClick.bind(this);
    },
    mounted() {
        this.computeButtonNavHeight();

        this.bindEvents();
    },
    beforeDestroy() {
        this.unbindEvents();
    },
    methods: {
        bindEvents() {
            const $links = this.$el.querySelectorAll('a');
            let linkIndex = 0;
            while (linkIndex < $links.length) {
                const $link = $links[linkIndex];
                $link.addEventListener('click', this.onClickBind);
                linkIndex++;
            }
        },
        unbindEvents() {
            const $links = this.$el.querySelectorAll('a');
            let linkIndex = 0;
            while (linkIndex < $links.length) {
                const $link = $links[linkIndex];
                $link.removeEventListener('click', this.onClickBind);
                linkIndex++;
            }
        },
        toggle(isOpen) {
            if (isOpen) {
                this.open();
            } else {
                this.close();
            }
        },
        open() {
            document.documentElement.classList.add('is-menu-open');
            this.modalFocusTrap();
        },
        close() {
            document.documentElement.classList.remove('is-menu-open');
        },
        onClick() {
            this.$nextTick(() => {
                this.closeAllParentsAccordions();
                this.$nextTick(() => {
                    this.close();
                });
            });
        },
        onResize() {
            if (this.metrics.width > this.breakpoints.header) {
                this.computeButtonNavHeight();
            }
        },
        onAccordionParentOpen(accordionsId) {
            this.closeUnactiveAccordions(accordionsId, this.$refs.accordions);
        },
        onAccordionParentClose() {
            this.closeAllChildAccordions();
        },
        onAccordionChildOpen(accordionsId) {
            this.closeUnactiveAccordions(accordionsId, this.$refs.accordionsItem);
        },
        closeUnactiveAccordions(accordionsId, accordionsList) {
            const $accordionsList = accordionsList.filter($item => $item._uid !== accordionsId);
            $accordionsList.forEach($item => $item.close());
        },
        closeAllParentsAccordions() {
            const accordionsList = this.$refs.accordions;
            let index = 0;
            while (index < accordionsList.length) {
                const $element = accordionsList[index];
                $element.close && $element.close();
                index++;
            }
        },
        closeAllChildAccordions() {
            const accordionsList = this.$refs.accordionsItem;
            let index = 0;
            while (index < accordionsList.length) {
                const $element = accordionsList[index];
                const $accordions = $element.$refs.accordions;
                $accordions.forceClose && $accordions.forceClose();
                index++;
            }
        },
        computeButtonNavHeight() {
            const $items = this.$el.querySelectorAll('.c-block-menu_item.-link');
            const arrHeight = [];
            for (let index = 0; index < $items.length; index++) {
                const $item = $items[index];
                arrHeight.push($item.offsetHeight);
            }
            const height = Math.max(...arrHeight);
            for (let index = 0; index < $items.length; index++) {
                const $item = $items[index];
                $item.style.height = `${height}px`;
            }
        },
        getId() {
            return this._uid + 1;
        },
        modalFocusTrap() {
            // add all the elements inside modal which you want to make focusable
            const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            const modal = document.querySelector('.is-menu-open header > .c-block-header_wrapper'); // select the modal

            const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
            const focusableContent = modal.querySelectorAll(focusableElements);
            const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

            document.addEventListener('keydown', function(e) {
                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    // if shift key pressed for shift + tab combination
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus(); // add focus for the last focusable element
                        e.preventDefault();
                    }
                } else {
                    // if tab key is pressed
                    // eslint-disable-next-line no-lonely-if
                    if (document.activeElement === lastFocusableElement) {
                        // if focused has reached to last focusable element then focus first focusable element after pressing tab
                        firstFocusableElement.focus(); // add focus for the first focusable element
                        e.preventDefault();
                    }
                }
            });

            firstFocusableElement.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-menu {
    @media (min-width: $from-header) {
        color: $loco-tonic-dark;
        background-color: $loco-neutral-lighter;
    }

    @media (max-width: $to-header) {
        position: relative;
        z-index: 1;
    }
    @media (min-width: $from-header) {
        padding: 0 loco-rem($loco-padding);
    }
}

.c-block-menu_item {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: -0.03em;
    color: $loco-light;

    @media (max-width: $to-header) {
        padding-left: loco-rem(20px);
        padding-right: loco-rem(48px);
    }

    @media (min-width: $from-header) {
        transition: background-color $loco-speed-fast $loco-easing;

        &:hover {
            background-color: $loco-neutral-darker;
        }
    }

    &.-link {
        @include fontFamily($loco-font-medium, $loco-font-family-fallback);
        transition: color $loco-speed-fast $loco-easing, background-color $loco-speed-fast $loco-easing;

        @media (min-width: $from-header) {
            color: $loco-tonic-dark;

            &:hover {
                color: $loco-light;
            }
        }

        .has-scrolled &,
        .has-menu-overlay & {
            @media (min-width: $from-header) {
                color: $loco-tonic-dark;

                &:hover {
                    color: $loco-light;
                }
            }
        }
        .c-block-accordions-wrapper.is-active & {
            @media (max-width: $to-header) {
                background-color: $loco-neutral-darker-hover;
            }
            @media (min-width: $from-header) {
                background-color: $loco-neutral-darker;

                .has-scrolled &,
                .has-menu-overlay & {
                    color: $loco-light;
                }
            }
        }

        @media (max-width: $to-header) {
            padding-top: loco-rem(16px);
            padding-bottom: loco-rem(16px);
            width: 100%;
            font-size: loco-rem(32px);
            line-height: 1;
            letter-spacing: -0.03em;

            &:after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: calc(100% - #{loco-rem(40px)});
                height: 1px;
                transform: translate3d(-50%, 0, 0);
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        @media (min-width: $from-header) {
            @include fontFamily($loco-font-bold, $loco-font-family-fallback);
            font-size: loco-rem(16px);
            line-height: 1.2;
            letter-spacing: -0.01em;

            &:not(.-internal) {
                padding: loco-rem(20px) loco-rem(40px) loco-rem(20px) loco-rem(16px);
            }

            &.-internal {
                padding: loco-rem(20px) loco-rem(20px) loco-rem(20px) loco-rem(16px);
            }

            .c-ui-icon.-chevronSelect {
                right: loco-rem(16px);
            }
            .c-ui-icon.-externalMin {
                right: loco-rem(12px);
            }
        }
    }

    &.-subnav-link {
        width: 100%;
        background-color: $loco-neutral-darker;

        &:hover {
            background-color: $loco-neutral-darker-hover;
        }

        @media (max-width: $to-header) {
            padding-top: loco-rem(16px);
            padding-bottom: loco-rem(16px);
            padding-right: loco-rem(28px);
            font-size: loco-rem(32px);
            line-height: 1;
            letter-spacing: -0.03em;
        }

        @media (min-width: $from-header) {
            font-size: loco-rem(45px);
            line-height: 1;
            letter-spacing: -0.04em;
            padding: loco-rem(16px) loco-rem($loco-padding);

            .c-ui-icon {
                right: loco-rem($loco-padding);
            }
        }
    }

    .c-ui-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);

        @media (max-width: $to-header) {
            right: loco-rem(20px);
        }
    }
}

.c-block-menu_nav {
    @media (min-width: $from-header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.c-block-menu_subnav {
    @media (min-width: $from-medium) {
        column-count: 2;
    }
}

.c-block-menu_subnav_item {
    margin-bottom: loco-rem(8px);

    @media (min-width: $from-medium) {
        width: 50%;
    }
}
</style>
