import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '..\\src\\layouts\\error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_fae7751c from 'nuxt_plugin_sentryserver_fae7751c' // Source: .\\sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_1cd2d3ea from 'nuxt_plugin_sentryclient_1cd2d3ea' // Source: .\\sentry.client.js (mode: 'client')
import nuxt_plugin_gtm_5087ee04 from 'nuxt_plugin_gtm_5087ee04' // Source: .\\gtm.js (mode: 'all')
import nuxt_plugin_pluginrouting_60d7d9cb from 'nuxt_plugin_pluginrouting_60d7d9cb' // Source: .\\nuxt-i18n\\plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_76e544f8 from 'nuxt_plugin_pluginmain_76e544f8' // Source: .\\nuxt-i18n\\plugin.main.js (mode: 'all')
import nuxt_plugin_moment_4a93f3aa from 'nuxt_plugin_moment_4a93f3aa' // Source: .\\moment.js (mode: 'all')
import nuxt_plugin_vuesocialsharingplugin_320eaba7 from 'nuxt_plugin_vuesocialsharingplugin_320eaba7' // Source: .\\vue-social-sharing-plugin.js (mode: 'all')
import nuxt_plugin_workbox_7a17556c from 'nuxt_plugin_workbox_7a17556c' // Source: .\\workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_9efb406c from 'nuxt_plugin_metaplugin_9efb406c' // Source: .\\pwa\\meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_95abf584 from 'nuxt_plugin_iconplugin_95abf584' // Source: .\\pwa\\icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_a14bd8a4 from 'nuxt_plugin_axios_a14bd8a4' // Source: .\\axios.js (mode: 'all')
import nuxt_plugin_router_1f96247e from 'nuxt_plugin_router_1f96247e' // Source: .\\router.js (mode: 'all')
import nuxt_plugin_eventBus_6855d77a from 'nuxt_plugin_eventBus_6855d77a' // Source: ..\\src\\plugins\\eventBus (mode: 'all')
import nuxt_plugin_elementui_d905880e from 'nuxt_plugin_elementui_d905880e' // Source: ..\\src\\plugins\\element-ui (mode: 'all')
import nuxt_plugin_utils_2340f33d from 'nuxt_plugin_utils_2340f33d' // Source: ..\\src\\plugins\\utils (mode: 'all')
import nuxt_plugin_plyr_9263abee from 'nuxt_plugin_plyr_9263abee' // Source: ..\\src\\plugins\\plyr (mode: 'all')
import nuxt_plugin_api_caeae0b4 from 'nuxt_plugin_api_caeae0b4' // Source: ..\\src\\plugins\\api (mode: 'all')
import nuxt_plugin_mailto_362af166 from 'nuxt_plugin_mailto_362af166' // Source: ..\\src\\plugins\\mailto (mode: 'all')
import nuxt_plugin_slugify_3ff6d006 from 'nuxt_plugin_slugify_3ff6d006' // Source: ..\\src\\plugins\\slugify (mode: 'all')
import nuxt_plugin_validator_5e67c7de from 'nuxt_plugin_validator_5e67c7de' // Source: ..\\src\\plugins\\validator (mode: 'all')
import nuxt_plugin_filters_2abc1387 from 'nuxt_plugin_filters_2abc1387' // Source: ..\\src\\plugins\\filters (mode: 'all')
import nuxt_plugin_metaTagsHelper_00fdb800 from 'nuxt_plugin_metaTagsHelper_00fdb800' // Source: ..\\src\\plugins\\metaTagsHelper (mode: 'all')
import nuxt_plugin_drupalComponentsAdapter_b114a09e from 'nuxt_plugin_drupalComponentsAdapter_b114a09e' // Source: ..\\src\\plugins\\drupalComponentsAdapter (mode: 'all')
import nuxt_plugin_swiper_419e176c from 'nuxt_plugin_swiper_419e176c' // Source: ..\\src\\plugins\\swiper (mode: 'all')
import nuxt_plugin_solrUrlBuilder_42a070a2 from 'nuxt_plugin_solrUrlBuilder_42a070a2' // Source: ..\\src\\plugins\\solrUrlBuilder (mode: 'all')
import nuxt_plugin_rruleTranslator_2820d298 from 'nuxt_plugin_rruleTranslator_2820d298' // Source: ..\\src\\plugins\\rruleTranslator (mode: 'all')
import nuxt_plugin_clickOutside_a592b21e from 'nuxt_plugin_clickOutside_a592b21e' // Source: ..\\src\\plugins\\clickOutside (mode: 'all')
import nuxt_plugin_externalLogger_45bf3c2f from 'nuxt_plugin_externalLogger_45bf3c2f' // Source: ..\\src\\plugins\\externalLogger (mode: 'all')
import nuxt_plugin_loadFonts_1c02b56c from 'nuxt_plugin_loadFonts_1c02b56c' // Source: ..\\src\\plugins\\loadFonts (mode: 'all')
import nuxt_plugin_globals_64e2e0bc from 'nuxt_plugin_globals_64e2e0bc' // Source: ..\\src\\plugins\\globals (mode: 'all')
import nuxt_plugin_tabbable_0af98048 from 'nuxt_plugin_tabbable_0af98048' // Source: ..\\src\\plugins\\tabbable.js (mode: 'client')
import nuxt_plugin_localStorage_8a74121a from 'nuxt_plugin_localStorage_8a74121a' // Source: ..\\src\\plugins\\localStorage.js (mode: 'client')
import nuxt_plugin_accessibility_335f504c from 'nuxt_plugin_accessibility_335f504c' // Source: ..\\src\\plugins\\accessibility (mode: 'client')
import nuxt_plugin_vuesocialsharing_dccf00ce from 'nuxt_plugin_vuesocialsharing_dccf00ce' // Source: ..\\src\\plugins\\vue-social-sharing (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Longueuil","meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Longueuil"},{"name":"google-site-verification","content":"G6CXMHcx8YNqlfpRf1yGCgSmWw6ZslA1SdDgA6sYKu8"}],"script":[{"src":"https:\u002F\u002Faddevent.com\u002Flibs\u002Fatc\u002F1.6.1\u002Fatc.min.js","async":true,"defer":true},{"src":"https:\u002F\u002Fapi.byscuit.com\u002Fdata\u002Fclient\u002F2DCF0980-648F-4DD5-811C-FBF76F41CBCA\u002Fscript\u002Fscript.js"},{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=GTM-KFNCPVM","async":true},{"src":"\u002Fgtag.js","async":true},{"hid":"gtm-script","innerHTML":"if(!window._gtm_init){window._gtm_init=1;(function(w,n,d,m,e,p){w[d]=(w[d]==1||n[d]=='yes'||n[d]==1||n[m]==1||(w[e]&&w[e][p]&&w[e][p]()))?1:0})(window,navigator,'doNotTrack','msDoNotTrack','external','msTrackingProtectionEnabled');(function(w,d,s,l,x,y){w[x]={};w._gtm_inject=function(i){if(w.doNotTrack||w[x][i])return;w[x][i]=1;w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src='https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i;f.parentNode.insertBefore(j,f);};w[y]('GTM-KFNCPVM')})(window,document,'script','dataLayer','_gtm_ids','_gtm_inject')}"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"}],"style":[],"noscript":[{"hid":"gtm-noscript","pbody":true,"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-KFNCPVM&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"\u003E\u003C\u002Fiframe\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gtm-script":["innerHTML"],"gtm-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_fae7751c === 'function') {
    await nuxt_plugin_sentryserver_fae7751c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_1cd2d3ea === 'function') {
    await nuxt_plugin_sentryclient_1cd2d3ea(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_5087ee04 === 'function') {
    await nuxt_plugin_gtm_5087ee04(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_60d7d9cb === 'function') {
    await nuxt_plugin_pluginrouting_60d7d9cb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_76e544f8 === 'function') {
    await nuxt_plugin_pluginmain_76e544f8(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_4a93f3aa === 'function') {
    await nuxt_plugin_moment_4a93f3aa(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesocialsharingplugin_320eaba7 === 'function') {
    await nuxt_plugin_vuesocialsharingplugin_320eaba7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_7a17556c === 'function') {
    await nuxt_plugin_workbox_7a17556c(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_9efb406c === 'function') {
    await nuxt_plugin_metaplugin_9efb406c(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_95abf584 === 'function') {
    await nuxt_plugin_iconplugin_95abf584(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_a14bd8a4 === 'function') {
    await nuxt_plugin_axios_a14bd8a4(app.context, inject)
  }

  if (typeof nuxt_plugin_router_1f96247e === 'function') {
    await nuxt_plugin_router_1f96247e(app.context, inject)
  }

  if (typeof nuxt_plugin_eventBus_6855d77a === 'function') {
    await nuxt_plugin_eventBus_6855d77a(app.context, inject)
  }

  if (typeof nuxt_plugin_elementui_d905880e === 'function') {
    await nuxt_plugin_elementui_d905880e(app.context, inject)
  }

  if (typeof nuxt_plugin_utils_2340f33d === 'function') {
    await nuxt_plugin_utils_2340f33d(app.context, inject)
  }

  if (typeof nuxt_plugin_plyr_9263abee === 'function') {
    await nuxt_plugin_plyr_9263abee(app.context, inject)
  }

  if (typeof nuxt_plugin_api_caeae0b4 === 'function') {
    await nuxt_plugin_api_caeae0b4(app.context, inject)
  }

  if (typeof nuxt_plugin_mailto_362af166 === 'function') {
    await nuxt_plugin_mailto_362af166(app.context, inject)
  }

  if (typeof nuxt_plugin_slugify_3ff6d006 === 'function') {
    await nuxt_plugin_slugify_3ff6d006(app.context, inject)
  }

  if (typeof nuxt_plugin_validator_5e67c7de === 'function') {
    await nuxt_plugin_validator_5e67c7de(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2abc1387 === 'function') {
    await nuxt_plugin_filters_2abc1387(app.context, inject)
  }

  if (typeof nuxt_plugin_metaTagsHelper_00fdb800 === 'function') {
    await nuxt_plugin_metaTagsHelper_00fdb800(app.context, inject)
  }

  if (typeof nuxt_plugin_drupalComponentsAdapter_b114a09e === 'function') {
    await nuxt_plugin_drupalComponentsAdapter_b114a09e(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_419e176c === 'function') {
    await nuxt_plugin_swiper_419e176c(app.context, inject)
  }

  if (typeof nuxt_plugin_solrUrlBuilder_42a070a2 === 'function') {
    await nuxt_plugin_solrUrlBuilder_42a070a2(app.context, inject)
  }

  if (typeof nuxt_plugin_rruleTranslator_2820d298 === 'function') {
    await nuxt_plugin_rruleTranslator_2820d298(app.context, inject)
  }

  if (typeof nuxt_plugin_clickOutside_a592b21e === 'function') {
    await nuxt_plugin_clickOutside_a592b21e(app.context, inject)
  }

  if (typeof nuxt_plugin_externalLogger_45bf3c2f === 'function') {
    await nuxt_plugin_externalLogger_45bf3c2f(app.context, inject)
  }

  if (typeof nuxt_plugin_loadFonts_1c02b56c === 'function') {
    await nuxt_plugin_loadFonts_1c02b56c(app.context, inject)
  }

  if (typeof nuxt_plugin_globals_64e2e0bc === 'function') {
    await nuxt_plugin_globals_64e2e0bc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tabbable_0af98048 === 'function') {
    await nuxt_plugin_tabbable_0af98048(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_8a74121a === 'function') {
    await nuxt_plugin_localStorage_8a74121a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_accessibility_335f504c === 'function') {
    await nuxt_plugin_accessibility_335f504c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesocialsharing_dccf00ce === 'function') {
    await nuxt_plugin_vuesocialsharing_dccf00ce(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
