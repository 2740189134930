<template>
    <div class="content content-details">
        <div v-if="headerImg || mediaImage" class="content__header">
            <slot name="top"></slot>

            <uploaded-image
                v-if="!mediaImage && headerImg"
                class="content__header-img"
                :path="headerImg.src ? headerImg.src : '/placeholder_1400x400.svg'"
                :alt="headerImg.alt"
                fit="cover"
            />
            <media-image v-else :image="mediaImage" fit="cover" :size="size" class="content__header-img"> </media-image>
        </div>

        <Container :offset-size="offsetSize" offset padding>
            <Container max-width="700px" no-margin>
                <Row>
                    <el-col :span="24">
                        <Title
                            :title="title"
                            :type-tags="typeTags"
                            :inside-img="!!headerImg || !!mediaImage"
                            :more-link="moreLink"
                            padding
                        />
                    </el-col>
                </Row>
            </Container>
        </Container>

        <Container padding :offset-size="offsetSize" offset>
            <slot>There is nothing to show here</slot>
        </Container>

        <slot name="map"></slot>

        <Container padding :offset-size="offsetSize" offset>
            <slot name="bottom"></slot>
        </Container>
    </div>
</template>

<script>
import Container from '~/components/Container.vue';
import MediaImage from '~/components/MediaImage';
import Row from '~/components/Row.vue';
import Title from '~/components/Title.vue';
import UploadedImage from '~/components/UploadedImage';

export default {
    components: {
        Container,
        Title,
        Row,
        UploadedImage,
        MediaImage,
    },

    props: {
        title: {
            type: String,
            default: 'Title not found',
        },
        size: {
            type: String,
            default: 'large',
        },
        headerImg: {
            type: Object,
            default: null,
        },
        typeTags: {
            type: Array,
            default: () => [],
        },
        infos: {
            type: Array,
            default: null,
        },
        mediaImage: {
            type: Object,
            default: null,
        },
        offsetSize: {
            type: Number,
            default: 0,
        },
        // Has key: url & txt
        moreLink: {
            default: null,
            type: Object,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    &__header {
        margin-top: 43px;
    }

    &__header-img {
        display: flex;
        margin-top: -43px !important;
        max-width: 1135px;
        width: 100%;
        height: 350px;
        margin: auto;

        @include media(xs) {
            min-height: 128px;
            height: 40vw;
            margin-bottom: -40px !important;
        }

        @include media(lg) {
            transform: translateX(180px);
        }
    }
}
</style>

<style lang="scss">
.content-details {
    .title__htag--h1 {
        font-size: rem(25px);
    }

    .el-col.el-col-offset-5 {
        margin-left: 0px;
    }
}

@include media(sm) {
    .content-details {
        .title__htag--h1 {
            font-size: rem(42px);
        }
    }
}
@include media(xs) {
    .content-details {
        .title.padding.inside-img {
            padding-top: 15px;
            padding-left: 23px;
            padding-right: 0px;
            margin-left: -20px;
        }
    }
}
</style>
