<template>
    <div>
        <div class="locomotive-styles">
            <block-hero :title="heroData.title" :image="heroData.image" :video="heroData.video" :news="heroData.news" />
            <block-access
                :category="accessData.category"
                :title="accessData.title"
                :link-label="accessData.linkLabel"
                :link-url="accessData.linkUrl"
                :link-type="accessData.linkType"
                :links="accessData.items"
            />
            <block-carousel
                v-if="eventsData && eventsData.items && eventsData.items.length"
                :title="eventsData.title"
                :link-label="eventsData.linkLabel"
                :link-url="eventsData.linkUrl"
                :link-type="eventsData.linkType"
                class="c-section -padding-top-medium -padding-bottom-medium"
            >
                <div
                    v-for="(eventDataItem, index) in eventsData.items"
                    :key="index"
                    class="c-block-carousel_slide swiper-slide"
                >
                    <ui-tile
                        :tags="eventDataItem.tags"
                        :category="eventDataItem.category"
                        :title="eventDataItem.title"
                        :date-start="eventDataItem.dateStart"
                        :date-end="eventDataItem.dateEnd"
                        :image-src="eventDataItem.image.src"
                        :is-fluid="true"
                        :link-url="eventDataItem.url"
                    />
                </div>
            </block-carousel>
            <block-splash
                :title="splashData.title"
                :description="splashData.description"
                :image-src="splashData.image.src"
                :image-width="splashData.image.width"
                :image-height="splashData.image.height"
                :image-alt="splashData.image.alt"
                :link-label="splashData.linkLabel"
                :link-type="splashData.linkType"
                :link-url="splashData.linkUrl"
            />
            <block-carousel
                v-if="newsData && newsData.items && newsData.items.length"
                :title="newsData.title"
                :link-label="newsData.linkLabel"
                :link-url="newsData.linkUrl"
                :link-type="newsData.linkType"
                class="c-section -padding-top-medium -padding-bottom-medium"
            >
                <div
                    v-for="(newsDataItem, index) in newsData.items"
                    :key="index"
                    class="c-block-carousel_slide swiper-slide"
                >
                    <ui-tile
                        :tags="newsDataItem.tags"
                        :category="newsDataItem.category"
                        :title="newsDataItem.title"
                        :date-start="newsDataItem.dateStart"
                        :date-end="newsDataItem.dateEnd"
                        :is-fluid="true"
                        :link-url="newsDataItem.url"
                    />
                </div>
            </block-carousel>
            <block-push
                :title="pushData.title"
                :description="pushData.description"
                :link-label="pushData.linkLabel"
                :link-url="pushData.linkUrl"
                :link-type="pushData.linkType"
                :images="pushData.images"
            />
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';

import HomepageDataMixin from '~/mixins/homepage-data-mixin.js';
import ScrollMixin from '~/mixins/scroll-mixin.js';

import Card from '~/components/Card';
import Container from '~/components/Container';
import HomeCarousel from '~/components/HomeCarousel';
import ListCards from '~/components/ListCards';
import MenuExplorer from '~/components/header/MenuExplorer';
import ReminderBlockList from '~/components/ReminderBlockList';
import Row from '~/components/Row';
import SocialNetwork from '~/components/SocialNetwork';

import BlockAccess from '~/components/locomotive/block-access.vue';
import BlockCarousel from '~/components/locomotive/block-carousel.vue';
import BlockHero from '~/components/locomotive/block-hero.vue';
import BlockPush from '~/components/locomotive/block-push.vue';
import BlockSplash from '~/components/locomotive/block-splash.vue';

import UiTile from '~/components/locomotive/ui-tile.vue';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        Card,
        Container,
        HomeCarousel,
        Row,
        MenuExplorer,
        ListCards,
        SocialNetwork,
        ReminderBlockList,

        BlockPush,
        BlockAccess,
        BlockSplash,
        BlockCarousel,
        BlockHero,

        UiTile,
    },

    mixins: [ScrollMixin, HomepageDataMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);

        return {
            apiUrl: process.env.CMS_API_ENDPOINT,
            item: initData.item,
            caroussel: initData.caroussel,
            eventToday: initData.eventToday,
            futureEvent: initData.futureEvent,
            newsList: initData.newsList,
            actionsPromoted: initData.actionsPromoted,
            heroNews: initData.heroNews,
            accesRapides: initData.accesRapides,
            carteInteractive: initData.carteInteractive,
            heroVideoImage: initData.heroVideoImage,
            emploi: initData.emploi,
        };
    },

    computed: {
        ...mapState({
            menu: state => state.menu.menu,
            profile: state => state.authentication.profile,
            isLoggedIn: state => state.authentication.isLoggedIn,
            reminders: state => state.reminders.reminders,
        }),
        createAccountLink() {
            if (
                this.menu &&
                this.menu.accountExternalJson &&
                this.menu.accountExternalJson[this.$i18n.locale] &&
                this.menu.accountExternalJson[this.$i18n.locale].footer &&
                this.menu.accountExternalJson[this.$i18n.locale].footer.anonyme
            )
                return this.menu.accountExternalJson[this.$i18n.locale].footer.anonyme.filter(
                    menuItem => menuItem.id === 'header_menu_create_account',
                )[0];
            else return null;
        },
    },
    async asyncData({ app, query, store, params, error, env }, item) {
        /*
            Fonction pour faire un appel json qui retourne un tableau de résultats. 
        */
        async function getDataCollection(endPoint) {
            const response = await fetch(endPoint);
            const responseJson = await response.json();
            /*
                TODO ML : Ce code est utile, car si l'API Drupal voit qu'il n'y a pas de résultat,
                il retourne une structure vide. 

                structure "{"content":"[]","headers":{"Content-Type":"application\/json; type="}}"

                au lieu de []
            */
            if (responseJson && Array.isArray(responseJson)) {
                return responseJson;
            }
            // TODO ML : Sinon, nous avons reçu la structure de Drupal avec la
            return [];
        }
        function getNamesList(elementArray) {
            const elementNames = [];
            for (let j = 0; j < elementArray.length; j++) {
                elementNames[j] = elementArray[j].name;
            }
            return elementNames;
        }
        const today = app
            .$moment()
            .startOf('day')
            .toDate();

        const filterTodayEvent = {
            filterTodayEventStart: {
                condition: {
                    path: 'recurring_dates.value',
                    operator: '<=',
                    value: today,
                },
            },
            filterTodayEventEnd: {
                condition: {
                    path: 'recurring_dates.end_value',
                    operator: '>=',
                    value: today,
                },
            },
        };

        const filterActionPromoted = {
            filterMedia: {
                condition: {
                    path: 'promote',
                    operator: '=',
                    value: '1',
                },
            },
        };

        const filterFutureEventPromoted = {
            filterMedia: {
                condition: {
                    path: 'promote',
                    operator: '=',
                    value: '1',
                },
            },
            byRecurringDates: {
                condition: {
                    path: `recurring_dates.value`,
                    operator: '>=',
                    value: today,
                },
            },
        };

        // Caroussel d'images
        let caroussel = {};

        if (item.banner_item && item.banner_item === 'carousel' && item.carrousel && item.carrousel.id) {
            caroussel = {
                caroussel: app.$api.caroussel.getIndividual({
                    id: item.carrousel.id,
                }),
            };
        }

        if (item.banner_item && item.banner_item === 'video' && item.media_video && item.media_video.id) {
            caroussel = {
                caroussel: app.$api.video.getIndividual({
                    id: item.media_video.id,
                    include: ['field_media_video_file', 'field_media_file'],
                }),
            };
        }

        const homePageInfo = await app.$client.subRequests({
            ...caroussel,
            eventToday: app.$api.events.getCollection({
                sort: '-created',
                limit: 1,
                include: [],
                filters: filterTodayEvent,
            }),
            futureEvent: app.$api.events.getCollection({
                sort: 'recurring_dates.value',
                limit: 4,
                include: ['place', 'event_type', 'interests'],
                filters: filterFutureEventPromoted,
            }),
            newsList: app.$api.news.getCollection({
                sort: '-created',
                limit: 3,
                include: ['news_type', 'interests'],
                filters: filterActionPromoted,
            }),
            actionsPromoted: app.$api.actions.getCollection({
                sort: '-created',
                limit: 5,
                filters: filterActionPromoted,
                include: ['media_icon'],
            }),
        });
        // Les types d'événement auraient dû êtres mappés dans la fonction de normalisation.
        // Nous faisons le mappage ici pour ne pas impacter le reste du code.
        const futureEventsNormalized = app.$drupalComponentsAdapter.normalizeItems(homePageInfo.futureEvent.data);
        if (homePageInfo.futureEvent && homePageInfo.futureEvent.data) {
            for (let i = 0; i < homePageInfo.futureEvent.data.length; i++) {
                if (homePageInfo.futureEvent.data[i].event_type) {
                    futureEventsNormalized[i].event_type = getNamesList(homePageInfo.futureEvent.data[i].event_type);
                }
                if (homePageInfo.futureEvent.data[i].interests) {
                    futureEventsNormalized[i].interests = getNamesList(homePageInfo.futureEvent.data[i].interests);
                }
            }
        }

        // Les types d'événement auraient dû êtres mappés dans la fonction de normalisation.
        // Nous faisons le mappage ici pour ne pas impacter le reste du code.
        const newsNormalized = app.$drupalComponentsAdapter.normalizeItems(homePageInfo.newsList.data);
        if (homePageInfo.newsList && homePageInfo.newsList.data) {
            for (let i = 0; i < homePageInfo.newsList.data.length; i++) {
                if (homePageInfo.newsList.data[i].news_type) {
                    newsNormalized[i].news_type = getNamesList([homePageInfo.newsList.data[i].news_type]);
                }
                if (homePageInfo.newsList.data[i].interests) {
                    newsNormalized[i].interests = getNamesList(homePageInfo.newsList.data[i].interests);
                }
            }
        }

        let carousselData = null;

        try {
            // carousselConfiguration.banner.uri
            carousselData = {
                type: item.banner_item,
                data: homePageInfo.caroussel.data,
            };
        } catch (e) {
            // No need of log here
            // Only try to set carousselData if it exist
        }

        // On appelle les données ici séparément des appels normalisés ci-dessus. La raison est
        // pour simplifier les appels sans passer par la lourde mécanique de création et de mappage.
        const heroNews = await getDataCollection(
            process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/hero-carrousel',
        );
        const accesRapides = await getDataCollection(
            process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/acces-rapide',
        );
        const carteInteractive = await getDataCollection(
            process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/carte-interactive-home',
        );
        const heroVideoImage = await getDataCollection(
            process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/hero',
        );
        const emploi = await getDataCollection(
            process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/emploi-home',
        );

        return {
            item,
            eventToday: app.$drupalComponentsAdapter.normalizeItems(homePageInfo.eventToday.data),
            futureEvent: futureEventsNormalized,
            newsList: newsNormalized,
            actionsPromoted: homePageInfo.actionsPromoted.data,
            caroussel: carousselData,
            heroNews,
            accesRapides,
            carteInteractive: carteInteractive.length === 1 ? carteInteractive[0] : null,
            heroVideoImage: heroVideoImage.length === 1 ? heroVideoImage[0] : null,
            emploi: emploi.length === 1 ? emploi[0] : null,
        };
    },

    created() {
        this.setNewsData(this.newsList);
        this.setEventsData(this.futureEvent);
    },
};
</script>

<style lang="scss">
.reminders {
    align-items: stretch;
    .link {
        display: block;
        height: 100%;
        &__external,
        &__internal {
            height: 100%;
        }

        &__txt {
            display: block;
            height: 100%;
        }
    }
}
</style>
<style lang="scss" scoped>
.alerts-container {
    position: absolute;
    top: rem(-136px);
    width: 100%;
    z-index: 3;
    @include media(sm) {
        top: rem(-43px);
    }
}
</style>

<style lang="scss">
$sectionTitleFontSize: 15;

.home {
    &:first-child {
        margin-top: 43px;

        @include media(xs) {
            margin-top: 136px;
        }
    }

    &__no-account {
        margin-top: 130px;
        margin-bottom: 150px;

        @include media(xs) {
            margin-top: 30px;
        }

        &-container {
            width: 100%;
            border-top: 2px solid black;
            text-align: center;
        }

        &-title {
            margin: -2px auto;
            color: black;
            border: 2px solid black;
            font-weight: bold;
            letter-spacing: 0.9px;
            font-size: rem(15);
            text-transform: uppercase;
            padding: 18px 0px;
            width: 220px;
        }

        .link__external {
            &:hover {
                text-decoration: none !important;
            }
        }

        &-img {
            margin: 40px auto 0px;
        }

        &-txt {
            color: $--color-primary;
            font-size: rem(40);
            font-weight: 600;
            line-height: 1.2;
            margin-top: 26px;

            &-time {
                display: block;
                font-weight: bold;
            }
        }
    }
    &__top-container {
        @include media(md) {
            padding: 0 !important;
        }
    }

    &__first-row {
        margin-bottom: 30px;

        @include media(md) {
            margin-bottom: 110px;
        }

        @include media(xs) {
            margin-bottom: 0px;
        }
    }

    &__slider-col {
        position: relative;
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include media(sm) {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    &__welcome-container {
        margin-bottom: 50px;
        @include media(md) {
            margin-left: calc((100vw - 1073px) / 2);
        }
        @media only screen and (max-width: 1110px) {
            margin-left: none;
            margin: 0 auto;
        }
        @include media(xs) {
            margin: 0;
            margin-top: -100px;
        }
    }

    &__welcome {
        margin-top: 30px;
        color: $--color-dark-black;
        font-size: rem(40);
        font-weight: 700;
        line-height: 1;
    }

    &__spacer {
        height: 0px;
        width: 100%;
        display: flex;
    }

    &__welcome-col {
        display: flex;
        align-items: center;
    }

    &__welcome-name {
        margin-top: 0px;
        color: $--color-dark-black;
        font-size: rem(56);
        font-weight: bold;
        margin-bottom: 0px;
    }

    &__social {
        margin-top: 23px;

        @include media(xs) {
            display: none;
        }

        &--mobile {
            display: none;
            align-items: center;
            flex-flow: column;
            margin-bottom: 35px;
            width: 225px;
            margin-left: auto;
            margin-right: auto;

            @include media(xs) {
                display: flex !important;
            }
        }
    }

    &__social-txt {
        font-size: rem(14);
        color: #757575;
        letter-spacing: 0.9px;
        font-weight: 400;
        margin-bottom: 8px;

        &--mobile {
            width: 100%;
            margin-bottom: -3px;
        }
    }

    &__section {
        .items-list__header {
            margin-bottom: 39px;
        }

        .items-list__title {
            font-size: rem(15px);
            font-weight: 500;
        }

        .card__image-container .media__image {
            width: 100%;
        }

        .card__summary {
            font-size: rem(16px);
            max-width: 280px;
        }
    }

    &__section,
    &__section:last-child {
        margin-top: 40px;
        margin-bottom: 40px;

        .items__title {
            font-size: rem($sectionTitleFontSize);
        }
    }

    &__event-of-the-day {
        margin-top: rem(50);
    }

    &__sectionTitle {
        font-size: rem(56);
        font-weight: bold;
        margin: 0;
        display: inline-flex;
    }

    &__explorer {
        position: initial;
        margin-top: 120px;

        @include media(md) {
            margin-bottom: 80px;
            margin-top: 246px;
        }

        @include media(xs) {
            margin-top: 70px;
            margin-bottom: 40px;
        }

        &::before {
            content: '';
            display: flex;
            background-color: $--color-primary;
            height: 420px;
            position: absolute;
            transform: translateY(-75px);
            left: -100%;
            width: calc(200% - 130px);
            z-index: -1;

            @include media(md) {
                transform: translateY(-150px);
            }

            @include media(xs) {
                height: 321px;
                left: -30%;
                transform: translateY(-56px);
            }
        }

        .menu-explorer__container-title {
            width: 100%;
            margin-left: 0;
            margin-bottom: 31px;

            .link__txt {
                font-weight: 500 !important;
                font-size: rem(15px);
            }
        }

        .image-container {
            max-width: 1375px;
        }

        .menu__link-sub {
            text-align: left;
            font-size: rem($sectionTitleFontSize);

            .link__internal-container {
                margin-left: 0;
                border-bottom: 0px solid transparent;
            }

            .link__txt {
                font-weight: 400;
                color: white;
            }
        }
    }

    .service__action-wrapper {
        max-width: 436px;
        min-height: 92px;
        margin: auto;
        padding-top: 70px;
        padding-bottom: 100px;
        box-shadow: 0 0 64px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
    }

    .service__action-title {
        max-width: 190px;
        margin: auto;
        text-align: center;
        text-transform: uppercase;
        font-size: rem(15);
        font-weight: 500;
        letter-spacing: 0.9px;
        margin-bottom: 40px;
    }

    .service__content__more_actions {
        text-align: center;
    }

    .service__content__actions__item-col,
    .service__content__actions__item-container-link {
        width: 100%;

        .link__internal-container {
            width: 100%;
        }
    }

    .service__content__actions__item-link {
        width: 100%;
        text-align: left;
    }

    &__actions_item {
        border-bottom: 1px solid darkgray;
        padding-bottom: 2rem;
        padding-top: 2rem;
        i {
            float: right;
        }
    }
    &__more_actions {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
}

.home {
    &__section--news {
        padding-bottom: 0;
        margin-bottom: 0;

        @include media(md) {
            padding-bottom: 46px;
            margin-bottom: 126px;
        }

        @include media(xs) {
            margin-top: 0;
            margin-bottom: -39px;
            padding-bottom: 0px;
        }
    }

    &__section-container {
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 152px;
            left: -144px;
            background-color: #f9f7f0;
            height: 830px;
            width: calc(100% + 288px);
            z-index: -1;

            @include media(md) {
                height: 425px;
            }

            @include media(xs) {
                display: none;
            }
        }

        .items-list__item {
            @include media(xs) {
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 15%;
                    left: 0;
                    background-color: #f9f7f0;
                    height: 80%;
                    width: 100%;
                    z-index: -1;
                }
            }
        }
    }
}
</style>
