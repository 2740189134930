<template>
    <div class="wysiwyg" v-html="body"></div>
</template>

<script>
export default {
    props: {
        body: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.wysiwyg {
    font-size: rem(14px);
    font-weight: 400;

    @include media(sm) {
        font-size: rem(18px);
    }

    & > *:first-child {
        margin-top: 0 !important;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }

    ol,
    ul {
        padding-left: 21px;
    }

    ul {
        padding-left: 18px;
    }

    a {
        color: $--color-primary;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
        &.wysiwyg-button {
            background: none;
            background-color: transparent;
            border: 2px solid $--color-primary;
            color: $--color-primary;
            border-radius: 4px;
            padding: 15px 25px;
            font-size: rem(13px);
            font-weight: 400;
            font-family: $font-base;
            text-transform: uppercase;
            cursor: pointer;
            transition: 0.3s all;
            display: inline-block;
            line-height: 1;
        }

        &.wysiwyg-button {
            text-decoration: none;
        }

        &.wysiwyg-button:hover {
            color: white;
            background-color: $--color-primary;
        }

        &.wysiwyg-button--large {
            padding: 20px 30px;
        }
    }

    h2 {
        margin-top: 30px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-top: 3rem;
        margin-bottom: 3rem;
        display: block;
        overflow-x: scroll;

        @include media(sm) {
            display: table;
            overflow: auto;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }

        caption {
            display: none;
            color: $--color-table;
            font-size: rem(16px);
            font-weight: 500;
        }

        td,
        th {
            font-size: rem(14px);
            padding: 22px 27px;
            min-width: 232px;

            @include media(sm) {
                font-size: rem(16px);
                min-width: auto;
            }
            & > *:first-child {
                margin-top: 0;
            }
            & > *:last-child {
                margin-bottom: 0;
            }
        }

        thead {
            min-width: 710px;

            th {
                background-color: $--color-table-border;
                border: 2px solid $--color-table-border;
                text-align: left;

                &:not(:last-child) {
                    border-right-color: white;
                }
            }
        }

        tbody {
            min-width: 710px;
            background-color: white;
            border-collapse: collapse;
            border-spacing: 0;

            td {
                border: 2px solid $--color-table-border;
            }
        }
    }

    .embedded-entity {
        &.align-center {
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
        &.align-left {
            float: left;
        }
        &.align-right {
            float: right;
        }
    }

    img {
        max-width: 100%;
        &.align-center {
            display: block;
            margin-right: auto;
            margin-left: auto;
        }
        &.align-left {
            float: left;
        }
        &.align-right {
            float: right;
        }
    }
}
</style>
