import BaseResource from '../BaseResource';
import RequestFactory from '../RequestFactory';

class Profile extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/profil';
    }

    /**
     * Returns logged in user profile
     * @returns {RequestObject}
     */
    getMe() {
        return RequestFactory(this.resourcePath);
    }

    /**
     * Get Single Event
     * @param id
     * @param filters
     * @param others
     * @returns RequestObject
     */
    getIndividual({ id, filters = {}, include = [], waitFor = [] } = {}) {
        throw new Error('Method not supported');
    }

    /**
     * Get Collection of Events
     * @param params
     * @returns RequestObject|void
     */
    getCollection({ limit = 50, offset = 0, sort = [], filters = {}, include = [], waitFor = [] } = {}) {
        throw new Error('Method not supported');
    }
}

export default Profile;
