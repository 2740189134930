import Vue from 'vue';
import he from 'he';
import striptags from 'striptags';

export default ({ app }) => {
    const moment = app.$moment;

    Vue.filter('slug', slug => {
        return slug.slice(1);
    });

    Vue.filter('day', date => {
        return moment(date).format('D');
    });

    Vue.filter('month', date => {
        return moment(date).format('MMMM');
    });

    Vue.filter('shortMonth', date => {
        return moment(date).format('MMM');
    });

    Vue.filter('year', date => {
        return moment(date).format('YYYY');
    });

    Vue.filter('formatDate', (date, format) => {
        return moment(date).format(format);
    });

    Vue.filter('striphtml', function(value) {
        return striptags(value);
    });

    Vue.filter('encodehtml', function(value) {
        if (value === null) {
            return value;
        }
        return he.encode(value);
    });

    Vue.filter('decodehtml', function(value) {
        if (value === null) {
            return value;
        }
        return he.decode(value);
    });
};
