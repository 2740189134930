<template>
    <div
        :style="cssProps"
        class="container"
        :class="{
            'full-width': full,
            'big-padding': bigPadding,
            'huge-padding': hugePadding,
            padding: padding,
            'is-horizontal': horizontal,
            'no-margin': noMargin,
            'max-width-md': maxWidthMdOnly,
        }"
    >
        <Row :justify="justify ? justify : offset ? 'end' : 'start'">
            <el-col :span="24" :offset="pageWidth > 1395 && offset ? offsetSize : 0">
                <slot />
            </el-col>
        </Row>
    </div>
</template>

<script>
import Row from '~/components/Row.vue';

export default {
    components: {
        Row,
    },

    props: {
        full: {
            type: Boolean,
            default: false,
        },
        justify: {
            type: String,
            default: null,
        },
        padding: {
            type: Boolean,
            default: false,
        },
        bigPadding: {
            type: Boolean,
            default: false,
        },
        hugePadding: {
            type: Boolean,
            default: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: null,
        },
        offset: {
            type: Boolean,
            default: false,
        },
        offsetSize: {
            type: Number,
            default: 4,
        },
        maxWidth: {
            type: String,
            default: null,
        },
        maxWidthMdOnly: {
            type: Boolean,
            default: false,
        },
        noMargin: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            pageWidth: 1920,
        };
    },

    computed: {
        cssProps() {
            return {
                '--calc-max-width': this.maxWidth ? this.maxWidth : '1073px',
                '--calc-height': this.height ? this.height : 'auto',
            };
        },
    },

    mounted() {
        this.pageWidth = window.outerWidth;

        if (this.offset)
            window.addEventListener('resize', () => {
                this.pageWidth = window.outerWidth;
            });
    },
};
</script>

<style lang="scss" scoped>
.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1073px;
    height: var(--calc-height);

    @include media(md) {
        max-width: var(--calc-max-width);
    }

    &.max-width-md {
        @include media(lg) {
            max-width: 1073px;
        }
    }

    &.no-margin {
        @include media(lg) {
            margin-left: 0;
        }
    }

    &.padding {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.big-padding {
        @include padding-content();
    }

    &.huge-padding {
        @include huge-padding-content();
    }

    &.full-width {
        max-width: 100%;
    }

    &.is-horizontal > .el-row > .el-col {
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
    }
}
</style>
