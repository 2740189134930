<template>
    <li :class="{ 'is-active': isActive }" class="c-block-accordions">
        <block-accordions-wrapper ref="accordions" @onOpen="isActive = true" @onClose="isActive = false">
            <template v-slot:toggler="props">
                <button
                    :id="'accordion--toggle--' + getId()"
                    class="c-block-accordions_toggler || c-heading -h4-regular"
                    :aria-expanded="isActive ? 'true' : 'false'"
                    :aria-controls="'accordion-content--' + getId()"
                    @click="props.toggle"
                >
                    <span>
                        {{ label }}
                    </span>
                    <ui-icon type="chevronDown" />
                </button>
            </template>
            <template v-slot:content>
                <slot :id="'accordion-content--' + getId()" />
            </template>
        </block-accordions-wrapper>
    </li>
</template>

<script>
import BlockAccordionsWrapper from '~/components/locomotive/block-accordions-wrapper.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';
export default {
    components: {
        UiIcon,
        BlockAccordionsWrapper,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
    },
    data: () => {
        return {
            isActive: false,
        };
    },
    watch: {
        isActive: 'emitState',
    },
    methods: {
        open() {
            this.$refs.accordions.open();
        },
        close() {
            this.$refs.accordions.close();
        },
        emitState(isActive) {
            if (isActive) {
                this.$emit('onOpen', this._uid);
            } else {
                this.$emit('onClose', this._uid);
            }
        },
        getId() {
            return this._uid + 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-accordions_toggler {
    position: relative;
    padding-top: loco-rem(16px);
    padding-bottom: loco-rem(16px);
    padding-right: loco-rem(28px);
    width: 100%;
    text-align: left;
    color: $loco-light;
    background-color: $loco-neutral-darker;

    &:hover,
    .c-block-accordions.is-active & {
        background-color: $loco-neutral-darker-hover;
    }

    .c-ui-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: rotate(0deg) translate3d(0, -50%, 0);
        transition: transform $loco-speed $loco-easing;
        transform-origin: top center;
    }

    .c-block-accordions.is-active & {
        .c-ui-icon.-chevronDown {
            transform: rotate(-180deg) translate3d(0, -50%, 0);
        }
    }
}
</style>
