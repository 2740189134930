export const state = () => ({
    isLoggedIn: false,
    profile: null,
});

export const mutations = {
    setIsLoggedIn(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn;
    },

    setProfile(state, profileObject) {
        state.profile = profileObject;
    },
};

export const actions = {
    fetchProfile({ commit }, client) {
        client
            .execute('profile', 'getMe')
            .then(results => {
                commit('setIsLoggedIn', true);
                commit('setProfile', results.data);
            })
            .catch(e => {
                client.ctx.$externalLog.captureException(e);
            });
    },
};

export const getters = {
    getPostalCodeObject: state => {
        if (
            state.profile &&
            state.profile.adresses &&
            state.profile.adresses.length > 0 &&
            state.profile.adresses[0].codePostal.length > 0
        ) {
            const rawPostalCode = state.profile.adresses[0].codePostal;
            return {
                fsa: rawPostalCode.slice(0, 3),
                ldu: rawPostalCode.slice(3),
            };
        }
        return null;
    },
};
