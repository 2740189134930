<template>
    <container class="interactive-map" padding>
        <h1 class="visually-hidden">{{ $t('interactiveMap.title') }}</h1>
        <Container v-if="initData.item.body != null" class="interactive-map__intro" big-padding padding>
            <wysiwyg class="item__wysiwyg item__wysiwyg--bigger" :body="initData.item.body.processed" />
        </Container>
        <maps-list :items="items"></maps-list>
    </container>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import MapsList from '~/components/MapsList';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        MapsList,
        Container,
        Wysiwyg,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            items: initData.items,
        };
    },

    async asyncData({ app, query, store }, item) {
        const results = await app.$client.execute('interactiveMaps', 'getCollection', {
            include: ['node_type'],
        });

        return {
            item,
            items: results.data,
        };
    },
};
</script>

<style lang="scss" scoped>
.interactive-map {
    margin-top: 30px;
    &__intro {
        margin-bottom: 26px;

        @include media(sm) {
            margin-bottom: 34px;
        }
    }
}
</style>
