<template>
    <content-details :title="item.title" :type-tags="item.typeTags" :infos="item.infos">
        <template class="requests_and_payments">
            <transition name="fade">
                <div>
                    <Row gutter>
                        <el-col class="requests_and_payments__summary" :span="16" :sm="16">
                            <!-- WYSIWYG (Contenu) -->
                            <h3 v-if="item.body && item.body.summary">
                                {{ item.body.summary | decodehtml }}
                            </h3>
                        </el-col>
                    </Row>
                    <Row gutter class="requests_and_payments__body">
                        <!-- eslint-disable-next-line prettier/prettier -->
                        <el-col :span="14" :sm="14" :offset="5">
                            <!-- WYSIWYG (Contenu) -->
                            <wysiwyg
                                v-if="item.body && item.body.processed"
                                class="requests_and_payments__body__wysiwyg"
                                :body="item.body.processed"
                            />
                        </el-col>
                    </Row>
                    <Row v-if="form" gutter class="requests_and_payments__form">
                        <!-- eslint-disable-next-line prettier/prettier -->
                        <el-col :span="14" :sm="14" :offset="5">
                            <!-- WYSIWYG (Contenu) -->
                            <FormSchemaBuilder :webform="form" />
                        </el-col>
                    </Row>
                </div>
            </transition>
        </template>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import ContentDetails from '~/components/layout/ContentDetails';
import FormSchemaBuilder from '~/components/forms/FormSchemaBuilder';
import Row from '~/components/Row';
import Wysiwyg from '~/components/Wysiwyg';
export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        ContentDetails,
        Row,
        FormSchemaBuilder,
        Wysiwyg,
        // EventsCards,
    },
    mixins: [ScrollMixin],
    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },
    data() {
        const initData = cloneDeep(this.initData);
        return {
            sideMenu: [],
            activeActionTab: 'linkedActions',
            item: initData.item,
            form: initData.form,
        };
    },
    computed: {},
    async asyncData({ app, query, store, params, error, env }, item) {
        // Page infos
        let form;
        if (item.form) {
            const results = await app.$client.execute('webformJsonSchema', 'getIndividual', {
                id: item.form.id,
            });

            form = results.data;
        }

        return { item, form };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="scss">
.requests_and_payments {
    &__top_content {
        margin-bottom: 1rem;
    }
    &__bottom_content {
        margin-bottom: 3rem;
        margin-top: 3rem;
    }
    &__summary {
        margin-bottom: 2rem;
    }
    &__body {
        margin-top: 2rem;
        &__wysiwyg {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
        }
    }

    &__related-cards-item {
        margin-bottom: 25px;
    }

    &__menu {
        margin-bottom: 20px;
    }

    &__menu-link-container {
        margin-bottom: 8px;
    }
    &__form {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__actions {
        border: 1px solid darkgrey;
        margin-bottom: 3rem;
        padding: 1rem;
        &__item {
            border-bottom: 1px solid darkgray;
            padding-bottom: 1rem;
            padding-top: 1rem;
            i {
                float: right;
            }
        }
        &__more_actions {
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
    }
    &__events {
        margin-bottom: 3rem;
    }
}
</style>
