<template>
    <div gutter-small class="menu-explorer">
        <div v-for="item in menuItem" :key="item.title" class="menu__content">
            <!-- Link with image -->
            <h2 v-if="!isMenuItem" class="menu-explorer__title">
                {{ item.attributes.link.title }}
            </h2>
            <ul class="menu-explorer image-container">
                <li v-for="itemSub in item.children" :key="itemSub.title" class="menu__content">
                    <custom-link
                        class="menu__link menu__link-sub-sub"
                        :to="itemSub.attributes.link.uri"
                        :no-link="isTrueLink(itemSub.attributes.link.uri)"
                        :style="`border-radius: ${radius ? radius : 0}px;`"
                        white
                    >
                        <component :is="tag">
                            <span
                                class="menu__link-bg"
                                :style="`background-image: url(${itemSub.attributes.bg_image})`"
                            ></span>
                            <span class="menu__link-txt">
                                {{ itemSub.attributes.link.title }}
                                <span class="menu__link-txt__city">Longueuil</span>
                            </span>
                        </component>
                    </custom-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        CustomLink,
    },

    props: {
        menuItem: {
            type: Array,
            default: () => [],
        },
        isMenuItem: {
            type: Boolean,
            default: true,
        },
        span: {
            type: Number,
            default: 24,
        },
        sm: {
            type: Number,
            default: 8,
        },
        md: {
            type: Number,
            default: 4,
        },
        customGutter: {
            type: Number,
            default: 0,
        },
        radius: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        tag() {
            return this.isMenuItem ? 'span' : 'h3';
        },
    },

    methods: {
        // Sert à savoir si l'url pointe vers la page d'accueil ("/fr ou /en")
        isTrueLink(link) {
            return link === `/${this.$i18n.locale}`;
        },
    },
};
</script>

<style lang="scss">
$minWidth: 234px;
$minWidthMobile: 105px;

#maVille0 {
    .menu__sub-menu-ul {
        width: 100%;
        margin-bottom: 2rem;
    }

    .menu-explorer__container-title {
        .link__internal-container {
            border: none;

            .link__txt {
                &:after {
                    display: none;
                }
            }
        }
    }

    .image-container {
        & > .menu__content {
            padding: 0;
            max-width: none;
            margin-bottom: 0;
            width: 50%;

            @include media(sm) {
                width: 25%;
            }

            .menu__link-txt {
                font-size: rem(18px);

                @include media(xs) {
                    font-size: rem(12px) !important;
                }

                &__city {
                    font-size: 15px;
                    color: $--color-white;

                    @include media(xs) {
                        font-size: rem(10px) !important;
                    }

                    @include media(md) {
                        color: $--color-dark-gray;
                    }
                }
            }
        }
    }

    & > .menu-explorer > .menu__content {
        max-width: 100%;
        padding: 0 !important;
    }
}

.menu-explorer {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    max-width: rem(1074px);

    &__title {
        color: $--color-white;
        text-transform: uppercase;
        @include trait();
        font-size: rem(16px);
        @include media(sm) {
            margin-bottom: rem(48px);
        }
    }

    .menu {
        &__content {
            width: 100%;

            .menu__content {
                transition: 0.45s all, visibility 0ms;
                width: 50%;

                @include media(sm) {
                    width: 25%;
                }

                &:hover {
                    transition: 1s all, visibility 0ms;
                }
            }

            .menu__content {
                &:hover {
                    transform: translateY(-5px);
                }
            }

            &:nth-child(2n) {
                margin-top: 25px;

                @include media(xs) {
                    margin-top: 34px;
                }

                &:hover {
                    transform: translateY(5px);
                }
            }

            .image-container {
                margin: 0 -11px;
                max-width: calc(1074px + 22px);
                width: calc(100% + 22px);

                @include media(xs) {
                    width: calc(100% + 5px);
                    margin: 0 -3px;
                }

                & > .menu__content {
                    margin-bottom: 65px;
                }
            }
        }

        &__link-sub {
            text-align: right;
            display: flex;
            width: 100%;

            .link__internal-container,
            .link__internal {
                margin: auto;
                .link__txt {
                    @include trait(true);
                }
            }
        }

        &__link-bg {
            position: absolute;
            text-align: left;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 66px);
            background-color: $--color-primary;
            background-position: center;
            background-size: cover;
            box-shadow: 0px 11px 30px 2px rgba(0, 0, 0, 0.37);
            filter: sepia(0%);
            transition: 0.3s all, visibility 0ms;
            border-radius: 4px;
        }

        &__link-txt {
            position: absolute;
            bottom: 47px;
            left: 0px;
            transform: translateY(100%);
            color: $--color-info;
            font-weight: bold;
            pointer-events: none;
            font-size: rem(16);
            line-height: 1;
            text-align: left;

            @include media(sm) {
                letter-spacing: 0.5px;
                text-align: left;
                font-size: rem(18);
            }

            @include media(md) {
                font-size: rem(24);
            }

            &__city {
                display: block;
                margin-top: 5px;
                letter-spacing: rem(1.68);
                font-weight: 500;
                color: $--color-text-regular;
                font-size: rem(18);
                @include media(xs) {
                    letter-spacing: 0.28px;
                    font-size: rem(14) !important;
                }
            }
        }

        &__link-sub-sub {
            margin: 0 11px;
            transition: 0.25s all, visibility 0ms;

            &:hover {
                .menu__link-bg {
                    box-shadow: 0px 12px 31px 3px rgba(0, 0, 0, 0.4);
                    filter: sepia(40%);
                }
            }

            .link__internal,
            .link__internal-container {
                background-image: cover;
                display: flex;
                width: 100%;
                height: 480px;
                align-items: center;
                align-content: center;
                text-align: right;

                @include media(xs) {
                    height: 286px;
                }
            }
            .link__external-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                transform: none;
            }

            .link__internal-container {
                position: relative;
            }

            .link__txt {
                z-index: 2;
            }
        }
    }
}
</style>
