<template>
    <component
        :is="noAutocomplete ? 'el-input' : 'el-autocomplete'"
        :id="id"
        v-model="txt"
        :placeholder="placeholder"
        :label="barLabel"
        class="search-bar input-with-select"
        :class="{ 'with-bg': background, 'small-padding': smallPadding }"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        :select-when-unmatched="noAutocomplete ? undefined : true"
        @select="handleSelect"
    >
        <button
            slot="suffix"
            :aria-label="`${$t('a11y.searchBars.searchButton')}`"
            class="el-input__icon"
            @click="
                e => {
                    search();
                }
            "
        >
            <searchIcon />
        </button>
    </component>
</template>

<script>
import searchIcon from '~/components/icons/search';

export default {
    components: {
        searchIcon,
    },

    props: {
        id: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'PLACEHOLDER',
        },
        isGlobal: {
            type: Boolean,
            default: false,
        },
        background: {
            type: Boolean,
            default: false,
        },
        smallPadding: {
            type: Boolean,
            default: false,
        },
        noAutocomplete: {
            type: Boolean,
            default: false,
        },
        barLabel: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            txt: this.value,
        };
    },

    watch: {
        txt(val) {
            this.$emit('input', val);
        },
        value(val) {
            this.txt = val;
        },
    },
    methods: {
        async querySearch(queryString, cb) {
            if (queryString.length < 3) {
                const a = [];
                cb(a);
                return;
            }
            const results = await this.$client.get(
                process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale,
                '/search_api_autocomplete/solr_search_content?q=' + queryString,
            );
            cb(results.data);
        },
        handleSelect(item) {
            this.txt = item.value;
            this.search();
        },

        search() {
            this.$emit('search', this.txt);
        },
    },
};
</script>

<style lang="scss" scoped>
.search-bar.input-with-select {
    display: flex;
    width: 100%;

    &__button {
        opacity: 0.4;
        &:hover {
            opacity: 0.8;
        }
        &:active {
            opacity: 1;
        }
    }
    li {
        line-height: normal;
        padding: 7px;

        .value {
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .link {
            font-size: 12px;
            color: #b4b4b4;
        }
    }

    .el-input__icon {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        border: none;
        display: flex;
        margin-right: -5px;
        border-radius: 0 4px 4px 0px;
    }
}
</style>

<style lang="scss">
@keyframes searchAnimation {
    0% {
        transform: rotate(0deg) scale(1.01) translate(0.25px, 0.25px);
    }
    33% {
        transform: rotate(7deg) scale(1.01) translate(0.25px, 0.25px);
    }
    66% {
        transform: rotate(-7deg) scale(1.01) translate(0.25px, 0.25px);
    }
    100% {
        transform: rotate(0deg) scale(1.01) translate(0.25px, 0.25px);
    }
}

.search-bar.input-with-select {
    .el-input__inner {
        height: rem(40px);
        padding-left: 0;
        border-width: 0px 0px 2px 0px;
        border-radius: 4px 4px 0 0;
        font-weight: 400;
        font-size: rem(13px);
        font-style: italic;

        @include media(sm) {
            height: rem(54px);
        }

        &:not(:focus) {
            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom-color: $--color-primary;
        }

        &::placeholder {
            color: #333;
        }
    }

    .el-input__suffix {
        right: rem(-2px);
        @include media(sm) {
            right: rem(5px);
        }
    }

    .el-input__icon {
        padding-right: 0px;
        padding-left: 15px;
        width: 32px;

        svg {
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transform-origin: bottom right;
            transition: 1s all;
        }

        &:hover {
            svg {
                animation-name: searchAnimation;
                animation-duration: 1s;
                filter: drop-shadow(1px 0px 8px rgba($--color-primary, 1));
            }
        }
    }

    &.with-bg {
        .el-input__inner {
            background-color: #f3f1eb;
            padding-left: rem(16px);
            font-style: normal;
            @include media(sm) {
                padding-left: rem(28px);
            }
        }

        .el-input__icon {
            padding-right: 10px;
            width: 50px;
        }
    }

    &.small-padding {
        .el-input__inner {
            padding-left: 22px;
        }

        .el-input__icon {
            padding-right: 24px;
            opacity: 0;

            @include media(sm) {
                opacity: 1;
            }
        }
    }
}
</style>
