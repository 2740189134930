import { render, staticRenderFns } from "./LoaderWrapper.vue?vue&type=template&id=c05c7368&scoped=true&"
import script from "./LoaderWrapper.vue?vue&type=script&lang=js&"
export * from "./LoaderWrapper.vue?vue&type=script&lang=js&"
import style0 from "./LoaderWrapper.vue?vue&type=style&index=0&id=c05c7368&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c05c7368",
  null
  
)

export default component.exports