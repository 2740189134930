<template>
    <div class="c-block-push" data-scroll>
        <div class="o-container">
            <div class="o-layout -gutter-small -reverse -middle">
                <div class="o-layout_item u-7/12@from-medium">
                    <div class="c-block-push_visuals">
                        <div
                            v-for="(image, index) in images"
                            :key="index"
                            :class="[{ '-top': index === 0 }, { '-middle': index === 1 }, { '-bottom': index === 2 }]"
                            class="c-block-push_visuals_item"
                        >
                            <ui-lazyimage
                                v-if="image.src"
                                :image-src="image.src"
                                :image-width="image.width"
                                :image-height="image.height"
                                :image-alt="image.alt"
                                class="c-block-push_image"
                            />
                        </div>
                    </div>
                </div>
                <div class="o-layout_item u-5/12@from-medium">
                    <div class="c-block-push_inner">
                        <h2 class="c-block-push_title || c-heading -h2">{{ title }}</h2>
                        <p class="c-block-push_description || c-text -body1">
                            {{ description }}
                        </p>
                        <div class="c-block-push_cta_wrapper">
                            <ui-cta
                                :title="linkLabel"
                                :label="linkLabel"
                                :url="linkUrl"
                                :link-type="linkType"
                                class="c-block-push_cta || u-mt-6"
                                type="button"
                                color="tonicDark"
                                icon-type="arrowRight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiLazyimage from '~/components/locomotive/ui-lazyimage.vue';
export default {
    components: {
        UiLazyimage,
        UiCta,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        linkLabel: {
            type: String,
            default: '',
        },
        linkUrl: {
            type: String,
            default: '',
        },
        linkType: {
            type: String,
            default: '',
        },
        images: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-push {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: loco-rem($loco-margin-wide);
    padding-bottom: loco-rem($loco-margin-wide);
    background-color: $loco-tonic;
}
.c-block-push_inner {
    position: relative;
    color: $loco-light;
    text-align: left;

    @media (max-width: $to-medium) {
        margin-top: calc(clamp(#{loco-rem(96px)}, 12.8vw, #{loco-rem(128px)}));
    }
}
.c-block-push_title {
    max-width: loco-rem(546px);
}
.c-block-push_description {
    max-width: loco-rem(318px);
    padding-right: loco-rem(32px);
    margin-left: auto;
    margin-right: 0;
    margin-top: calc(clamp(#{loco-rem(32px)}, 7.5vw, #{loco-rem(108px)}));

    @media (min-width: $from-medium) {
        margin-right: auto;
    }
}
.c-block-push_cta_wrapper {
    max-width: loco-rem(318px);
    margin-left: auto;
    margin-right: 0;

    @media (min-width: $from-medium) {
        margin-right: auto;
    }
}

.c-block-push_visuals {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: (380 / 387) * 100%;
    }
}

.c-block-push_visuals_item {
    position: absolute;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    @media (max-width: $to-medium) {
        border-radius: 16px 16px 16px 0px;
    }
    @media (min-width: $from-medium) {
        border-radius: 24px 24px 24px 0px;
    }

    &:before {
        display: block;
        content: '';
        width: 100%;
    }

    &:after {
        content: '';
        @include u-stretch;
    }

    &.-top {
        top: 0;
        right: span(116/776);
        width: span(204/776);
        background-color: $loco-tonic-dark;
        z-index: 1;
        &:before {
            padding-top: (240 / 204) * 100%;
        }
        &:after {
            background: linear-gradient(63.84deg, rgba(0, 150, 214, 0.64) 0%, rgba(0, 150, 214, 0) 36.85%);
        }
    }
    &.-middle {
        top: span(160/760);
        left: 0;
        width: 100%;
        background-color: $loco-tonic-dark;
        &:before {
            padding-top: (464 / 776) * 100%;
        }
        &:after {
            background: linear-gradient(64.12deg, rgba(0, 150, 214, 0.64) 0%, rgba(0, 150, 214, 0) 50.36%);
        }
    }
    &.-bottom {
        bottom: 0;
        left: span(114/776);
        width: span(318/776);
        background-color: $loco-tonic-dark;
        &:before {
            padding-top: (224 / 318) * 100%;
        }
        &:after {
            background: linear-gradient(71.55deg, rgba(0, 150, 214, 0.64) 0%, rgba(0, 150, 214, 0) 35.94%);
        }
    }

    .c-block-push_image {
        @include u-stretch;
    }
}
</style>
