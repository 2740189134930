const initReminders = () => ({
    reminders: {
        en: null,
        fr: null,
    },
});

export const state = initReminders;

export const mutations = {
    setReminders(state, { lang, val }) {
        state.reminders[lang] = val;
    },

    resetReminders(state, type) {
        state[type] = initReminders()[type];
    },

    reset(state) {
        const initState = initReminders();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};

export const actions = {
    async fetchReminderForUser({ commit, rootState }, app) {
        const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;

        if (rootState.authentication.isLoggedIn) {
            try {
                const resultsReminders = await app.$client.execute('rappels', 'getCollection');
                commit('setReminders', { lang, val: resultsReminders });
            } catch (e) {
                app.$externalLog.captureException(e);
            }
        }
    },
};
