import BaseResource from '../BaseResource';

class Alerts extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/alerts';
    }
}

export default Alerts;
