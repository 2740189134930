import qs from 'qs';

export default (ctx, inject) => {
    inject('mailto', new Mailer());
};

class Mailer {
    getMailtoString(params = {}) {
        const { to = '', ...paramsWithoutTo } = params;
        let string = 'mailto:' + to + '?';
        return (string += qs.stringify(paramsWithoutTo));
    }
}
