import BaseResource from '../BaseResource';
import SolrSearchFactory from '../SolrSearchFactory';

class SolrSearch extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/solrSearch';
    }

    getDatas({ uri, limit = 50, offset = 0, sort = [], filters = {}, include = [], waitFor = [] } = {}) {
        return SolrSearchFactory(uri, [], [], []);
    }
}

export default SolrSearch;
