<template>
    <Container padding class="profile">
        <Title title="Profil"></Title>
        <Container padding>
            <Row>
                <el-col :span="5" class="profile__left_menu">
                    <!--left side menu-->
                    <Row class="profile__left_menu__item">
                        <el-button type="text">
                            Mon profil
                        </el-button>
                    </Row>
                    <Row class="profile__left_menu__item">
                        <el-button type="text">
                            Ma famille
                        </el-button>
                    </Row>
                </el-col>
                <el-col :span="17" :offset="2" class="profile__content">
                    <!--content page-->
                    <div class="profile__content__top">
                        <Row class="profile__content__top__row">
                            <el-col :span="9" class="profile__content__top__row__item">
                                <p>Prénom : {{ profile.prenom }}</p>
                                <p>Nom : {{ profile.nom }}</p>
                                <p>Sexe : {{ profile.sexe }}</p>
                            </el-col>
                            <el-col :span="14" :offset="1" class="profile__content__top__row__item"></el-col>
                        </Row>
                        <Row class="profile__content__top__row">
                            <el-col :span="24" class="profile__content__top__row__item"></el-col>
                        </Row>
                    </div>
                    <div class="profile__content__bottom">
                        <Poll :poll="poll"></Poll>
                    </div>
                </el-col>
            </Row>
        </Container>
    </Container>
</template>

<script>
import { mapState } from 'vuex';
import Container from '../components/Container';
import Poll from '../components/Poll';
import Row from '../components/Row';
import Title from '../components/Title';

import ScrollMixin from '~/mixins/scroll-mixin.js';

export default {
    nuxtI18n: {
        paths: {
            fr: '/profil',
            en: '/profile',
        },
    },

    components: {
        Title,
        Container,
        Row,
        Poll,
    },

    mixins: [ScrollMixin],

    head() {
        return this.$metaTags.head(this.metaTags);
    },
    props: {
        metaTags: {
            default: null,
            type: Array,
        },
    },

    data() {
        return {
            poll: [
                {
                    question: '1. Je cherche des activités à faire...',
                    answers: ['Option A', 'Option B', 'Option C'],
                },
                {
                    question: "2. J'aime les événements...",
                    answers: ['Option A', 'Option B', 'Option C'],
                },
                {
                    question: '3. Pour aller au travail, je me déplace...',
                    answers: ['Option A', 'Option B', 'Option C'],
                },
                {
                    question: '4. Je pratique régulièrement les sports suivants...',
                    answers: ['Option A', 'Option B', 'Option C'],
                },
            ],
        };
    },

    computed: {
        ...mapState({
            profile: state => state.authentication.profile,
        }),
    },
};
</script>

<style lang="scss">
.profile {
    margin-bottom: 15rem;
    &__left_menu {
        &__item {
            border-bottom: 1px solid rgba(228, 228, 228, 1);
            padding-top: 0.5rem;
        }
    }
    &__content {
        &__top {
            &__row {
                &__item {
                    background: gainsboro;
                    height: 9rem;
                    margin-bottom: 2rem;
                    padding: 2rem;
                }
            }
        }
    }
}
</style>
