<template>
    <list-with-filter
        class="places-list-page"
        taxonomy-type="event_type"
        :title="$t('place.title')"
        :search-bar-placeholder="$t('place.findPlace')"
        :facets="facets"
        :filter-values="filterValues"
        :total-count="totalCount"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-filter-header
        @filterUpdate="filterUpdateHandler"
    >
        <!-- Promoted events -->
        <template slot="top">
            <div v-if="horizontalPlaces.length > 0" class="list places-list" horizontal full>
                <div class="promoted__block">
                    <row align>
                        <h2 class="promoted__block-header">
                            {{ $t('place.featured') }}
                        </h2>
                        <div class="promoted__block-separator"></div>
                    </row>
                </div>
                <row gutter-small class="promoted">
                    <el-col v-for="place in horizontalPlaces" :key="place.id" :span="24" :sm="24" :md="8">
                        <custom-link :to="place.url" class="promoted__url">
                            <div class="promoted__item">
                                <div v-if="place.mediaImage" class="promoted__image">
                                    <media-image fit="cover" :image="place.mediaImage" size="front_large_21_9" />
                                </div>
                                <div class="promoted__item__block">
                                    <!-- <p>{{ place.adresse.locality }}</p> -->
                                    <h3 class="promoted__title" v-html="place.title"></h3>
                                </div>
                            </div>
                        </custom-link>
                    </el-col>
                    <div class="promoted__fading"></div>
                </row>
            </div>
        </template>

        <template>
            <loader-wrapper v-if="places.length !== 0" :loading="isUpdating">
                <section>
                    <article v-for="p in places" :key="p.entity_id" class="place__item">
                        <div class="place__image">
                            <uploaded-image fit="cover" :path="p.image ? p.image : '/placeholder_284x190.svg'" />
                        </div>
                        <div class="place__block">
                            <h3 class="place__title">
                                <custom-link :to="p.entity_alias" info>
                                    {{ p.entity_title | decodehtml }}
                                </custom-link>
                            </h3>
                            <p class="place__excerpt">{{ p.summary | striphtml }}</p>
                        </div>
                    </article>
                </section>
            </loader-wrapper>
            <p v-else>{{ this.$t('places.noPublications') + '.' }}</p>
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';
import Row from '~/components/Row';
import UploadedImage from '~/components/UploadedImage';
import mediaImage from '~/components/MediaImage';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        LoaderWrapper,
        Row,
        ListWithFilter,
        mediaImage,
        UploadedImage,
        CustomLink,
    },
    mixins: [ScrollMixin],
    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },
    data() {
        const initData = cloneDeep(this.initData);
        return {
            places: initData.places,
            totalCount: initData.totalCount,
            itemsPerPage: initData.itemsPerPage,
            horizontalPlaces: initData.horizontalPlaces,
            filterValues: initData.filterValues,
            facets: initData.facets,
            page: parseInt(this.$route.query.page) || 1,
            isUpdating: false,
        };
    },
    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        page() {
            this.updatePlaces(true);
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        // Load initial datas, format filters and sets them
        const facets = [];
        const filterValues = {
            byTitle: '',
            byDate: 'default',
            byDateRange: [],
            byType: [],
        };
        const path =
            process.env.CMS_API_ENDPOINT +
            '/' +
            app.i18n.locale +
            `/api/search-places?search=&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`;
        const parameters = {};
        const response = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(path, parameters),
        });

        if (response.data.facets) {
            for (const [facetId, filters] of Object.entries(response.data.facets)) {
                const facet = { facetId, filters: [] };
                for (const [key, f] of Object.entries(filters)) {
                    facet.filters.push({
                        id: key,
                        value: f.values.value,
                        name: f.values.label,
                        active: f.values.active ? f.values.active === 'true' : false,
                    });
                }
                facets.push(facet);
            }
        }

        const promotedPlacesResponse = await app.$client.execute('places', 'getCollection', {
            filters: { promoted_events_page: 1 },
            sort: '-created',
            include: [],
            limit: 3,
        });

        const horizontalPlaces = app.$drupalComponentsAdapter.normalizeItems(promotedPlacesResponse.data);

        return {
            horizontalPlaces,
            places: response.data.results,
            totalCount: parseInt(response.data.pager.count),
            itemsPerPage: parseInt(response.data.pager.items_per_page),
            filterValues,
            facets,
        };
    },

    methods: {
        async updatePlaces(pageOnly = false) {
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;

            const path = process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + `/api/search-places?search=`;
            const parameters = {
                search: this.filterValues.byTitle + `&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`,
                facets: this.filterValues.byType,
            };
            const response = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(path, parameters),
            });

            if (response.data.facets) {
                const facets = [];
                for (const [facetId, filters] of Object.entries(response.data.facets)) {
                    const facet = { facetId, filters: [] };
                    for (const [key, f] of Object.entries(filters)) {
                        facet.filters.push({
                            id: key,
                            value: f.values.value,
                            name: f.values.label,
                            active: f.values.active ? f.values.active === 'true' : false,
                        });
                    }
                    facets.push(facet);
                }
                this.facets = facets;
            }

            this.places = response.data.results;
            this.totalCount = parseInt(response.data.pager.count);
            this.itemsPerPage = parseInt(response.data.pager.items_per_page);
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updatePlaces();
        },
    },
};
</script>

<style lang="scss" scoped>
.list-with-filter {
    &__top-slot {
        padding-left: -72px;
    }
    &__first-row {
        margin-bottom: 50px;
    }

    &__header-img {
        width: 100%;
        margin-bottom: 60px;
    }
}

.list {
    .row {
        margin-left: 0;
    }
}

.promoted {
    margin: -75px 0px 0px 70px;
    max-width: 1188px;

    &__item {
        display: flex;
        flex-flow: column;

        width: 100%;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
        text-align: left;
        background-color: $--color-white;
        margin-bottom: 20px;
        margin-right: 22px;

        @include media(sm) {
            flex-flow: row;
            align-items: center;
            height: 108px;
        }

        &__block {
            padding: 13px 25px 30px;

            @include media(sm) {
                padding: 23px 24px;
            }
        }
    }

    &__image {
        height: 100%;

        .media__image {
            width: 100%;
            height: 100%;

            @include media(sm) {
                min-height: auto;
                width: 161px;
            }
        }
    }

    &__block {
        width: 721px;
        max-height: 100%;
        height: 175px;
        background-color: $--color-primary;
        margin-left: -69px;

        &-header {
            color: $--color-white;
            font-size: rem(15px);
            margin-right: 20px;
            margin-top: 58px;
            margin-left: 70px;
            font-weight: 500;
            letter-spacing: rem(2.24px);
        }

        &-separator {
            background-color: $--color-white;
            width: 100px;
            height: 2px;
            margin-top: 66px;
        }
    }

    &__title {
        text-decoration: none;
        color: $--color-info;
        font-size: rem(14px);
        font-weight: 800;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(md) {
            width: 11vw;
            max-width: 147px;
            height: 18px;
            white-space: nowrap;
        }
    }

    &__place {
        font-size: rem(12px);
        font-weight: 700;
        color: $--color-primary;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(md) {
            width: 11vw;
            height: 16px;
            max-width: 147px;
            white-space: nowrap;
        }

        &-icone {
            margin-right: 7px;
            width: 9px;
        }
    }
}
.places-list-page {
    .filters {
        @include media(sm) {
            margin-top: 0;
        }
    }

    .list-with-filter {
        &__first-row {
            @include media(sm) {
                margin-bottom: 58px;
            }
        }
        &__main-cards-item-wrapper {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 1rem;
            }

            @include media(sm) {
                margin-bottom: 0;
            }

            .el-card__body {
                padding: 0;

                @include media(sm) {
                    padding: 20px;
                }
            }

            .media__image {
                height: 52vw;
                min-height: 172px;

                @include media(sm) {
                    height: 190px;
                    min-height: 190px;
                    max-width: 320px;
                }
            }

            &:first-child {
                .el-card__body {
                    @include media(sm) {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .title {
        &__htag--h1 {
            margin-top: 44px;
            margin-bottom: 24px;
            font-size: rem(25px);
            font-weight: 700;

            @include media(sm) {
                margin-top: 30px;
                margin-bottom: 27px;
                font-size: rem(42px);
            }
        }
    }
    .place {
        &__group {
            margin-bottom: 48px;
            display: flex;
            align-items: flex-start;
        }

        &__block {
            width: auto;
            height: auto;

            @include media(md) {
                width: 490px;
                height: 190px;
            }
        }

        &__date {
            flex-basis: 157px;
            margin-block-start: 0px;
            margin-right: 1.5em;
            margin-bottom: 5px;
            color: $--color-black;
            font-size: rem(15px);
            font-weight: 700;

            @include media(sm) {
                font-size: rem(14px);
            }
        }

        &__title {
            font-size: rem(18px);
            font-weight: 700 !important;
            line-height: 1.45em;
            margin-top: 0px;
            margin-bottom: 9px;

            @include media(sm) {
                margin-bottom: 7px;
            }
        }

        &__title a {
            color: $--color-info;
            text-decoration: none;
            .link__txt {
                font-weight: 700 !important;
            }
        }

        &__item {
            margin-bottom: 66px;
            display: flex;
            flex-flow: column;

            @include media(md) {
                margin-bottom: 48px;
                height: 190px;
                flex-flow: row;
            }
        }

        &__image {
            width: 100%;
            margin-right: 32px;
            margin-bottom: 12px;

            @include media(sm) {
                width: 320px;
            }
            @include media(md) {
                height: 190px;
                width: 284px;
            }

            .uploaded-image {
                height: 100%;
            }
        }

        &__excerpt {
            font-size: rem(13px);
            font-weight: 400;
            line-height: 1.6em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: -3px;

            @include media(sm) {
                font-size: rem(15px);
                line-height: 1.5em;
            }
        }
    }

    .filterButton {
        .reset__button {
            margin-top: 8px;
            margin-left: 0;
        }
    }

    .filter-accordion-header {
        margin-bottom: 16px;

        @include media(sm) {
            margin-bottom: 10px;
        }
    }
}

.promoted {
    &__url {
        .link__internal {
            &:hover {
                text-decoration: none !important;
            }
        }
    }
}
</style>
