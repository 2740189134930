<template>
    <div class="parag">
        <template v-for="paragraph in item.customizable_content">
            <component :is="paragType[paragraph.type]" :key="paragraph.id" :paragraph="paragraph"></component>
        </template>
    </div>
</template>

<script>
import Accordions from '~/components/paragraphs/Accordions.vue';
import FileList from '~/components/paragraphs/FileList';
import ParagRawHtml from '~/components/paragraphs/RawHtml';
import ReusableContent from '~/components/paragraphs/ReusableContent';
import Webform from '~/components/paragraphs/Webform';
import Wysiwyg from '~/components/paragraphs/Wysiwyg';

export default {
    components: {
        // due to "unused import" rule and the component binding,
        // you must comment paragraph imports
        ParagRawHtml, // eslint-disable-line
        ReusableContent, // eslint-disable-line
        Accordions, // eslint-disable-line
        FileList, // eslint-disable-line
        Wysiwyg, // eslint-disable-line
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    computed: {
        paragType() {
            return {
                'paragraph--parag_raw_html': ParagRawHtml,
                'paragraph--reusable_contents_block': ReusableContent,
                'paragraph--accordions': Accordions,
                'paragraph--files_list': FileList,
                'paragraph--wysiwyg_content': Wysiwyg,
                'paragraph--webform': Webform,
            };
        },
    },
};
</script>

<style lang="scss">
.parag {
    &__raw,
    &__wysiwyg,
    &__webform,
    &__accordion {
        margin-bottom: 1rem;
    }

    &__reusable {
        margin-bottom: 2rem;
    }
    & > *:last-child {
        margin-bottom: 0 !important;
    }
}
</style>
