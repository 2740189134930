<template>
    <div class="hub-service-page">
        <content-details
            :title="item.title"
            :offset-size="5"
            :type-tags="item.typeTags"
            :infos="item.infos"
            size="front_large_21_9"
            :header-img="{
                src: item.image ? item.image.uri.url : null,
                alt: item.image ? item.image.meta.alt : null,
            }"
            :media-image="item.media_image ? item.media_image : null"
        >
            <template class="hub-service">
                <Row class="hub-service__top_content">
                    <el-col
                        v-if="serviceList && serviceList.length"
                        ref="sideMenu"
                        class="hub-service__menu"
                        :span="24"
                    >
                        <h2
                            id="side-menu--title"
                            class="hub-service__menu-title"
                            @click="isMobileSize ? (sideMenuOpen = !sideMenuOpen) : false"
                        >
                            {{ $t('services.inThisSection') }}
                        </h2>
                        <nav aria-labelledby="side-menu--title">
                            <ul v-if="!isMobileSize || sideMenuOpen" class="hub-service__menu-container">
                                <li
                                    v-for="menuItem in serviceList"
                                    :key="menuItem.entity_id"
                                    class="hub-service__menu-link-container"
                                >
                                    <custom-link
                                        class="hub-service__menu-link"
                                        :to="menuItem.entity_alias"
                                        name="fade"
                                        primary
                                        >{{ menuItem.entity_title | decodehtml }}</custom-link
                                    >
                                </li>
                            </ul>
                        </nav>
                    </el-col>

                    <!-- WYSIWYG (Contenu) -->
                    <el-col :span="24" class="hub-service__content" :style="{ minHeight: contentMinHeight }">
                        <container
                            v-if="reminders && reminders[$i18n.locale] && reminders[$i18n.locale].data.length > 0"
                            class="hub-service__reminders"
                            max-width="700px"
                            :no-margin="true"
                            :padding="false"
                        >
                            <reminder-block-list
                                :reminders="reminders"
                                :max-texte-length="60"
                                :section-title="$t('alerts.noticesAndReminders')"
                                :show-more-link="false"
                                :slides-per-view="3"
                            />
                        </container>

                        <transition name="fade">
                            <Container v-if="item.body && item.body.processed" class="hub-service__wysiwyg-wrapper">
                                <Container max-width="700px" no-margin class="hub-service__wysiwyg">
                                    <wysiwyg :body="item.body.processed" />
                                </Container>
                            </Container>
                        </transition>

                        <!-- Pour le catalogue Leonard / ajouter le code suivant dans le
                        wysiwyg (body) du hub_service 'bibliotheques' dans drupal-->

                        <!--<form action="https://bibliotheques.longueuil.quebec/uhtbin/cgisirsi.exe/x/0/0/5?" class="catalogue-leonard" method="post" target="_blank">
                        <div class="catalogue-leonard__title-wrapper">
                        <h2 class="catalogue-leonard__title">Catalogue <span class="catalogue-leonard__br">Léonard</span></h2>
                        <img alt="Catalogue Léonard" class="catalogue-leonard__logo" data-align="right" data-entity-type="" data-entity-uuid="" src="https://www.longueuil.quebec/files/longueuil/images/biblio/Leonard_40.gif" /></div>

                        <p class="catalogue-leonard__text">Accédez au <a href="https://bibliotheques.longueuil.quebec" target="_blank">catalogue Léonard</a> pour chercher un document et connaître sa disponibilité.</p>
                        <input name="library" type="hidden" value="ALL" /> <input name="sort_by" type="hidden" value="ANY" /> <input name="relevance" type="hidden" value="off" /> <input name="srchfield1" type="hidden" value="GENERAL^SUBJECT^^^words or phrase" /> <input class="catalogue-leonard__input" maxlength="50" name="searchdata1" size="25" type="text" /> <input align="middle" alt="Loupe" class="catalogue-leonard__submit" src="https://www.longueuil.quebec/files/longueuil/images/biblio/loupe.gif" type="submit" value="Rechercher" />&nbsp;</form>-->

                        <!-- Hub service - actualités vertical -->
                        <container v-if="newsList.length > 0" class="hub-service__news-list" max-width="700px">
                            <h2 class="hub-service__section-title">
                                {{ this.$t('news.linkedNews') }}
                            </h2>
                            <template v-for="n in newsList.slice(0, 3)">
                                <transition :key="n.id" name="fade">
                                    <Card
                                        type="news"
                                        v-bind="n"
                                        size="front_large_21_9"
                                        :media-image="n.mediaImage"
                                        :shadow="false"
                                        :url="`/${n.pathLangcode}${n.pathAlias}`"
                                    />
                                </transition>
                            </template>

                            <div class="link__section">
                                <span class="separator"></span>
                                <custom-link class="guide__link" :to="newsRoute" underline primary>
                                    {{ this.$t('news.seeAllNews') }}
                                </custom-link>
                            </div>
                        </container>

                        <!-- Hub service - events vertical -->
                        <container v-if="events.length > 0" class="hub-service__events-list" max-width="700px">
                            <h2 class="hub-service__section-title">
                                {{ this.$t('events.linkedEvents') }}
                            </h2>
                            <template v-for="event in events.slice(0, 2)">
                                <transition :key="event.id" name="fade">
                                    <Card
                                        type="event"
                                        v-bind="event"
                                        :media-image="event.mediaImage"
                                        size="front_large_21_9"
                                        :shadow="false"
                                        :url="`/${event.pathLangcode}${event.pathAlias}`"
                                    />
                                </transition>
                            </template>

                            <div class="link__section">
                                <span class="separator"></span>
                                <custom-link class="guide__link" :to="eventsRoute" underline primary>
                                    {{ this.$t('events.seeAllEvents') }}
                                </custom-link>
                            </div>
                        </container>

                        <!-- Box (actions et réglements) -->
                        <transition name="fade">
                            <Container
                                v-if="actions.length > 0 || rules.length > 0"
                                class="hub-service__action-wrapper"
                            >
                                <Container max-width="700px" no-margin>
                                    <el-tabs v-model="activeActionTab">
                                        <el-tab-pane
                                            :disabled="actions.length === 0"
                                            :label="$t('services.iWant')"
                                            lazy
                                            name="linkedActions"
                                            class="hub-service__actions-tab"
                                        >
                                            <Row
                                                v-for="action in actions"
                                                :key="action.id"
                                                class="hub-service__actions-row"
                                            >
                                                <el-col class="hub-service__actions-col" :span="24">
                                                    <custom-link
                                                        class="hub-service__actions-container-link"
                                                        :to="'/' + action.pathLangcode + action.pathAlias"
                                                        primary
                                                        bold
                                                    >
                                                        <uploaded-image
                                                            class="hub-service__actions-icon"
                                                            :path="checkActionMedia(action)"
                                                            alt
                                                        />

                                                        <span class="hub-service__actions-link">{{
                                                            action.title
                                                        }}</span>
                                                        <i
                                                            class="hub-service__actions-arrow el-icon-arrow-right el-icon-right"
                                                        ></i>
                                                    </custom-link>
                                                </el-col>
                                            </Row>

                                            <Row class="hub-service__actions-more">
                                                <el-col :span="24">
                                                    <!-- TODO: change to to request and payments -->
                                                    <custom-link
                                                        :to="
                                                            $i18n.locale === 'fr'
                                                                ? '/fr/services-en-ligne'
                                                                : '/en/online-services'
                                                        "
                                                        primary
                                                        underline
                                                        bold
                                                        >{{ this.$t('services.allActions') }}</custom-link
                                                    >
                                                </el-col>
                                            </Row>
                                        </el-tab-pane>

                                        <el-tab-pane
                                            :disabled="rules.length === 0"
                                            :label="$t('services.associatedRules')"
                                            lazy
                                            name="associatedRules"
                                            class="hub-service__actions__tab"
                                        >
                                            <Row
                                                v-for="(rule, index) in rules"
                                                :key="index"
                                                class="hub-service__actions-row"
                                            >
                                                <el-col class="hub-service__actions-col" :span="24">
                                                    <custom-link
                                                        class="hub-service__actions-container-link"
                                                        :to="rule.uri"
                                                        primary
                                                        bold
                                                        hide-external
                                                    >
                                                        <span
                                                            class="hub-service__actions-link hub-service__rules-link"
                                                            >{{ rule.title }}</span
                                                        >
                                                        <i
                                                            class="hub-service__actions-arrow el-icon-arrow-right el-icon-right"
                                                        ></i>
                                                    </custom-link>
                                                </el-col>
                                            </Row>
                                        </el-tab-pane>
                                    </el-tabs>
                                </Container>
                            </Container>
                        </transition>
                        <!-- Paragraphs -->
                        <Container
                            v-if="item.customizable_content"
                            class="hub-service__custom_content"
                            max-width="700px"
                            :no-margin="true"
                        >
                            <paragraphs :item="item"></paragraphs>
                        </Container>

                        <Container
                            v-if="directions && directions.length > 0"
                            class="hub-service__bottom_content service__events"
                            max-width="700px"
                            no-margin
                        >
                            <transition name="fade">
                                <ListCards
                                    :items="directions"
                                    :title="`${this.$t('directions.linkedDirections')} ${item.title}`"
                                    :more-link="{
                                        txt: $t('directions.seeAllDirections'),
                                        url: $t('directions.url'),
                                    }"
                                ></ListCards>
                            </transition>
                        </Container>
                    </el-col>
                </Row>
            </template>
        </content-details>

        <div v-if="item.map_link && item.map_link.uri" class="hub-service__map-container">
            <interactive-map class="hub-service__map" :uri="item.map_link.uri" :height="'791px'" />
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Card from '~/components/Card';
import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import CustomLink from '~/components/CustomLink';
import InteractiveMap from '~/components/InteractiveMap';
import Paragraphs from '~/components/Paragraphs';
import ReminderBlockList from '~/components/ReminderBlockList';
import Row from '~/components/Row';
import UploadedImage from '~/components/UploadedImage';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        Card,
        UploadedImage,
        ContentDetails,
        CustomLink,
        Row,
        Container,
        Paragraphs,
        InteractiveMap,
        Wysiwyg,
        ReminderBlockList,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            rules: initData.rules,
            actions: initData.actions,
            serviceList: initData.serviceList,
            events: initData.events,
            newsList: initData.newsList,
            directions: initData.directions,
            sideMenu: [],
            sideMenuOpen: false,
            activeActionTab: 'linkedActions',
            currentpath: null,
            isMobileSize: false,
            contentMinHeight: 'none',
        };
    },

    computed: {
        ...mapState({
            menu: state => state.menu.menu,
            reminders: state => state.reminders.reminders,
        }),
        newsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('news.url');
        },
        eventsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('events.url');
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        const serviceList = await app.$client.execute('serviceByHubService', 'getIndividual', item.service_type);

        return {
            item,
            actions: app.$drupalComponentsAdapter.normalizeItems(item.related_actions),
            events: app.$drupalComponentsAdapter.normalizeItems(item.related_events),
            newsList: app.$drupalComponentsAdapter.normalizeItems(item.related_news),
            serviceList: serviceList.results,
            rules: item.list_links,
        };
    },

    mounted() {
        this.getSectionMenu();
        this.getWindowSize();
        this.activeActionTab = this.actions.length === 0 && this.rules.length > 0 ? 'associatedRules' : 'linkedActions';
        window.addEventListener('resize', this.getWindowSize);
    },

    methods: {
        getWindowSize() {
            const windowWidth = window.innerWidth;
            this.isMobileSize = windowWidth <= 767;
            if (!this.isMobileSize && this.$refs.sideMenu && this.$refs.sideMenu.$el) {
                this.contentMinHeight = `${this.$refs.sideMenu.$el.clientHeight}px`;
            } else {
                this.contentMinHeight = 'auto';
            }
        },
        getSectionMenu() {
            // Example: /fr/services/bibliotheque
            const currentPath = window.location.pathname;
            this.currentpath = currentPath;

            // Set side bar menu
            this.menu.services[this.$i18n.locale].forEach(level1 =>
                level1.children.forEach(level2 =>
                    level2.children.forEach(level3 => {
                        if (level3.attributes.link.uri === currentPath && level3.children && level3.children.length) {
                            this.sideMenu = level3.children;
                        }
                    }),
                ),
            );
        },
        checkActionMedia(action) {
            try {
                return action.media_icon.meta.links.front_medium_16_9;
            } catch (e) {
                return null;
            }
        },
    },
};
</script>

<style lang="scss">
div.hub-service-page {
    @include media(xs) {
        margin-bottom: 60px;
    }

    @include media(lg) {
        transform: translateX(200px);
    }

    .catalogue-leonard {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 10px 10px 20px 10px;
        box-sizing: border-box;

        @include media(sm) {
            padding: 20px 20px 30px 20px;
        }

        &__title-wrapper {
            position: relative;
        }

        &__title {
            margin-top: 0;
        }

        &__content {
            display: flex;
            margin-bottom: 10px;
        }

        &__text {
            width: 66%;
            margin-bottom: 20px;

            @include media(sm) {
                width: 80%;
            }
        }

        &__input {
            height: 32px;
            width: 100%;
            border-width: 1px;
            background-color: #f3f1eb;
            border-color: #f3f1eb;
            color: #595959;
            border-radius: 4px;
            box-sizing: border-box;
            &:focus {
                border-color: $--color-primary;
            }

            @include media(sm) {
                width: calc(100% - 160px);
            }
        }

        &__logo {
            position: absolute;
            right: 0;
            top: 0;
        }

        &__br {
            @include media('370px', true, 'max') {
                display: block;
            }
        }

        &__submit {
            width: 100%;
            box-sizing: border-box;
            text-decoration: none;
            color: $--color-primary;
            font-size: rem(14px);
            font-weight: 500;
            border: $--color-primary 2px solid;
            border-radius: 4px;
            padding: 10px 10px;
            cursor: pointer;
            transition: 0.5s all;
            padding: 5px 20px;
            margin-top: 8px;

            @include media(sm) {
                font-size: rem(16px);
                padding: 5px 20px;
                width: 150px;
            }

            &:hover {
                color: white;
                background-color: $--color-primary;
            }
        }
    }

    .title__htag.title__htag--h1 {
        @include media(xs) {
            font-size: rem(25px);
        }
    }

    .media-pdf.container.padding {
        padding: 0;
    }

    .guide__link {
        font-size: rem(15px);
        font-weight: 700;
        text-decoration: underline;

        @include media(xs) {
            font-size: rem(13px);
        }
    }

    .separator {
        display: inline-block;
        height: 2px;
        width: 430px;
        background: lightgray;
        margin-right: 42px;
        transform: translateY(12px);

        @include media(xs) {
            transform: translateY(10px);
            margin-right: 20px;
        }
    }

    .link__section {
        display: flex;
        margin-bottom: 30px;
        margin-top: 34px;
        white-space: nowrap;

        .link__txt {
            font-weight: 400;
        }

        custom-link {
            display: inline-block;
            text-decoration: none;
        }
    }

    .card__image-container {
        @include media(sm) {
            max-width: 320px;
        }
    }

    div.content__header-img {
        @include media(lg) {
            transform: translateX(-50px);
        }
    }

    .title__htag.title__htag--h1 {
        margin-top: 31px !important;
        margin-bottom: 15px;

        @include media(sm) {
            margin-bottom: 0;
        }
    }
}

.hub-service {
    &__content,
    &__events {
        @include padding-content();
    }

    &__map-container {
        margin-top: 78px;
        margin-bottom: -4px;

        @include media(xs) {
            margin-bottom: -64px;
        }
    }

    &__section-title {
        font-size: rem(15px);
        letter-spacing: 0.6px;
        font-weight: 600;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    // CARD STYLE
    &__news-list,
    &__events-list {
        @include media(lg) {
            margin-left: 0 !important;
        }

        .card:not(:last-of-type) {
            margin-bottom: 65px;
        }

        .el-card__body {
            padding: 0;
        }

        .card__content {
            display: flex;

            @include media(sm) {
                align-items: center;
                flex-flow: row !important;
            }
        }

        .media__image,
        .uploaded-image {
            min-height: 183px;
            height: 48vw;

            @include media(sm) {
                height: 183px;
            }
        }

        .card__image-container {
            @include media(sm) {
                margin-right: 50px;
            }
        }

        .card__title {
            font-size: 20px;
            font-weight: 700;

            @include media(xs) {
                font-size: 18px;
            }
        }

        .card__summary {
            font-size: rem(16px);
            font-weight: 400;
        }
    }
    // CARD STYLE END

    &__reminders {
        margin: 0;

        .reminders-block-list__header {
            margin-bottom: 5px;
        }

        .reminders-block-list {
            margin-top: -40px;
            margin-bottom: -8px;

            @include media(sm) {
                margin-top: -20px;
                margin-bottom: 45px;
            }
        }
    }

    &__top_content {
        margin-top: rem(40px);
        margin-bottom: 3rem;
    }

    &__bottom_content {
        margin-bottom: 3rem;
    }

    &__custom_content {
        margin-left: 0;

        @include media(xs) {
            margin-top: 30px;
            margin-bottom: -30px;
        }
    }

    &__wysiwyg {
        margin-bottom: 25px;
        font-size: rem(13px);

        @include media(sm) {
            margin-bottom: 50px;
            font-size: rem(18px);
        }
    }

    &__related-cards-item {
        margin-bottom: 25px;
    }

    &__menu {
        border-radius: 4px;
        background-color: transparent;
        margin: auto;

        @include media(sm) {
            margin-bottom: 20px;
            padding: 45px;
            height: auto;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        }

        @include media(md) {
            max-width: 700px;
        }

        @include media(lg) {
            position: absolute;
            padding: 56px;
            left: -320px;
            max-width: 320px;
        }
    }

    &__menu-title {
        font-size: rem(13px);
        margin-top: 0;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 26px;
        position: relative;
        cursor: pointer;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
        padding: 15px 25px;
        border-radius: 4px;
        z-index: 2;
        background-color: white;

        @include media(sm) {
            background-color: transparent;
            padding: 0;
            font-size: rem(15px);
            cursor: initial;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0);
        }

        &:after {
            content: '';
            background-image: url('~assets/img/gray-arrow.svg');
            display: block;
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            height: 6px;

            @include media(sm) {
                display: none;
            }
        }
    }

    &__menu-container {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media(xs) {
            margin-top: -50px;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            background-color: #fffefa;
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
            padding: 63px 35px;
            box-sizing: border-box;
            margin-bottom: 60px;
        }
    }

    &__menu-link-container {
        margin-bottom: 8px;

        &:not(:last-child) {
            &:after {
                content: '';
                display: block;
                height: 1px;
                width: 64px;
                background-color: $--color-gray;
                margin: 19px 0;
            }
        }
    }

    &__menu-link {
        font-size: rem(15px);
        color: $--color-primary;
        font-weight: 400;
    }

    &__rules-link {
        width: 100%;
    }

    &__actions-tab {
        text-align: center;

        @include media(xs) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__action-wrapper {
        margin-top: 48px;
        margin-bottom: 32px;

        @include media(xs) {
            margin-top: -12px;
        }

        .el-tabs {
            &__nav {
                width: 100%;
            }

            &__item {
                width: 50%;
                height: 92px;
                padding-top: 24px;
                padding-bottom: 24px;
                text-align: center;
                font-weight: 500;
                font-size: rem(14);
                text-transform: uppercase;
                transition: 0.5s all;
                letter-spacing: 0.56px;

                @include media(xs) {
                    white-space: initial;
                    line-height: 1.4;
                    padding-bottom: 18px;
                    min-height: 53px;
                    font-weight: 400;
                    height: auto;
                    padding-left: 0;
                    padding-right: 0;
                }

                &:hover {
                    color: $--color-green;
                }

                &.is-active {
                    color: $--color-green;
                    font-size: rem(16);
                    font-weight: 700;

                    @include media(xs) {
                        font-size: rem(14);
                    }
                }
            }

            &__nav-wrap {
                @include media(xs) {
                    //left: -10px;
                }

                &:after {
                    background-color: transparent;
                }
            }

            &__active-bar {
                height: 5px;
                background-color: $--color-green;
                width: 50% !important;
            }

            &__header {
                margin-bottom: -5px;
            }

            &__content {
                padding: 38px 12px 44px;
                box-shadow: 0 0 64px rgba(0, 0, 0, 0.16);
                background-color: #f9f7f0;

                @include media(xs) {
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                    //margin-left: -10px;
                }

                @include media(sm) {
                    padding: 44px 47px 60px;
                }
            }
        }
    }

    &__actions-row {
        position: relative;
        min-height: 92px;
        margin: auto;
        max-width: 360px;
        padding-bottom: 35px;
        padding-top: 11px;
        box-shadow: 10 0 44px rgba(0, 0, 0, 0.16);

        @include media(xs) {
            min-height: 60px;
            padding-top: 0px;
            padding-bottom: 20px;
        }

        i {
            float: right;
        }
    }

    &__actions-col {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -20px;
            height: 36px;
            width: 100%;
            background-size: 100% 80%;
            background: radial-gradient(ellipse at top, rgba(7, 38, 104, 0.5) 0%, rgba(255, 255, 255, 0) 40%) no-repeat;
            opacity: 0.15;

            @include media(xs) {
                opacity: 0.1;
                filter: blur(5px);
            }
        }
    }

    &__actions-container-link {
        display: flex;
        width: 100%;
        text-align: center;

        .link__txt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include media(xs) {
                padding: 0px 0 10px;
            }
        }

        .link__internal-container {
            width: 100%;

            @include media(xs) {
                font-size: rem(13px);
                font-weight: 500;
            }
        }
    }

    &__actions-link {
        padding: 0 10px;
        color: $--color-green;

        @include media(xs) {
            padding: 0 18px;
            font-size: rem(13px);
            font-weight: 500;
        }
    }

    &__action-icon {
        max-width: 54px;

        @include media(xs) {
            max-width: 40px;
        }
    }

    &__actions-arrow {
        &::before {
            text-decoration: none !important;
            color: $--color-green;
            font-weight: bold;

            &:focus,
            &:hover {
                text-decoration: none !important;
            }
        }
    }

    &__actions-more {
        padding-top: 30px;

        @include media(xs) {
            padding-top: 0px;
        }

        .link__internal,
        .link__txt {
            font-weight: 500;
            color: $--color-primary;

            @include media(xs) {
                font-size: rem(13px);
            }
        }
    }

    &__actions {
        margin-bottom: 3rem;
        padding: 1rem;
    }

    .block {
        transform: translateY(1.5em);
    }

    &__map {
        @include media(lg) {
            transform: translateX(-200px);
        }
    }
}
</style>
