var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.customLinkWrapperTag,{tag:"component",staticClass:"link",class:{ active: _vm.lastRoute === _vm.to }},[(_vm.calculatedExternal)?_c('span',{staticClass:"link__internal-container"},[_c('a',{staticClass:"link__external",class:{
                'link__external--underline': _vm.underline,
                'link__external--bold': _vm.bold,
                'link__external--gray': _vm.gray,
                'link__external--dark-gray': _vm.darkGray,
                'link__external--blue': _vm.blue,
                'link__external--white': _vm.white,
                'link__external--black': _vm.black,
                'link__external--primary': _vm.primary,
                'link__external--warning': _vm.warning,
                'link__external--info': _vm.info,
                'link__external--inline': _vm.inline,
            },attrs:{"id":_vm.id,"tabindex":_vm.tabindex,"href":_vm.toExternal,"target":_vm.target,"rel":"noopener noreferrer"},on:{"click":function($event){return _vm.$emit('click')}}},[_c('span',{staticClass:"link__txt"},[_vm._t("default")],2),_vm._v(" "),(!_vm.hideExternal)?_c('i',{staticClass:"link__external-icon"},[_vm._v("\n                 \n                "),_c('svg',{attrs:{"width":"12px","height":"12px","viewBox":"0 0 18 18","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink"}},[_c('g',{attrs:{"id":"Programmation","stroke":"none","stroke-width":"1","fill":"none","fill-rule":"evenodd"}},[_c('g',{attrs:{"id":"Desktop","transform":"translate(-1212.000000, -2361.000000)","fill":"#FFFFFF","fill-rule":"nonzero"}},[_c('g',{attrs:{"id":"noun_External_626436","transform":"translate(1212.000000, 2361.000000)"}},[_c('path',{staticClass:"link__svg-path",class:{
                                        'link__svg--gray': _vm.gray,
                                        'link__svg--dark-gray': _vm.darkGray,
                                        'link__svg--blue': _vm.blue,
                                        'link__svg--white': _vm.white,
                                        'link__svg--black': _vm.black,
                                        'link__svg--primary': _vm.primary,
                                        'link__svg--warning': _vm.warning,
                                        'link__svg--info': _vm.info,
                                    },attrs:{"id":"Shape","d":"M17.15625,7.875 C16.929,7.875 16.7265,7.78275 16.5735,7.63875 C16.569,7.63425 16.56225,7.63425 16.55775,7.62975 L14.46075,5.5305 L9.15075,10.8405 C8.60175,11.38725 7.71075,11.38725 7.16175,10.8405 C6.61275,10.2915 6.61275,9.4005 7.16175,8.8515 L12.4695,3.54375 L10.37025,1.44225 C10.36575,1.43775 10.36575,1.431 10.36125,1.4265 C10.21725,1.2735 10.125,1.071 10.125,0.84375 C10.125,0.378 10.503,0 10.96875,0 L16.875,0 C17.496,0 18,0.504 18,1.125 L18,7.03125 C18,7.497 17.622,7.875 17.15625,7.875 Z M2.25,5.0625 L2.25,15.1875 C2.25,15.498 2.502,15.75 2.8125,15.75 L12.9375,15.75 C13.248,15.75 13.5,15.498 13.5,15.1875 L13.5,9 L15.75,11.25 L15.75,15.75 C15.75,16.99425 14.74425,18 13.5,18 L2.25,18 C1.008,18 0,16.99425 0,15.75 L0,4.5 C0,3.258 1.008,2.25 2.25,2.25 L6.75,2.25 L9,4.5 L2.8125,4.5 C2.502,4.5 2.25,4.752 2.25,5.0625 Z"}})])])])])]):_vm._e()])]):_vm._e(),_vm._v(" "),(!_vm.calculatedExternal)?_c('span',{staticClass:"link__internal-container"},[(!_vm.calculatedExternal && !_vm.noLink)?_c('nuxt-link',{staticClass:"link__internal",class:{
                'link__external--underline': _vm.underline,
                'link__external--bold': _vm.bold,
                'link__internal--dark-gray': _vm.darkGray,
                'link__internal--gray': _vm.gray,
                'link__internal--blue': _vm.blue,
                'link__internal--white': _vm.white,
                'link__internal--black': _vm.black,
                'link__internal--primary': _vm.primary,
                'link__external--warning': _vm.warning,
                'link__external--info': _vm.info,
                'link__external--inline': _vm.inline,
                active: _vm.lastRoute === _vm.to,
            },attrs:{"id":_vm.id,"aria-label":_vm.label,"tabindex":_vm.tabindex,"to":_vm.ensureInternalLinkIsRelative},nativeOn:{"click":function($event){return (function (e) { return _vm.beforeRouteChange(e, _vm.to); })($event)}}},[_c('span',{staticClass:"link__txt",class:{ active: _vm.lastRoute === _vm.to }},[_vm._t("default")],2)]):_vm._e(),_vm._v(" "),(!_vm.calculatedExternal && _vm.noLink)?_c('button',{staticClass:"link__internal link__internal--nolink",class:{
                'link__internal--gray': _vm.gray,
                'link__internal--dark-gray': _vm.darkGray,
                'link__internal--blue': _vm.blue,
                'link__internal--white': _vm.white,
                'link__internal--black': _vm.black,
                'link__internal--primary': _vm.primary,
                'link__external--warning': _vm.warning,
                'link__external--info': _vm.info,
                'link__external--inline': _vm.inline,
                active: _vm.lastRoute === _vm.to,
            },attrs:{"tabindex":"0","type":"button"}},[_c('span',{staticClass:"link__txt",class:{ active: _vm.lastRoute === _vm.to },attrs:{"aria-label":_vm.label + ' ' + _vm.$t('a11y.link.active')}},[_vm._t("default")],2)]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }