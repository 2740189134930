const initLoading = () => ({
    isPageLoad: false,
    lastRoute: null,
});

export const state = initLoading;

export const mutations = {
    setIsPageLoad(state, isPageLoad) {
        state.isPageLoad = isPageLoad;
    },

    resetIsPageLoad(state) {
        state.isPageLoad = initLoading().isPageLoad;
    },

    setLastRoute(state, lastRoute) {
        state.lastRoute = lastRoute;
    },

    reset() {
        const initState = initLoading();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};
