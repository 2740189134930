<template>
    <div class="c-news-carousel" :class="{ 'is-active': isReady }">
        <div class="c-news-carousel_wrapper">
            <div class="c-news-carousel_inner">
                <div v-if="isReady" class="c-news-carousel_top">
                    <ul ref="itemsContainer" class="c-news-carousel_list">
                        <li
                            v-for="(item, index) in items"
                            ref="item"
                            :key="index"
                            :class="{ 'is-active': index === activeIndex }"
                            class="c-news-carousel_list_item"
                        >
                            <p class="c-news-carousel_category || c-text -overline">
                                <span v-html="item.category"></span>
                            </p>
                            <h3 class="c-news-carousel_title || c-heading -h6 || u-mt-4">
                                <span v-split-text.lines>
                                    {{ item.title }}
                                </span>
                            </h3>
                            <ui-cta
                                :url="item.linkUrl"
                                :label="item.linkLabel"
                                :title="item.linkLabel"
                                :link-type="item.linkType"
                                class="c-news-carousel_cta || u-mt-8"
                                type="button"
                                color="neutral"
                                size="small"
                                icon-type="arrowRight"
                            />
                        </li>
                    </ul>
                </div>

                <div ref="carouselBottom" class="c-news-carousel_bottom || u-color-tonic-dark">
                    <ui-bullets :length="items.length" :active-index="activeIndex" @onClick="onBulletsClick" />
                    <button
                        class="c-news-carousel__stop c-news-carousel__control-button"
                        type="button"
                        @click="pauseCarousel"
                    >
                        <ui-icon type="pause" />
                        <span class="visually-hidden">{{ $t('a11y.carousel.pause') }}</span>
                    </button>
                    <button
                        class="c-news-carousel__play c-news-carousel__control-button"
                        type="button"
                        @click="playCarousel"
                    >
                        <ui-icon type="play" />
                        <span class="visually-hidden">{{ $t('a11y.carousel.play') }}</span>
                    </button>
                    <ui-carousel-navigation @onPrev="onPrev" @onNext="onNext" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { mapState } from 'vuex';
import Raf from 'quark-raf';

import UiBullets from '~/components/locomotive/ui-bullets.vue';
import UiCarouselNavigation from '~/components/locomotive/ui-carousel-navigation.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';

export default {
    components: {
        UiIcon,
        UiCta,
        UiBullets,
        UiCarouselNavigation,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        loopTime: {
            type: Number,
            default: 7000,
        },
        autoPlay: {
            type: Boolean,
            default: true,
        },
    },
    data: () => {
        return {
            activeIndex: 0,
            incrementIndex: 0,
            isReady: false,
        };
    },
    computed: {
        ...mapState('locomotive-loading', ['isFontsLoaded']),
    },
    watch: {
        isFontsLoaded: 'onFontsLoaded',
    },
    created() {
        this.lastActiveIndex = 0;

        this.isAnimating = false;

        this.isPlaying = false;
        this.baseTime = Date.now();
    },
    mounted() {
        this.incrementSlideBind = this.incrementSlide.bind(this);
        this.onVisibilityChangeBind = this.onVisibilityChange.bind(this);
        if (this.isFontsLoaded) {
            this.componentsFontsLoaded = true;
            this.init();
        }
    },
    beforeDestroy() {
        this.resetLoop();
        this.unbindEvents();
    },
    methods: {
        bindEvents() {
            document.addEventListener('visibilitychange', this.onVisibilityChangeBind);
        },
        unbindEvents() {
            document.removeEventListener('visibilitychange', this.onVisibilityChangeBind);
        },
        init() {
            setTimeout(() => {
                this.isReady = true;
                this.$nextTick(() => {
                    this.setStyles();
                    this.bindEvents();
                    if (this.autoPlay) {
                        this.setLoop();
                    }
                });
            }, 1000);
        },
        playTimeline(disappearItemIndex, appearItemIndex) {
            const $items = this.$refs.item;

            const $currentItem = $items[disappearItemIndex];
            const $nextItem = $items[appearItemIndex];
            const $nextHeight = $nextItem.offsetHeight;
            const $container = this.$refs.itemsContainer;

            const tl = gsap.timeline({
                onStart: () => {
                    this.isAnimating = true;
                },
                onComplete: () => {
                    this.isAnimating = false;
                },
            });
            tl.to($currentItem, {
                duration: 0.3,
                autoAlpha: 0,
            });
            tl.to(
                $container,
                {
                    duration: 0.3,
                    height: $nextHeight,
                },
                0.2,
            );
            tl.to(
                $nextItem,
                {
                    duration: 0.1,
                    autoAlpha: 1,
                },
                0.6,
            );
        },
        setStyles() {
            const $items = this.$refs.item;

            const heights = [];

            for (let index = 0; index < $items.length; index++) {
                const $currentItem = $items[index];
                const $currentHeight = $currentItem.offsetHeight;
                const $container = this.$refs.itemsContainer;
                heights.push($currentHeight);
                // show first items
                if (index === this.activeIndex) {
                    $container.style.height = $currentHeight + 'px';
                    // hide others
                } else {
                    gsap.set($currentItem, { autoAlpha: 0 });
                }
            }

            const maxHeight = Math.max(...heights);
            const paddingBox = 24 * 2;
            const navHeight = this.$refs.carouselBottom.offsetHeight;
            this.$el.style.height = maxHeight + paddingBox + navHeight + 'px';
        },
        setLoop() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdate);
        },
        resetLoop() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdate);
        },
        incrementSlide() {
            if (this.isAnimating) return;
            this.lastActiveIndex = this.activeIndex;
            this.activeIndex = (this.activeIndex + 1) % this.items.length;
            this.baseTime = Date.now();
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
        decrementSlide() {
            if (this.isAnimating) return;
            this.lastActiveIndex = this.activeIndex;
            this.activeIndex =
                this.activeIndex === 0 ? this.items.length - 1 : (this.activeIndex - 1) % this.items.length;
            this.baseTime = Date.now();
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
        onPrev() {
            this.resetLoop();
            this.decrementSlide();
            if (this.autoPlay) {
                this.setLoop();
            }
        },
        onNext() {
            this.resetLoop();
            this.incrementSlide();
            if (this.autoPlay) {
                this.setLoop();
            }
        },
        onVisibilityChange() {
            if (document.hidden) {
                this.resetLoop();
            } else if (this.autoPlay) {
                this.setLoop();
            }
        },
        onFontsLoaded() {
            if (!this.componentsFontsLoaded) {
                this.componentsFontsLoaded = true;
                this.init();
            }
        },
        onUpdate() {
            const dateNow = Date.now();
            const elapsedTime = dateNow - this.baseTime;

            // If minimum duration passed
            if (elapsedTime > this.loopTime) {
                this.incrementSlideBind();
            }
        },
        onBulletsClick(index) {
            const normalizedIndex = index - 1;

            if (normalizedIndex === this.activeIndex) return;

            this.lastActiveIndex = this.activeIndex;
            this.activeIndex = normalizedIndex;
            this.baseTime = Date.now();
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
        pauseCarousel() {
            this.resetLoop();
        },
        playCarousel() {
            if (this.autoPlay) {
                this.setLoop();
            }
        },
    },
};
</script>

<style lang="scss">
.c-news-carousel {
    html.is-ready & {
        .c-news-carousel_wrapper {
            transition: clip-path $loco-speed $loco-easing 0.2s;

            &:before {
                transition: transform $loco-speed $loco-easing 0.2s;
            }
        }

        .c-news-carousel_inner {
            transition: opacity $loco-speed linear 0.2s;
        }
    }

    .c-news-carousel_list_item.is-active {
        .c-text-lines_item {
            @for $i from 1 to 10 {
                &:nth-child(#{$i}) {
                    .c-text-lines_item_inner {
                        transition-delay: (($i - 1) * 0.1s) + $loco-news-carousel-delay;
                    }
                }
            }
        }

        .c-text-lines_item_inner {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            transition: opacity $loco-speed $loco-easing, transform $loco-speed $loco-easing;
        }
    }
}
</style>

<style lang="scss" scoped>
.c-news-carousel {
    position: relative;
    display: block;
    width: 100%;
    max-width: loco-rem(430px);
}

.c-news-carousel_wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: $loco-light;
        z-index: -1;
        transform: translate3d(0, 100%, 0);
    }

    .c-news-carousel.is-active & {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

        &:before {
            transform: translate3d(0, 0, 0);
        }
    }
}

.c-news-carousel_inner {
    opacity: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);

    .c-news-carousel.is-active & {
        opacity: 1;
    }
}

.c-news-carousel_top {
    padding: 24px;
}

.c-news-carousel_bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: loco-rem(16px) loco-rem(24px) loco-rem(16px) loco-rem(16px);
    border-top: 1px solid $loco-grey;

    .c-news-carousel__control-button {
        width: 10px;
        margin: 0 10px;
    }

    .c-ui-carousel-navigation {
        margin-left: auto;
    }

    .c-ui-bullets {
        line-height: normal;
    }
}

.c-news-carousel_category {
    color: $loco-tonic;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: opacity 0s linear 0.3s, transform 0s linear 0.3s;

    .c-news-carousel_list_item.is-active & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity $loco-speed $loco-easing $loco-news-carousel-delay,
            transform $loco-speed $loco-easing $loco-news-carousel-delay;
    }
}

.c-news-carousel_title {
    color: $loco-tonic-dark;
    padding-right: loco-rem(16px);
}

.c-news-carousel_list {
    position: relative;
}

.c-news-carousel_cta {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: opacity 0s linear 0.3s, transform 0s linear 0.3s;

    .c-news-carousel_list_item.is-active & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity $loco-speed $loco-easing ($loco-news-carousel-delay + 0.2s),
            transform $loco-speed $loco-easing ($loco-news-carousel-delay + 0.2s);
    }
}

.c-news-carousel_list_item {
    position: absolute;
    top: 0;
    left: 0;
}
</style>
