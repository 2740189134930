<template>
    <list-with-filter
        title="Administration"
        :total-count="totalCount"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        :has-filters="false"
    >
        <template>
            <loader-wrapper :loading="isUpdating">
                <Row v-if="directions.length > 0" gutter-small class="list-with-filter__main-cards">
                    <el-col
                        v-for="item in directions"
                        :key="item.id"
                        class="list-with-filter__main-cards-item"
                        :span="24"
                        :sm="24"
                        :md="24"
                    >
                        <el-card>
                            <h2>
                                {{ item.title }}
                                <custom-link
                                    v-if="item.place.path"
                                    :to="`/${item.place.path.langcode}${item.place.path.alias}`"
                                >
                                    <i class="el-icon-location-outline"> </i>
                                    {{ item.place.title }}
                                </custom-link>
                            </h2>
                            <uploaded-image
                                :path="
                                    item.accountable.media_image && item.accountable.media_image.meta
                                        ? item.accountable.media_image.meta.links.front_large_21_9
                                        : null
                                "
                            >
                            </uploaded-image>
                            <p>{{ item.accountable.name }}</p>
                            <p>{{ item.accountable.title }}</p>
                            <span v-html="item.body.processed"></span>
                        </el-card>
                    </el-col>
                </Row>
                <Row v-else>
                    <el-col class="list-with-filter__main-cards-item" :span="24" :sm="24" :md="24">
                        {{ $t('filter.noResult') }}
                    </el-col>
                </Row>
            </loader-wrapper>
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';
import Row from '~/components/Row/';
import UploadedImage from '~/components/UploadedImage';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        // Card,
        CustomLink,
        ListWithFilter,
        LoaderWrapper,
        Row,
        UploadedImage,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);

        return {
            directions: initData.directions,
            filterValues: {
                byTitle: '',
                byDate: 'default',
                byDateRange: [],
                byType: [],
            },
            page: parseInt(this.$route.query.page) || 1,
            itemsPerPage: 6,
            isUpdating: false,
        };
    },
    computed: {
        totalCount() {
            return this.directions.length;
        },
        displayArray() {
            return this.directions.slice(
                this.page * this.itemsPerPage - this.itemsPerPage,
                this.page * this.itemsPerPage,
            );
        },
    },
    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
            this.offset = this.itemsPerPage * (this.page > 0 ? this.page - 1 : 0);
        },
    },
    async asyncData({ app, query, store, params, error, env }, item) {
        const sort = '-created';

        // Get list of services
        const directions = await app.$client.execute('directions', 'getCollection', {
            sort,
            include: ['accountable', 'place'],
        });

        return {
            directions: directions.data,
        };
    },
};
</script>

<style lang="scss" scoped>
.list-with-filter {
    &__first-row {
        margin-bottom: 50px;
    }

    &__main-cards-item {
        margin-bottom: 25px;
    }

    &__header-img {
        width: 100%;
        margin-bottom: 60px;
    }
}
</style>
