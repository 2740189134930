// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/dmsans-bold-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/dmsans-bold-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/dmsans-medium-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/dmsans-medium-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/dmsans-regular-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/dmsans-regular-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:78.375rem){:root{--header-height:5.75rem}}@media (min-width:79.375rem){:root{--header-height:8.5rem}}@font-face{font-family:\"dm_sansbold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansmedium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansregular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:400;font-style:normal}.page-de-base{max-width:810px;margin:auto auto 80px}.page-de-base .item__related-cards-item{margin-bottom:25px}.page-de-base .custom_content{margin-bottom:12px}.page-de-base .custom_content__paragraphs{margin-top:29px;margin-bottom:16px}.page-de-base .title{padding-top:0;margin-top:30px}@media (min-width:768px){.page-de-base .title{padding-top:60px;margin-top:0}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
