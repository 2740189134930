<template>
    <article class="card" :class="{ 'is-horizontal': horizontal, primary: primary }">
        <custom-link :to="url" :label="`${$t('a11y.link.articleLink')} ${title}`" class="card__url">
            <el-card shadow="never" :class="{ 'el-card--vertical': vertical, 'has-shadow': shadow }">
                <div class="card__content" :class="{ 'card__content--vertical': vertical }">
                    <div class="card__image-container">
                        <media-image fit="cover" :image="mediaImage" :size="size" />
                    </div>

                    <div class="card__text">
                        <div class="card__body">
                            <h3 v-if="title" class="card__title">
                                {{ title | striphtml }}
                            </h3>
                            <div v-if="showCreated" class="card__date-container">
                                <time v-if="type === 'news' && created" class="card__date">
                                    {{ $moment(created).format('LL') }}
                                </time>

                                <time
                                    v-if="type === 'event' && dateStart"
                                    class="card__date"
                                    :datetime="$moment(dateStart).format()"
                                >
                                    {{ $moment(dateStart).format('LL') }}
                                </time>
                                <template
                                    v-if="
                                        type === 'event' &&
                                            dateEnd &&
                                            $moment(dateEnd).format('LL') !== $moment(dateStart).format('LL')
                                    "
                                >
                                    <span class="card__date">
                                        au
                                    </span>
                                    <time class="card__date" :datetime="$moment(dateEnd).format()">
                                        {{ $moment(dateEnd).format('LL') }}
                                    </time>
                                </template>
                            </div>
                            <p v-if="showSummary" class="card__summary">
                                {{ summary | striphtml }}
                            </p>
                            <div v-if="place" class="card__place">
                                <img class="card__place-icone" src="~assets/img/map-marker-primary.svg" alt="" />
                                <span v-html="place"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-card>
        </custom-link>
    </article>
</template>

<script>
import CustomLink from '~/components/CustomLink';
import MediaImage from '~/components/MediaImage';

export default {
    components: { CustomLink, MediaImage },

    props: {
        shadow: {
            type: Boolean,
            default: false,
        },
        showSummary: {
            type: Boolean,
            default: true,
        },
        showCreated: {
            type: Boolean,
            default: true,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '#',
        },
        title: {
            type: String,
            default: null,
            required: true,
        },
        content: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'front_large_21_9',
        },
        summary: {
            type: String,
            default: null,
        },
        created: {
            type: [Object, String],
            default: null,
        },
        mediaImage: {
            type: Object,
            default: null,
        },
        pathLangcode: {
            type: String,
            default: null,
        },
        place: {
            type: String,
            default: null,
        },
        pathAlias: {
            type: String,
            default: null,
        },
        // eslint-disable-next-line
        type: {
            type: String,
            enum: ['event', 'news', 'place'],
            default: 'news',
        },
        url: {
            type: String,
            default: '',
        },
        dateStart: {
            type: [Object, String],
            default: null,
        },
        dateEnd: {
            type: [Object, String],
            default: null,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    height: auto;
    width: 100%;

    &:hover {
        .card__thumbnail {
            filter: sepia(40%);
        }
    }

    &__thumbnail {
        width: 100%;
        display: block;
        filter: sepia(0%);
        transition: 0.3s all;
    }

    .media__image,
    .uploaded-image {
        @include media(xs) {
            width: calc(100% + 20px);
            margin-left: -10px;
            margin-right: -10px;
            min-height: 172px;
            height: 53vw;
        }

        @include media(sm) {
            min-width: 320px;
        }
    }

    &__image-container {
        width: 100%;
        margin-bottom: 20px;

        @include media(md) {
            margin-right: 48px;
            margin-bottom: 0;
        }
    }

    &__bottom {
        margin-top: 13px;
        line-height: 12px;
        @include clearfix;
    }

    &__date-container {
        margin-bottom: 8px;
    }

    &__date {
        margin-top: 18px;
        color: $--color-black;
        font-size: rem(15px);
        font-weight: bold;

        @include media(xs) {
            font-size: rem(14px);
        }
    }

    &__big-date {
        font-size: rem(40);
        color: white;
        font-weight: bold;
        padding-top: 32px;
        padding-bottom: 19px;
    }

    &__content {
        display: flex;
        align-items: flex-start;
        height: auto;
        width: 100%;
        flex-flow: column;

        @include media(md) {
            flex-flow: row;
            align-items: center;
        }

        &--vertical {
            @include media(md) {
                max-height: initial;
                flex-flow: column;
            }
        }
    }

    &__title {
        margin-top: 0px;
        margin-bottom: 6px;
        font-size: rem(18px);
        color: $--color-info;
    }

    &__text {
        display: flex;
        flex-direction: column;
        color: $--color-primary;
        font-size: rem(15px);
        width: 100%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0;
    }

    &__summary {
        font-size: rem(16px);
        font-weight: 500;
        margin-top: 6px;
        margin-bottom: 20px;
        color: $--color-black;

        @include media(xs) {
            margin-bottom: 10px;
        }
    }
}

// Horizontal state style
.is-horizontal.card {
    min-width: 300px;
    max-width: 666px;
    width: 100%;

    .card {
        &__image-container {
            margin-bottom: 20px;

            @include media(sm) {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }

        &__content {
            flex-direction: row;
            align-items: center;
        }
    }

    &:not(:last-child) {
        margin-right: 16px;
    }
}

.primary.card {
    .card {
        background-color: $--color-primary !important;

        &__title {
            color: white;
            font-size: rem(18);
            font-weight: 300;
        }

        &__text {
            color: white;
        }

        &__summary {
            color: white;
        }

        &__date {
            font-size: rem(25);
            color: white;
            font-weight: bold;
            padding-top: 32px;
            padding-bottom: 19px;
        }
    }
}
</style>

<style lang="scss">
.has-shadow .el-card__body {
    &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
}
.card {
    .el-card {
        border: none;
        border-radius: 0;
        height: 100%;
        width: 100%;
        overflow: visible;

        &__body {
            display: flex;
            flex-direction: column;
        }

        .card__image-container,
        .media__image {
            .el-image {
                box-shadow: 0 0 32px rgba(0, 0, 0, 0.24);
            }
        }

        &--vertical {
            .el-card__body {
                height: auto;
                box-sizing: border-box;
                padding: 0;
            }

            .card__image-container {
                margin-bottom: 24px;
                margin-right: 0;
                width: 100%;
                height: auto;
            }

            .card__date-container {
                margin-bottom: 5px;
            }

            .card__title {
                margin-bottom: 5px;
            }
        }
    }

    &__url {
        .link__internal {
            &:hover {
                text-decoration: none !important;
            }
        }
    }

    &__date-container {
        order: -1;
        font-weight: 700;
    }

    &__place {
        display: flex;
        align-items: flex-start;
        font-weight: 600;
        font-size: rem(14px);

        &-icone {
            margin-right: 7px;
            width: 11px;
        }
    }

    &.primary {
        .el-card {
            background-color: $--color-primary !important;
        }
    }
}
</style>
