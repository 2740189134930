<template>
    <div class="reminder-block">
        <div
            class="reminder-block__item-inner"
            :class="{
                'with-button': link,
            }"
        >
            <div class="reminder-block__txt" tabindex="0">
                <span class="reminder-block__txt-inner">{{
                    content.length > maxTexteLength ? content.substr(0, maxTexteLength) + '...' : content
                }}</span>
            </div>

            <CustomLink v-if="link" :to="link ? link : '#'">
                <el-button tabindex="-1" class="reminder-block__button" type="primary" plain>{{
                    $t('home.reminderButton')
                }}</el-button>
            </CustomLink>
        </div>
    </div>
</template>

<script>
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        CustomLink,
    },

    props: {
        link: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        maxTexteLength: {
            type: Number,
            default: 100,
        },
    },
};
</script>

<style lang="scss">
.reminder-block .link__internal {
    text-decoration: none !important;
}
</style>

<style lang="scss">
.reminder-block {
    width: 100%;
    height: 100%;
    margin-bottom: 28px;

    @include media(md) {
        display: flex;
        align-items: stretch;
    }

    .link__internal-container {
        @include media(md) {
            display: flex;
            align-items: stretch;
        }
    }

    &__item-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    &__txt {
        flex-grow: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 22px 27px;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 57px;
        background-color: $--color-primary;
        color: white;
        font-size: rem(14px);
        line-height: 19px;
        font-weight: 500;
        overflow-wrap: break-word;
        border-radius: 4px;
        transition: 0.4s all;
        word-break: break-word;

        @include media(md) {
            font-size: rem(16px);
            line-height: 22px;
        }

        .with-button & {
            margin-bottom: rem(16px);
        }

        &:hover {
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
        }
    }

    &__txt-inner {
        margin: auto;
    }

    &__button {
        background-color: white !important;
        font-weight: 600 !important;
        font-size: rem(13px) !important;
        max-width: 170px;
        width: 100%;
        border: 2px solid $--color-primary !important;
        color: $--color-primary !important;
        text-transform: uppercase !important;

        &:hover {
            opacity: 0.85;
        }
    }

    .link__internal {
        text-decoration: none !important;
    }
}
</style>
