import { STATUS_CODES } from 'http';
// import uppercamelcase from 'uppercamelcase';

class HTTPError extends Error {
    constructor(code, message, extras) {
        super(message || STATUS_CODES[code]);
        if (arguments.length >= 3 && extras) {
            Object.assign(this, extras);
        }
        this.name = toName(code);
        this.statusCode = code;
    }
}

export function toName(code) {
    // const suffix = ((code / 100) | 0) === 4 || ((code / 100) | 0) === 5 ? 'error' : '';
    // return uppercamelcase(String(STATUS_CODES[code]).replace(/error$/i, ''), suffix);
    return String(STATUS_CODES[code]).replace(/error$/i, '');
}

export default HTTPError;
