<template>
    <div class="c-block-header-bar">
        <div class="o-container -menu">
            <div class="c-block-header-bar_inner">
                <div class="c-block-header-bar_col || hide-from-header">
                    <ui-burger />
                </div>
                <div class="c-block-header-bar_col">
                    <h1 v-if="$nuxt.$route.name === 'index'" class="c-block-header-bar_logo">
                        <ui-icon type="logo" />
                        <span class="u-screen-reader-text">{{ $t('a11y.logoHomeLink') }}</span>
                    </h1>
                    <NuxtLink v-else to="/" class="c-block-header-bar_logo">
                        <ui-icon type="logo" />
                        <span class="u-screen-reader-text">{{ $t('a11y.logoHomeLink') }}</span>
                    </NuxtLink>
                </div>
                <div class="c-block-header-bar_col">
                    <div class="c-block-header-bar_ctas">
                        <nav :aria-label="$t('a11y.menu.adminMenu')">
                            <ul>
                                <li>
                                    <ui-cta
                                        type="internal"
                                        size="wide"
                                        title="Recherche"
                                        icon-type="search"
                                        :url="'/' + this.$i18n.locale + '/resultats-de-recherche'"
                                    />
                                </li>
                                <li>
                                    <ui-cta
                                        class="-inline || show-from-header"
                                        :url="portalUrl"
                                        :title="$t('header.portailCitoyen')"
                                        type="link"
                                        :label="$t('header.portailCitoyen')"
                                    />
                                </li>
                                <li>
                                    <ui-cta
                                        class="-inline || show-from-header"
                                        :url="'/' + this.$i18n.locale + '/langue'"
                                        :title="$t('header.language')"
                                        type="internal"
                                        :label="$t('header.language')"
                                    />
                                </li>
                                <li>
                                    <ui-cta
                                        class="-inline || show-from-header"
                                        :url="'/' + this.$i18n.locale + '/emplois'"
                                        :title="$t('header.emplois')"
                                        type="internal"
                                        :label="$t('header.emplois')"
                                    />
                                </li>
                            </ul>
                            <ui-cta
                                :icon-width="19"
                                :icon-height="17"
                                type="button"
                                :title="$t('header.snapEngage')"
                                :label="$t('header.snapEngage')"
                                color="tonic"
                                icon-type="chat"
                                class="c-block-header-bar_contact || show-from-header"
                                @onClick="openSnapEngage"
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UiBurger from '~/components/locomotive/ui-burger.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';
export default {
    components: {
        UiCta,
        UiIcon,
        UiBurger,
    },
    props: {
        siteTitle: {
            type: String,
            default: '',
        },
    },
    data: () => {
        return {
            portalUrl: process.env.LONGUEUIL_PORTAL_DOMAIN,
        };
    },
    mounted() {},
    methods: {
        openSnapEngage() {
            if (process.client) {
                // console.log('Snap', window?.SnapEngage);
                window?.SnapEngage?.startLink?.();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-header-bar {
    color: $loco-tonic-dark;
    background-color: $loco-neutral-lighter;
    pointer-events: auto;
    transition: color $loco-speed-fast $loco-easing, background-color $loco-speed-fast $loco-easing;

    .is-menu-open & {
        @media (max-width: $to-header) {
            color: $loco-light;
            background-color: $loco-neutral-darker;
        }
    }

    @media (max-width: $to-header) {
        padding-top: loco-rem(20px);
        padding-bottom: loco-rem(20px);
    }

    @media (min-width: $from-header) {
        padding-top: loco-rem(12px);
        padding-bottom: loco-rem(12px);
        border-bottom: 1px solid $loco-grey;
    }
}

.c-block-header-bar_inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.c-block-header-bar_logo {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $to-header) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: loco-rem(96px);
        height: loco-rem(32px);
        transform: translate3d(-50%, -50%, 0);
    }

    @media (min-width: $from-header) {
        width: loco-rem(117px);
        height: loco-rem(40px);
    }
}

.c-block-header-bar_ctas {
    display: flex;
    align-items: center;

    & > nav,
    ul {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
}

.c-block-header-bar_contact {
    margin-left: loco-rem(26px);
    visibility: hidden;
}
</style>
