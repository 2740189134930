<template>
    <article :class="[typeClass, { '-fluid': isFluid }]" class="c-ui-tile" data-scroll>
        <div class="c-ui-tile_wrapper || js-slide-wrapper">
            <div v-if="imageSrc" class="c-ui-tile_image">
                <ui-lazyimage
                    :image-src="imageSrc"
                    :image-width="imageWidth"
                    :image-height="imageHeight"
                    :image-alt="$t('a11y.emptyValue')"
                />
            </div>
            <div class="c-ui-tile_inner">
                <div class="c-ui-tile_top">
                    <p class="c-ui-tile_category || c-text -overline">{{ category }}</p>
                    <h3 class="c-ui-tile_title || c-heading -h6 || u-mt-4">
                        {{ title }}
                    </h3>
                </div>
                <footer class="c-ui-tile_footer || u-mt-11">
                    <div class="c-ui-tile_date || c-ui-tile_footer_item || c-text -caption">
                        <span>{{ dateStart }}</span>
                        <span v-if="dateEndFormatted">{{ dateEndFormatted }}</span>
                    </div>
                    <ul v-if="tags" class="c-ui-tile_tags || c-ui-tile_footer_item || c-text -caption">
                        <li v-for="(tag, index) in tags" :key="index" class="c-ui-tile_tags_item">
                            {{ tag }}
                        </li>
                    </ul>
                </footer>
            </div>
            <a class="c-ui-tile_link" :href="linkUrl" :title="title">
                {{ title }}
            </a>
        </div>
    </article>
</template>
<script>
import UiLazyimage from '~/components/locomotive/ui-lazyimage.vue';
export default {
    components: { UiLazyimage },
    props: {
        category: {
            type: String,
            required: true,
            default: '',
        },
        title: {
            type: String,
            required: true,
        },
        dateStart: {
            type: String,
            default: '',
        },
        dateEnd: {
            type: String,
            default: '',
        },
        tags: {
            type: Array,
            default: () => [],
        },
        imageSrc: {
            type: String,
            default: '',
        },
        imageWidth: {
            type: Number,
            default: 2,
        },
        imageHeight: {
            type: Number,
            default: 1,
        },
        imageAlt: {
            type: String,
            default: '',
        },
        isFluid: {
            type: Boolean,
            default: false,
        },
        linkUrl: {
            type: String,
            required: true,
        },
    },

    computed: {
        dateEndFormatted() {
            if (!this.dateEnd) return '';
            return `au ${this.dateEnd}`;
        },
        typeClass() {
            if (this.imageSrc) {
                return '-rich';
            } else {
                return '-minimal';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-tile {
    position: relative;
    border-radius: 8px 8px 8px 0px;
    overflow: hidden;
    max-width: 100%;

    &:not(.-fluid) {
        max-width: loco-rem(434px);
    }

    &:before {
        content: '';
        @include u-stretch;
        background-color: $loco-light;
        z-index: -1;
    }
}

.c-ui-tile_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
}

.c-ui-tile_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: loco-rem(16px);
    padding-left: loco-rem(12px);
    padding-right: loco-rem(12px);
    height: 100%;
    flex: 1;

    .c-ui-tile.-rich & {
        padding-top: loco-rem(24px);
    }

    .c-ui-tile.-minimal & {
        padding-top: loco-rem(12px);
    }
}

.c-ui-tile_category {
    color: $loco-tonic;
}

.c-ui-tile_title {
    color: $loco-tonic-dark;
    padding-right: loco-rem(32px);
    max-width: loco-rem(410px);
}

.c-ui-tile_footer {
    display: flex;
    align-items: flex-end;
    color: $loco-grey-dark;
}

.c-ui-tile_footer_item {
    width: 50%;

    &:nth-child(odd) {
        padding-right: loco-rem(8px);
    }

    &:nth-child(even) {
        padding-left: loco-rem(8px);
    }
}

.c-ui-tile_date {
    span {
        display: block;
    }
}

.c-ui-tile_tags_item {
    display: block;
}

.c-ui-tile_link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;

    &:focus-visible {
        outline-offset: -2px;
    }
}
</style>
