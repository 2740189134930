export default (ctx, inject) => {
    const rruleTranslator = {
        toText: item => {
            // takes a rule object (rrule) as an input
            const text = item;

            const $t = () => {
                const lang = ctx.app.i18n.locale;
                return ctx.app.i18n.messages[lang];
            };

            const every = $t().events.frequences.each;
            const frequences = {
                'FREQ=DAILY': $t().events.frequences.daily,
                'FREQ=WEEKLY': $t().events.frequences.weekly,
                'FREQ=MONTHLY': $t().events.frequences.monthly,
                'FREQ=YEARLY': $t().events.frequences.yearly,
            };

            const repetition = {
                'BYSETPOS=1': $t().events.occurence.first,
                'BYSETPOS=2': $t().events.occurence.second,
                'BYSETPOS=3': $t().events.occurence.third,
                'BYSETPOS=4': $t().events.occurence.fourth,
                'BYSETPOS=5': $t().events.occurence.fifth,
            };

            const weekdays = {
                MO: $t().events.days.monday,
                TU: $t().events.days.tuesday,
                WE: $t().events.days.wednesday,
                TH: $t().events.days.thursday,
                FR: $t().events.days.friday,
                SA: $t().events.days.saturday,
                SU: $t().events.days.sunday,
            };

            const infos = text.split(';');

            function addS(info) {
                const val = 'INTERVAL=';
                const part = text.slice(text.indexOf(val) + val.length);
                const v = part.split(';')[0];

                return v === '1' ? '' : info[info.length - 1] === 's' ? '' : 's';
            }

            let interval = 0;
            let dayOccurence = '';
            let isYearly = false;
            let infosDates = infos
                .map(i => {
                    let occurence = '';

                    switch (true) {
                        case i.includes('FREQ'):
                            occurence = text.includes('INTERVAL')
                                ? every + ' ' + frequences[i] + addS(frequences[i]) + ','
                                : every + ' ' + frequences[i] + ',';

                            isYearly = i === 'FREQ=YEARLY';
                            break;

                        case i.includes('BYDAY'): {
                            const dayList = i.split('=')[1].split(',');

                            if (text.includes('BYSETPOS')) {
                                dayOccurence = dayList;
                            } else {
                                occurence =
                                    ' ' +
                                    dayList.reduce((occurence, d) => {
                                        return occurence.concat(weekdays[d] + ' ');
                                    }, '');
                            }
                            break;
                        }

                        case i.includes('INTERVAL'):
                            interval = i.split('=')[1] === '1' ? 0 : i.split('=')[1];
                            break;

                        case i.includes('BYMONTHDAY'):
                            occurence = isYearly ? '' : ' ' + $t().events.frequences.onThe + ' ' + i.split('=')[1];
                            break;

                        case i.includes('BYSETPOS'):
                            occurence = ' ' + repetition[i] + ' ' + weekdays[dayOccurence];
                            break;

                        case i.includes('OCCURENCE'):
                            // FAIS PAS DE SENSE
                            break;

                        case i.includes('UNTIL'):
                            // FAIS PAS DE SENSE
                            break;

                        default:
                    }

                    return occurence;
                })
                .join('');

            // insert interval
            infosDates =
                interval > 0
                    ? infosDates.slice(0, every.length) + ' ' + interval + infosDates.slice(every.length)
                    : infosDates;

            return infosDates;
        },
    };

    inject('rruleTranslator', rruleTranslator);
};
