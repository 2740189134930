<template>
    <Container class="error-page" padding>
        <Container>
            <div class="error-page__main-container">
                <div class="error-page__container">
                    <template v-if="error.statusCode === 404">
                        <h1 class="error-page__title" v-html="$t('errorPage.404.title')"></h1>
                        <p v-if="error.message.search('statusCode') === -1 && isDev" class="error-page__message-dev">
                            {{ $t('errorPage.exception') }}
                            <span class="error-page__message-dev-exception">{{ error.message }}</span>
                        </p>
                    </template>

                    <template v-else-if="error.statusCode === 401">
                        <h1 class="error-page__title" v-html="$t('errorPage.unauthorized.title')"></h1>
                        <p v-if="error.message.search('statusCode') === -1 && isDev" class="error-page__message-dev">
                            {{ $t('errorPage.exception') }}
                            <span class="error-page__message-dev-exception">{{ error.message }}</span>
                        </p>
                    </template>

                    <template v-else-if="error.statusCode === 500">
                        <h1 class="error-page__title" v-html="$t('errorPage.500.title')"></h1>
                        <p class="error-page__message" v-html="$t('errorPage.500.message')" />
                        <p v-if="error.message.search('statusCode') === -1 && isDev" class="error-page__message-dev">
                            {{ $t('errorPage.exception') }}
                            <span class="error-page__message-dev-exception">{{ error.message }}</span>
                        </p>
                    </template>

                    <template v-else>
                        <h1 class="error-page__title" v-html="$t('errorPage.error.title')"></h1>
                        <p v-if="error.message.search('statusCode') === -1 && isDev" class="error-page__message-dev">
                            {{ $t('errorPage.exception') }}
                            <span class="error-page__message-dev-exception">{{ error.message }}</span>
                        </p>
                    </template>
                    <custom-link :to="`/${$i18n.locale}`">{{ $t('errorPage.action') }}</custom-link>
                </div>
            </div>
        </Container>
    </Container>
</template>

<script>
import Container from '~/components/Container.vue';
import CustomLink from '~/components/CustomLink.vue';

export default {
    components: {
        Container,
        CustomLink,
    },

    props: {
        error: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isDev: process.env.NODE_ENV === 'development',
        };
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
.error-page {
    &__main-container {
        height: 600px;
        display: flex;
        text-align: center;
        align-items: center;
    }

    &__container {
        text-align: center;
        margin: auto;
    }

    &__title {
    }

    &__message {
        &-dev {
            font-style: italic;
            border: 1px solid #aaa;
            background-color: rgb(233, 233, 233);
            padding: 20px;
            &-exception {
                font-weight: bold;
            }
        }
    }
}
</style>
