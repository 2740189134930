<template>
    <list-with-filter
        class="allocutions-list"
        taxonomy-type="news_type"
        :title="$t('news.speechesTitle')"
        :intro="initData.item.body != null ? initData.item.body.processed : null"
        :filter-values="filterValues"
        :search-bar-placeholder="$t('news.findSpeeches')"
        :total-count="parseInt(news.pager.count)"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        year-filter
        hide-filter-header
        @filterUpdate="filterUpdateHandler"
    >
        <template>
            <loader-wrapper v-if="news.results.length !== 0" :loading="isUpdating">
                <section>
                    <article v-for="n in news.results" :key="n.entity_id" class="allocutions__item">
                        <div class="allocutions__image">
                            <uploaded-image fit="cover" :path="n.image ? n.image : '/placeholder_284x190.svg'" />
                        </div>
                        <div class="allocutions__block">
                            <p class="allocutions__date" v-html="n.created"></p>
                            <h3 class="allocutions__title">
                                <custom-link :to="n.entity_alias" info>
                                    {{ n.entity_title | decodehtml }}
                                </custom-link>
                            </h3>
                            <p class="allocutions__excerpt">{{ n.summary | striphtml }}</p>
                        </div>
                    </article>
                </section>
            </loader-wrapper>
            <p v-else>{{ this.$t('news.noSpeeches') + '.' }}</p>
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';
import UploadedImage from '~/components/UploadedImage';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        LoaderWrapper,
        ListWithFilter,
        UploadedImage,
        CustomLink,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            news: initData.news,
            filterValues: {
                byTitle: '',
                byDate: 'week',
                byDateRange: [],
                byType: [],
            },
            page: parseInt(this.$route.query.page) || 1,
            itemsPerPage: parseInt(initData.news.pager.items_per_page),
            isUpdating: false,
        };
    },

    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        page() {
            this.updateNews(true);
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        // Load initial datas, format filters and sets them
        const path = process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/search-speeches?search=';
        const parameters = {
            dates: {
                search: [`&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`],
                type: 'created',
                start: app
                    .$moment()
                    .startOf('year')
                    .format('YYYY-MM-DD HH:mm:ss'),
                end: app
                    .$moment()
                    .endOf('year')
                    .format('YYYY-MM-DD HH:mm:ss'),
            },
        };
        const response = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(path, parameters),
        });

        return {
            item,
            news: response.data,
        };
    },

    methods: {
        async updateNews(pageOnly = false) {
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;

            const path = process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + '/api/search-speeches?search=';

            const parameters = {
                search: [...this.filterValues.byTitle, `&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`],
                dates: {
                    type: 'created',
                    start: this.$moment()
                        .startOf('year')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    end: this.$moment()
                        .endOf('year')
                        .format('YYYY-MM-DD HH:mm:ss'),
                },
            };

            if (this.filterValues.byDate !== 'default') {
                parameters.dates = {
                    type: 'created',
                    start: this.filterValues.byDateRange[0],
                    end: this.filterValues.byDateRange[1],
                };
            }

            const response = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(path, parameters),
            });

            this.news = response.data;
            this.isUpdating = false;
        },

        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updateNews();
        },
    },
};
</script>

<style lang="scss">
.allocutions {
    &__group {
        margin-bottom: 48px;
        display: flex;
        align-items: flex-start;
    }

    &__block {
        width: auto;
        height: auto;

        @include media(md) {
            width: 490px;
            height: 190px;
        }
    }

    &__date {
        flex-basis: 157px;
        margin-block-start: 0px;
        margin-right: 1.5em;
        margin-bottom: 5px;
        color: $--color-black;
        font-size: rem(15px);
        font-weight: 700;

        @include media(sm) {
            font-size: rem(14px);
        }
    }

    &__title {
        font-size: rem(18px);
        font-weight: 700 !important;
        line-height: 1.45em;
        margin-top: 0px;
        margin-bottom: 9px;

        @include media(sm) {
            margin-bottom: 7px;
        }
    }

    &__title a {
        color: $--color-info;
        text-decoration: none;
        .link__txt {
            font-weight: 700 !important;
        }
    }

    &__item {
        margin-bottom: 66px;
        display: flex;
        flex-flow: column;

        @include media(md) {
            margin-bottom: 48px;
            height: 190px;
            flex-flow: row;
        }
    }

    &__image {
        width: 100%;
        margin-right: 32px;
        margin-bottom: 12px;

        @include media(sm) {
            width: 320px;
        }
        @include media(md) {
            height: 190px;
            width: 284px;
        }
    }

    &__excerpt {
        font-size: rem(13px);
        font-weight: 400;
        line-height: 1.6em;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: -3px;

        @include media(sm) {
            font-size: rem(15px);
            line-height: 1.5em;
        }
    }
}
.filterButton {
    .el-button {
        border: none;
        color: $--color-primary;
        width: auto;
        height: auto;
        text-decoration: underline;
        font-size: rem(16px);
        font-weight: 500;
        padding: 0;

        @include media(lg) {
            margin-left: 30px;
        }

        &:hover {
            background-color: $--color-white;
        }

        &:focus {
            background-color: $--color-white;
        }
    }
}

.allocutions-list {
    .list-with-filter {
        &__content {
            margin-top: 20px;
            margin-bottom: 10px;

            @include media(sm) {
                margin-top: 0;
            }

            .loader-wrapper {
                @include media(sm) {
                    margin-left: 3px;
                }
            }
        }
    }

    .title {
        &__htag--h1 {
            margin-top: 44px;
            margin-bottom: 24px;
            font-size: rem(25px);
            font-weight: 700;

            @include media(sm) {
                margin-top: 28px;
                margin-bottom: 22px;
                font-size: rem(42px);
            }
        }
    }

    .container > div {
        @include media(lg) {
            margin-left: -30px;
        }
    }
}
</style>
