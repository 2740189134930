<template>
    <iframe v-if="uri" class="map map__frame" :src="uri" :style="height ? `height: ${height};` : ''"></iframe>
</template>

<script>
export default {
    props: {
        uri: {
            type: String,
            default: null,
        },
        height: {
            default: null,
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
.map {
    width: 100%;
    min-height: 600px;
    height: 750px;
    max-height: 85vh;
    border: none;
}
</style>
