import BaseResource from '../BaseResource';
import TranslatePathRequestFactory from '../TranslatePathRequestFactory';

class TranslatePath extends BaseResource {
    constructor(ctx) {
        super();
        this.resourcePath = '/router/translate-path';
        this.app = ctx.app;
    }

    getIndividual({ pathAlias, waitFor = [] } = {}) {
        return TranslatePathRequestFactory(
            `/${this.app.i18n.locale}${this.resourcePath}`,
            [],
            { path: `/${pathAlias}` },
            waitFor,
        );
    }

    getCollection() {
        throw new Error('Method not supported');
    }
}

export default TranslatePath;
