<template>
    <container full class="map">
        <h1 v-if="item.link.title" class="visually-hidden">{{ item.link.title }}</h1>
        <h1 v-else class="visually-hidden">{{ $t('a11y.interactiveMapTitle') }}</h1>
        <interactive-map :uri="item.link.uri"></interactive-map>
    </container>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import InteractiveMap from '~/components/InteractiveMap';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        Container,
        InteractiveMap,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            navIsShowed: false,
        };
    },

    computed: {
        showNav() {
            return this.navIsShowed ? 'map__nav--show' : '';
        },
        icon() {
            return this.navIsShowed
                ? 'map__nav--icon el-icon-arrow-left el-icon--left'
                : 'map__nav--icon el-icon-arrow-right el-icon--right';
        },
    },

    asyncData({ app, query, store, params, error, env }, item) {
        return {
            item,
        };
    },
};
</script>

<style lang="scss" scoped>
.map {
    position: relative;
    &__nav {
        position: absolute;
        z-index: 10;
        top: 7em;
        left: -210px;
        width: 250px;
        max-width: 325px;
        transition: width 0.5s ease-in-out, left 0.5s ease-in-out;
        &--icon {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.25em 0.75em;
        }
        &--show {
            left: 4em;
            width: 25%;
        }
    }
}
</style>
