<template>
    <content-details :title="pageTitle ? pageTitle : $t('requestsAndPayments.title')" class="wrapper online-services">
        <Container v-if="initData.item.body != null" class="online-services__intro" big-padding padding>
            <wysiwyg class="item__wysiwyg item__wysiwyg--bigger" :body="initData.item.body.processed" />
        </Container>
        <search-bar
            :placeholder="$t('requestsAndPayments.searchRequests')"
            class="searchBar"
            :bar-label="$t('a11y.searchBars.searchServices')"
            background
            small-padding
            :no-autocomplete="true"
            @input="filter($event)"
        ></search-bar>

        <container v-if="reqAndPay.length > 0" class="requests">
            <Row gutter align tag="ul">
                <el-col v-for="action in reqAndPay" :key="action.id" :span="24" :md="8" :sm="12" tag="li">
                    <custom-link :to="action.link_text" :hide-external="true" primary external class="item">
                        <template>
                            <!-- gérer l'icone -->
                            <decorative-media-image
                                v-if="action.media_icon"
                                :image="action.media_icon"
                                size="front_large_21_9"
                                class="item__content item__image"
                                img-tag
                            ></decorative-media-image>
                            <span class="item__content item__title">{{ action.title }}</span>
                        </template>
                    </custom-link>
                </el-col>
            </Row>
        </container>
        <div v-else class="no-services">
            {{ this.$t('requestsAndPayments.noResults') }}
        </div>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';

import DecorativeMediaImage from '../../components/DecorativeMediaImage';
import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import CustomLink from '~/components/CustomLink';
import MediaImage from '~/components/MediaImage';
import Row from '~/components/Row';
import SearchBar from '~/components/SearchBar';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        DecorativeMediaImage,
        ContentDetails,
        Container,
        CustomLink,
        MediaImage,
        Row,
        SearchBar,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            actions: initData.actions,
            pageTitle: initData.pageTitle,
            isUpdating: false,
            reqAndPay: [],
            sortedList: [],
            localUrl: '',
        };
    },

    async asyncData({ app, query, store, params, error, env }, item, routeInfo) {
        const sort = 'order';

        // Get list of services
        const actions = await app.$client.execute('actions', 'getCollection', {
            sort,
            includes: ['image'],
        });

        return {
            item,
            pageTitle: item.title,
            actions: actions.data,
        };
    },

    mounted() {
        // handling of special charcaters like ê
        const sortedActions = this.actions;
        sortedActions.forEach(t => {
            const newTitle = this.$utils.cleanify(t.title);
            t.sortedTitle = newTitle;
        });

        const newListAlpha = this.$utils.sortBy(sortedActions, 'sortedTitle');
        const newList = this.$utils.sortByOrder(newListAlpha, 'order');

        // reqAndPay represents the actual actions displayed while sortedList corresponds
        // to the original actions sorted with the parameter that enables filtering: sortedTitle

        this.reqAndPay = newList;
        this.sortedList = newList;
    },

    methods: {
        filter(val) {
            const fil = this.$utils.cleanify(val);
            if (fil.length > 2) {
                this.reqAndPay = this.reqAndPay.filter(action => action.sortedTitle.includes(fil.toLowerCase()));
            } else this.reqAndPay = this.sortedList;
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 42px;

    @include media(sm) {
        margin-bottom: 152px;
    }
}

.requests {
    margin-left: 0;
    max-width: 980px;
    margin-top: -6px;

    @include media(lg) {
        padding-left: $content-padding;
        margin-top: 0;
    }
}

.item {
    display: block;
    height: rem(120px);
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    max-height: 109px;

    @include media(sm) {
        margin-bottom: 24px;
        max-height: 100%;
    }

    &__image {
        margin-left: rem(24px);
        max-width: rem(50px);
        max-height: rem(50px);
        margin-bottom: 0;
    }

    &__title {
        font-size: rem(14px);
        font-weight: 400;
        line-height: 1.4em;
        color: $--color-primary;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0em;
        margin-inline-end: 0em;
        padding-left: 20px;
        padding-right: 30px;

        @include media(sm) {
            font-size: rem(16px);
        }
    }
}

.link__external {
    cursor: pointer;
    width: inherit;
    height: inherit;
}

.searchBar {
    max-width: 980px;
    margin: 27px 0 30px 0;

    @include media(lg) {
        padding-left: $content-padding;
    }
}
.no-services {
    @include media(lg) {
        margin-left: 60px;
    }
}
</style>

<style lang="scss">
div.online-services {
    &__intro {
        margin-bottom: 26px;

        @include media(sm) {
            margin-bottom: 34px;
        }
    }

    > div > div {
        margin-left: 0;

        @include media(lg) {
            margin-left: -80px;
        }
    }

    .title__htag--h1 {
        font-size: rem(25px);
        margin-top: 14px;
        margin-bottom: -10px;

        @include media(sm) {
            font-size: rem(40px);
            margin-bottom: -2px;
            margin-top: 26px;
        }
    }

    .el-input__inner {
        font-style: italic;
    }

    .requests {
        ul {
            list-style: none;
            padding-left: 0;
        }
        .link__txt {
            display: flex;
            height: inherit;
            width: inherit;
            text-align: left;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }
    }
}
</style>
