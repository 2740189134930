import { flatten, isEmpty, join } from 'lodash';
import BaseResource from '../BaseResource';
import ByPathRequestFactory from '../ByPathRequestFactory';

class ByPath extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '';
    }

    getIndividual({ uri, limit = 50, offset = 0, sort = [], filters = {}, include = [], waitFor = [] } = {}) {
        const query = {
            filter: {
                status: 1,
                ...filters,
            },
            page: {
                limit,
                offset,
            },
        };

        if (!isEmpty(sort)) {
            query.sort = join(flatten([sort]), ',');
        }

        if (!isEmpty(include)) {
            query.include = join(flatten([include]), ',');
        }
        return ByPathRequestFactory(uri, {}, query, waitFor);
    }
}

export default ByPath;
