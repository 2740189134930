<template>
    <container v-if="files.length" class="media-pdf">
        <h2 class="media-pdf__title separation">{{ title ? bigTitle : linkedPublications }}</h2>
        <div class="media-pdf__container">
            <div v-for="(file, index) in files" :key="file.id + index" class="media-pdf__content">
                <custom-link
                    class="media-pdf__link"
                    :to="file.field_media_file ? file.field_media_file.meta.uri : file.meta.uri"
                    external
                    hide-external
                >
                    <img class="media-pdf__image" :src="image(file)" alt />
                </custom-link>
                <div class="media-pdf__info">
                    <div>
                        <div class="media-pdf__file-name">{{ fileName(file) }}</div>
                        <div class="media-pdf__file-size">
                            <img src="~assets/img/mini-pdf-icone.png" alt />
                            <span>({{ fileSize(file) }}ko)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </container>
</template>

<script>
import Container from '~/components/Container.vue';
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        Container,
        CustomLink,
    },
    props: {
        paragraph: {
            type: Object,
            default: null,
        },
        fileList: {
            type: Array,
            default: null,
        },
        forceTitle: {
            type: String,
            default: null,
        },
    },
    computed: {
        bigTitle() {
            return this.title.toUpperCase();
        },
        linkedPublications() {
            return this.$t('news.publications').toUpperCase();
        },
        title() {
            let title = '';

            if (this.paragraph) {
                title = this.forceTitle ? this.forceTitle : this.paragraph.title;
            } else if (this.forceTitle) title = this.forceTitle;

            return title;
        },
        files() {
            let files = [];

            if (this.paragraph) {
                // this.title = this.forceTitle ? this.forceTitle : this.paragraph.title;
                files = this.paragraph.media_pdf;
            } else {
                // if (this.forceTitle) this.title = this.forceTitle;
                files = this.fileList;
            }

            return files;
        },
    },
    mounted() {},
    methods: {
        fileSize(file) {
            const size = file.meta.filesize ? file.meta.filesize : file.field_media_file.meta.filesize;

            return (size / 1024).toFixed(2);
        },
        image(file) {
            let image = '/img/pdf_preview.jpg'; // default

            if (file.meta.pdfThumbnail) image = file.meta.pdfThumbnail;
            else if (file.thumbnail.meta.uri) image = file.thumbnail.meta.uri; // rules images

            return image;
        },
        fileName(file) {
            let fileName = '???';

            if (file.field_media_file)
                fileName = file.field_media_file.meta.description + '.' + file.field_media_file.meta.extension;
            else if (file.meta.name) fileName = file.meta.name + '.' + file.meta.extension;

            return fileName;
        },
    },
};
</script>

<style lang="scss" scoped>
.media-pdf {
    margin-top: 35px;
    margin-bottom: 25px;

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__content {
        display: flex;
        flex-flow: row;
        min-width: 260px;
        flex-grow: 1;
        margin-bottom: 17px;
    }

    &__title.separation {
        margin-top: 3rem;
        margin-bottom: 3rem;
        font-weight: 500;
        letter-spacing: 0.6px;
        font-size: rem(15px);

        @include media(xs) {
            font-size: rem(14px) !important;
            letter-spacing: 0.56px;
        }

        &:after {
            width: 100px;
        }
    }

    &__image {
        max-width: 88px;
        max-height: 120px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        margin-right: 16px;
    }

    &__info {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    &__file-name {
        font-size: rem(15px);
        font-weight: 500;
        color: $--color-primary;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        width: 100%;

        @include media(sm) {
            font-size: rem(16px);
        }
    }

    &__file-size {
        margin-top: 12px;
        width: 100%;
        font-size: rem(15px);
        font-weight: 500;

        span {
            position: relative;
            top: -6px;
            left: 4px;
        }
    }
}

.separation {
    @include trait();
    margin-bottom: 35px;
}
</style>
