<template>
    <div class="c-block-splash" data-scroll>
        <ui-lazyimage
            v-if="imageSrc"
            :image-src="imageSrc"
            :image-width="imageWidth"
            :image-height="imageHeight"
            :image-alt="imageAlt"
            class="c-block-splash_image"
        />
        <div class="o-container">
            <div class="c-block-splash_inner">
                <h2 class="c-heading -h2" v-html="title"></h2>
                <p class="c-block-splash_description || c-text -body1 || u-mt-6" v-html="description"></p>
                <ui-cta
                    :title="linkLabel"
                    :label="linkLabel"
                    :link-type="linkType"
                    :url="linkUrl"
                    class="u-mt-6"
                    type="button"
                    color="tonic"
                    icon-type="arrowRight"
                />
            </div>
        </div>
    </div>
</template>
<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiLazyimage from '~/components/locomotive/ui-lazyimage.vue';
export default {
    components: { UiLazyimage, UiCta },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        imageSrc: {
            type: String,
            default: '',
        },
        imageWidth: {
            type: Number,
            default: 1440,
        },
        imageHeight: {
            type: Number,
            default: 776,
        },
        imageAlt: {
            type: String,
            default: '',
        },
        linkLabel: {
            type: String,
            default: '',
        },
        linkUrl: {
            type: String,
            default: '',
        },
        linkType: {
            type: String,
            default: '',
        },
    },
    data: () => {
        return {
            incrementIndex: 0,
            isReady: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.c-block-splash {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: loco-rem($loco-margin-wide);
    padding-bottom: loco-rem($loco-margin-wide);
    min-height: calc((var(--vh, 1vh) * 100));
    background-color: $loco-tonic-dark;
}
.c-block-splash_image {
    @include u-stretch;
    opacity: 0.8;

    &:before {
        content: '';
        @include u-stretch;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }
}
.c-block-splash_inner {
    position: relative;
    color: $loco-light;
    margin: 0 auto;
    text-align: center;
    max-width: loco-rem(600px);
    z-index: 1;
}
.c-block-splash_description {
    max-width: loco-rem(480px);
    margin: 0 auto;
}
</style>
