<template>
    <container v-if="reminders && reminders[$i18n.locale]" class="reminders-block-list">
        <div>
            <Row align="center" class="reminders-block-list__header">
                <el-col :span="24" :sm="showMoreLink ? 16 : 24" :md="showMoreLink ? 14 : 24">
                    <h2>
                        <span
                            v-if="sectionTitle"
                            class="reminders-block-list__time reminders-block-list__time--full-line"
                            >{{ sectionTitle }}</span
                        >
                        <time v-else class="reminders-block-list__time">{{ $moment().format('LL') }}</time>
                    </h2>
                </el-col>

                <el-col v-if="showMoreLink" class="reminders-block-list__more" :span="24" :sm="8" :md="10">
                    <!-- @TODO: Ajouter la bonne URL à suivre dans https://projets.libeo.com/issues/107661 -->
                    <custom-link to="https://monportail.longueuil.quebec/" primary underline>{{
                        $t('home.allNotifications')
                    }}</custom-link>
                </el-col>
            </Row>

            <Row gutter-small>
                <el-col :span="24" class="reminders-block-list__col">
                    <template v-if="isDesktop">
                        <el-col v-for="(reminder, i) in reminders[$i18n.locale].data" :key="i" :span="6">
                            <reminder-block
                                :link="reminder.links"
                                :content="reminder.contenu"
                                :max-texte-length="maxTexteLength"
                            />
                        </el-col>
                    </template>
                    <carousel-base
                        v-else
                        :slides="reminders[$i18n.locale].data.filter((el, i) => i <= slidesPerView)"
                        :slides-per-view="slidesPerView"
                        class="reminder-slider"
                        name="reminders"
                    >
                        <template slot="slide" slot-scope="{ slideNb }">
                            <reminder-block
                                :link="reminders[$i18n.locale].data[slideNb].links"
                                :content="reminders[$i18n.locale].data[slideNb].contenu"
                                :max-texte-length="maxTexteLength"
                            />
                        </template>
                    </carousel-base>
                </el-col>
            </Row>

            <div v-if="showMoreLink" class="reminders-block-list__more reminders-block-list__more--mobile">
                <custom-link to="#" primary underline>{{ $t('home.allNotifications') }}</custom-link>
            </div>
        </div>
    </container>
</template>

<script>
import CarouselBase from '~/components/CarouselBase';
import Container from '~/components/Container';
import CustomLink from '~/components/CustomLink';
import ReminderBlock from '~/components/ReminderBlock';
import Row from '~/components/Row';

export default {
    components: {
        CustomLink,
        Container,
        ReminderBlock,
        Row,
        CarouselBase,
    },

    props: {
        sectionTitle: {
            type: String,
            default: null,
        },
        reminders: {
            type: Object,
            default: null,
        },
        maxTexteLength: {
            type: Number,
            default: 100,
        },
        slidesPerView: {
            type: Number,
            default: 4,
        },
        showMoreLink: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isDesktop: true,
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth >= 768) {
                this.isDesktop = true;
            } else {
                this.isDesktop = false;
            }
        },
    },
};
</script>

<style lang="scss">
// Desktop & wrapper
.reminders-block-list {
    align-items: stretch;
    margin-top: -44px;
    margin-bottom: -10px;

    @include media(sm) {
        margin-top: rem(36px);
        margin-bottom: rem(110px);
    }

    .caroussel-base__slider-container {
        margin-top: 2px;

        @include media(sm) {
            position: relative;
            left: 7px;
        }
    }

    .caroussel-base__slider-arrow {
        transform: translateY(-50%);
        top: calc(50% - 27px);

        @include media(sm) {
            left: -25px;
        }

        @include media(xs) {
            height: rem(26);
            width: rem(26);
        }

        &--right {
            @include media(sm) {
                top: 50%;
            }
        }

        &--left {
            @include media(sm) {
                top: calc(50% - 57px);
            }
        }
    }

    &__col {
        @include media(md) {
            display: flex;
            align-items: stretch;
        }

        &.el-col {
            padding: 0 !important;
        }
    }

    &__item {
        display: flex;
        align-items: stretch;
    }

    &__header {
        @include media(sm) {
            margin-bottom: rem(27px);
        }
    }

    &__time {
        display: block;
        font-size: rem(15);
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        color: $--color-text-regular;

        @include trait();

        &--full-line {
            position: relative;
            display: inline-block;

            &:after {
                width: 5000%;
                position: absolute;
                left: 100%;
                top: 49%;
            }
        }
    }

    &__card {
        display: none;

        @include media(sm) {
            display: block;
            margin-bottom: rem(24px);
        }

        @include media(md) {
            margin-bottom: 0;
        }
    }

    &__more {
        display: none;
        text-align: right;
        font-weight: 500;

        @include media(sm) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            align-self: center;
        }

        &--mobile {
            display: block;
            margin-top: 30px;
            margin-bottom: rem(38px);
            font-size: rem(13px);
            @include trait(false, 60%, true);

            @include media(sm) {
                display: none;
            }
        }
    }

    .focusable-slide {
        width: calc(100% - 18px);
    }
}

// Slider mobile
.reminder-slider {
    display: block;
    max-width: 100%;

    .reminders__item {
        width: rem(250px);
        height: rem(280px);
        margin: auto;
    }
}
</style>
