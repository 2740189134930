<template>
    <div :class="[themeClass]" class="c-block-newsletter-push" data-scroll>
        <h2 class="c-block-newsletter-push_category || c-text -overline">
            {{ category }}
        </h2>
        <h3 class="c-block-newsletter-push_description || c-heading -h5 || u-mt-4">
            {{ title }}
        </h3>
        <ui-cta
            :url="linkUrl"
            :label="linkLabel"
            :title="linkLabel"
            :color="buttonColor"
            :link-type="linkType"
            class="u-mt-8"
            type="button"
            size="small"
            icon-type="arrowRight"
        />
    </div>
</template>
<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
export default {
    components: {
        UiCta,
    },
    props: {
        category: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        theme: {
            type: String,
            default: 'light',
        },
        linkUrl: {
            type: String,
            default: '',
        },
        linkLabel: {
            type: String,
            default: '',
        },
        linkType: {
            type: String,
            default: '',
        },
    },
    computed: {
        themeClass() {
            switch (this.theme) {
                case 'light':
                    return '-light';
                case 'dark':
                    return '-dark';
            }
            return '-light';
        },
        buttonColor() {
            switch (this.theme) {
                case 'light':
                    return 'neutral';
                case 'dark':
                    return 'tonicDark';
            }
            return 'neutral';
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-newsletter-push {
    max-width: loco-rem(430px);

    @media (max-width: $to-tiny) {
        text-align: center;
    }

    @media (max-width: $to-medium) {
        padding-bottom: loco-rem(68px);
    }

    &.-light {
        color: $loco-light;
    }

    &.-dark {
        color: $loco-tonic-dark;
    }
}

.c-block-newsletter-push_category {
    color: $loco-light;
}

.c-block-newsletter-push_description {
    padding-right: loco-rem(20px);
}
</style>
