const initMenu = () => ({
    isMobileMenuOpen: false,
    currentContentType: null,
    currentApiUrl: null,
    menu: {
        services: {
            fr: null,
            en: null,
        },
        demandesPaiements: {
            fr: null,
            en: null,
        },
        maVille: {
            fr: null,
            en: null,
        },
        explorer: {
            fr: null,
            en: null,
        },
        footer: {
            fr: null,
            en: null,
        },
        footerBottom: {
            fr: null,
            en: null,
        },
        contactUs: {
            fr: null,
            en: null,
        },
        account: {
            fr: null,
            en: null,
        },
        accountExternalJson: {
            fr: null,
            en: null,
        },
        investirLongueuil: {
            fr: null,
            en: null,
        },
        mairesse: {
            fr: null,
            en: null,
        },
        socialNetworks: {
            fr: null,
            en: null,
        },
    },
});

export const state = initMenu;

export const mutations = {
    setMenu(state, { type, items, lang }) {
        state.menu[type][lang] = items;
    },

    setCurrentContentType(state, currentContentType) {
        state.currentContentType = currentContentType;
    },

    setCurrentApiUrl(state, currentApiUrl) {
        state.currentApiUrl = currentApiUrl;
    },

    resetMenuItems(state, isMobileMenuOpen) {
        state.menu = initMenu().menu;
    },

    setIsMobileMenuOpen(state, isMobileMenuOpen) {
        state.isMobileMenuOpen = isMobileMenuOpen;
    },

    resetIsMobileMenuOpen(state) {
        state.isMobileMenuOpen = initMenu().isMobileMenuOpen;
    },

    toogleMenuItem(state, { lang, type, index, isOpen }) {
        if (state.menu && state.menu[type] && state.menu[type][lang] && state.menu[type][lang][index]) {
            state.menu[type][lang][index].isOpen = isOpen;
        }

        if (isOpen) {
            setTimeout(() => {
                document.getElementById(type + index + '-button').focus();
            }, 500);
        }
    },

    closeMainMenu(state, lang) {
        // @TODO On devrait identifier les éléments du menu de manière dynamique
        const mainMenuKeys = ['services', 'demandesPaiements', 'maVille', 'mairesse'];
        if (state.menu) {
            mainMenuKeys.forEach((key, index) => {
                if (state.menu[key] && state.menu[key][lang]) {
                    state.menu[key][lang].forEach((menu, menuIndex) => {
                        state.menu[key][lang][menuIndex].isOpen = false;
                    });
                }
            });
        }
    },

    reset() {
        const initState = initMenu();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};

export const actions = {
    async fetchMenu({ commit }, app) {
        // Check context (If this(front) or app from back)
        const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;

        try {
            const allMenu = await app.$client.subRequests({
                services: app.$api.menu.getIndividual({ id: 'services' }),
                demandesPaiements: app.$api.menu.getIndividual({ id: 'demandes-et-paiements' }),
                maVille: app.$api.menu.getIndividual({ id: 'ma-ville' }),
                explorer: app.$api.menu.getIndividual({ id: 'explorer' }),
                footer: app.$api.menu.getIndividual({ id: 'footer' }),
                footerBottom: app.$api.menu.getIndividual({ id: 'footer-bas' }),
                contactUs: app.$api.menu.getIndividual({ id: 'contact-us' }),
                /*
                account: app.$api.menu.getIndividual({ id: 'account' }),
                accountExternalJson: app.$api.static.getIndividual({ id: 'header_menu.json' }),
                // TODO Attention ceci a déjà causé une erreur
                */
                investirLongueuil: app.$api.menu.getIndividual({ id: 'investir-a-longueuil' }),
                mairesse: app.$api.menu.getIndividual({ id: 'mairesse' }),
                socialNetworks: app.$api.menu.getIndividual({ id: 'social-networks' }),
            });
            // Commit all menu in the store
            Object.keys(allMenu).forEach(key => {
                if (allMenu[key].menu_items)
                    commit('setMenu', {
                        type: key,
                        lang,
                        items: allMenu[key].menu_items.map(menuElement => {
                            return {
                                ...menuElement,
                                isOpen: false,
                            };
                        }),
                    });
            });

            if (allMenu.accountExternalJson && allMenu.accountExternalJson.data) {
                commit('setMenu', {
                    type: 'accountExternalJson',
                    lang,
                    items: allMenu.accountExternalJson.data,
                });
            }
        } catch (e) {
            app.$externalLog.captureException(e);
        }
    },
};
