<template>
    <content-details :title="item.title" :type-tags="item.typeTags" :infos="item.infos" class="rule-with-pdf">
        <template>
            <Container big-padding no-margin>
                <wysiwyg v-if="item.body && item.body.processed" :body="item.body.processed" class="item__wysiwyg" />
                <div class="custom_content">
                    <file-list
                        :force-title="$t('rules.rules')"
                        :file-list="item.media_pdf"
                        class="custom_content__paragraphs"
                    ></file-list>
                </div>
            </Container>
        </template>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container.vue';
import ContentDetails from '~/components/layout/ContentDetails';
import FileList from '~/components/paragraphs/FileList';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        ContentDetails,
        Container,
        Wysiwyg,
        FileList,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
        };
    },

    asyncData({ app, query, store, params, error, env }, item) {
        return { item };
    },
};
</script>

<style lang="scss">
.rule-with-pdf {
    max-width: 810px;
    margin: auto;
    margin-bottom: 80px;

    .item {
        &__related-cards-item {
            margin-bottom: 25px;
        }
    }

    .custom_content {
        margin-bottom: 12px;

        &__paragraphs {
            margin-top: 29px;
            margin-bottom: 16px;
        }
    }

    .title {
        padding-top: 0;
        margin-top: 30px;

        @include media(sm) {
            padding-top: 60px;
            margin-top: 0px;
        }
    }
}
</style>
