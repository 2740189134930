f<template>
    <component :is="tag" :class="{ 'is-active': isOpen }">
        <button
            class="acc-header"
            :aria-expanded="isOpen.toString()"
            :aria-controls="`accPanel-${id || $slugify(title)}`"
            @click="toggleVisibility"
        >
            <slot name="acc-header" :isOpen="isOpen">
                <span>{{ title }}</span>
                <img src="~assets/img/dropdown-arrow.png" alt="" />
            </slot>
        </button>
        <div v-show="isOpen" :id="`accPanel-${id || $slugify(title)}`" role="region">
            <slot name="acc-content"></slot>
            <div v-if="webformId !== null" class="accordion-content-wrapper">
                <LoadingDots v-if="!formReady" />
                <template v-else-if="webformError">
                    <span>{{ $t('pargraphs.webforms.error') }}</span>
                </template>
                <template v-else>
                    <FormSchemaBuilder :webform="webform" />
                </template>
            </div>
        </div>
    </component>
</template>
<script>
import FormSchemaBuilder from '~/components/forms/FormSchemaBuilder';
import LoadingDots from '~/components/LoadingDots';

export default {
    components: {
        FormSchemaBuilder,
        LoadingDots,
    },
    props: {
        id: {
            type: Number,
            default: null,
        },
        title: {
            type: String,
            default: 'Accordion title',
            required: true,
        },
        tag: {
            type: String,
            default: 'li',
        },
        webformId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isOpen: false,
            webform: null,
            webformLoading: true,
            webformError: false,
        };
    },
    computed: {
        formReady() {
            return this.webformError || (!this.webformLoading && this.webform !== null);
        },
    },
    mounted() {
        const self = this;
        if (this.webformId !== null) {
            this.$client
                .execute('webformJsonSchema', 'getIndividual', {
                    id: self.webformId,
                })
                .then(results => {
                    self.webform = results.data;
                    self.webformLoading = false;
                })
                .catch(e => {
                    this.webformLoading = false;
                    this.webformError = true;
                    console.error(e);
                });
        }
    },
    methods: {
        toggleVisibility() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
<style lang="scss">
.acc-header {
    border: none;
    background-color: transparent;
    width: calc(100% + 20px);
    margin-left: -10px;
    justify-content: space-between;
    color: $--color-primary;
    font-weight: 700;
    font-size: rem(16px);
    padding: 0px;
    border-radius: rem(8px);
    line-height: 1.1;
    background-color: $--color-white;
    text-align: left;
    cursor: pointer;

    @include media(sm) {
        margin-left: initial;
        width: 100%;
        color: $--color-green;
    }

    .accordion-arrow {
        transition: all 0.2s;
        .is-active & {
            transform: rotate(180deg);
        }
    }
}
.accordion-content-wrapper {
    padding: rem(16px);
    margin: 0 rem(8px) rem(8px);
    border: 0px solid transparent;
    background-color: $--collapse-content-background-color;
    @include media(sm) {
        padding: rem(32px);
    }
}
</style>
