import { mapState } from 'vuex';

export default {
    data: () => {
        return {
            alertsData: [],
            footerData: {},
            menuData: [],
            socialsData: [],
        };
    },
    computed: {
        ...mapState('menu', ['menu']),
    },
    created() {
        this.footerData = this.formatFooterData();
        this.menuData = this.formatMenuData();
        this.socialsData = this.formatSocialsData();
    },
    methods: {
        getLinkType(uri) {
            if (uri && uri.startsWith('/')) {
                return 'internal';
            } else {
                return 'external';
            }
        },

        // Menu
        formatMenuData() {
            let navigation = [];

            // services
            const services = this.setMenuServices();
            services && navigation.push(services);

            // online services
            const onlineServices = this.setDemandesPaiements();
            onlineServices && navigation.push(onlineServices);

            // city
            const city = this.setMaVille();
            const cityItems = [...city.items, ...this.setExplorer()];
            city.items = cityItems;
            city && navigation.push(city);

            // invest
            const invest = this.setInvestirLongueuil();
            invest && navigation.push(invest);

            // Elected
            navigation = [...navigation, ...this.setMairesse()];

            // contact us
            const contactUs = this.setNousJoindre();
            contactUs && navigation.push(contactUs);

            return navigation;
        },

        setMenuServices() {
            // Services
            const servicesObj = {
                label: '',
                url: '',
                type: '',
                items: [],
            };
            const servicesData = this.menu.services[this.$i18n.locale]?.[0];
            if (!servicesData) return servicesObj;
            servicesObj.label = servicesData?.attributes?.link?.title;

            // Links
            servicesData?.children.forEach(link => {
                const linkObj = {
                    label: link?.attributes?.link?.title,
                    url: '',
                    type: '',
                    items: [],
                };

                // Sublinks
                link?.children.forEach(sublink => {
                    // Navigation link object
                    const sublinkObj = {
                        label: sublink?.attributes?.link?.title,
                        url: sublink?.attributes?.link?.uri,
                        type: this.getLinkType(sublink?.attributes?.link?.uri),
                        items: [],
                    };
                    linkObj.items.push(sublinkObj);
                });
                // End Sublinks

                servicesObj.items.push(linkObj);
            });
            // End Links

            return servicesObj;
        },

        setDemandesPaiements() {
            const onlineServicesObj = {
                label: '',
                url: '',
                type: '',
                items: [],
            };
            const onlineServicesData = this.menu.demandesPaiements[this.$i18n.locale]?.[0];
            onlineServicesObj.label = onlineServicesData?.attributes?.link?.title;
            onlineServicesObj.url = onlineServicesData?.attributes?.link?.uri;
            onlineServicesObj.type = this.getLinkType(onlineServicesObj.url);

            return onlineServicesObj;
        },

        setInvestirLongueuil() {
            const investObj = {
                label: '',
                url: '',
                type: '',
                items: [],
            };
            const investData = this.menu.investirLongueuil[this.$i18n.locale]?.[0];
            investObj.label = investData?.attributes?.link?.title;
            investObj.url = investData?.attributes?.link?.uri;
            investObj.type = this.getLinkType(investObj.url);

            return investObj;
        },

        setMaVille() {
            const myCityObj = {
                label: '',
                url: '',
                type: '',
                items: [],
            };
            const myCityData = this.menu.maVille[this.$i18n.locale]?.[0];
            if (!myCityData) return myCityObj;
            myCityObj.label = myCityData?.attributes?.link?.title;

            // Links
            myCityData?.children.forEach(link => {
                // Navigation link object
                const linkObj = {
                    label: link?.attributes?.link?.title,
                    url: link?.attributes?.link?.uri,
                    type: this.getLinkType(link?.attributes?.link?.uri),
                    items: [],
                };
                myCityObj.items.push(linkObj);
            });
            // End Links

            return myCityObj;
        },

        setExplorer() {
            const explorerData = this.menu.explorer[this.$i18n.locale]?.[0];
            const explorerArray = [];

            if (!explorerData) return explorerArray;

            return explorerArray;
        },

        setMairesse() {
            const electedData = this.menu.mairesse[this.$i18n.locale];
            const electedArray = [];
            if (!electedData) return electedArray;

            // Links
            electedData.forEach(link => {
                // Navigation link object
                const linkObj = {
                    label: link?.attributes?.link?.title,
                    url: link?.attributes?.link?.uri,
                    type: this.getLinkType(link?.attributes?.link?.uri),
                    items: [],
                };
                electedArray.push(linkObj);
            });
            // End Links

            return electedArray;
        },

        setNousJoindre() {
            const contactUsObj = {
                label: '',
                url: '',
                type: '',
                items: [],
            };
            const contactUsData = this.menu.contactUs[this.$i18n.locale]?.[0];
            contactUsObj.label = contactUsData?.attributes?.link?.title;
            contactUsObj.url = contactUsData?.attributes?.link?.uri;
            contactUsObj.type = this.getLinkType(contactUsObj.url);

            return contactUsObj;
        },

        // Footer
        formatFooterData() {
            const navigation = this.setFooterNavigation();
            const navigationBottom = this.setFooterNavigationBottom();
            const phone = this.setPhoneNavigation();
            const address = this.setAddressNavigation();
            const newsletter = this.setFooterNewsletter();
            return {
                copyright: this.$t('footer.copyright'),
                navigation,
                navigationBottom,
                phone,
                address,
                newsletter,
            };
        },

        setFooterNavigation() {
            const footerNavigationData = this.menu.footer[this.$i18n.locale];

            if (!footerNavigationData) {
                return [];
            }

            const navigation = footerNavigationData.map(navigationCol => {
                const navigationColObj = {};

                // Column title
                navigationColObj.title = navigationCol?.attributes?.link?.title;

                // Links
                navigationColObj.links = [];
                navigationCol.children.forEach(child => {
                    const uri = child?.attributes?.link?.uri;

                    // Navigation link object
                    const linkObj = {
                        label: child?.attributes?.link?.title,
                        url: uri,
                        type: this.getLinkType(uri),
                    };
                    navigationColObj.links.push(linkObj);
                });
                // End Links

                return navigationColObj;
            });
            return navigation;
        },

        setFooterNavigationBottom() {
            const footerNavigationData = this.menu.footerBottom[this.$i18n.locale];
            const links = [];
            if (!footerNavigationData) {
                return links;
            }
            footerNavigationData.forEach(child => {
                // Navigation link object
                const linkObj = {
                    label: child?.attributes?.link?.title,
                    url: child?.attributes?.link?.uri,
                    type: child?.attributes?.resource_link ? 'internal' : 'external',
                };
                links.push(linkObj);
            });
            return links;
        },

        setPhoneNavigation() {
            return {
                icon: 'phone',
                description: this.$t('footer.joinUs'),
                links: [
                    {
                        label: '311',
                        url: 'tel:3-1-1',
                    },
                    {
                        label: '450 463-7311',
                        url: 'tel:4504637311',
                    },
                ],
            };
        },

        setAddressNavigation() {
            // TODO: [X - Locomotive] - Add address url (google map)
            return {
                icon: 'address',
                description: `${this.$t('footer.address.street')}<br>${this.$t('footer.address.cityPostalCode')}`,
                links: [
                    {
                        label: this.$t('footer.address.place'),
                        url: this.$t('footer.address.googleMapUrl'),
                    },
                ],
            };
        },

        setFooterNewsletter() {
            const newsletterData = {
                category: this.$t('footer.newsLetter.category'),
                title: this.$t('footer.newsLetter.title'),
                linkUrl: this.$t('footer.newsLetter.linkUrl'),
                linkLabel: this.$t('footer.newsLetter.linkLabel'),
                linkType: 'internal',
            };

            return newsletterData;
        },

        // Socials
        formatSocialsData() {
            const socialsData = this.menu.socialNetworks[this.$i18n.locale];
            const socialsList = [];
            if (!socialsData || !socialsData.length) return [];
            socialsData.forEach(social => {
                socialsList.push({
                    label: social?.attributes?.link?.title,
                    url: social?.attributes?.link?.uri,
                    icon: this.$slugify(social?.attributes?.link?.title),
                });
            });
            return socialsList;
        },
    },
};
