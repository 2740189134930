const initGlobal = () => ({
    breadcrumb: null,
});

export const state = initGlobal;

export const mutations = {
    setBreadCrumb(state, breadcrumb) {
        state.breadcrumb = breadcrumb;
    },

    reset() {
        const initState = initGlobal();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};

export const actions = {};
