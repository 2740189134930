import BaseResource from '../BaseResource';

class Actions extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/actions';
    }
}

export default Actions;
