import BaseResource from '../BaseResource';

class Video extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/mediaVideo';
    }
}

export default Video;
