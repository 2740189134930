export const state = () => ({
    isFirstLoading: true,
    isFontsLoaded: false,
    isPageLoaded: false,
});

export const actions = {
    setFirstLoading: (context, val) => {
        context.commit('SET_FIRST_LOADING', val);
    },
    setFontsLoaded: (context, val) => {
        context.commit('SET_FONTS_LOADED', val);
    },
    setPageLoaded: (context, val) => {
        context.commit('SET_PAGE_LOADED', val);
    },
};

export const mutations = {
    SET_FIRST_LOADING: (state, val) => {
        state.isFirstLoading = val;
    },
    SET_FONTS_LOADED: (state, val) => {
        state.isFontsLoaded = val;
    },
    SET_PAGE_LOADED: (state, val) => {
        state.isPageLoaded = val;
    },
};
