import BaseResource from '../BaseResource';

class News extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/news';
    }
}

export default News;
