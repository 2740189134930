<template>
    <div class="c-block-carousel">
        <div class="o-container">
            <div class="c-block-carousel_header">
                <div class="o-layout -gutter-small">
                    <div class="o-layout_item u-1/3@from-medium">
                        <h2 class="c-heading -h5">{{ title }}</h2>
                    </div>
                    <div class="o-layout_item u-5/12@from-medium">
                        <ui-cta
                            :url="linkUrl"
                            :label="linkLabel"
                            :title="linkLabel"
                            :link-type="linkType"
                            type="button"
                            color="tonic"
                            icon-type="arrowRight"
                            class="c-block-carousel_cta"
                        />
                    </div>
                    <div class="o-layout_item u-1/4@from-medium || u-color-tonic-dark ">
                        <ui-carousel-navigation
                            class="c-block-carousel_navigation"
                            :is-wide="true"
                            @onPrev="onPrev"
                            @onNext="onNext"
                        />
                    </div>
                </div>
            </div>
            <div class="c-block-carousel_content">
                <div ref="container" class="c-block-carousel_container swiper-container">
                    <!-- Additional required wrapper -->
                    <div class="c-block-carousel_wrapper swiper-wrapper">
                        <!-- Slides -->
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Swiper JS
import { Swiper } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css';

import { mapState } from 'vuex';

import UiCarouselNavigation from '~/components/locomotive/ui-carousel-navigation.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';

export default {
    components: {
        UiCta,
        UiCarouselNavigation,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        linkUrl: {
            type: String,
            default: '',
        },
        linkLabel: {
            type: String,
            default: '',
        },
        linkType: {
            type: String,
            default: '',
        },
    },

    data: () => {
        return {};
    },

    computed: {
        ...mapState('locomotive-global', ['metrics']),
        ...mapState('locomotive-loading', ['isFontsLoaded']),
    },

    watch: {
        metrics: 'onMetricsChange',
        isFontsLoaded: 'onFontsLoaded',
    },

    mounted() {
        this.carousel = new Swiper(this.$refs.container, {
            slidesPerView: 1.1,
            spaceBetween: 16,
            a11y: {
                enabled: true,
            },

            breakpoints: {
                700: {
                    slidesPerView: 'auto',
                    spaceBetween: 24,
                },
                1445: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
            },
        });

        this.$slides = this.$el.querySelectorAll('.swiper-slide');
        this.setSlideSide();
    },

    beforeDestroy() {
        this.carousel.destroy(true, true);
    },

    methods: {
        onMetricsChange() {
            this.onResize();
        },

        onResize() {
            this.setSlideSide();
        },

        onFontsLoaded() {
            setTimeout(() => {
                this.onResize();
            }, 1000);
        },

        setSlideSide() {
            for (let index = 0; index < this.$slides.length; index++) {
                const $slide = this.$slides[index];
                const $slideChild = $slide.firstChild;
                $slideChild.style.height = '';
            }

            setTimeout(() => {
                const heights = [];
                for (let index = 0; index < this.$slides.length; index++) {
                    const $slide = this.$slides[index];
                    const $wrapper = $slide.querySelector('.js-slide-wrapper');
                    heights.push($wrapper.offsetHeight);
                }
                const maxHeight = Math.max(...heights);
                for (let index = 0; index < this.$slides.length; index++) {
                    const $slide = this.$slides[index];
                    const $slideChild = $slide.firstChild;
                    $slideChild.style.height = maxHeight + 'px';
                }
            }, 100);
        },
        onPrev() {
            this.carousel.slidePrev();
        },
        onNext() {
            this.carousel.slideNext();
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-carousel {
    overflow: hidden;

    & + .c-block-push,
    & + .c-block-splash {
        margin-top: loco-rem($loco-margin-medium);
    }

    .c-ui-carousel-navigation {
        margin-left: auto;
        margin-right: 0;
    }
}
.c-block-carousel_slide {
    width: span(1/3);
    box-sizing: border-box;
    height: auto;

    @media (min-width: $from-small) {
        min-width: loco-rem(434px);
    }

    .c-ui-tile {
        height: 100% !important;
    }
}

.c-block-carousel_content {
    @media (max-width: $to-small) {
        margin-top: loco-rem($loco-margin-medium-mobile);
    }
    @media (min-width: $from-small) {
        margin-top: loco-rem($loco-margin-medium);
    }
}

.c-block-carousel_container {
    overflow: inherit;
}

.c-block-carousel_header {
    position: relative;

    @media (max-width: $to-tiny) {
        text-align: center;
    }
}

.c-block-carousel_navigation {
    @media (max-width: $to-tiny) {
        display: none;
    }

    @media (max-width: $to-medium) {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.c-block-carousel_cta {
    @media (max-width: $to-medium) {
        margin-top: loco-rem(20px);
    }
}
</style>

<style lang="scss">
.c-block-carousel {
    overflow: hidden;

    & + .c-block-push,
    & + .c-block-splash {
        margin-top: loco-rem($loco-margin-medium * -2);
    }
}
</style>
