<template>
    <div class="c-block-access || c-section -padding-top-regular -padding-bottom-regular -padding-bottom@from-medium">
        <div class="o-container">
            <div class="o-layout -gutter-small">
                <div class="o-layout_item u-1/3@from-medium">
                    <div class="c-block-access_header">
                        <h2 class="c-block-access_category || c-text -overline || u-mb-4 || hide-from-tablet">
                            {{ category }}
                        </h2>
                        <h3 class="c-block-access_title || c-heading -h6">
                            {{ title }}
                        </h3>
                        <ui-cta
                            :url="linkUrl"
                            :title="linkLabel"
                            :label="linkLabel"
                            :link-type="linkType"
                            color="tonic"
                            icon-type="arrowRight"
                            type="button"
                            class="u-mt-6"
                        />
                    </div>
                </div>
                <div class="o-layout_item u-1/2@from-small u-1/3@from-medium">
                    <ul v-if="getLists[0]" class="c-block-access_list">
                        <li v-for="(link, linkIndex) in getLists[0]" :key="linkIndex" class="c-block-access_list_item">
                            <ui-cta
                                :type="link.type"
                                :title="link.label"
                                :label="link.label"
                                :url="link.url"
                                color="tonicDark"
                                :icon-type="link.type === 'external' ? 'external' : 'arrowRight'"
                            />
                        </li>
                    </ul>
                </div>
                <div class="o-layout_item u-1/2@from-small u-1/3@from-medium">
                    <ul v-if="getLists[1]" class="c-block-access_list -last">
                        <li v-for="(link, linkIndex) in getLists[1]" :key="linkIndex" class="c-block-access_list_item">
                            <ui-cta
                                :type="link.type"
                                :title="link.label"
                                :label="link.label"
                                :url="link.url"
                                color="tonicDark"
                                :icon-type="link.type === 'external' ? 'external' : 'arrowRight'"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
export default {
    components: {
        UiCta,
    },
    props: {
        category: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        linkLabel: {
            type: String,
            default: '',
        },
        linkUrl: {
            type: String,
            default: '',
        },
        linkType: {
            type: String,
            default: '',
        },
        links: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        getLists() {
            const links = [[], []];
            const half = Math.ceil(this.links.length / 2);

            for (let index = 0; index < this.links.length; index++) {
                const link = this.links[index];

                if (index < half) {
                    links[0].push(link);
                } else {
                    links[1].push(link);
                }
            }

            return links;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-access {
}
.c-block-access_list {
    @media (max-width: $to-small) {
        .c-ui-cta {
            width: 100%;
            text-align: left;
            justify-content: flex-start;
        }
    }

    &.-last {
        @media (max-width: $to-small) {
            margin-top: loco-rem(20px);
        }
    }
}

.c-block-access_list_item {
    & + .c-block-access_list_item {
        @media (max-width: $to-small) {
            margin-top: loco-rem(20px);
        }
        @media (min-width: $from-small) {
            margin-top: loco-rem(12px);
        }
    }
}

.c-block-access_header {
    max-width: loco-rem(430px);

    @media (max-width: $to-tiny) {
        text-align: center;
    }
    @media (max-width: $to-medium) {
        margin-bottom: loco-rem($loco-margin-medium);
    }
}

.c-block-access_category {
    color: $loco-tonic;
}
.c-block-access_title {
    padding-right: loco-rem(20px);
}
</style>
