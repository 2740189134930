const LAZY_LOADED_IMAGES = [];

export function loadImage(url, options = {}) {
    return new Promise((resolve, reject) => {
        const $img = new Image();
        if (options.crossOrigin) $img.crossOrigin = options.crossOrigin;
        $img.onload = () => {
            resolve({
                element: $img,
                ...getImageMetadata($img),
            });
        };
        $img.onerror = e => {
            reject(e);
        };
        $img.src = url;
    });
}

export function getImageMetadata($img) {
    return {
        url: $img.src,
        width: $img.naturalWidth,
        height: $img.naturalHeight,
        ratio: $img.naturalWidth / $img.naturalHeight,
    };
}

export async function lazyLoadImage($el, url, callback = () => {}) {
    let src;

    if (url) {
        src = url;
    } else {
        src = $el.dataset.src;
    }

    let loadedImage = LAZY_LOADED_IMAGES.find(image => image.url === src);

    if (!loadedImage) {
        loadedImage = await loadImage(src);
        if (!loadedImage.url) return;
        LAZY_LOADED_IMAGES.push(loadedImage);
    }

    if ($el.src === src) {
        return;
    }

    if ($el.tagName === 'IMG') {
        $el.src = loadedImage.url;
    } else {
        $el.style.backgroundImage = `url(${loadedImage.url})`;
    }

    requestAnimationFrame(() => {
        const lazyParent = queryClosestParent($el, '.c-lazy');
        if (lazyParent) {
            lazyParent.classList.add('-lazy-loaded');
            lazyParent.style.backgroundImage = '';
        }
        $el.classList.add('-lazy-loaded');

        callback && callback();
    });
}

// https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/
function queryClosestParent(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                const matches = (this.document || this.ownerDocument).querySelectorAll(s);
                let i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }
    return null;
}
