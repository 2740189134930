<template>
    <div v-if="length" :class="{ '-alt': alt }" class="c-ui-bullets">
        <div class="c-ui-bullets_list">
            <button
                v-for="index in length"
                :key="index"
                class="c-ui-bullets_bullet"
                :aria-label="$t('a11y.carousel.slide') + ' ' + index"
                @click="onClick(index)"
            >
                <span></span>
            </button>
            <div ref="indicator" class="c-ui-bullets_indicator || c-ui-bullets_bullet">
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
import { transform } from '~/utils/transform.js';

export default {
    components: {},
    props: {
        length: {
            type: Number,
            default: 0,
        },
        activeIndex: {
            type: Number,
            default: 0,
        },
        alt: {
            type: Boolean,
            default: true,
        },
    },
    data: () => {
        return {};
    },
    computed: {
        transformValue() {
            return `${this.activeIndex * 100}%`;
        },
    },
    watch: {
        transformValue: 'translateIndicator',
    },
    mounted() {},

    methods: {
        translateIndicator(value) {
            transform(this.$refs.indicator, `translate3d(${value}, 0, 0)`);
        },

        onClick(index) {
            this.$emit('onClick', index);
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-bullets {
}
.c-ui-bullets_list {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.c-ui-bullets_bullet {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: loco-rem(8px);

    .c-ui-bullets.-alt & {
        padding: loco-rem(8px) loco-rem(6px);
    }

    span {
        display: block;
        width: loco-rem(8px);
        height: loco-rem(8px);
        border-radius: 50%;
        border: 1px solid currentColor;
    }
}
.c-ui-bullets_indicator {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform $loco-speed $loco-easing;
    span {
        background-color: currentColor;
    }
}
</style>
