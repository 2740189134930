import BaseResource from '../BaseResource';

class Static extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/static_content';
    }
}

export default Static;
