export const state = () => ({
    metrics: {
        width: 0,
        height: 0,
    },
    isSafeMode: false, // IOS video
    isMenuOpen: false,
    hasScrolled: false,
    isScrollingDown: false,
    breakpoints: {
        tiny: 500,
        small: 700,
        medium: 1000,
        large: 1200,
        header: 1270,
        big: 1440,
    },
});

export const actions = {
    setMetrics: (context, val) => {
        context.commit('SET_METRICS', val);
    },
    setSafeMode: (context, val) => {
        context.commit('SET_SAFE_MODE', val);
    },
    setMenuOpen: (context, val) => {
        context.commit('SET_MENU_OPEN', val);
    },
    setHeaderHeight: (context, val) => {
        context.commit('SET_HEADER_HEIGHT', val);
    },
    setHasScrolled: (context, val) => {
        context.commit('SET_HAS_SCROLLED', val);
    },
    setIsScrollingDown: (context, val) => {
        context.commit('SET_IS_SCROLLING_DOWN', val);
    },
};

export const mutations = {
    SET_METRICS: (state, val) => {
        state.metrics = val;
    },
    SET_SAFE_MODE: (state, val) => {
        state.isSafeMode = val;
    },
    SET_MENU_OPEN: (state, val) => {
        state.isMenuOpen = val;
    },
    SET_HEADER_HEIGHT: (state, val) => {
        state.headerHeight = val;
    },
    SET_HAS_SCROLLED: (state, val) => {
        state.hasScrolled = val;
    },
    SET_IS_SCROLLING_DOWN: (state, val) => {
        state.isScrollingDown = val;
    },
};
