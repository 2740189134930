<template>
    <div>
        <carousel-base :slides="slides" name="home">
            <template slot="slide" slot-scope="{ slideNb }">
                <img
                    v-if="slides[slideNb].meta.relationships"
                    class="home__slider-img"
                    :src="slides[slideNb].meta.relationships.media_image.data.meta.links.front_large_21_9"
                    :alt="slides[slideNb].meta.relationships.media_image.data.meta.alt"
                />

                <div class="home__slider-info">
                    <div
                        v-if="slides[slideNb].meta.attributes && slides[slideNb].meta.attributes.content"
                        class="home__slider-txt"
                        v-html="slides[slideNb].meta.attributes.content.value"
                    ></div>

                    <custom-link
                        v-if="slides[slideNb].meta.attributes && slides[slideNb].meta.attributes.link"
                        :to="slides[slideNb].meta.attributes.link.uri"
                        class="home__slider-button"
                    >
                        {{ slides[slideNb].meta.attributes.link.title }}
                    </custom-link>
                </div>
            </template>
        </carousel-base>
    </div>
</template>
<script>
import CarouselBase from '~/components/CarouselBase';
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        CarouselBase,
        CustomLink,
    },
    props: {
        slides: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};
</script>
<style lang="scss">
.home__slider-txt {
    p,
    p:last-child {
        margin: 0 0 rem(8px);
    }
}
</style>
<style lang="scss">
.home__slider {
    &-img {
        width: 100%;
        height: auto;
    }

    &-info {
        position: relative;
        margin-top: rem(-50px);
        width: 90%;
        padding: rem(35px);
        box-sizing: border-box;
        background-color: $--color-dark-green;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        border-radius: rem(4px);

        @include media(sm) {
            padding: rem(32px) rem(45px);
            margin-top: rem(-120px);
        }
    }

    &-txt {
        margin-top: 0;
        color: white;
        margin-bottom: rem(12px);

        p:first-child {
            margin-top: none;
        }
    }

    &-button {
        display: inline-block;
        color: white;
        background: transparent;
        border: rem(2px) solid #f9f7f1 !important;
        border-radius: rem(4px);
        font-size: rem(12px);
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            color: $--color-dark-green !important;
        }

        a {
            display: inline-block !important;
            padding: rem(15px);
            color: white;
        }
    }
}
</style>
