<template>
    <el-image v-if="!imgTag" class="media__image" :src="imageToDisplay" alt="" :fit="fit" :lazy="lazy" />
    <img v-else class="media__image" :src="imageToDisplay" alt="" />
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: null,
        },
        image: {
            type: Object,
            default: null,
        },
        fit: {
            type: String,
            default: '',
        },
        imgTag: {
            type: Boolean,
            default: false,
        },
        lazy: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            imageToDisplay: null,
        };
    },
    mounted() {
        try {
            // Check if its the new format or the old one
            // "thumbnail style" is mostly in the page header
            // "meta style" is mostly in the page header
            if (this.image.thumbnail)
                this.imageToDisplay = this.image.thumbnail.meta.imageDerivatives.links[this.size].href;
            else this.imageToDisplay = this.image.meta.links[this.size];

            if (!this.imageToDisplay) throw new Error("L'image n'existe pas.");
        } catch (e) {
            if (this.size === 'large' || this.size === 'front_large_21_9') {
                this.imageToDisplay = '/placeholder_1400x400.svg';
            } else {
                this.imageToDisplay = '/placeholder_284x190.svg';
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>
