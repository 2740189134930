<template>
    <nav v-if="pageCount > 1" aria-label="pagination" class="pagination-wrapper">
        <ul class="pagination">
            <li v-if="firstLastButton" :class="pageClass">
                <button
                    :class="[pageLinkClass, firstPageSelected ? disabledClass : '']"
                    :content="firstButtonText"
                    :disabled="firstPageSelected"
                    :aria-disabled="firstPageSelected"
                    :page="1"
                    :tabindex="firstPageSelected ? -1 : 0"
                    @click="changePage(1)"
                >
                    <span class="visuallyhidden">{{ $t('first') }}</span>
                </button>
            </li>

            <li v-if="!(firstPageSelected && hidePrevNext)" :class="prevClass">
                <button
                    :class-name="[prevLinkClass, firstPageSelected ? disabledClass : '']"
                    :content="prevText"
                    :disabled="firstPageSelected"
                    :page="prevPage"
                    :tabindex="firstPageSelected ? -1 : 0"
                    rel="prev"
                    @click="changePage(prevPage)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9">
                        <g>
                            <g>
                                <path
                                    d="M1.333 9c-.345 0-.689-.123-.944-.38a1.266 1.266 0 0 1 0-1.828l2.378-2.293L.389 2.206a1.266 1.266 0 0 1 0-1.829 1.382 1.382 0 0 1 1.897 0l3.321 3.212a1.253 1.253 0 0 1 0 1.82L2.275 8.621c-.264.256-.609.38-.942.38z"
                                />
                            </g>
                        </g>
                    </svg>
                    <span v-if="!firstPageSelected" class="visuallyhidden">
                        {{ $t('previous') }} page {{ prevPage }}
                    </span>
                    <span v-else class="visuallyhidden">
                        {{ $t('previous') }}
                    </span>
                </button>
            </li>

            <li v-for="(page, i) in pages" :key="`page-${i}`" :class="[page.breakView ? breakViewClass : null]">
                <span
                    v-if="page.breakView && page.direction === 'left'"
                    aria-hidden="true"
                    :class="[breakViewLinkClass, disabledClass]"
                >
                    {{ breakViewText }}
                </span>
                <span class="visuallyhidden">Page</span>
                <button
                    :aria-current="[page.selected ? 'page' : null]"
                    :aria-disabled="page.selected"
                    :disabled="page.selected"
                    :class="[pageClass, page.selected ? activeClass : '']"
                    :content="page.content"
                    :page="page.content"
                    @click="changePage(page.index + 1)"
                >
                    {{ page.index + 1 }}
                </button>
                <span
                    v-if="page.breakView && page.direction === 'right'"
                    aria-hidden="true"
                    :class="[breakViewLinkClass, disabledClass]"
                >
                    {{ breakViewText }}
                </span>
            </li>

            <li v-if="!(lastPageSelected && hidePrevNext)" :class="nextClass">
                <button
                    :class-name="[nextLinkClass, lastPageSelected ? disabledClass : '']"
                    :content="nextText"
                    :disabled="lastPageSelected"
                    :page="nextPage"
                    :tabindex="lastPageSelected ? -1 : 0"
                    rel="next"
                    @click="changePage(nextPage)"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9">
                        <g>
                            <g>
                                <path
                                    d="M1.333 9c-.345 0-.689-.123-.944-.38a1.266 1.266 0 0 1 0-1.828l2.378-2.293L.389 2.206a1.266 1.266 0 0 1 0-1.829 1.382 1.382 0 0 1 1.897 0l3.321 3.212a1.253 1.253 0 0 1 0 1.82L2.275 8.621c-.264.256-.609.38-.942.38z"
                                />
                            </g>
                        </g>
                    </svg>
                    <span v-if="!lastPageSelected" class="visuallyhidden">{{ $t('next') }} page {{ nextPage }}</span>
                    <span v-else class="visuallyhidden">{{ $t('next') }}</span>
                </button>
            </li>

            <li v-if="firstLastButton" :class="pageClass">
                <button
                    :class-name="[pageLinkClass, lastPageSelected ? disabledClass : '']"
                    :content="lastButtonText"
                    :disabled="lastPageSelected"
                    :page="pageCount"
                    :tabindex="lastPageSelected ? -1 : 0"
                    @click="changePage(pageCount)"
                >
                    <span class="visuallyhidden">{{ $t('last') }}</span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    i18n: {
        messages: {
            fr: {
                first: 'Premier',
                last: 'Dernier',
                previous: 'Précédent',
                next: 'Suivant',
                pageHasChanged: 'La page {page} des résultats est affichée',
            },
            en: {
                first: 'First',
                last: 'Last',
                previous: 'Previous',
                next: 'Next',
                pageHasChanged: 'Page {page} of results is displayed',
            },
        },
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Number,
            default: 1,
        },
        pageCount: {
            type: Number,
            required: true,
        },
        pageRange: {
            type: Number,
            default: 3,
        },
        marginPages: {
            type: Number,
            default: 1,
        },
        prevText: {
            type: String,
            default: 'Prev',
        },
        nextText: {
            type: String,
            default: 'Next',
        },
        breakViewText: {
            type: String,
            default: '…',
        },
        pageClass: {
            type: String,
            default: 'pagination-item',
        },
        pageLinkClass: {
            type: String,
            default: 'pagination-link',
        },
        prevClass: {
            type: String,
            default: 'pagination-prev-item',
        },
        prevLinkClass: {
            type: String,
            default: 'pagination-prev-link',
        },
        nextClass: {
            type: String,
            default: 'pagination-next-item',
        },
        nextLinkClass: {
            type: String,
            default: 'pagination-next-link',
        },
        breakViewClass: {
            type: String,
            default: 'pagination-break-item',
        },
        breakViewLinkClass: {
            type: String,
            default: 'pagination-break-element',
        },
        activeClass: {
            type: String,
            default: 'active',
        },
        disabledClass: {
            type: String,
            default: 'disabled',
        },
        firstLastButton: {
            type: Boolean,
            default: false,
        },
        hidePrevNext: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            innerValue: 1,
        };
    },
    computed: {
        selected: {
            get() {
                return this.value || this.innerValue;
            },
            set(newValue) {
                this.innerValue = newValue;
            },
        },
        prevPage() {
            return this.selected <= 1 ? this.selected : this.selected - 1;
        },
        nextPage() {
            return this.selected >= this.pageCount ? this.selected : this.selected + 1;
        },
        firstPageSelected() {
            return this.selected === 1;
        },
        lastPageSelected() {
            return this.selected === this.pageCount || this.pageCount === 0;
        },
        pages() {
            const items = {};
            if (this.pageCount <= this.pageRange) {
                for (let index = 0; index < this.pageCount; index++) {
                    const page = {
                        index,
                        content: index + 1,
                        selected: index === this.selected - 1,
                        breakView: false,
                    };
                    items[index] = page;
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2);

                const setPageItem = (index, breakView, direction) => {
                    const page = {
                        index,
                        content: index + 1,
                        selected: index === this.selected - 1,
                        breakView,
                        direction,
                    };
                    items[index] = page;
                };

                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i);
                }

                // 2nd - loop thru selected range
                let selectedRangeLow = 0;
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange;
                }

                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1;
                if (selectedRangeHigh >= this.pageCount) {
                    selectedRangeHigh = this.pageCount - 1;
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1;
                }

                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                    setPageItem(i);
                }

                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    setPageItem(selectedRangeLow, true, 'left');
                }

                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    setPageItem(selectedRangeHigh, true, 'right');
                }

                // 3rd - loop thru high end of margin pages
                for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                    setPageItem(i);
                }
            }
            return items;
        },
    },
    methods: {
        changePage(pageNb) {
            this.$eventBus.$emit('setLiveRegion', this.$t('pageHasChanged', { page: pageNb }));
            this.$emit('input', pageNb);
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 22px;
}
.pagination {
    display: flex;
    vertical-align: top;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        margin-right: rem(8px);
    }
}
.pagination-item,
.pagination-prev-item button,
.pagination-next-item button,
.pagination-break-element {
    padding: 0 rem(4px);
    min-width: rem(40px);
    height: rem(40px);
    box-sizing: border-box;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
}
.pagination-item {
    background: $--color-white;
    display: inline-block;
    font-size: rem(16px);
    border: rem(1px) solid $--color-primary;
    color: $--color-primary;
    border-radius: rem(4px);

    &[disabled='disabled'] {
        cursor: auto;
    }

    &.active,
    &:hover {
        border-width: rem(3px);
        font-weight: 700;
    }
}
.pagination-prev-item {
    margin-right: rem(8px);
    svg {
        transform: rotate(180deg);
    }
}
.pagination-prev-item,
.pagination-next-item {
    button {
        border: none;
        background-color: $--color-white;
        &[disabled='disabled'] {
            svg {
                fill: $--color-gray;
            }
        }
    }
    svg {
        fill: $--color-primary;
    }
}
.pagination-break-element {
    display: inline-block;
    line-height: 2;
    padding: 0;
}
</style>
