const initGlobal = () => ({
    pageWidth: 1920,
});

export const state = initGlobal;

export const mutations = {
    setPageWidth(state, width) {
        state.pageWidth = width;
    },

    reset() {
        const initState = initGlobal();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};

export const actions = {};
