/**
 * @name RequestObject
 * @type {{_id: ?number, _type: string, _uri: string, _action: string, _headers: Array, _params: Object, _body: Object, _waitFor: Array}}
 */

/**
 * @param uri
 * @param action
 * @param headers
 * @param params
 * @param body
 * @param waitFor
 * @returns RequestObject
 * @constructor
 */
const RequestFactory = (uri, action = 'view', headers = {}, params = {}, body = {}, waitFor = []) => {
    /**
     * @type {RequestObject}
     */
    const request = {};

    request._id = null;
    request._type = 'JsonAPI';
    request._uri = uri;
    request._action = action;
    request._headers = headers;
    request._params = params;
    request._body = body;
    request._waitFor = waitFor;

    return request;
};

export default RequestFactory;
