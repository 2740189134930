<template>
    <transition name="fade">
        <button v-if="visible" type="button" class="el-backtop backtop" :style="cssProps" @click.stop="handleClick">
            <slot>
                <el-icon name="caret-top"></el-icon>
                <span class="visually-hidden">{{ $t('a11y.scrollBackToTop') }}</span>
            </slot>
        </button>
    </transition>
</template>

<script>
export default {
    props: {
        visibilityHeight: {
            type: Number,
            default: 1200,
        },
        right: {
            type: Number,
            default: 40,
        },
        bottom: {
            type: Number,
            default: 40,
        },
        mRight: {
            type: Number,
            default: 16,
        },
        mBottom: {
            type: Number,
            default: 80,
        },
        duration: {
            type: Number,
            default: 500,
        },
    },

    data() {
        return {
            el: null,
            container: null,
            visible: false,
        };
    },

    computed: {
        cssProps() {
            return {
                '--right': this.right + 'px',
                '--bottom': this.bottom + 'px',
                '--m-right': this.mRight + 'px',
                '--m-bottom': this.mBottom + 'px',
            };
        },
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },

    methods: {
        onScroll() {
            const scrollTop = document.documentElement.scrollTop;
            this.visible = scrollTop >= this.visibilityHeight;
        },
        handleClick(e) {
            this.scrollToTop(this.duration);
            this.$emit('click', e);
        },
        scrollToTop(duration) {
            const cosParameter = window.scrollY / 2;
            let scrollCount = 0;
            let oldTimestamp = performance.now();
            function step(newTimestamp) {
                scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
                if (scrollCount >= Math.PI) window.scrollTo(0, 0);
                if (window.scrollY === 0) return;
                window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)));
                oldTimestamp = newTimestamp;
                window.requestAnimationFrame(step);
            }
            window.requestAnimationFrame(step);
        },
    },
};
</script>

<style lang="scss" scoped>
.backtop {
    width: 45px;
    height: 45px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: 0.5s all;
    border: none;
    // position: absolute;
    bottom: var(--m-bottom);
    right: var(--m-right);
    transition: 0.5s all;

    &:hover {
        box-shadow: 3px 8px 22px rgba(0, 0, 0, 0.7);
    }

    @include media(sm) {
        width: 65px;
        height: 65px;
        bottom: var(--bottom);
        right: var(--right);
    }
}
</style>
