<template>
    <div class="service-page">
        <content-details :title="item.title" class="single-news">
            <template>
                <reminder-block-list :reminders="reminders" />

                <!-- Text -->
                <Container v-if="item.body && item.body.processed" max-width="650px" big-padding>
                    <wysiwyg class="item__wysiwyg item__wysiwyg--bigger" :body="item.body.processed" />
                </Container>

                <!-- Image -->
                <Container v-if="item.media_image" max-width="1070px" class="item__image-wrapper">
                    <media-image
                        v-if="item.media_image"
                        :image="item.media_image"
                        size="front_large_21_9"
                        class="item__image"
                        fit="cover"
                    ></media-image>
                </Container>

                <!-- Paragraphs -->
                <Container v-if="item.customizable_content" class="service__custom_content" max-width="640px">
                    <paragraphs :item="item"></paragraphs>
                </Container>

                <!-- Linked News -->
                <Row class="section--news">
                    <el-col :span="24">
                        <Container class="section-container" max-width="1070px">
                            <ListCards
                                type="news"
                                :items="newsList"
                                :title="`${$t('news.interestedIn')}`"
                                :more-link="{
                                    txt: $t('news.seeAllNews'),
                                    url: $t('news.url'),
                                }"
                                :custom-gutter="63"
                                show-created
                                primary
                                trait
                            />
                        </Container>
                    </el-col>
                </Row>

                <!-- Linked Events -->
                <Row class="section--events">
                    <el-col :span="24">
                        <Container class="section-container" max-width="1070px">
                            <ListCards
                                type="event"
                                :items="events"
                                :title="`${$t('events.futureEvents')}`"
                                :more-link="{
                                    txt: $t('events.seeAllEvents'),
                                    url: $t('events.url'),
                                }"
                                :custom-gutter="63"
                                show-created
                                primary
                                trait
                            />
                        </Container>
                    </el-col>
                </Row>
            </template>
        </content-details>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import ListCards from '~/components/ListCards';
import MediaImage from '~/components/MediaImage';
import Paragraphs from '~/components/Paragraphs';
import ReminderBlockList from '~/components/ReminderBlockList';
import Row from '~/components/Row';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        ContentDetails,
        Row,
        Container,
        ListCards,
        MediaImage,
        Paragraphs,
        Wysiwyg,
        ReminderBlockList,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);

        return {
            item: initData.item,
            events: initData.events,
            newsList: initData.newsList,
            sideMenu: [],
            activeActionTab: 'linkedActions',
            currentpath: null,
        };
    },
    computed: {
        ...mapState({
            menu: state => state.menu.menu,
            reminders: state => state.reminders.reminders,
        }),
        newsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('news.url');
        },
        eventsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('events.url');
        },
    },
    asyncData({ app, query, store, params, error, env }, item) {
        return {
            item,
            events: app.$drupalComponentsAdapter.normalizeItems(item.related_events),
            newsList: app.$drupalComponentsAdapter.normalizeItems(item.related_news),
        };
    },

    mounted() {
        this.getSectionMenu();
    },

    methods: {
        getSectionMenu() {
            // Example: /fr/services/bibliotheque
            const currentPath = window.location.pathname;
            this.currentpath = currentPath;

            // Set side bar menu
            this.menu.services[this.$i18n.locale].forEach(level1 =>
                level1.children.forEach(level2 =>
                    level2.children.forEach(level3 => {
                        if (level3.attributes.link.uri === currentPath && level3.children && level3.children.length) {
                            this.sideMenu = level3.children;
                        }
                    }),
                ),
            );
        },
        checkActionMedia(action) {
            try {
                return action.media_icon.meta.links.large;
            } catch (e) {
                return null;
            }
        },
    },
};
</script>

<style lang="scss">
.service-page {
    .title.padding {
        @include media(md) {
            width: 100%;
            margin: auto;
            max-width: 650px;
        }
    }

    .parag__reusable {
        margin-bottom: 23px;

        & > div:not(:last-child) {
            margin-bottom: 23px;
        }
    }

    .accordion-list {
        li:not(:last-child) {
            margin-bottom: 23px;
        }
    }

    .item {
        &__wysiwyg {
            margin-bottom: 48px;

            ul {
                padding-left: 18px;
            }

            &--bigger {
                @include media(sm) {
                    font-size: rem(18px);
                }
            }
        }

        &__related-cards-item {
            margin-bottom: 25px;
        }

        &__image {
            display: flex;
            min-height: 153px;
            height: 50vw;
            margin-left: -20px;
            width: calc(100% + 40px);
            margin-top: -7px;
            margin-bottom: 25px;

            @include media(sm) {
                margin-left: initial;
                width: 100%;
                height: 510px;
                margin-top: -7px;
                margin-bottom: 40px;
            }

            &-wrapper {
                margin-bottom: 16px;
            }
        }

        &__custom-content {
            .container {
                padding-left: 0;
            }
        }
    }

    .container {
        &.container {
            margin: auto;
        }

        .title {
            @include media(lg) {
                padding-left: 0;
                padding-right: 0;
            }

            &__sub {
                & > * {
                    margin-bottom: 0;
                }
            }
        }
    }

    .section--news,
    .section--events {
        margin-top: 24px;

        @include media(sm) {
            margin-top: 80px;
        }

        .items-list__title {
            font-size: 15px;
            font-weight: 500;

            @include media(xs) {
                margin-bottom: -16px;
            }
        }

        .card__title {
            @include media(md) {
                font-size: rem(20px);
            }
        }

        .card__body {
            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .section--events {
        margin-top: -40px;
    }

    .items-list {
        margin-bottom: 100px;
    }
}
</style>
