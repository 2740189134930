<template>
    <div class="el-container is-vertical">
        <Loading />
        <a href="#main-content" class="skip-link">{{ $t('a11y.menu.skipContent') }}</a>

        <block-header v-if="menuData" :alerts="alertes" :navigation="menuData" class="locomotive-styles" />

        <el-main id="main-content">
            <transition name="fade">
                <nuxt />
            </transition>
        </el-main>

        <Backtop :bottom="135" :right="45" :m-bottom="80" :m-right="16"></Backtop>

        <block-footer
            :push-newsletter="footerData.newsletter"
            :copyright="footerData.copyright"
            :links-lists="footerData.navigation"
            :links-list-bottom="footerData.navigationBottom"
            :phone-cta="footerData.phone"
            :address-cta="footerData.address"
            :socials="socialsData"
            class="locomotive-styles"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CommonDataMixin from '~/mixins/common-data-mixin.js';

import Backtop from '~/components/Backtop.vue';
import Loading from '~/components/layout/Loading.vue';

import BlockFooter from '~/components/locomotive/block-footer';
import BlockHeader from '~/components/locomotive/block-header';

export default {
    components: {
        Loading,
        Backtop,
        BlockHeader,
        BlockFooter,
    },

    head() {
        return this.$nuxtI18nSeo();
    },

    mixins: [CommonDataMixin],

    computed: {
        ...mapState({
            isLoading: state => state.loading.isPageLoad,
            translatePathStore: state => state.translatePath,
            isLoggedIn: state => state.authentication.isLoggedIn,
        }),

        ...mapState('locomotive-global', ['siteData']),
        ...mapState('alertes', ['alertes']),
    },

    watch: {
        $route() {
            // For loading effect
            this.$store.commit('loading/setIsPageLoad', false);
            // Change url on switchlang
            this.changeUrlOnSwitchLang();
            this.$store.commit('menu/closeMainMenu', this.$i18n.locale);
        },
        '$route.query'() {
            setTimeout(() => {
                this.$store.commit('menu/closeMainMenu', this.$i18n.locale);
                this.$store.commit('loading/setIsPageLoad', false);
            }, 500);
        },
        async isLoggedIn() {
            await this.$store.dispatch('reminders/fetchReminderForUser', this);
        },
    },
    mounted() {
        const today = this.$moment()
            .utc()
            .format();
        this.$store.dispatch('alerts/fetchAlertsWithinDate', today);
        // eslint-disable-next-line nuxt/no-env-in-hooks
        if (process.client && window) {
            window.history.scrollRestoration = 'manual';
        }

        this.bindEvents();
        this.onResize();
    },

    methods: {
        ...mapActions('locomotive-global', ['setMetrics']),

        bindEvents() {
            if (!process.client) return;
            const resizeBind = this.debounce(this.onResize, 250).bind(this);
            window.addEventListener('resize', resizeBind);
        },
        onResize() {
            if (!process.client) return;

            // Set store metrics
            this.setMetrics({
                width: window.innerWidth,
                height: window.innerHeight,
            });

            // Set CSS Var metrics
            const vw = document.documentElement.offsetWidth * 0.01;
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vw', `${vw}px`);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        debounce(func, wait, immediate) {
            let timeout;

            return function executedFunction() {
                const context = this;
                const args = arguments;

                const later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };

                const callNow = immediate && !timeout;

                clearTimeout(timeout);

                timeout = setTimeout(later, wait);

                if (callNow) func.apply(context, args);
            };
        },
        changeUrlOnSwitchLang() {
            // This is for switch lang URL
            if (this.translatePathStore.isSwitchLangAction) {
                if (this.translatePathStore.newPath)
                    window.history.pushState('', '', `${this.translatePathStore.newPath}`);
                this.$store.commit('translatePath/setSwitchLangAction', false);
                this.$store.commit('translatePath/setNewPath', null);
                const today = this.$moment()
                    .utc()
                    .format();
                this.$store.dispatch('alerts/fetchAlertsWithinDate', today);
            }
        },
    },
};
</script>

<style lang="scss">
html,
body {
    color: $--color-text-regular;
}
.chat-button {
    height: 84px !important;
    width: 84px !important;
    margin-bottom: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.5s all;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0));

    &:hover {
        transform: translateY(-4px);
        filter: drop-shadow(7px 11px 12px rgba(0, 0, 0, 0.4));
    }

    @include media(sm) {
        margin-bottom: 35px;
        margin-right: 35px;
    }
}

#iframe-designstudio-button {
    @extend .chat-button;
}
#iframe-button-container,
#designstudio-button {
    height: 84px !important;
    width: 84px !important;
}

#designstudio-button {
    @include media(sm) {
        bottom: 35px !important;
        right: 35px !important;
    }
}

.is-menu-open {
    @media (max-width: $to-medium) {
        overflow: hidden;
    }
}
</style>
