import BaseResource from '../BaseResource';

class Caroussel extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/carouselBlock';
    }
}

export default Caroussel;
