<template>
    <nav class="maps-list">
        <h2 class="maps-list__title">{{ $t('interactiveMap.navTitle') }}</h2>
        <ul class="maps-list__list">
            <li v-for="item in items" :key="item.id" class="maps-list__item">
                <custom-link v-if="item.link" :to="`/${item.path.langcode}${item.path.alias}`" class="maps-list__link">
                    {{ item.link.title || item.title }}<i class="el-icon-arrow-right el-icon--right" />
                </custom-link>
            </li>
        </ul>
    </nav>
</template>

<script>
import CustomLink from '~/components/CustomLink';

export default {
    components: { CustomLink },

    props: {
        items: {
            type: Array,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.maps-list {
    padding: 2.25em 2.5em 4em 3em;
    background-color: #ffffff;
    box-shadow: 0 0 0.75em rgba(0, 0, 0, 0.2);
    &__title {
        text-align: center;
        margin-bottom: 1em;
    }
    &__list {
        padding: 0;
    }
    &__item {
        width: 100%;
        list-style: none;
        border-bottom: 1px solid #e0e0e0;
    }
    &__link {
        display: inline-block;
        width: 100%;
        padding: 1em 0 0.75em;
        color: inherit;
        text-decoration: none;
    }
}
.el-icon--right {
    float: right;
}
</style>
