<template>
    <div class="parag__accordion">
        <h3 v-if="body.title">{{ body.title }}</h3>
        <wysiwyg :body="body.text" />
        <ul class="accordion-list">
            <accordion-item
                v-for="(accordion, i) in body.accordionInfos"
                :id="i"
                :key="accordion.id"
                :title="accordion.title"
                :webform-id="accordion.webform"
            >
                <div slot="acc-header" class="accordion-header">
                    <span>{{ accordion.title }}</span>
                    <img class="accordion-arrow" src="~assets/img/dropdown-arrow.png" alt="" />
                </div>
                <template slot="acc-content">
                    <div class="accordion-content-wrapper">
                        <wysiwyg class="accordion-content" :body="accordion.text" />
                    </div>
                </template>
            </accordion-item>
        </ul>
    </div>
</template>

<script>
import AccordionItem from '~/components/AccordionItem';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    components: {
        AccordionItem,
        Wysiwyg,
    },
    props: {
        accordions: {
            type: Array,
            default: null,
        },
        block: {
            type: Object,
            default: null,
        },
        paragraph: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            body: '',
        };
    },
    mounted() {
        function getReusableDatas(item, type) {
            const obj = item.meta.relationships[type].data;
            return obj;
        }

        function getContent(obj, text) {
            if (Array.isArray(obj)) {
                const accordions = [];
                obj.map(function(accordion) {
                    if (accordion.meta.attributes) {
                        const infos = {
                            title: accordion.meta.attributes.title ? accordion.meta.attributes.title : null,
                            id: accordion.id,
                            text: accordion.meta.attributes[text] ? accordion.meta.attributes[text].processed : null,
                            webform:
                                accordion.meta.relationships &&
                                accordion.meta.relationships.field_webform &&
                                accordion.meta.relationships.field_webform.data
                                    ? accordion.meta.relationships.field_webform.data.id
                                    : null,
                        };
                        accordions.push(infos);
                    }
                });
                return accordions;
            } else {
                return {
                    title: obj.meta.attributes.title ? obj.meta.attributes.title : null,
                    id: obj.id,
                    text: obj.meta.attributes[text] ? obj.meta.attributes[text].processed : null,
                    webform:
                        obj.meta.relationships &&
                        obj.meta.relationships.field_webform &&
                        obj.meta.relationships.field_webform.data
                            ? obj.meta.relationships.field_webform.data.id
                            : null,
                };
            }
        }
        if (this.block) {
            const infos = getReusableDatas(this.block, 'accordions');
            const blockInfos = getContent(infos, 'introduction');
            const datas = getReusableDatas(infos, 'accordion_items');
            const accordionInfos = getContent(datas, 'content');

            const block = { ...blockInfos, accordionInfos };
            this.body = block;
        }

        if (this.paragraph) {
            const blockInfos = {
                title: this.paragraph.title ? this.paragraph.title : null,
                id: this.paragraph.id,
                text: this.paragraph.introduction ? this.paragraph.introduction.processed : null,
            };
            const accordionInfos = getContent(this.paragraph.accordion_items, 'content');
            const block = { ...blockInfos, accordionInfos };
            this.body = block;
        }
    },
};
</script>
<style lang="scss" scoped>
.accordion {
    &-list {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }
}
.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #003da5;
    font-weight: 700;
    font-size: rem(16px);
    padding: rem(16px);
    border: rem(2px) solid #003da5;
    border-radius: rem(8px);
    line-height: 1.1;
    background-color: $--color-white;

    @include media(sm) {
        color: $--color-green;
        border-color: $--collapse-border-color;
        padding: rem(16px) rem(24px);
    }

    .accordion-arrow {
        transition: all 0.2s;
        .is-active & {
            transform: rotate(180deg);
        }
    }
}
.accordion-content-wrapper {
    padding: rem(16px);
    margin: 0 rem(8px) 0 rem(8px);
    border: 0px solid transparent;
    background-color: $--collapse-content-background-color;
    @include media(sm) {
        padding: rem(32px);
    }
}
</style>
<style lang="scss">
.accordion-content {
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
    font-size: rem(16px);

    table {
        td,
        th {
            padding: 22px 27px;
            min-width: none;
        }
    }
}
</style>
