import {
    Actions,
    Alerts,
    ByPath,
    Caroussel,
    Directions,
    Events,
    InteractiveMaps,
    Medias,
    Menu,
    News,
    Places,
    Profile,
    Rappels,
    ServiceByHubService,
    Services,
    SolrSearch,
    Static,
    Taxonomy,
    TranslatePath,
    Video,
    WebformJsonSchema,
} from './resources';
/**
 * @name apiList
 * @param ctx
 * @returns {{news: News, events: Events}}
 */
export default ctx => ({
    actions: new Actions(),
    alerts: new Alerts(),
    caroussel: new Caroussel(),
    video: new Video(),
    news: new News(),
    events: new Events(),
    places: new Places(),
    menu: new Menu(),
    translatePath: new TranslatePath(ctx),
    taxonomy: new Taxonomy(),
    rappels: new Rappels(),
    interactiveMaps: new InteractiveMaps(),
    profile: new Profile(),
    byPath: new ByPath(),
    solrSearch: new SolrSearch(),
    services: new Services(),
    medias: new Medias(),
    static: new Static(),
    webformJsonSchema: new WebformJsonSchema(),
    directions: new Directions(),
    serviceByHubService: new ServiceByHubService(ctx),
});
