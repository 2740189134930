<template>
    <list-with-filter
        class="event-list-page"
        taxonomy-type="event_type"
        :title="$t('events.title')"
        :facets="facets"
        :filter-values="filterValues"
        :total-count="totalCount"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        :search-bar="false"
        has-date
        :date-filters="['today', 'week', 'month', 'custom']"
        hide-filter-header
        :intro="pageIntro"
        @filterUpdate="filterUpdateHandler"
    >
        <!-- Promoted events -->
        <template slot="top">
            <div v-if="horizontalEvents.length > 0" class="list event-list" horizontal full>
                <div class="promoted__block">
                    <row align>
                        <h2 class="promoted__block-header">
                            {{ $t('events.featured') }}
                        </h2>
                        <div class="promoted__block-separator"></div>
                    </row>
                </div>
                <row gutter-small class="promoted">
                    <el-col v-for="event in horizontalEvents" :key="event.id" :span="24" :sm="24" :md="24" :lg="8">
                        <custom-link :to="event.url" class="promoted__url">
                            <div class="promoted__item">
                                <div v-if="event.mediaImage" class="promoted__image">
                                    <decorative-media-image
                                        fit="cover"
                                        :image="event.mediaImage"
                                        size="front_large_21_9"
                                    />
                                </div>
                                <div class="promoted__item__block">
                                    <h3 class="promoted__title">
                                        {{ event.title }}
                                    </h3>
                                    <div v-if="event.place" class="promoted__place">
                                        <img
                                            class="promoted__place-icone"
                                            src="~assets/img/map-marker-primary.svg"
                                            alt=""
                                        />
                                        {{ event.place }}
                                    </div>
                                </div>
                            </div>
                        </custom-link>
                    </el-col>
                    <div class="promoted__fading"></div>
                </row>
            </div>
        </template>

        <template>
            <loader-wrapper v-if="filteredEvents.length !== 0" :loading="isUpdating">
                <section>
                    <article v-for="e in filteredEvents" :key="e.entity_id" class="event__item">
                        <div class="event__image">
                            <uploaded-image fit="cover" :path="e.image ? e.image : '/placeholder_284x190.svg'" />
                        </div>
                        <div class="event__block">
                            <div class="event__date">
                                <time
                                    v-if="e.recurring_dates && e.recurring_dates.value"
                                    :datetime="$moment(e.recurring_dates.value).format()"
                                >
                                    {{ $moment(e.recurring_dates.value).format('LL') }}
                                </time>
                                <template
                                    v-if="
                                        e.recurring_dates &&
                                            e.recurring_dates.end_value &&
                                            $moment(e.recurring_dates.end_value).format('LL') !==
                                                $moment(e.recurring_dates.value).format('LL')
                                    "
                                >
                                    <span>
                                        au
                                    </span>
                                    <time :datetime="$moment(e.recurring_dates.end_value).format()">
                                        {{ $moment(e.recurring_dates.end_value).format('LL') }}
                                    </time>
                                </template>
                            </div>
                            <h3 class="event__title">
                                <custom-link :to="e.entity_alias" info>
                                    {{ e.entity_title | decodehtml }}
                                </custom-link>
                            </h3>
                            <p class="event__excerpt">
                                {{ e.summary | striphtml }}
                            </p>
                            <div v-if="e.place" class="event__place">
                                <img class="card__place-icone" src="~assets/img/map-marker-primary.svg" alt="" />
                                <span v-html="e.place"></span>
                            </div>
                        </div>
                    </article>
                </section>
            </loader-wrapper>
            <p v-else>
                {{ $t('filter.noResult') }}
            </p>
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import DecorativeMediaImage from '../../components/DecorativeMediaImage';
import ScrollMixin from '~/mixins/scroll-mixin.js';

import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';
import Row from '~/components/Row';
import UploadedImage from '~/components/UploadedImage';
import mediaImage from '~/components/MediaImage';
const parseEventRecurringDates = recurringDatesString => {
    if (!recurringDatesString || recurringDatesString.length === 0) {
        return null;
    }
    return JSON.parse(recurringDatesString);
};
export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        DecorativeMediaImage,
        LoaderWrapper,
        Row,
        ListWithFilter,
        mediaImage,
        CustomLink,
        UploadedImage,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },
    data() {
        const initData = cloneDeep(this.initData);
        return {
            events: initData.events,
            horizontalEvents: initData.horizontalEvents,
            filterValues: initData.filterValues,
            facets: initData.facets,
            page: parseInt(this.$route.query.page) || 0,
            itemsPerPage: 10,
            isUpdating: false,
            totalCount: initData.totalCount,
        };
    },
    computed: {
        pageIntro() {
            return this.initData.item.body != null ? this.initData.item.body.processed : null;
        },
        filteredEvents() {
            // On calcule la page en index 0 pour faciliter le subset de notre liste de résultats.
            // Si la page est plus grande que 0, alors cest la pagination du sous-module qui retourne. Sinon c'est
            // la valeur par défaut passé par les autres composantes.
            const pageIndex0 = this.page > 0 ? this.page - 1 : 0;
            if (this.events.results && this.events.results.length > 0) {
                // Index sur base de 0. Donc on obtient de 0 .. 9
                const minInterval = pageIndex0 * this.itemsPerPage;
                const maxInterval = Math.min((pageIndex0 + 1) * this.itemsPerPage, this.totalCount);

                return this.events.results.slice(minInterval, maxInterval);
            } else {
                return this.events.results;
            }
        },
    },
    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 0;
        },
        page() {
            // La composante d'appel traite en base de 1, alors que nous sommes en base 0.
            this.updateEvents(true);
        },
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        // Load initial datas, format filters and sets them
        const sort = '-recurring_dates.value';

        await store.dispatch('taxonomy/fetchEvent', app);

        const selectTaxonomy = store.state.taxonomy.events[app.i18n.locale].filter(
            taxonomy => app.$slugify(taxonomy.name) === app.$slugify(query.type),
        );

        // Events
        const resultsCombined = await app.$client.subRequests({
            promoted: app.$api.events.getCollection({
                filters: {
                    promote: 1,
                },
                sort,
                include: ['place'],
                limit: 3,
            }),
        });

        const horizontalEvents = app.$drupalComponentsAdapter.normalizeItems(resultsCombined.promoted.data);

        // Cette section traite l'appel pour obtenir les événements en considérant le filtre des dates.
        // Nous faison deux appels séparés à cause d'une anomalie que les facettes ne sont pas retournées
        // dans cet appel API.
        const facets = [];
        const pathDonnees = process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/events-list';
        // TODO Nettoyer les paramètres de pages. Non utilisé.
        const parametersDonnees = {
            search: [`?page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`],
            dates: {
                type: 'date',
                start: app
                    .$moment()
                    .startOf('month')
                    .format('YYYY-MM-DD HH:mm:ss'),
                end: app
                    .$moment()
                    .endOf('month')
                    .format('YYYY-MM-DD HH:mm:ss'),
            },
        };

        const responseDonnees = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(pathDonnees, parametersDonnees),
        });

        if (responseDonnees.data.results) {
            responseDonnees.data.results = responseDonnees.data.results.map(e => {
                return { ...e, recurring_dates: parseEventRecurringDates(e.recurring_dates) };
            });
        }

        // Cette partie est pour appeler les facettes, qui est un autre appel API vers une vue Solr.
        // ceci est un contournement pour obtenir les facettes, qui est une fonctionnalité bloquée pour
        // l'appel des données précédent.
        // TODO Modifier le URL pour quelque chose qui représente l'appel par facettes.
        const pathFacettes = process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/search-events-list-facets';
        const parametersFacettes = {
            search: [`?search=&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`],
        };

        const responseFacettes = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(pathFacettes, parametersFacettes),
        });
        if (responseFacettes.data.facets) {
            for (const [facetId, filters] of Object.entries(responseFacettes.data.facets)) {
                const facet = { facetId, filters: [] };
                facet.facetId = 'event_type';
                for (const [key, f] of Object.entries(filters)) {
                    facet.filters.push({
                        id: key,
                        value: f.values.value,
                        name: f.values.label,
                        active: f.values.active ? f.values.active === 'true' : false,
                    });
                }
                facets.push(facet);
            }
        }

        // Filtres pour associer les résultats retournées par filtre de facettes, avec celles provenant des dates
        // TODO Est-ce possible d'avoir une seule fonction pour le filtre ?
        // TODO Est-ce possible de remplacer la boucle imbriquée par un Dictionnaire indexé ?
        if (responseDonnees.data.results) {
            responseDonnees.data.results = responseDonnees.data.results.filter(eventDonnee => {
                for (const eventFacette of responseFacettes.data.results) {
                    if (eventFacette.entity_id === eventDonnee.entity_id) {
                        return true;
                    }
                }
                return false;
            });
        }

        const totalCount = responseDonnees.data.results ? responseDonnees.data.results.length : 0;

        return {
            item,
            events: responseDonnees.data,
            horizontalEvents,
            filterValues: {
                byTitle: '',
                byDate: 'month',
                byDateRange: [],
                byType: selectTaxonomy.length ? [selectTaxonomy[0].id] : [],
            },
            facets,
            totalCount,
        };
    },

    methods: {
        getDateEnd(recurringDatesString) {
            const obj = JSON.parse(recurringDatesString);
            return obj.end_value;
        },
        async updateEvents(pageOnly = false) {
            // updates the page's content depending on the filter used
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;

            // Cette section traite l'appel pour obtenir les événements en considérant le filtre des dates.
            // Nous faison deux appels séparés à cause d'une anomalie que les facettes ne sont pas retournées
            // dans cet appel API.
            const pathDonnees = process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + '/api/events-list';

            const parametersDonnees = {
                search: [`?page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`],
                facets: this.filterValues.byType,
            };

            if (this.filterValues.byDate !== 'default') {
                parametersDonnees.dates = {
                    type: 'date',
                    start: this.filterValues.byDateRange[0],
                    end: this.filterValues.byDateRange[1],
                };
            }

            const responseDonnees = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(pathDonnees, parametersDonnees),
            });

            if (responseDonnees.data.results) {
                responseDonnees.data.results = responseDonnees.data.results.map(e => {
                    return { ...e, recurring_dates: parseEventRecurringDates(e.recurring_dates) };
                });
            }

            // Cette partie est pour appeler les facettes, qui est un autre appel API vers une vue Solr.
            // ceci est un contournement pour obtenir les facettes, qui est une fonctionnalité bloquée pour
            // l'appel des données précédent.
            // TODO remplacer le URL
            const pathFacettes =
                process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + '/api/search-events-list-facets';

            const parametersFacettes = {
                search: [`?search=&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`],
                facets: this.filterValues.byType,
            };

            const responseFacettes = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(pathFacettes, parametersFacettes),
            });

            if (responseFacettes.data.facets) {
                const facets = [];
                for (const [facetId, filters] of Object.entries(responseFacettes.data.facets)) {
                    const facet = { facetId, filters: [] };
                    for (const [key, f] of Object.entries(filters)) {
                        facet.filters.push({
                            id: key,
                            value: f.values.value,
                            name: f.values.label,
                            active: f.values.active ? f.values.active === 'true' : false,
                        });
                    }
                    facets.push(facet);
                }
                this.facets = facets;
            }

            // Filtres pour associer les résultats retournées par filtre de facettes, avec celles provenant des dates
            // TODO voir si on peut utiliser une même fonction pour le filtre.
            if (responseDonnees.data.results) {
                responseDonnees.data.results = responseDonnees.data.results.filter(eventDonnee => {
                    for (const eventFacette of responseFacettes.data.results) {
                        if (eventFacette.entity_id === eventDonnee.entity_id) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            // Retourner les éléments de la pagination
            this.totalCount = responseDonnees.data.results ? responseDonnees.data.results.length : 0;
            this.events = responseDonnees.data;
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updateEvents();
        },
    },
};
</script>

<style lang="scss" scoped>
.list-with-filter {
    &__top-slot {
        padding-left: -72px;
    }
    &__first-row {
        margin-bottom: 50px;
    }

    &__header-img {
        width: 100%;
        margin-bottom: 60px;
    }
}

.list {
    .row {
        margin-left: 0;
    }
}

.promoted {
    margin: -75px 0px 0px 70px;
    max-width: 1188px;

    &__item {
        display: flex;
        flex-flow: column;

        width: 100%;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
        text-align: left;
        background-color: $--color-white;
        margin-bottom: 20px;
        margin-right: 22px;

        @include media(sm) {
            flex-flow: row;
            align-items: center;
            height: rem(108px);
        }

        &__block {
            padding: 13px 25px 30px;

            @include media(sm) {
                padding: 23px 24px;
            }
        }
    }

    &__image {
        height: 100%;

        .media__image {
            width: 100%;
            height: 100%;

            @include media(sm) {
                min-height: auto;
                width: rem(161px);
            }
        }
    }

    &__block {
        width: 721px;
        max-height: 100%;
        height: 175px;
        background-color: $--color-primary;
        margin-left: -69px;

        &-header {
            color: $--color-white;
            font-size: rem(15px);
            margin-right: 20px;
            margin-top: 58px;
            margin-left: 70px;
            font-weight: 500;
            letter-spacing: rem(2.24px);
        }

        &-separator {
            background-color: $--color-white;
            width: 100px;
            height: 2px;
            margin-top: 66px;
        }
    }

    &__title {
        text-decoration: none;
        color: $--color-info;
        font-size: rem(14px);
        font-weight: 800;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(lg) {
            width: 11vw;
            max-width: 147px;
            height: 18px;
            white-space: nowrap;
        }
    }

    &__place {
        font-size: rem(12px);
        font-weight: 700;
        color: $--color-primary;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media(lg) {
            width: 11vw;
            height: 16px;
            max-width: 147px;
            white-space: nowrap;
        }

        &-icone {
            margin-right: 7px;
            width: 9px;
        }
    }
}
</style>

<style lang="scss">
.event-list-page {
    .filters {
        @include media(sm) {
            margin-top: 0;
        }
    }

    .list-with-filter {
        &__first-row {
            @include media(sm) {
                margin-bottom: 58px;
            }
        }
        &__main-cards-item-wrapper {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 1rem;
            }

            @include media(sm) {
                margin-bottom: 0;
            }

            .el-card__body {
                padding: 0;

                @include media(sm) {
                    padding: 20px;
                }
            }

            .media__image {
                height: 52vw;
                min-height: 172px;

                @include media(sm) {
                    height: 190px;
                    min-height: 190px;
                    max-width: 320px;
                }
            }

            &:first-child {
                .el-card__body {
                    @include media(sm) {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .title {
        &__htag--h1 {
            margin-top: 44px;
            margin-bottom: 24px;
            font-size: rem(25px);
            font-weight: 700;

            @include media(sm) {
                margin-top: 30px;
                margin-bottom: 27px;
                font-size: rem(42px);
            }
        }
    }
    .event {
        &__block {
            width: auto;
            height: auto;

            @include media(md) {
                width: 490px;
                // height: 190px;
            }
        }

        &__date {
            flex-basis: 157px;
            margin-block-start: 0;
            margin-right: 1.5em;
            margin-bottom: 5px;
            color: $--color-black;
            font-size: rem(15px);
            font-weight: 700;

            @include media(sm) {
                font-size: rem(14px);
            }
        }

        &__title {
            font-size: rem(18px);
            font-weight: 700 !important;
            line-height: 1.45em;
            margin-top: 0;
            margin-bottom: 9px;

            @include media(sm) {
                margin-bottom: 7px;
            }
        }

        &__title a {
            color: $--color-info;
            text-decoration: none;
            .link__txt {
                font-weight: 700 !important;
            }
        }

        &__item {
            margin-bottom: rem(66px);
            display: flex;
            flex-flow: column;

            @include media(md) {
                margin-bottom: rem(48px);
                // height: 190px;
                flex-flow: row;
            }
        }

        &__image {
            width: 100%;
            margin-right: 32px;
            margin-bottom: 12px;

            @include media(sm) {
                width: 320px;
            }
            @include media(md) {
                height: 190px;
                width: 284px;
            }

            .uploaded-image {
                height: 100%;
            }
        }
        &__excerpt {
            font-size: rem(13px);
            font-weight: 400;
            line-height: 1.6em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: -3px;

            @include media(sm) {
                font-size: rem(15px);
                line-height: 1.5em;
            }
        }
        &__place {
            display: flex;
            align-items: flex-start;
            font-weight: 600;
            font-size: 0.875rem;
            color: $--color-primary;
        }
    }

    .filterButton {
        .reset__button {
            margin-top: 8px;
            margin-left: 0;
        }
    }

    .filter-accordion-header {
        margin-bottom: 16px;

        @include media(sm) {
            margin-bottom: 10px;
        }
    }
}

.promoted {
    &__url {
        .link__internal {
            &:hover {
                text-decoration: none !important;
            }
        }
    }
}
</style>
