<template>
    <component :is="component" v-bind="params"></component>
</template>

<script>
import HTTPError from '../plugins/apis/HTTPError';
// Accueil
import Home from '~/pages/home';

// Page de base
import Page from '~/pages/page';

// Reglements
import Reglement from '~/pages/reglements/reglement';
import ReglementPdf from '~/pages/reglements/reglementPdf';
import Reglements from '~/pages/reglements/reglements';
import ReglementsPdf from '~/pages/reglements/reglementsPdf';

// Place
import Place from '~/pages/place/place';

// Guide
import Guide from '~/pages/guide/guide';

// Events
import EventListPage from '~/pages/event/events_list';
import EventPage from '~/pages/event/event';

// Jobs
import JobListPage from '~/pages/career/jobs_list';
import OpportunityListPage from '~/pages/career/career_opportunities';

// News
import NewsListPage from '~/pages/news/news_list';
import NewsPage from '~/pages/news/news';

// Allocutions
import AllocutionsListPage from '~/pages/allocutions/allocutions_list';

// Places
import PlacesListPage from '~/pages/place/places_list';

// Interactive map
import InteractiveMap from '~/pages/interactive_map/interactive_map';
import InteractiveMapList from '~/pages/interactive_map/interactive_map_list';

// Requests and payments
import RequestsAndPayments from '~/pages/requests_and_payments/requests_and_payments';
import RequestsAndPaymentsList from '~/pages/requests_and_payments/requests_and_payments_list';

// SearchResults
import SearchResults from '~/pages/search_results';

// Services
import Service from '~/pages/services/service';
import ServicesList from '~/pages/services/services_list';

// Hub Services
import HubServices from '~/pages/hub_services/hub_service';

// Mairesse
import Mairesse from '~/pages/mairesse';

// Profile
import Profile from '~/pages/profile';

// Direction
import DirectionsList from '~/pages/directions/directions_list';

// Déclarer ici afin d'être accessible dans le asyncData
const components = {
    NewsPage,
    DirectionsList,
    EventPage,
    NewsListPage,
    EventListPage,
    AllocutionsListPage,
    PlacesListPage,
    InteractiveMap,
    InteractiveMapList,
    RequestsAndPaymentsList,
    RequestsAndPayments,
    Service,
    ServicesList,
    SearchResults,
    Page,
    Place,
    Home,
    Profile,
    Guide,
    OpportunityListPage,
    JobListPage,
    HubServices,
    Mairesse,
    Reglement,
    Reglements,
    ReglementPdf,
    ReglementsPdf,
};

export default {
    components,
    async asyncData(context) {
        const { app, store, params, error, env } = context;
        // Liaison entre les type de contenu et les composants a charger
        const routeMapping = {
            node: {
                news: {
                    component: () => 'NewsPage',
                    includes: ['interests', 'news_type'],
                },
                event: {
                    component: () => 'EventPage',
                    includes: ['place', 'interests', 'event_type'],
                },
                interactive_map: {
                    component: () => 'InteractiveMap',
                    includes: [],
                },
                service: {
                    component: () => 'Service',
                    includes: ['customizable_content', 'related_events', 'related_events.place', 'related_news'],
                },
                hub_service: {
                    component: () => 'HubServices',
                    includes: [
                        'customizable_content',
                        'service_type',
                        'related_actions',
                        'related_events',
                        'related_events.place',
                        'related_news',
                        'media_image',
                    ],
                },
                hub_service_mayor: {
                    component: () => 'Mairesse',
                    includes: [
                        'customizable_content',
                        'related_actions',
                        'related_events',
                        'related_events.place',
                        'related_speeches',
                        'related_content',
                        'social_networks',
                    ],
                },
                page: {
                    component: () => 'Page',
                    includes: ['customizable_content'],
                },
                etat_des_installations: {
                    component: () => 'Page',
                    includes: ['customizable_content'],
                },
                transcript_regulation: {
                    component: () => 'ReglementPdf',
                    includes: ['borough', 'category_transcript_regulation', 'media_pdf', 'type_transcript_regulation'],
                },
                regulation_page: {
                    component: () => 'Reglement',
                    includes: ['customizable_content'],
                },
                place: {
                    component: () => 'Place',
                    includes: ['office_hours', 'customizable_content'],
                },
                profile: {
                    component: () => 'Profile',
                    includes: [],
                },
                direction: {
                    component: () => 'Page',
                    includes: [],
                },
                action: {
                    component: () => 'RequestsAndPayments',
                    includes: [],
                },
                custom_layout: {
                    component: mainItem => {
                        const layout = mainItem.data.layout;
                        return routeMapping.node[layout].component(mainItem);
                    },
                    includes: [],
                },
                /**
                 * Custom Layouts
                 */
                directions_list: {
                    component: () => 'DirectionsList',
                    includes: [],
                },
                requests_and_payments: {
                    component: () => 'RequestsAndPaymentsList',
                    includes: [],
                },
                homepage: {
                    component: () => 'Home',
                    includes: [],
                },
                services_list: {
                    component: () => 'ServicesList',
                    includes: [],
                },
                news_list: {
                    component: () => 'NewsListPage',
                    includes: [],
                },
                allocutions_list: {
                    component: () => 'AllocutionsListPage',
                    includes: [],
                },
                places_list: {
                    component: () => 'PlacesListPage',
                    includes: [],
                },
                jobs_list: {
                    component: () => 'JobListPage',
                    includes: [],
                },
                career_opportunities: {
                    component: () => 'OpportunityListPage',
                    includes: [],
                },
                event_list: {
                    component: () => 'EventListPage',
                    includes: [],
                },
                interactive_map_list: {
                    component: () => 'InteractiveMapList',
                    includes: [],
                },
                search_results: {
                    component: () => 'SearchResults',
                    includes: [],
                },
                guide: {
                    component: () => 'Guide',
                    includes: [
                        'related_news',
                        'related_events',
                        'related_events.place',
                        'related_services',
                        'customizable_content',
                    ],
                },
            },
        };

        store.commit('translatePath/setTranslationMissing', false);

        try {
            let individualUrl;
            let bundle;
            let type;
            const isSwitchLang = store.state.translatePath.isSwitchLangAction;
            // Si ce n'est pas un changement de langue
            if (isSwitchLang) {
                // Pour récuperer le UUID
                const pathObject = store.state.translatePath.pathObject;
                const basePath = pathObject.basePath;
                const resourceName = pathObject.resourceName;
                const uuid = pathObject.uuid;
                type = pathObject.type;
                bundle = pathObject.bundle;

                individualUrl = `${env.CMS_API_ENDPOINT}/${app.i18n.locale}${basePath}/${resourceName}/${uuid}`;
            }
            // Si c'est un changement de langue, remplacer la valeur resultsCombined.individual
            else {
                // Récupération du translatePath
                const path = await app.$client.execute('translatePath', 'getIndividual', {
                    pathAlias:
                        params.pathMatch && params.pathMatch !== 'null' && params.pathMatch !== '/null'
                            ? params.pathMatch
                            : '/',
                    include: ['node_type'],
                });

                individualUrl = path.jsonapi.individual;
                bundle = path.entity.bundle;
                type = path.entity.type;
                const basePath = path.jsonapi.basePath;
                const resourceName = path.jsonapi.resourceName;
                const uuid = path.entity.uuid;

                store.commit('translatePath/setPathObject', {
                    type,
                    bundle,
                    basePath,
                    resourceName,
                    uuid,
                });
            }

            // Deuxième requête qui gère les includes
            const mainItem = await app.$client.execute('byPath', 'getIndividual', {
                uri: individualUrl,
                include: routeMapping[type][bundle].includes,
            });
            if (mainItem.data.path.langcode !== app.i18n.locale) {
                store.commit('translatePath/setTranslationMissing', true);
            }
            store.commit('translatePath/setNewPath', `/${mainItem.data.path.langcode}${mainItem.data.path.alias}`);
            store.commit('breadcrumb/setBreadCrumb', mainItem.data.breadcrumb);

            let component = routeMapping.node.page.component();

            // Récupère le nom du composant selon le type de contenu
            if (routeMapping[type][bundle]) {
                component = routeMapping[type][bundle].component(mainItem);
            }

            // Déclanche la fonction asyncData du composant définie.
            const returnedData = {
                initData: await components[component].asyncData(context, mainItem.data),
                metaTags: app.$metaTags.setFromResponse(mainItem),
            };

            return {
                component,
                params: returnedData, // Sera set en props et contiendra toutes les infos du asyncData
            };
        } catch (e) {
            if (e instanceof HTTPError) {
                return error({ statusCode: e.statusCode });
            } else {
                app.$externalLog.captureException(e);
                error({ statusCode: 500, message: e.message });
            }
        }

        return {
            layout: 'LoaderWrapper',
            params: null,
        };
    },

    mounted() {
        // Le groupe de clavardage de SnapEngage est déterminé selon le URL.
        // Le CSC ou l'évaluation peuvent prendre le contact avec le citoyen.
        this.$nextTick(function() {
            // this.$updateSnapEngageFromLocale('fr');
        });
    },
};
</script>

<style lang="scss">
.test {
    border: 1px dotted red;

    &__col {
        height: 200px;
        display: flex;
        align-items: center;
        text-align: center;

        @include media(sm) {
            height: 600px;
        }
    }

    &__txt {
        width: 100%;
    }
}
</style>
