<template>
    <div class="parag__raw">
        <h3>{{ paragraphTitle }}</h3>
        <div v-if="intro" v-html="intro" />
        <div class="parag__raw__body" v-html="body"></div>
    </div>
</template>

<script>
export default {
    props: {
        paragraph: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            paragraphTitle: '',
            intro: '',
            body: '',
        };
    },
    mounted() {
        this.paragraphTitle = this.paragraph.title;
        if (this.paragraph.introduction) {
            this.intro = this.paragraph.introduction.value;
        }
        this.body = this.paragraph.raw_html;
    },
};
</script>
<style lang="scss">
.parag__raw__body {
    margin-top: 1rem;
}
</style>
