import { SplitText } from 'gsap/SplitText';

const split = (el, { value: wordDelimiter, arg: position, modifiers }) => {
    const defaults = {
        type: 'lines',
        linesClass: 'c-text-lines_item',
    };
    const settings = {};
    const types = Object.keys(modifiers);
    if (types) {
        settings.type = types.join(',');
    }
    if (position) {
        settings.position = position;
    }
    if (wordDelimiter) {
        settings.wordDelimiter = wordDelimiter;
    }
    const options = Object.assign({}, defaults, settings);
    const split = new SplitText(el, options);
    el.classList.add('c-text-lines');
    for (const line of split.lines) {
        line.innerHTML = `<span class="c-text-lines_item_inner">${line.innerHTML}</span>`;
    }
};

export default {
    install(Vue) {
        Vue.directive('split-text', { inserted: split });
    },
};
