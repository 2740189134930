// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/dmsans-bold-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/dmsans-bold-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/dmsans-medium-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/dmsans-medium-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/dmsans-regular-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/dmsans-regular-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:78.375rem){[data-v-67b0cbb4]:root{--header-height:5.75rem}}@media (min-width:79.375rem){[data-v-67b0cbb4]:root{--header-height:8.5rem}}@font-face{font-family:\"dm_sansbold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansmedium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansregular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:400;font-style:normal}.loader-wrapper[data-v-67b0cbb4]{align-self:center;width:100%;height:100%;text-align:center}.loader[data-v-67b0cbb4],.loader[data-v-67b0cbb4]:after,.loader[data-v-67b0cbb4]:before{border-radius:50%;width:2.5em;height:2.5em;animation-fill-mode:both;animation:load7-data-v-67b0cbb4 1.8s ease-in-out infinite}.loader[data-v-67b0cbb4]{color:#003da7;font-size:10px;margin:80px auto;position:relative;text-indent:-9999em;transform:translateZ(0);animation-delay:-.16s}.loader[data-v-67b0cbb4]:after,.loader[data-v-67b0cbb4]:before{content:\"\";position:absolute;top:0}.loader[data-v-67b0cbb4]:before{left:-3.5em;animation-delay:-.32s}.loader[data-v-67b0cbb4]:after{left:3.5em}@keyframes load7-data-v-67b0cbb4{0%,80%,to{box-shadow:0 2.5em 0 -1.3em}40%{box-shadow:0 2.5em 0 0}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
