<template>
    <div class="c-block-cta">
        <ui-icon v-if="iconType" :type="iconType" class="c-block-cta_icon" />
        <div class="c-block-cta_links">
            <span v-for="(link, index) in links" :key="index">
                <span v-if="index > 0" class="c-heading -h6">&nbsp;ou&nbsp;</span>
                <ui-cta :url="link.url" :label="link.label" :title="link.label" type="link" size="wide" />
            </span>
        </div>
        <p class="c-block-cta_description || c-text -caption || u-mt-2" v-html="description"></p>
    </div>
</template>

<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';
export default {
    components: {
        UiCta,
        UiIcon,
    },
    props: {
        iconType: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            default: () => [],
        },
    },
    computed: {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.c-block-cta {
    position: relative;

    @media (max-width: $to-small) {
        padding-left: loco-rem(96px);
    }

    @media (min-width: $from-small) {
        padding-left: loco-rem(40px);
    }
}
.c-block-cta_icon {
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: $to-small) {
        transform: scale(1.3);
        transform-origin: top left;
    }
}
.c-block-cta_description {
    max-width: loco-rem(210px);
}
</style>
