<template>
    <button class="c-ui-burger" @click="toggleMenu">
        <span class="c-ui-burger_icon">
            <span></span>
        </span>
        <span class="c-ui-burger_text || c-text -button-small">
            <span class="open-text">Menu</span>
            <span class="close-text">Fermer</span>
        </span>
    </button>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    created() {
        this.isActive = false;
    },
    methods: {
        ...mapActions('locomotive-global', ['setMenuOpen']),

        toggleMenu() {
            const headerLogo = document.querySelector('.c-block-header-bar_logo');
            const headerSearch = document.querySelector("nav[aria-label='Menu administratif'] .c-ui-cta");
            const mobileMenu = document.querySelector('.c-block-header_menu');
            const openText = this.$el.querySelector('.open-text');
            const closeText = this.$el.querySelector('.close-text');
            this.isActive = !this.isActive;
            if (this.isActive) {
                this.setMenuOpen(true);
                headerLogo.setAttribute('aria-hidden', true);
                headerLogo.setAttribute('tabindex', '-1');
                headerSearch.setAttribute('aria-hidden', true);
                headerSearch.setAttribute('tabindex', '-1');
                mobileMenu.setAttribute('role', 'dialog');
                mobileMenu.setAttribute('aria-modal', true);
                openText.setAttribute('aria-hidden', true);
                closeText.removeAttribute('aria-hidden');
            } else {
                this.setMenuOpen(false);
                headerLogo.removeAttribute('aria-hidden');
                headerLogo.removeAttribute('tabindex');
                headerSearch.removeAttribute('aria-hidden');
                headerSearch.removeAttribute('tabindex');
                mobileMenu.removeAttribute('role');
                mobileMenu.removeAttribute('aria-modal');
                openText.removeAttribute('aria-hidden');
                closeText.setAttribute('aria-hidden', true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-burger {
    display: flex;
    align-items: center;
}

.c-ui-burger_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    span {
        position: relative;
        display: block;
        width: 18px;
        height: 2px;
        background-color: currentColor;
        transition: background-color $loco-speed $loco-easing;

        .is-menu-open & {
            background-color: transparent;
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: currentColor;
            transition: transform $loco-speed $loco-easing;
        }

        &:before {
            top: -6px;
            .is-menu-open & {
                transform: rotate(45deg) translate3d(4px, 4px, 0);
            }
        }

        &:after {
            bottom: -6px;
            .is-menu-open & {
                transform: rotate(-45deg) translate3d(4px, -4px, 0);
            }
        }
    }
}

.c-ui-burger_text {
    display: block;
    position: relative;
    min-width: loco-rem(60px);
    margin-top: loco-rem(2px);
    text-align: left;
    overflow: hidden;

    span {
        display: block;
        transition: transform $loco-speed $loco-easing;
        .is-menu-open & {
            transform: translate3d(0, -100%, 0);
        }
        &:last-child {
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
        }
    }
}
</style>
