// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/dmsans-bold-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/dmsans-bold-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/dmsans-medium-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/dmsans-medium-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/dmsans-regular-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/dmsans-regular-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (max-width:78.375rem){:root{--header-height:5.75rem}}@media (min-width:79.375rem){:root{--header-height:8.5rem}}@font-face{font-family:\"dm_sansbold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansmedium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:\"dm_sansregular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\");font-weight:400;font-style:normal}.breadcrumb{display:flex;align-items:center}@media (max-width:767px){.breadcrumb{display:block}}.breadcrumb__item{font-size:.9375rem;font-weight:400}@media (max-width:767px){.breadcrumb__item:not(:last-child){margin-bottom:5px}}.breadcrumb__item:first-letter{text-transform:capitalize}.breadcrumb__item.is-last{pointer-events:none}.breadcrumb__item.is-last .is-link{text-decoration:none}.breadcrumb__item span.is-link a{color:#003da7;text-decoration:underline;font-weight:400}.breadcrumb__item span.is-link a:hover{text-decoration:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
