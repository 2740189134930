import FontFaceObserver from 'fontfaceobserver';
import Vue from 'vue';

const loadFonts = (fonts, callback = () => {}) => {
    return new Promise(resolve => {
        // Load fonts
        fontsLoader(fonts, () => {
            document.documentElement.classList.add('is-fonts-loaded');
            callback();
            resolve();
        });
    });
};

async function fontsLoader(fonts, callback = () => {}) {
    const fontFaceObservers = [];

    if (!fonts.length) return;

    fonts.forEach(fontFamily => {
        const observer = new FontFaceObserver(fontFamily);
        fontFaceObservers.push(observer);
    });

    try {
        await Promise.all(fontFaceObservers);
        callback();
    } catch (err) {
        console.warn('Some critical font are not available:', err);
    }
}

Vue.prototype.$loadFonts = loadFonts;
