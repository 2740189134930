import BaseResource from '../BaseResource';

class Services extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/services';
    }
}

export default Services;
