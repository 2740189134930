import BaseResource from '../BaseResource';

class Rappels extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/rappels';
    }
}

export default Rappels;
