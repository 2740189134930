<template>
    <span :class="[typeClass]" class="c-ui-icon">
        <svg
            v-if="icon"
            :viewBox="viewBox"
            :width="iconWidth"
            :height="iconHeight"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            v-html="icon"
        ></svg>
    </span>
</template>

<script>
import { iconTypes } from '~/utils/icons/icon-types.js';

export default {
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data: () => {
        return {
            icon: '',
            iconWidth: 0,
            iconHeight: 0,
        };
    },
    computed: {
        typeClass() {
            return `-${this.type}`;
        },

        viewBox() {
            return `0 0 ${this.iconWidth} ${this.iconHeight}`;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.getIconContent();
        },

        getIconContent() {
            const icon = iconTypes[this.type];

            if (!icon) return console.log(`No svg found for type: ${this.type}`);

            this.icon = icon.content;
            this.iconWidth = icon.width;
            this.iconHeight = icon.height;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-icon {
    font-size: 0;

    svg {
        display: block;
        max-width: 100%;

        path {
            fill: currentColor;
        }
    }
}
</style>
