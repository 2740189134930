<template>
    <div class="loader-wrapper">
        <div v-if="loading && !overContent" class="loader-wrapper__spinner-box">
            <svg class="loader-wrapper__spinner" viewBox="0 0 50 50">
                <circle class="loader-wrapper__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>
        <div v-if="loading && overContent" class="loader-wrapper__spinner-box">
            <div class="loader-wrapper__background">
                <svg class="loader-wrapper__spinner" viewBox="0 0 50 50">
                    <circle class="loader-wrapper__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
            </div>
            <slot />
        </div>
        <slot v-if="!loading" />
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        overContent: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
    position: relative;
    min-height: 300px;
    &__spinner-box {
        // background-image: linear-gradient(-105deg, #fcfcff, #fcfcff);
        position: relative;
        top: 200px;
    }
    &__background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: white;
        opacity: 0.5;
    }
    &__spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -25px 0 0 -25px;
        width: 50px;
        height: 50px;
    }

    &__path {
        stroke: #45485a;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
</style>
