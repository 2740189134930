import BaseResource from '../BaseResource';
import RequestFactory from '../RequestFactory';

class Taxonomy extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/taxonomyTerms';
    }

    /**
     * Get Single Event
     * @param id
     * @returns RequestObject
     */
    getIndividual(type, id) {
        return RequestFactory(`${this.resourcePath}/${type}/${id}`, 'view');
    }

    /**
     * Get Collection of Events
     * @param params
     * @returns RequestObject
     */
    getCollection(type) {
        return RequestFactory(`${this.resourcePath}/${type}`, 'view');
    }
}

export default Taxonomy;
