import BaseResource from '../BaseResource';

class Medias extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/mediaImages';
    }
}

export default Medias;
