<template>
    <div class="parag__webform">
        <LoadingDots v-if="!formReady" />
        <template v-else-if="error">
            <span>{{ $t('pargraphs.webforms.error') }}</span>
        </template>
        <template v-else>
            <h2 v-if="title">{{ title | decodehtml }}</h2>
            <Wysiwyg v-if="intro" :body="intro" />
            <FormSchemaBuilder :webform="webform" />
        </template>
    </div>
</template>

<script>
import FormSchemaBuilder from '~/components/forms/FormSchemaBuilder';
import LoadingDots from '~/components/LoadingDots';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    components: {
        FormSchemaBuilder,
        LoadingDots,
        Wysiwyg,
    },
    props: {
        paragraph: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: true,
            webform: null,
            error: false,
        };
    },
    computed: {
        title() {
            return this.paragraph.title;
        },
        intro() {
            return this.paragraph.introduction ? this.paragraph.introduction.processed : null;
        },
        formReady() {
            return this.error || (!this.loading && this.webform !== null);
        },
    },
    mounted() {
        const self = this;
        this.$client
            .execute('webformJsonSchema', 'getIndividual', {
                id: this.paragraph.field_webform.id,
            })
            .then(results => {
                self.webform = results.data;
                this.loading = false;
            })
            .catch(e => {
                this.loading = false;
                this.error = true;
                console.error('Error when webformJsonSchema try to getIndividual', e);
            });
    },
};
</script>
