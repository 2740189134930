/* eslint sort-imports-es6-autofix/sort-imports-es6: 0 */
import Vue from 'vue';
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js';

export default (ctx, inject) => {
    const options = {
        fullscreen: { enabled: false },
        i18n: {
            restart: ctx.app.i18n.t('plyr.restart'),
            rewind: ctx.app.i18n.t('plyr.rewind'),
            play: ctx.app.i18n.t('plyr.play'),
            pause: ctx.app.i18n.t('plyr.pause'),
            fastForward: ctx.app.i18n.t('plyr.fastForward'),
            seek: ctx.app.i18n.t('plyr.seek'),
            seekLabel: ctx.app.i18n.t('plyr.seekLabel'),
            played: ctx.app.i18n.t('plyr.played'),
            buffered: ctx.app.i18n.t('plyr.buffered'),
            currentTime: ctx.app.i18n.t('plyr.currentTime'),
            duration: ctx.app.i18n.t('plyr.duration'),
            volume: ctx.app.i18n.t('plyr.volume'),
            mute: ctx.app.i18n.t('plyr.mute'),
            unmute: ctx.app.i18n.t('plyr.unmute'),
            enableCaptions: ctx.app.i18n.t('plyr.enableCaptions'),
            disableCaptions: ctx.app.i18n.t('plyr.disableCaptions'),
            download: ctx.app.i18n.t('plyr.download'),
            enterFullscreen: ctx.app.i18n.t('plyr.enterFullscreen'),
            exitFullscreen: ctx.app.i18n.t('plyr.exitFullscreen'),
            frameTitle: ctx.app.i18n.t('plyr.frameTitle'),
            captions: ctx.app.i18n.t('plyr.captions'),
            settings: ctx.app.i18n.t('plyr.settings'),
            pip: ctx.app.i18n.t('plyr.pip'),
            menuBack: ctx.app.i18n.t('plyr.menuBack'),
            speed: ctx.app.i18n.t('plyr.speed'),
            normal: ctx.app.i18n.t('plyr.normal'),
            quality: ctx.app.i18n.t('plyr.quality'),
            loop: ctx.app.i18n.t('plyr.loop'),
            start: ctx.app.i18n.t('plyr.start'),
            end: ctx.app.i18n.t('plyr.end'),
            all: ctx.app.i18n.t('plyr.all'),
            reset: ctx.app.i18n.t('plyr.reset'),
            disabled: ctx.app.i18n.t('plyr.disabled'),
            enabled: ctx.app.i18n.t('plyr.enabled'),
            advertisement: ctx.app.i18n.t('plyr.advertisement'),
        },
    };
    Vue.use(VuePlyr, { plyr: options, emit: ['ended'] });
};
