<template>
    <div class="c-ui-lazyimage">
        <div
            :style="{ 'padding-bottom': (imageRatio[1] / imageRatio[0]) * 100 + '%' }"
            class="c-ui-lazyimage_ratio"
        ></div>
        <img
            :src="defaultSrc"
            :alt="imageAlt"
            :data-src="imageSrc"
            data-scroll
            data-scroll-call="lazyLoad"
            class="c-ui-lazyimage_image"
        />
    </div>
</template>
<script>
import { nearestNormalAspectRatio } from '~/utils/nearest-ratio.js';
export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        imageWidth: {
            type: Number,
            default: 2,
        },
        imageHeight: {
            type: Number,
            default: 1,
        },
        imageAlt: {
            type: String,
            default: '',
        },
    },
    computed: {
        imageRatio() {
            const nearestRatio = nearestNormalAspectRatio(this.imageWidth, this.imageHeight);
            return nearestRatio.split(':');
        },
        defaultSrc() {
            return `data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20${this.imageRatio[0]}%20${this.imageRatio[1]}'%3E%3C/svg%3E`;
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-lazyimage {
    position: relative;
    overflow: hidden;
}
.c-ui-lazyimage_ratio {
    width: 100%;
}
.c-ui-lazyimage_image {
    @include u-stretch();
    object-fit: cover;
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);

    &.-lazy-loaded {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        transition: opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95), transform 1s cubic-bezier(0.1, 0, 0, 1);
    }
}
</style>
