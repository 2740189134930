<template>
    <el-image v-if="path" class="uploaded-image" :fit="fit" :src="path" :alt="alt" lazy />
</template>

<script>
export default {
    props: {
        path: {
            type: String,
            default: null,
        },
        alt: {
            type: String,
            default: '',
        },
        fit: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            domain: process.env.CMS_API_ENDPOINT,
        };
    },
};
</script>

<style lang="scss" scoped>
.uploaded-image {
    width: 100%;
}
</style>
