export default {
    data: () => {
        return {
            heroData: {},
            pushData: {},
            accessData: {},
            splashData: {},
            newsData: {},
            eventsData: {
                items: [],
            },
        };
    },
    created() {
        this.heroData = this.setHeroData();
        this.pushData = this.setPushData();
        this.accessData = this.setAccessData();
        this.splashData = this.setSplashData();
    },
    methods: {
        setHeroData() {
            return {
                title: this.$t('home.welcome'),
                // TODO: [X - Locomotive] - Add default cover image (if video doesn't work)
                image: {
                    src: this.heroVideoImage.image_src,
                    width: parseInt(this.heroVideoImage.image_width),
                    height: parseInt(this.heroVideoImage.image_height),
                    alt: 'Image Longueuil',
                },
                // TODO: [X - Locomotive] - Add video (set width/height for ratio)
                video: {
                    src: this.heroVideoImage.video_src,
                    width: parseInt(this.heroVideoImage.video_width),
                    height: parseInt(this.heroVideoImage.video_height),
                },
                news: this.setHeroNewsData(),
            };
        },
        setHeroNewsData() {
            return this.heroNews;
        },
        setAccessData() {
            return {
                category: this.$t('home.accesRapide'),
                title: this.$t('home.accesRapideTitre'),
                linkUrl: '/' + this.$i18n.locale + '/' + this.$t('services.url'),
                linkLabel: this.$t('services.allServices'),
                linkType: 'internal',
                items: this.accesRapides,
            };
        },
        setPushData() {
            return {
                title: this.emploi.title,
                description: this.emploi.description,
                linkUrl: this.emploi.linkUrl,
                linkLabel: this.emploi.linkLabel,
                linkType: this.emploi.linkType,
                images: [
                    {
                        src: this.emploi.image_url_1,
                        width: parseInt(this.emploi.image_width_1),
                        height: parseInt(this.emploi.image_height_1),
                        alt: 'Image emploi Longueuil',
                    },
                    {
                        src: this.emploi.image_url_2,
                        width: parseInt(this.emploi.image_width_2),
                        height: parseInt(this.emploi.image_height_2),
                        alt: 'Image emploi Longueuil',
                    },
                    {
                        src: this.emploi.image_url_3,
                        width: parseInt(this.emploi.image_width_3),
                        height: parseInt(this.emploi.image_height_3),
                        alt: 'Image emploi Longueuil',
                    },
                ],
            };
        },
        setSplashData() {
            return {
                title: this.carteInteractive.title,
                description: this.carteInteractive.description,
                linkUrl: this.carteInteractive.linkUrl,
                linkLabel: this.carteInteractive.linkLabel,
                linkType: this.carteInteractive.linkType,
                image: {
                    src: this.carteInteractive.image_src,
                    width: parseInt(this.carteInteractive.width),
                    height: parseInt(this.carteInteractive.height),
                    alt: 'Carte interactive',
                },
            };
        },
        setNewsData(news) {
            const newsData = {
                title: this.$t('news.title'),
                linkLabel: this.$t('news.seeAllNews'),
                linkUrl: '/' + this.$i18n.locale + '/' + this.$t('news.url'),
                linkType: 'internal',
                items: [],
            };

            if (!news.length) {
                this.newsData = newsData;
                return;
            }

            news.forEach(item => {
                const newsObj = {
                    tags: item.interests ? item.interests : [],
                    category: item.news_type && item.news_type.length > 0 ? item.news_type[0] : '',
                    title: item.title,
                    url: item.url,
                    dateStart: this.$moment(item.created).format('LL'),
                    dateEnd: '',
                };

                // Push to newsData
                newsData.items.push(newsObj);
            });

            this.newsData = newsData;
        },
        setEventsData(events) {
            const eventsData = {
                title: this.$t('events.futureEvents'),
                linkLabel: this.$t('events.seeAllEvents'),
                linkUrl: '/' + this.$i18n.locale + '/' + this.$t('events.url'),
                linkType: 'internal',
                items: [],
            };

            if (!events.length) {
                this.eventsData = eventsData;
                return;
            }

            events.forEach(item => {
                const eventsObj = {
                    tags: item.place ? [item.place] : [],
                    category: item.event_type && item.event_type.length > 0 ? item.event_type[0] : '',
                    title: item.title,
                    url: item.url,
                    dateStart: this.$moment(item.dateStart).format('LL'),
                    dateEnd: this.$moment(item.dateEnd).format('LL'),
                    image: {},
                };

                // Set image
                const mediaImage = item?.mediaImage?.meta?.links?.front_large_21_9;
                if (mediaImage) {
                    eventsObj.image = {
                        src: mediaImage,
                        width: 2,
                        height: 1,
                        alt: item.mediaImage.meta.alt,
                    };
                } else {
                    eventsObj.image = {
                        src: '/placeholder_1400x400.svg',
                        width: 2,
                        height: 1,
                        alt: 'Default events thumbnail',
                    };
                }

                // Push to eventsData
                eventsData.items.push(eventsObj);
            });
            this.eventsData = eventsData;
        },
    },
};
