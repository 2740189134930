const initTaxonomy = () => ({
    news: {
        en: null,
        fr: null,
    },
    events: {
        en: null,
        fr: null,
    },
    install: {
        en: null,
        fr: null,
    },
    place: {
        en: null,
        fr: null,
    },
    client: {
        en: null,
        fr: null,
    },
    services: {
        en: null,
        fr: null,
    },
});

/* Ne pas oublier de prendre en compte la langue Exemple:
news: {
    en: {...}
    fr: {...}
}
*/

export const state = initTaxonomy;

export const mutations = {
    setTaxonomy(state, { lang, type, val }) {
        state[type][lang] = val;
    },

    resetTaxonomy(state, type) {
        state[type] = initTaxonomy()[type];
    },

    reset() {
        const initState = initTaxonomy();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};

export const actions = {
    async fetchEvent({ commit, rootState }, app) {
        if (!rootState.taxonomy.events[app.i18n.locale]) {
            try {
                const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;
                const taxonomyResults = await app.$client.execute('taxonomy', 'getCollection', 'eventTypes');
                commit('setTaxonomy', { lang, type: 'events', val: taxonomyResults.data });
            } catch (e) {
                app.$externalLog.captureException(e);
            }
        }
    },
    async fetchNews({ commit, rootState }, app) {
        if (!rootState.taxonomy.news[app.i18n.locale]) {
            try {
                const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;
                const taxonomyResults = await app.$client.execute('taxonomy', 'getCollection', 'newsTypes');
                commit('setTaxonomy', { lang, type: 'news', val: taxonomyResults.data });
            } catch (e) {
                app.$externalLog.captureException(e);
            }
        }
    },
    async fetchServices({ commit, rootState }, app) {
        if (!rootState.taxonomy.services[app.i18n.locale]) {
            try {
                const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;
                const taxonomyResults = await app.$client.execute('taxonomy', 'getCollection', 'servicesGrouping');
                commit('setTaxonomy', { lang, type: 'services', val: taxonomyResults.data });
            } catch (e) {
                app.$externalLog.captureException(e);
            }
        }
    },
};
