<template>
    <content-details
        :title="item.title"
        :type-tags="item.typeTags"
        :infos="item.infos"
        :more-link="{ txt: $t('news.backToNews'), url: $t('news.url') }"
        class="single-news"
    >
        <template>
            <!-- Social -->
            <Container max-width="650px">
                <SocialMedias
                    :title="item.title"
                    :tag="item.typeTags"
                    :url="$nuxt.$route.path"
                    :description="
                        item.details.processed.length < 50
                            ? item.details.processed
                            : item.details.processed.substr(50) + '...'
                    "
                ></SocialMedias>
            </Container>

            <!-- Text -->
            <Container
                v-if="item.details && item.details.processed"
                max-width="650px"
                class="item__wysiwyg item__wysiwyg--bigger"
            >
                <wysiwyg :body="item.details.processed" />
            </Container>

            <!-- Image -->
            <Container v-if="item.media_image" max-width="840px" class="item__image-wrapper">
                <media-image
                    v-if="item.media_image"
                    :image="item.media_image"
                    size="front_large_21_9"
                    class="item__image"
                    fit="cover"
                ></media-image>
            </Container>

            <!-- Text -->
            <Container
                v-if="item.additional_information && item.additional_information.processed"
                max-width="650px"
                class="item__wysiwyg"
            >
                <wysiwyg :body="item.additional_information.processed" />
            </Container>

            <!-- Files -->
            <Container class="item__custom-content" max-width="650px">
                <div v-if="item.media_pdf">
                    <file-list :file-list="item.media_pdf"></file-list>
                </div>
            </Container>

            <!-- LinkedNews -->
            <Container class="section--news section-container" max-width="979px">
                <ListCards
                    :items="news"
                    :title="`${$t('news.interestedIn')}`"
                    :more-link="{
                        txt: $t('news.seeAllNews'),
                        url: $t('news.url'),
                    }"
                    :custom-gutter="63"
                    show-created
                    primary
                    trait
                />
            </Container>
        </template>
    </content-details>
</template>

<script>
import { capitalize, cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import FileList from '~/components/paragraphs/FileList';
import ListCards from '~/components/ListCards';
import MediaImage from '~/components/MediaImage';
import SocialMedias from '~/components/SocialMedias.vue';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        Container,
        ContentDetails,
        ListCards,
        MediaImage,
        FileList,
        SocialMedias,
        Wysiwyg,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            news: initData.news,
        };
    },

    async asyncData({ app, query, store, params, error, env }, item) {
        await store.dispatch('taxonomy/fetchNews', app);

        const moment = app.$moment;
        moment.locale(app.i18n.locale);

        item.typeTags = [
            {
                name: capitalize(moment(item.created).format('dddd LL')),
                to: '#',
            },
            {
                name: item.news_type.name,
                to: '#',
            },
        ];

        let linkedNews = {};

        if (item.borough.length > 0) {
            linkedNews = await app.$client.execute('news', 'getCollection', {
                sort: '-created',
                limit: 3,
                filters: {
                    filterByBorough: {
                        condition: {
                            path: `borough.id`,
                            operator: 'IN',
                            value: item.borough.map(item => item.id),
                        },
                    },
                    filterNotItSelf: {
                        condition: {
                            path: `id`,
                            operator: '<>',
                            value: item.id,
                        },
                    },
                },
            });
        }

        if (!linkedNews.data || (linkedNews.data && linkedNews.data.length < 3)) {
            const remainingNewNb = linkedNews.data ? 3 - linkedNews.data.length : 3;
            const remainingNews = await app.$client.execute('news', 'getCollection', {
                sort: '-created',
                limit: remainingNewNb,
                filters: {
                    filterNotItSelf: {
                        condition: {
                            path: `id`,
                            operator: '<>',
                            value: item.id,
                        },
                    },
                },
            });
            if (linkedNews.data) {
                linkedNews.data = linkedNews.data.concat(remainingNews.data);
            } else {
                linkedNews = remainingNews;
            }
        }

        const newsRefined = linkedNews.data.map(news => {
            news.body = news.details;
            return news;
        });

        const news = app.$drupalComponentsAdapter.normalizeItems(newsRefined);

        return { item, news };
    },
};
</script>

<style lang="scss">
.single-news {
    .title.padding {
        @include media(md) {
            width: 100%;
            margin: auto;
            max-width: 650px;
        }
    }

    .item {
        &__wysiwyg {
            &--bigger {
                .wysiwyg {
                    margin-bottom: 32px;
                    font-size: rem(16px);
                    @include media(sm) {
                        font-size: rem(20px);
                    }
                }
            }
        }

        &__related-cards-item {
            margin-bottom: 25px;
        }

        &__image {
            display: flex;
            min-height: 197px;
            margin-bottom: 4px;
            height: 62vw;
            margin-left: -20px;
            width: calc(100% + 40px);
            margin-top: 27px;
            margin-bottom: 21px;

            @include media(sm) {
                margin-left: initial;
                width: 100%;
                height: 510px;
                margin-top: -3px;
                margin-bottom: 13px;
            }

            &-wrapper {
                margin-bottom: 16px;
            }
        }

        &__custom-content {
            .container {
                padding-left: 0;
            }
        }
    }

    .container {
        &.container {
            margin: auto !important;
        }

        .title {
            @include media(lg) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .section--news {
        margin-top: 57px !important;

        @include media(sm) {
            margin-top: 70px !important;
        }

        .items-list__title {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.9px;
        }

        .el-image {
            @include media(sm) {
                min-height: 190px;
            }
        }

        .card__body {
            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    .items-list {
        margin-bottom: 90px;
    }
}
</style>
