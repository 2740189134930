import BaseResource from '../BaseResource';

class Menu extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/menus';
    }
}

export default Menu;
