<template>
    <div class="parag__wysiwyg">
        <wysiwyg :body="body" />
    </div>
</template>

<script>
import Wysiwyg from '~/components/Wysiwyg';
export default {
    components: {
        Wysiwyg,
    },
    props: {
        paragraph: {
            type: Object,
            default: null,
        },
    },
    computed: {
        body() {
            return this.paragraph.content.processed;
        },
    },
};
</script>
