<template>
    <Row class="socials">
        <span class="socials__share">{{ $t('home.share') }}</span>
        <div class="socials__icones">
            <ShareNetwork
                v-for="social in socials"
                :key="social.type"
                :network="social.type"
                :url="`https://${domain}${url}`"
                :title="stripContent(title)"
                :description="stripContent(description)"
                :quote="quote"
                :hashtags="hashtags"
                class="socialNetwork__item"
            >
                <img
                    class="socialNetwork__ico"
                    :src="'/img/social-network/' + social.type + '-dark.svg'"
                    :alt="social.type"
                />
            </ShareNetwork>
        </div>
    </Row>
</template>

<script>
import Row from '~/components/Row.vue';

export default {
    components: {
        Row,
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
        quote: {
            type: String,
            default: '',
        },
        hashtags: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            domain: process.env.SELF_DOMAIN,
            socials: [
                {
                    type: 'facebook',
                },
                {
                    type: 'email',
                },
                {
                    type: 'twitter',
                },
            ],
        };
    },
    methods: {
        stripContent(content) {
            const regex = /(<([^>]+)>)/gi;
            return content.replace(regex, '');
        },
    },
};
</script>

<style lang="scss" scoped>
.socials {
    width: auto;
    margin-top: 30px;
    margin-bottom: 12px;
    display: flex;
    font-size: rem(13px);
    font-weight: 400;
    align-items: center;

    @include media(sm) {
        float: right;
        margin-top: 2px;
        margin-bottom: 8px;
    }

    &__share {
        margin-right: 8px;
        margin-bottom: 4px;
    }

    .socialNetwork__item {
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 4px;
        }
    }

    &__icones {
        display: flex;
    }

    .socialNetwork__ico {
        width: rem(31px);
        height: rem(31px);

        &:hover {
            opacity: 0.6;
        }

        @include media(sm) {
            width: rem(25px);
            height: rem(25px);
        }
    }
}
</style>
