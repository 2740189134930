<template>
    <div>
        <Container padding>
            <Title :title="$t('search.searchResults')" />
            <div v-if="resultWord && !isUpdating" class="list-with-filter__search-results">
                {{ $tc('search.resultsFor', trueTotalItems) }} :
                <b>« {{ resultWord }} »</b>
            </div>
            <search-bar
                v-if="!isMobile"
                id="main-search-bar"
                v-model="searchVal"
                class="search"
                :class="{
                    'search-mobile': isMobileSearchBarOpen && isMobile,
                }"
                background
                :bar-label="$t('a11y.searchBars.ariaLabel')"
                :placeholder="$t('header.searchBarTitle')"
                @search="onSearch"
            />
        </Container>
        <Container>
            <list-with-filter
                v-if="resultWord"
                class="search-results"
                taxonomy-type="content_type"
                :show-title="false"
                :facets="facets"
                :has-filters="false"
                :result-number="trueTotalItems"
                :search-bar="false"
                :page.sync="page"
                :total-count="totalCount"
                :items-per-page="itemsPerPage"
                :intro="pageIntro"
                @filterUpdate="filterUpdateHandler"
            >
                <template>
                    <loader-wrapper :loading="isUpdating">
                        <section class="result__group">
                            <div class="result__items">
                                <el-tabs v-model="activeName" @tab-click="handleClick">
                                    <el-tab-pane :label="pagesTab" name="pagesTab">
                                        <article
                                            v-for="item in pagesResults.results"
                                            :key="item.entity_id"
                                            class="result__item"
                                        >
                                            <search-result
                                                v-bind="item"
                                                :title="item.entity_title"
                                                :domain="domain"
                                                :alias="item.entity_alias"
                                            ></search-result>
                                        </article>
                                    </el-tab-pane>

                                    <el-tab-pane :label="documentsTab" name="documentsTab">
                                        <article
                                            v-for="item in documentsResults.results"
                                            :key="item.entity_id"
                                            class="result__item"
                                        >
                                            <search-result
                                                v-bind="item"
                                                :title="item.entity_title"
                                                :domain="domain"
                                                :alias="item.entity_alias"
                                            ></search-result>
                                        </article>
                                    </el-tab-pane>
                                </el-tabs>

                                <h3 v-if="totalResults === 0">
                                    {{ $t('search.noResult') }}
                                </h3>
                            </div>
                        </section>
                    </loader-wrapper>
                </template>
            </list-with-filter>
        </Container>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container.vue';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';
import SearchBar from '~/components/SearchBar.vue';
import SearchResult from '~/components/SearchResult';
import Title from '~/components/Title.vue';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        Container,
        SearchBar,
        LoaderWrapper,
        ListWithFilter,
        SearchResult,
        Title,
    },
    mixins: [ScrollMixin],
    props: {
        metaTags: {
            type: Array,
            default: () => [],
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            page: parseInt(this.$route.query.page) || 1,
            pagesResults: initData.pagesResults,
            documentsResults: initData.documentsResults,
            filterValues: initData.filterValues,
            facets: initData.facets,
            checkAll: true,
            isUpdating: false,
            domain: '',
            itemsPerPage: 10,
            activeName: this.$route.query.tab || 'pagesTab',
            pagesTab: '',
            documentsTab: '',
            searchVal: '',
            isMobile: false,
            isMobileMenuOpen: false,
            isMobileSearchBarOpen: false,
            resultWord: '',
        };
    },
    computed: {
        totalItems() {
            if (this.isUpdating) {
                return null;
            }
            return (this.activeName === 'pagesTab' ? this.pagesResults.results : this.documentsResults.results).length;
        },
        totalCount() {
            return this.activeName === 'pagesTab'
                ? parseInt(this.pagesResults.pager.count)
                : parseInt(this.documentsResults.pager.count);
        },
        totalResults() {
            return (this.activeName === 'pagesTab' ? this.pagesResults.results : this.documentsResults.results).length;
        },
        trueTotalItems() {
            return parseInt(this.pagesResults.pager.count) + parseInt(this.documentsResults.pager.count);
        },
        pageIntro() {
            return this.initData.item.body != null ? this.initData.item.body.processed : null;
        },
    },
    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        '$store.state.resetSearch.resetSearch'(v) {
            this.pageOrDocumentUpdater();
        },
        page() {
            this.pageOrDocumentUpdater(true);
        },
    },
    async asyncData({ app, query, store, $sentry }, item, dataInfo) {
        const filterValues = {
            byTitle: '',
            byDate: null,
            byDateRange: [],
            byType: [],
        };

        // TODO ML : Ce code n'est plus valide, car nous lançons la recherche à même cette page.
        // Si on ne met pas cette constante à vide, la recherche se lance avec le mot "undefined" et ça prend du temps.
        const mot = '';

        // PAGE
        const pagePath = process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/search-nodes?search=';

        const pageParameters = {
            search: [`${mot}&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`],
        };

        const pagesResults = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(pagePath, pageParameters),
        });

        const facets = [];

        if (pagesResults.data.facets) {
            for (const [facetId, filters] of Object.entries(pagesResults.data.facets)) {
                const facet = { facetId, filters: [] };
                for (const [key, f] of Object.entries(filters)) {
                    facet.filters.push({
                        id: key,
                        value: f.values.value,
                        name: f.values.label,
                        active: f.values.active ? f.values.active === 'true' : false,
                    });
                }
                facets.push(facet);
            }
        }

        // Documents
        const documentsPath = process.env.CMS_API_ENDPOINT + '/' + app.i18n.locale + '/api/search-medias?search=';

        const documentsParameters = {
            search: [`${mot}&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=10`],
        };

        const documentsResults = await app.$client.execute('solrSearch', 'getDatas', {
            uri: app.$solrUrlBuilder.buildUri(documentsPath, documentsParameters),
        });

        return {
            item,
            pagesResults: pagesResults.data,
            documentsResults: documentsResults.data,
            facets,
            filterValues,
        };
    },
    mounted() {
        this.domain = window.location.origin;
        this.updateTabs();
    },
    methods: {
        updateTabs() {
            this.pagesTab = `${this.pagesResults.pager.count} pages`;
            this.documentsTab = `${this.documentsResults.pager.count} documents`;
        },
        async updateResults(pageOnly = false, type = 'search-nodes', skipLoading = false) {
            console.log('On met à jour les résultats');
            if (!pageOnly) this.page = 0;
            if (!skipLoading) this.isUpdating = true;

            console.log('Type', type);

            // const pageOrDocumentResults = type === 'search-nodes' ? 'pagesResults' : 'documentsResults';
            const path = `${process.env.CMS_API_ENDPOINT}/${this.$i18n.locale}/api/search-nodes?search=`;
            const parameters = {
                search: [`${this.$route.query.search}&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=10`],
                facets: this.filterValues.byType,
            };
            const results = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(path, parameters),
            });

            if (type === 'search-nodes') {
                const facets = [];
                if (results.data.facets) {
                    for (const [facetId, filters] of Object.entries(results.data.facets)) {
                        const facet = { facetId, filters: [] };
                        for (const [key, f] of Object.entries(filters)) {
                            facet.filters.push({
                                id: key,
                                value: f.values.value,
                                name: f.values.label,
                                active: f.values.active ? f.values.active === 'true' : false,
                            });
                        }
                        facets.push(facet);
                    }
                }

                this.facets = facets;
            }

            const documentsPath = process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + '/api/search-medias?search=';

            const documentsParameters = {
                search: [
                    `${this.$route.query.search}&page=${
                        this.page && this.page > 0 ? this.page - 1 : 0
                    }&items_per_page=10`,
                ],
            };

            const documentsResults = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(documentsPath, documentsParameters),
            });

            this.$store.commit('loading/setIsPageLoad', false);

            this.pagesResults = results.data;
            this.documentsResults = documentsResults.data;
            this.updateTabs();
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.pageOrDocumentUpdater();
        },
        pageOrDocumentUpdater(pageOnly = false) {
            if (pageOnly) {
                if (this.activeName === 'pagesTab') this.updateResults(pageOnly, 'search-nodes');
                else this.updateResults(pageOnly, 'search-medias');
            } else if (this.activeName === 'pagesTab') {
                this.updateResults(pageOnly, 'search-nodes');
            } else {
                this.activeName = 'pagesTab';
                this.updateResults(pageOnly, 'search-nodes');
            }
        },
        handleClick(tab, event) {
            // handles tabulations
            this.activeName = tab.name;
            this.page = 1;
        },
        onSearch(value) {
            const paths = {
                fr: '/fr/resultats-de-recherche',
                en: '/en/search-results',
            };

            const adress = paths[this.$i18n.locale] + '?search=' + value;
            const isHome =
                window.location.pathname === `/` ||
                window.location.pathname === `/${this.$i18n.locale}` ||
                window.location.pathname === `/${this.$i18n.locale}/`;
            // Cette condition est parce qu'initialement, la recherche était située dans le Header du site.
            // À présent, il y a la page ici présente (search_result) qui gère la recherche.
            if (!isHome && adress.includes(window.location.pathname)) {
                this.$route.query.search = value;
                this.$store.dispatch('resetSearch/changeResults');
                this.resultWord = value;
            } else {
                // should navigate to the result page
                this.$router.push(adress);
            }

            this.$store.commit('loading/setIsPageLoad', true);
            this.$store.commit('menu/closeMainMenu', this.$i18n.locale);
            this.$store.commit('loading/setLastRoute', adress);

            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss" scoped>
.result {
    &__group {
        margin-top: 30px;
        margin-bottom: 3px;
        display: flex;
        align-items: flex-start;

        @include media(sm) {
            margin-top: 0;
        }
    }

    &__date {
        flex-basis: 157px;
        margin-right: 1.5em;
        &-title {
            font-size: 1.5em;
            font-weight: bold;
        }
    }

    &__items {
        flex: 1;
    }

    &__item {
        margin-bottom: 1.5em;

        a,
        a span {
            color: $--color-primary;
        }
    }
}
</style>

<style lang="scss">
.search {
    padding-bottom: 30px;
}
.search-results {
    .pagination-wrapper {
        margin-top: 0;
    }

    .loader-wrapper {
        min-height: 300px;
    }

    .loader-wrapper__spinner-box {
        top: 200px;
    }

    .filterButton {
        .el-button {
            margin-left: 0px;

            @include media(sm) {
                margin-left: 24px;
            }
        }
    }

    .disabled {
        .filter-accordion-header {
            margin-bottom: rem(32px);
        }
    }

    .title__htag--h1 {
        font-size: rem(25px);
        margin-top: 47px;
        margin-bottom: 12px;

        @include media(sm) {
            font-size: rem(42px);
            margin-top: 27px;
            margin-bottom: 8px;
        }

        @include media(md) {
            margin-left: -2px;
        }
    }

    .el-tabs {
        border: 0px solid transparent;

        &__header {
            border-bottom: 2px solid $--color-green !important;
            border-radius: 4px 4px 0 0;
        }

        &__nav {
            display: flex;
            align-items: flex-end;
            height: 53px;
        }

        &__nav-wrap {
            margin-bottom: 0;
        }

        &__active-bar {
            display: none;
        }

        &__item {
            display: flex;
            align-items: center;
            margin-right: 4px;
            height: 42px;
            border: 2px solid $--color-dark-gray;
            border-bottom: 0px;
            color: $--color-dark-gray;
            border-radius: 4px 4px 0 0;
            font-size: rem(15px);
            font-weight: 400;
            padding: 6px 24px !important;

            &:hover {
                color: $--color-dark-gray;
                background-color: rgba($--color-dark-gray, 0.1);
                transition: 0.5s all;
            }

            &.is-active {
                border-color: $--color-green;
                color: white !important;
                font-weight: 500;
                font-size: rem(18px);
                height: 51px;
                background-color: $--color-green;
            }
        }
    }

    &__pagination {
        height: auto;
        display: flex;
        justify-content: center;
        margin-top: 0;
    }
}
</style>
