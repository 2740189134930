<template>
    <loader-wrapper :loading="isUpdating" :over-content="true">
        <form-schema
            v-if="!isSubmitted"
            v-model="model"
            :schema="webform.schema"
            :overrides="overrides"
            :ui="webform.ui"
            :buttons="webform.buttons"
            :submit-handler="submitHandler()"
            @input="input"
            @change="change"
            @invalid="invalid"
        />
        <div v-else class="submit-messages">
            <div v-for="(message, i) in submittedMessages" :key="i" class="submit-messages__message">
                {{ message | decodehtml }}
            </div>
        </div>
    </loader-wrapper>
</template>

<script>
import {
    Button,
    Checkbox,
    CheckboxGroup,
    DatePicker,
    Form,
    FormItem,
    Input,
    InputNumber,
    Option,
    Radio,
    RadioGroup,
    Select,
} from 'element-ui';

import FormSchema from '~/components/forms/FormSchema';
import LoaderWrapper from '~/components/LoaderWrapper';

export default {
    components: { FormSchema, LoaderWrapper },
    props: {
        webform: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    schema: {},
                    buttons: [],
                    ui: {},
                };
            },
        },
        input: {
            type: Function,
            default: e => {},
        },
        change: {
            type: Function,
            default: e => {},
        },
        invalid: {
            type: Function,
            default: e => {},
        },
    },
    data: () => ({
        model: {},
        isUpdating: false,
        isSubmitted: false,
        submittedMessages: [],
        overrides: {
            form: {
                component: Form,
                options: ({ vm }) => {
                    // vm is the FormSchema VM
                    const model = vm.flatData;
                    const rules = {};

                    vm.fields.forEach(field => {
                        if (field.type === 'page') {
                            field.fields.forEach(pageField => {
                                rules[pageField.name] = {
                                    required: pageField.required,
                                    message: pageField.title,
                                    trigger: pageField.type === 'input' ? 'blur' : 'change',
                                };
                            });
                        } else if (field.type !== 'markup') {
                            rules[field.name] = {
                                required: field.required,
                                message: field.requiredMessage
                                    ? vm.$root.$options.filters.decodehtml(field.requiredMessage)
                                    : null,
                            };
                        }
                    });
                    // returning the form props
                    return {
                        rules,
                        model,
                        validateOnRuleChange: false,
                    };
                },
            },
            label: {
                component: FormItem,
                options: { prop: null },
                mapping: { prop: 'name' },
            },
            input: { component: Input },
            number: { component: InputNumber },
            textarea: { component: Input },
            date: { component: DatePicker },
            radio: {
                component: Radio,
                itemMapping: { label: 'value', value: null },
            },
            radiogroup: {
                component: RadioGroup,
                options: { excludeLabel: true },
            },
            select: { component: Select },
            option: { component: Option },
            checkbox: {
                component: Checkbox,
                itemMapping: { label: 'value', value: null },
            },
            checkboxgroup: {
                component: CheckboxGroup,
                options: { excludeLabel: true },
            },
            button: {
                component: Button,
                options: {
                    'native-type': 'button',
                    label: 'ASD',
                },
            },
            submitButton: {
                component: Button,
                options: {
                    'native-type': 'submit',
                    label: 'Submit',
                },
            },
            pageChangeButton: {
                component: Button,
                options: {
                    'native-type': 'button',
                },
            },
        },
    }),
    methods: {
        submitHandler() {
            const self = this;
            return (event, fs) => {
                self.isUpdating = true;
                const formComponent = fs.form();
                formComponent.validate(async valid => {
                    if (valid) {
                        try {
                            const response = await fs.$client.execute('webformJsonSchema', 'saveEntry', {
                                id: self.webform.id,
                                body: fs.pagedData,
                                token: self.webform.csrfToken,
                            });
                            if (response.saved) {
                                self.isSubmitted = true;
                                self.submittedMessages = response.userMessages;
                            }
                        } catch (error) {
                            self.isUpdating = false;
                            this.$externalLog.captureException(error);
                        }
                    }
                    self.isUpdating = false;
                });
            };
        },
    },
};
</script>

<style lang="scss">
.el-form {
    .markup {
        margin: 1rem 0;
    }
    .submit-messages {
        font-size: 1.5rem;
        &__message {
            margin: 1rem 0;
        }
    }
    .el-form-item {
        &.is-error {
            .el-radio,
            .el-checkbox {
                color: #f56c6c;
            }
            .el-checkbox__inner,
            .el-radio__inner {
                border-color: #f56c6c;
            }
        }
        &__content {
            small {
                font-size: rem(12px);
                line-height: rem(14px);
                display: block;
                margin-top: 0.5rem;
            }
        }
    }
    .el-form-item__label {
        display: block;
        float: none !important;
        text-align: left !important;
    }
    .el-radio__label,
    .el-checkbox__label {
        font-weight: 400;
        font-size: rem(16px);
    }
    .el-textarea {
        &__inner {
            font-family: inherit !important;
            font-size: rem(14px) !important;
            padding: 8px 14px !important;
        }
    }
    button[type='submit'] {
        background-color: $--color-primary !important;
        color: white !important;
        font-weight: 500;
        &:hover {
            color: white;
            opacity: 0.8;
        }
    }
}
</style>
