'use strict';

const ARRAY_KEYWORDS = ['anyOf', 'oneOf', 'enum'];

const setCommonFields = (schema, field, ui) => {
    field.value = Object.prototype.hasOwnProperty.call(schema, 'default')
        ? schema.default
        : Object.prototype.hasOwnProperty.call(field, 'value')
        ? field.value
        : '';

    field.schemaType = schema.type;
    field.label = schema.title || '';
    field.description = schema.description || '';
    field.required = schema.required || false;
    field.disabled = schema.disabled || false;
    if (schema.required && `${schema.name}` in ui && ui[schema.name]['webform:validationErrorMessages']) {
        if (schema.type === 'array' && ui[schema.name]['webform:validationErrorMessages'].minItems) {
            field.requiredMessage = ui[schema.name]['webform:validationErrorMessages'].minItems;
        } else if (ui[schema.name]['webform:validationErrorMessages'].required) {
            field.requiredMessage = ui[schema.name]['webform:validationErrorMessages'].required;
        }
    }
};

const setFormValue = (vm, field, parentKey) => {
    if (vm.value && !vm.value[field.name]) {
        vm.$set(vm.value, field.name, field.value);
    }
};

export const loadFields = (vm, schema) => {
    if (!schema || schema.visible === false) {
        return;
    }
    let value;

    switch (schema.type) {
        case 'object': {
            const objectChildren = [];
            for (const key in schema.properties) {
                if (Object.prototype.hasOwnProperty.call(schema.properties, key)) {
                    schema.properties[key].name = key;

                    if (schema.required) {
                        for (const field of schema.required) {
                            if (schema.properties[field]) {
                                schema.properties[field].required = true;
                            }
                        }
                    }
                    objectChildren.push(loadFields(vm, schema.properties[key]));
                }
            }
            value = objectChildren;
            break;
        }

        case 'page':
            vm.pages.push(schema.name);
            value = parsePage(vm, schema);
            break;
        case 'boolean':
            value = parseBoolean(vm, schema);
            break;

        case 'array':
            value = parseArray(vm, schema);
            break;

        case 'integer':
        case 'number':
        case 'string': {
            let wasArrayKeywords = false;
            for (const keyword of ARRAY_KEYWORDS) {
                if (Object.prototype.hasOwnProperty.call(schema, keyword)) {
                    wasArrayKeywords = true;
                    schema.items = {
                        type: schema.type,
                    };
                    schema.items[keyword] = schema[keyword];
                    value = parseArray(vm, schema);
                }
            }
            if (!wasArrayKeywords) {
                value = parseString(vm, schema);
            }
            break;
        }

        case 'markup':
            value = parseMarkup(schema);
    }
    return value;
};

export const parsePage = (vm, schema) => {
    const page = {
        title: schema.title,
        pagination_labels: schema.pagination_labels,
        required: schema.required,
        type: 'page',
        id: schema.name,
    };

    const pageChildren = [];
    for (const key in schema.properties) {
        if (Object.prototype.hasOwnProperty.call(schema.properties, key)) {
            schema.properties[key].name = key;

            if (schema.required) {
                for (const field of schema.required) {
                    if (schema.properties[field]) {
                        schema.properties[field].required = true;
                    }
                }
            }
            pageChildren.push(loadFields(vm, schema.properties[key], false));
        }
    }

    page.fields = pageChildren;
    return page;
};

export const parseMarkup = schema => {
    return schema;
};

export const parseBoolean = (vm, schema) => {
    const field = schema.attrs || {};

    setCommonFields(schema, field, vm.ui);

    if (!field.type) {
        field.type = 'checkbox';
    }

    field.checked = schema.checked || false;

    if (schema.name) {
        field.name = schema.name;

        setFormValue(vm, field);
    }

    return field;
};

export const parseString = (vm, schema) => {
    let field = schema.attrs || {};

    if (!field.type) {
        switch (schema.type) {
            case 'number':
            case 'integer':
                field.type = 'number';
                field.value = 0;
                if (schema.minimum !== undefined) {
                    field.min = schema.minimum;
                    field.value = schema.minimum;
                }
                if (schema.maximum !== undefined) field.max = schema.maximum;
                break;
            default:
                field.type = 'text';
        }
    }

    if (schema.format) {
        switch (schema.format) {
            case 'email':
                field.type = 'email';
                break;
            case 'textarea':
                field.type = 'textarea';
                break;
            case 'date':
                field.type = 'date';
                break;
            case 'uri':
                field.type = 'url';
                break;
            case 'regex':
                if (!field.type) {
                    field.type = 'text';
                }

                field.pattern = schema.format;
                break;
        }
    }

    setCommonFields(schema, field, vm.ui);

    field = parseChoices(schema, field);

    if (schema.name) {
        field.name = schema.name;

        setFormValue(vm, field);
    }

    if (schema.minLength) {
        field.minlength = schema.minLength;
    }

    if (schema.maxLength) {
        field.maxlength = schema.maxLength;
    }

    return field;
};

export const parseItems = items => {
    return items.map(item => {
        if (typeof item !== 'object') {
            return { value: item, label: item };
        }

        return item;
    });
};

export const parseArray = (vm, schema) => {
    let field = schema.attrs || {};

    setCommonFields(schema, field, vm.ui);

    field.multiple = schema.minItems > 1;
    field.items = [];

    field = parseChoices(schema.items, field);

    if (schema.name) {
        field.name = schema.name;

        setFormValue(vm, field);
    }

    return field;
};

export const parseChoices = (schema, field) => {
    for (const keyword of ARRAY_KEYWORDS) {
        if (Object.prototype.hasOwnProperty.call(schema, keyword)) {
            switch (keyword) {
                case 'enum':
                    if (!field.type) {
                        field.type = 'select';
                    }
                    field.value = field.value || '';
                    field.items = parseItems(schema[keyword]);
                    break;

                case 'oneOf':
                    field.type = 'radio';
                    field.value = field.value || '';
                    field.items = parseItems(schema[keyword]);
                    break;

                case 'anyOf':
                    field.type = 'checkbox';
                    field.value = field.value || [];
                    field.items = parseItems(schema[keyword]);
                    break;
            }
        }
    }

    return field;
};
