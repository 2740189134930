import BaseResource from '../BaseResource';
import RequestFactory from '../RequestFactory';

class Events extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/events';
    }

    getEvents(filters) {
        const resourcePath = '/events-list';
        return RequestFactory(resourcePath, 'view', {}, filters, null, null);
    }
}

export default Events;
