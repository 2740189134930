<template>
    <NuxtLink
        v-if="
            (url && type === 'internal') ||
                (url && type === 'button' && linkType === 'internal') ||
                (url && type === 'link' && linkType === 'internal')
        "
        :to="url"
    >
        <slot />
    </NuxtLink>
    <a v-else-if="url && type !== 'internal'" :title="title" :href="url" target="_blank" rel="noopener">
        <slot />
        <span class="visuallyhidden">{{ $t('a11y.externalLink') }}</span>
    </a>
    <button v-else :aria-label="title" @click="onClick"><slot /></button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
        },
        linkType: {
            type: String,
            default: '',
        },
        url: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
};
</script>
