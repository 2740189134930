<template>
    <component :is="customLinkWrapperTag" class="link" :class="{ active: lastRoute === to }">
        <span v-if="calculatedExternal" class="link__internal-container">
            <a
                :id="id"
                :tabindex="tabindex"
                :class="{
                    'link__external--underline': underline,
                    'link__external--bold': bold,
                    'link__external--gray': gray,
                    'link__external--dark-gray': darkGray,
                    'link__external--blue': blue,
                    'link__external--white': white,
                    'link__external--black': black,
                    'link__external--primary': primary,
                    'link__external--warning': warning,
                    'link__external--info': info,
                    'link__external--inline': inline,
                }"
                class="link__external"
                :href="toExternal"
                :target="target"
                rel="noopener noreferrer"
                @click="$emit('click')"
                ><span class="link__txt"><slot /></span>
                <i v-if="!hideExternal" class="link__external-icon">
                    &nbsp;
                    <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 18 18"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <g id="Programmation" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g
                                id="Desktop"
                                transform="translate(-1212.000000, -2361.000000)"
                                fill="#FFFFFF"
                                fill-rule="nonzero"
                            >
                                <g id="noun_External_626436" transform="translate(1212.000000, 2361.000000)">
                                    <path
                                        id="Shape"
                                        class="link__svg-path"
                                        :class="{
                                            'link__svg--gray': gray,
                                            'link__svg--dark-gray': darkGray,
                                            'link__svg--blue': blue,
                                            'link__svg--white': white,
                                            'link__svg--black': black,
                                            'link__svg--primary': primary,
                                            'link__svg--warning': warning,
                                            'link__svg--info': info,
                                        }"
                                        d="M17.15625,7.875 C16.929,7.875 16.7265,7.78275 16.5735,7.63875 C16.569,7.63425 16.56225,7.63425 16.55775,7.62975 L14.46075,5.5305 L9.15075,10.8405 C8.60175,11.38725 7.71075,11.38725 7.16175,10.8405 C6.61275,10.2915 6.61275,9.4005 7.16175,8.8515 L12.4695,3.54375 L10.37025,1.44225 C10.36575,1.43775 10.36575,1.431 10.36125,1.4265 C10.21725,1.2735 10.125,1.071 10.125,0.84375 C10.125,0.378 10.503,0 10.96875,0 L16.875,0 C17.496,0 18,0.504 18,1.125 L18,7.03125 C18,7.497 17.622,7.875 17.15625,7.875 Z M2.25,5.0625 L2.25,15.1875 C2.25,15.498 2.502,15.75 2.8125,15.75 L12.9375,15.75 C13.248,15.75 13.5,15.498 13.5,15.1875 L13.5,9 L15.75,11.25 L15.75,15.75 C15.75,16.99425 14.74425,18 13.5,18 L2.25,18 C1.008,18 0,16.99425 0,15.75 L0,4.5 C0,3.258 1.008,2.25 2.25,2.25 L6.75,2.25 L9,4.5 L2.8125,4.5 C2.502,4.5 2.25,4.752 2.25,5.0625 Z"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>
                </i>
            </a>
        </span>

        <span v-if="!calculatedExternal" class="link__internal-container">
            <nuxt-link
                v-if="!calculatedExternal && !noLink"
                :id="id"
                class="link__internal"
                :aria-label="label"
                :tabindex="tabindex"
                :class="{
                    'link__external--underline': underline,
                    'link__external--bold': bold,
                    'link__internal--dark-gray': darkGray,
                    'link__internal--gray': gray,
                    'link__internal--blue': blue,
                    'link__internal--white': white,
                    'link__internal--black': black,
                    'link__internal--primary': primary,
                    'link__external--warning': warning,
                    'link__external--info': info,
                    'link__external--inline': inline,
                    active: lastRoute === to,
                }"
                :to="ensureInternalLinkIsRelative"
                @click.native="e => beforeRouteChange(e, to)"
            >
                <span class="link__txt" :class="{ active: lastRoute === to }"><slot /></span>
            </nuxt-link>

            <button
                v-if="!calculatedExternal && noLink"
                class="link__internal link__internal--nolink"
                tabindex="0"
                type="button"
                :class="{
                    'link__internal--gray': gray,
                    'link__internal--dark-gray': darkGray,
                    'link__internal--blue': blue,
                    'link__internal--white': white,
                    'link__internal--black': black,
                    'link__internal--primary': primary,
                    'link__external--warning': warning,
                    'link__external--info': info,
                    'link__external--inline': inline,
                    active: lastRoute === to,
                }"
            >
                <span
                    class="link__txt"
                    :aria-label="label + ' ' + $t('a11y.link.active')"
                    :class="{ active: lastRoute === to }"
                    ><slot
                /></span>
            </button>
        </span>
    </component>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        external: {
            type: Boolean,
            default: null,
        },
        tabindex: {
            type: Number,
            default: 0,
        },
        noLink: {
            type: Boolean,
            default: false,
        },
        to: {
            type: [String, Object],
            default: null,
        },
        id: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: '',
        },
        primary: {
            type: Boolean,
            default: false,
        },
        warning: {
            type: Boolean,
            default: false,
        },
        info: {
            type: Boolean,
            default: false,
        },
        gray: {
            type: Boolean,
            default: false,
        },
        darkGray: {
            type: Boolean,
            default: false,
        },
        blue: {
            type: Boolean,
            default: false,
        },
        white: {
            type: Boolean,
            default: false,
        },
        black: {
            type: Boolean,
            default: false,
        },
        underline: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        hideExternal: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: '_blank',
        },
        inline: {
            type: Boolean,
            default: false,
        },
        customLinkWrapperTag: {
            type: String,
            default: 'span',
        },
    },

    data() {
        return {
            lang: this.$i18n.locale,
        };
    },

    computed: {
        ...mapState({
            lastRoute: state => state.loading.lastRoute,
        }),
        calculatedExternal() {
            if (this.external !== null || this.to === '#' || this.noLink) return this.external;
            try {
                const url = new URL(this.to);
                return url.host !== process.env.SELF_DOMAIN;
            } catch (e) {
                // It's a part of logic. No need of catch log here
                return false;
            }
        },
        toExternal() {
            let urlString = this.to;
            try {
                const url = new URL(this.to);
                if (url.host === process.env.LONGUEUIL_PORTAL_DOMAIN) {
                    url.searchParams.append('origine', window.location.href);
                    urlString = url.toString();
                }
            } catch (e) {
                // It's a part of logic. No need of catch log here
                urlString = this.to;
            }

            return urlString;
        },
        ensureInternalLinkIsRelative() {
            try {
                const url = new URL(this.to);
                if (url.host === process.env.SELF_DOMAIN) {
                    return url.pathname + url.searchParams;
                }
            } catch (e) {
                // It's a part of logic. No need of catch log here
            }

            return this.to;
        },
    },

    mounted() {
        if (this.lastRoute === null) {
            this.$store.commit('loading/setLastRoute', window.location.pathname);
        }
    },

    methods: {
        beforeRouteChange(e, to) {
            if (!e.ctrlKey && !e.metaKey) {
                if (!this.noLink && this.lastRoute !== to) {
                    this.$store.commit('loading/setIsPageLoad', true);
                    this.$store.commit('menu/closeMainMenu', this.lang);
                    this.$store.commit('loading/setLastRoute', to);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    &__external,
    &__internal {
        position: relative;
        display: block;
        text-decoration: none;

        &:focus {
            z-index: 2;
        }

        &--primary {
            color: $--color-primary;
        }

        &--warning {
            color: $--color-warning;
        }

        &--info {
            color: $--color-info;
        }

        &--gray {
            color: $--color-gray;
        }

        &--dark-gray {
            color: $--color-dark-gray;
        }

        &--blue {
            color: $--color-blue;
        }

        &--white {
            color: $--color-white;
        }

        &--black {
            color: $--color-black;
        }

        &--bold {
            font-weight: bold;
        }

        &--nolink {
            border: none;
            background-color: transparent;
        }

        &:hover:not(.link__internal--nolink) {
            text-decoration: underline;
        }

        &--underline:not(.link__internal--nolink) {
            & .link__txt {
                text-decoration: underline;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &--inline {
            display: inline;
        }
    }

    &__external {
        width: 100%;
        height: 100%;
    }

    &__external-icon {
        position: relative;
        transform: translateY(-10%);
        white-space: nowrap;
    }

    &__txt {
        &.active {
            font-weight: 600;
        }
    }

    &__svg {
        width: 0.8em;
        height: 0.8em;
        margin-top: -4px;

        &--primary {
            fill: $--color-primary;
        }

        &--warning {
            fill: $--color-warning;
        }

        &--info {
            fill: $--color-info;
        }

        &--gray {
            color: $--color-gray;
        }

        &--blue {
            color: $--color-blue;
        }

        &--white {
            fill: $--color-white;
        }

        &--black {
            fill: $--color-black;
        }

        &--path {
            line-height: normal;
            text-indent: 0;
            text-align: start;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-transform: none;
            isolation: auto;
            mix-blend-mode: normal;
            font-weight: 400;
            white-space: normal;
            overflow: visible;
        }
    }
}
</style>
