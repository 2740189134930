import { mapActions, mapState } from 'vuex';

import { lazyLoadImage } from '~/utils/image.js';
import ScrollObserver from '~/utils/scroll.js';

export default {
    computed: {
        ...mapState('locomotive-global', ['hasScrolled', 'isScrollingDown']),
    },
    mounted() {
        this.bindScrollObserver();
    },
    beforeDestroy() {
        this.unbindScrollObserver();
    },
    methods: {
        ...mapActions('locomotive-global', ['setHasScrolled', 'setIsScrollingDown']),

        bindScrollObserver() {
            if (!process.client) return;

            this.scrollObserver = new ScrollObserver({
                el: this.$el,
                rootMargin: '20% 0% -10% 0%',
                hasScrollEvent: true,
                scrollCallback: this.onScroll.bind(this),
            });

            this.scrollObserver.on('call', (func, way, obj) => {
                switch (func) {
                    case 'lazyLoad':
                        this.lazyLoad(obj);
                        break;
                }
            });
        },

        unbindScrollObserver() {
            if (!process.client) return;

            this.scrollObserver && this.scrollObserver.destroy();
            this.scrollObserver = null;
        },

        onScroll() {
            if (this.scrollObserver.scrollDirection === 'down') {
                document.documentElement.classList.add('is-scrolling-down');
                if (!this.isScrollingDown) {
                    this.setIsScrollingDown(true);
                }
            } else {
                document.documentElement.classList.remove('is-scrolling-down');
                if (this.isScrollingDown) {
                    this.setIsScrollingDown(false);
                }
            }

            if (window.scrollY > 80) {
                document.documentElement.classList.add('has-scrolled');
                if (!this.hasScrolled) {
                    this.setHasScrolled(true);
                }
            } else {
                document.documentElement.classList.remove('has-scrolled');
                if (this.hasScrolled) {
                    this.setHasScrolled(false);
                }
            }
        },

        lazyLoad(obj) {
            lazyLoadImage(obj.target, null, () => {});
        },
    },
};
