import { cloneDeep } from 'lodash';

const initTranslatePath = () => ({
    uuid: null,
    pathBefore: null,
    newPath: null,
    lastPath: {
        en: null,
        fr: null,
    },
    isSwitchLangAction: false,
    resultsCombined: {},
    isTranslationMissing: false,
});

export const state = initTranslatePath;

export const mutations = {
    setLastPath(state, { lang, translatePath }) {
        state.lastPath[lang] = cloneDeep(translatePath);
    },

    setNewPath(state, newPath) {
        state.newPath = cloneDeep(newPath);
    },

    setUuid(state, uuid) {
        state.uuid = cloneDeep(uuid);
    },

    setPathObject(state, path) {
        state.pathObject = cloneDeep(path);
    },

    setSwitchLangAction(state, setSwitchLangAction) {
        state.isSwitchLangAction = cloneDeep(setSwitchLangAction);
    },

    setTranslationMissing(state, isMissing) {
        state.isTranslationMissing = cloneDeep(isMissing);
    },

    reset(state) {
        const initState = initTranslatePath();
        Object.keys(initState).forEach(key => {
            state[key] = initState[key];
        });
    },
};
