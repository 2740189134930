<template>
    <list-with-filter
        class="rules-with-pdf"
        taxonomy-type="service_type"
        :title="item.title"
        :search-bar-placeholder="$t('rules.findRules')"
        hide-filter-header
    >
        <template>
            <wysiwyg
                v-if="item.body && item.body.processed"
                slot="top"
                :body="item.body.processed"
                class="item__wysiwyg"
            />
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import ListWithFilter from '~/components/layout/ListWithFilter.vue';
import Wysiwyg from '~/components/Wysiwyg';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        Wysiwyg,
        ListWithFilter,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            isUpdating: false,
            searchFilter: '',
        };
    },

    asyncData({ app, query, store, params, error, env }, item) {
        return { item };
    },
};
</script>

<style lang="scss">
.rules-with-pdf {
    margin-bottom: 0px;

    @include media(sm) {
        margin-bottom: 68px;
    }

    .filter-accordion-header {
        @include media(sm) {
            margin-bottom: 0;
        }
    }

    .card__image-container {
        max-width: 320px;
    }

    .filterButton .el-button {
        margin-left: 0px;
    }

    .title__htag--h1 {
        font-size: rem(25px);
        margin-top: 48px;
        margin-bottom: 24px;

        @include media(sm) {
            font-size: rem(42px);
            margin-top: 27px;
            margin-bottom: 27px;
        }
    }

    .list-with-filter__content > .loader-wraper {
        margin-left: 8px;
    }
}

.service {
    &__section {
        &__item {
            margin-bottom: 2em;
            &__title {
            }
            &__body {
            }
        }

        &__title {
            font-size: rem(16px);
            font-weight: 500;
            color: $--color-primary;

            @include media(sm) {
                font-size: rem(18px);
                font-weight: 700;
            }

            a.link__txt {
                font-weight: 700 !important;
            }
        }

        &__excerpt {
            font-size: rem(13px);
            font-weight: 400;
            margin-top: 2px;
            line-height: 1.6em;

            @include media(sm) {
                font-size: rem(16px);
            }
        }

        &__header {
            font-size: rem(18px);
            color: $--color-info;
            font-weight: 700;
            margin-bottom: 17px;

            @include media(sm) {
                font-size: rem(24px);
                margin-bottom: 15px;
            }
        }

        &__content {
            margin-bottom: 28px;

            @include media(sm) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 48px;
            }
        }
    }

    &__title {
        margin-bottom: 10px;

        a {
            font-size: rem(18px);
            font-weight: 600;
        }
    }
}
</style>
