import Vue from 'vue';
import VueI18n from 'vue-i18n';

import 'array-from-polyfill';

Vue.use(VueI18n);

export default ({ app }, inject) => {
    const h = level => `h${level}`;
    const utils = {
        cleanify: obj => {
            return obj
                .normalize('NFD')
                .replace(/[\u0300-\u036F]/g, '')
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase();
        },
        sortBy: (obj, key) => {
            return obj.sort(function(a, b) {
                const nameA = a[key].toUpperCase(); // ignore upper and lowercase
                const nameB = b[key].toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                    return -1; // nameA comes first
                }
                if (nameA > nameB) {
                    return 1; // nameB comes first
                }
                return 0; // names must be equal
            });
        },
        sortByOrder: (obj, key) => {
            return obj.sort(function(a, b) {
                if (a[key] < b[key]) {
                    return -1; // nameA comes first
                }
                if (a[key] > b[key]) {
                    return 1; // nameB comes first
                }
                return 0; // names must be equal
            });
        },
        capitalizeFirstLetter: string => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        scrollToElement(element, yPadding = 0) {
            const rect = element.getBoundingClientRect();
            const windowY = window.scrollY;
            window.scroll({
                top: windowY + rect.top + yPadding,
                left: 0,
                behavior: 'smooth',
            });
        },
    };

    inject('utils', utils);
    inject('h', h);
};
