<script>
import { each, find, flatten, isEmpty } from 'lodash';
import { loadFields } from '~/components/forms/lib/parser';

const options = { native: true };
const NONVALUEFIELDTYPES = ['markup'];

export default {
    name: 'FormSchema',
    props: {
        /**
         * The JSON Schema object. Use the `v-if` directive to load asynchronous schema.
         */
        schema: { type: Object, required: true },

        /**
         *  Usually contains error messages and other data for the UI.
         **/
        ui: { type: Object, default: () => {}, required: false },
        /**
         * The Form buttons
         */
        buttons: { type: Array, default: () => [] },

        /**
         * Object containing component overrides
         **/
        overrides: { type: Object, required: false, default: () => ({}) },

        /**
         * Use this directive to create two-way data bindings with the component. It automatically picks the correct way to update the element based on the input type.
         * @model
         * @default {}
         */
        value: { type: Object, default: () => ({}) },

        /**
         * This property indicates whether the value of the control can be automatically completed by the browser. Possible values are: `off` and `on`.
         */
        autocomplete: { type: String, default: 'off' },

        /**
         * This Boolean attribute indicates that the form is not to be validated when submitted.
         */
        novalidate: { type: Boolean },

        /**
         * Define the inputs wrapping class. Leave `undefined` to disable input wrapping.
         */
        inputWrappingClass: { type: String, default: undefined },

        submitHandler: { type: Function, default: (event, self) => {} },
    },
    data: () => ({
        default: {},
        pages: [],
        activePageIndex: 0,
        fields: [],
        error: null,
        flatData: {},
        pagedData: {},
        components: {
            title: { component: 'h3', options },
            description: { component: 'p', options },
            error: { component: 'div', options },
            form: { component: 'form', options },
            file: { component: 'input', options: { ...options, type: 'file' } },
            label: { component: 'label', options },
            input: { component: 'input', options },
            number: { component: 'input', options: { ...options, type: 'number' } },
            date: { component: 'input', options: { ...options, type: 'date' } },
            radio: {
                component: 'input',
                options: {
                    ...options,
                    type: 'radio',
                },
            },
            checkbox: {
                component: 'input',
                options: {
                    ...options,
                    type: 'checkbox',
                },
            },
            select: { component: 'select', options },
            option: { component: 'option', options },
            button: {
                component: 'button',
                options: {
                    ...options,
                    type: 'button',
                    label: 'Button',
                },
            },
            submitButton: {
                component: 'button',
                options: {
                    ...options,
                    type: 'submit',
                    label: 'Submit',
                },
            },
            pageChangeButton: {
                component: 'button',
                options: {
                    ...options,
                    type: 'button',
                },
            },
            textarea: { component: 'textarea', options },
            radiogroup: { component: 'div', options },
            checkboxgroup: { component: 'div', options },
            defaultGroup: { component: 'div', options },
        },
    }),
    created() {
        const cleanedFields = JSON.parse(JSON.stringify(this.schema));
        this.fields = loadFields(this, cleanedFields, false);
        this.default = { ...this.value };
        this.flatData = { ...this.value };
        this.setComponentsOverrides();
        this.setPagedFields();
    },
    mounted() {
        this.reset();
    },
    methods: {
        /**
         * @private
         * Builds the value object including pagination depth.
         **/
        setPagedFields() {
            each(this.fields, field => {
                if (field.type === 'page') {
                    const pageFields = {};
                    each(field.fields, pageField => {
                        if (!NONVALUEFIELDTYPES.includes(pageField.type)) {
                            pageFields[pageField.name] = this.flatData[pageField.name];
                        }
                    });
                    this.pagedData[field.id] = pageFields;
                } else if (!NONVALUEFIELDTYPES.includes(field.type)) {
                    this.pagedData[field.name] = this.flatData[field.name];
                }
            });
        },

        /**
         * @private
         * Overrides the default components with these specified in the overrides prop.
         **/
        setComponentsOverrides() {
            each(this.overrides, (config, type) => {
                this.setComponent(type, { ...config });
            });
        },
        /**
         * @private
         * Set the props to define a component.
         **/
        setComponent(type, { component, options = {}, ...otherOptions }) {
            this.components[type] = { component, options, ...otherOptions };
        },
        /**
         * @private
         * Returns the options of a component. Can be a function to be executed.
         */
        optionValue(field, target, item = {}) {
            return typeof target === 'function' ? target({ vm: this, field, item }) : target;
        },

        /**
         * @private
         * Builds all props or attributes to be used in the createElement method when creating a component.
         */
        elementOptions(element, extendingOptions = {}, field = {}, item = {}, mapping = {}) {
            // attrs = native html element, props = vue component.
            const attrName = element.options.native ? 'attrs' : 'props';
            const elementProps =
                typeof element.options === 'function' ? element.options : { ...element.options, native: undefined };
            const elementOptions = this.optionValue(field, elementProps, item);
            const allOptions = { ...extendingOptions, ...elementOptions };
            const mappedOptions = {};
            Object.keys(allOptions).map(function(key) {
                mappedOptions[key] = Object.prototype.hasOwnProperty.call(mapping, key)
                    ? allOptions[mapping[key]]
                    : allOptions[key];
            });
            return { [attrName]: { ...mappedOptions } };
        },

        /**
         * @private
         * Fired when a change to the element's value is committed by the user.
         */
        changed(e) {
            this.error = null;
            this.$emit('change', e);
        },

        /**
         * Get a form input reference
         */
        input(name) {
            if (!this.$refs[name]) {
                throw new Error(`Undefined input reference '${name}'`);
            }
            return this.$refs[name][0];
        },

        /**
         * Get the form reference
         */
        form() {
            return this.$refs.__form;
        },

        /**
         * Checks whether the form has any constraints and whether it satisfies them. If the form fails its constraints, the browser fires a cancelable `invalid` event at the element, and then returns false.
         */
        checkValidity() {
            if (this.components.form.options.native === true) {
                return this.$refs.__form.checkValidity();
            }
            return true;
        },

        /**
         * @private
         */
        invalid(e) {
            /**
             * Fired when a submittable element has been checked and doesn't satisfy its constraints. The validity of submittable elements is checked before submitting their owner form, or after the `checkValidity()` of the element or its owner form is called.
             */
            this.$emit('invalid', e);
        },

        /**
         * Reset the value of all elements of the parent form.
         */
        reset() {
            for (const key in this.default) {
                this.$set(this.flatData, key, this.default[key]);
            }
            this.setPagedFields();
        },

        /**
         * Send the content of the form to the server
         */
        submit(event) {
            if (this.checkValidity()) {
                this.submitHandler(event, this);
            }
        },

        validateActivePage() {
            const pageId = this.pages[this.activePageIndex];
            const pageField = find(this.fields, { id: pageId });
            const errors = [];

            each(pageField.required, elementName => {
                if (isEmpty(this.flatData[elementName])) {
                    errors.push(elementName);
                }
            });

            if (!isEmpty(errors)) {
                this.setErrorMessage('Veuillez corriger les erreurs suivantes: ' + errors.join(','));
                return false;
            }
            this.clearErrorMessage();
            return true;
        },

        nextPage() {
            if (this.validateActivePage() && this.activePageIndex + 1 !== this.pages.length) {
                this.activePageIndex++;
            }
        },

        previousPage() {
            if (this.activePageIndex !== 0) {
                this.activePageIndex--;
            }
        },

        /**
         * Set a message error.
         */
        setErrorMessage(message) {
            this.error = message;
        },

        /**
         * clear the message error.
         */
        clearErrorMessage() {
            this.error = null;
        },
    },
    render(createElement) {
        const nodes = [];

        // Set Form title
        if (this.schema.title) {
            nodes.push(createElement(this.components.title.component, this.schema.title));
        }

        // Set Form description
        if (this.schema.description) {
            nodes.push(createElement(this.components.description.component, this.schema.description));
        }

        // Build Error messages
        if (this.error && !isEmpty(this.error)) {
            const errorOptions = this.elementOptions(this.components.error);
            const errorNodes = [];

            if (this.components.error.options.native) {
                errorNodes.push(this.error);
            }

            nodes.push(createElement(this.components.error.component, errorOptions, errorNodes));
        }

        /**
         * Builds a field with createElement
         **/
        const buildField = (field, pageId = null) => {
            // Set Default Values
            if (!field.value) {
                field.value = this.value[field.name];
            }

            if (field.type === 'markup') {
                return createElement('div', {
                    domProps: {
                        className: 'markup',
                        innerHTML: field.markup,
                    },
                });
            }

            // Define whas is the element to use (not the actual component, yet) - also defines if it's a group or not (for checkbox or radio)
            const element =
                Object.prototype.hasOwnProperty.call(field, 'items') && field.type !== 'select'
                    ? this.components[`${field.type}group`] || this.components.defaultGroup
                    : this.components[field.type] || this.components.input;
            // Get the set options for this type of element
            const fieldOptions = this.elementOptions(element, field, field, null, element.mapping);
            const children = [];
            const hasMultitpleElements = false;

            // Define mandatory configuration to be injected in the data object of createElement
            const input = {
                ref: field.name,
                domProps: {
                    value: this.value[field.name],
                },
                on: {
                    input: event => {
                        const value = event && event.target ? event.target.value : event;
                        // Set the data in the flat object (used for validation & models)
                        this.$set(this.flatData, field.name, value);
                        // Set the data in the paginated object (when pages are in use, the submitted data must respect the page hierarchy)
                        this.setPagedFields();
                        // Fired synchronously when the value of an element is changed. Emitting an input event will update the parent component, because it is binded with the model.
                        this.$emit('input', this.flatData);
                    },
                    change: this.changed,
                },
                ...fieldOptions,
            };

            delete field.value;

            switch (field.type) {
                case 'textarea':
                    if (element.options.native) {
                        input.domProps.innerHTML = this.value[field.name];
                    }
                    break;

                case 'radio':
                case 'checkbox':
                    if (Object.prototype.hasOwnProperty.call(field, 'items')) {
                        field.items.forEach(item => {
                            const isInFieldGroup =
                                Object.prototype.hasOwnProperty.call(field, 'items') && field.type !== 'select';
                            const itemOptions = this.elementOptions(
                                this.components[field.type],
                                item,
                                item,
                                item,
                                this.components[field.type].itemMapping || {},
                            );
                            if (isInFieldGroup && !this.components[`${field.type}group`].options.excludeLabel) {
                                const labelOptions = this.elementOptions(this.components.label, item, item);
                                children.push(
                                    createElement(this.components.label.component, labelOptions, item.label),
                                    createElement(this.components[field.type].component, itemOptions),
                                );
                            } else {
                                children.push(
                                    createElement(this.components[field.type].component, itemOptions, item.label),
                                );
                            }
                        });
                    }
                    break;

                case 'select':
                    field.items.forEach(option => {
                        const optionOptions = this.elementOptions(
                            this.components.options,
                            {
                                label: option.label,
                                value: option.value,
                            },
                            field,
                        );
                        children.push(
                            createElement(
                                this.components.options.component,
                                {
                                    domProps: {
                                        value: option.value,
                                    },
                                    ...optionOptions,
                                },
                                option.label,
                            ),
                        );
                    });
                    break;
            }

            const inputElement = hasMultitpleElements
                ? createElement(element.component, input, children)
                : createElement(element.component, input, children);
            const formControlsNodes = [];

            if (field.label && !element.options.disableWrappingLabel) {
                const labelOptions = this.elementOptions(
                    this.components.label,
                    field,
                    field,
                    null,
                    this.components.label.mapping,
                );
                const labelNodes = [];

                if (this.components.label.options.native) {
                    labelNodes.push(
                        createElement(
                            'span',
                            {
                                attrs: {
                                    'data-required-field': field.required ? 'true' : 'false',
                                },
                            },
                            field.schemaType === 'boolean' ? null : field.label,
                        ),
                    );
                }

                labelNodes.push(inputElement);
                if (field.description) {
                    labelNodes.push(createElement('small', this.$root.$options.filters.decodehtml(field.description)));
                }
                if (field.schemaType === 'boolean') {
                    labelOptions.props.label = null;
                }
                formControlsNodes.push(createElement(this.components.label.component, labelOptions, labelNodes));
            } else {
                formControlsNodes.push(inputElement);

                if (field.description) {
                    formControlsNodes.push(
                        createElement('small', this.$root.$options.filters.decodehtml(field.description)),
                    );
                }
            }

            if (this.inputWrappingClass) {
                return createElement(
                    'div',
                    {
                        class: this.inputWrappingClass,
                    },
                    formControlsNodes,
                );
            } else {
                return formControlsNodes;
            }
        };

        if (this.fields.length) {
            const formNodes = [];

            // Loop through all fieds
            this.fields.forEach(field => {
                if (field.type === 'page') {
                    const fields = field.fields.map(pageField => {
                        return flatten([buildField(pageField, field.id)]);
                    });

                    if (field.pagination_labels.previous_button_label !== null) {
                        fields.push(
                            createElement(
                                this.components.pageChangeButton.component,
                                {
                                    on: {
                                        click: this.previousPage,
                                    },
                                    attrs: {
                                        type: 'button',
                                    },
                                },
                                field.pagination_labels.previous_button_label,
                            ),
                        );
                    }

                    if (field.pagination_labels.next_button_label !== null) {
                        fields.push(
                            createElement(
                                this.components.pageChangeButton.component,
                                {
                                    on: {
                                        click: this.nextPage,
                                    },
                                    attrs: {
                                        type: 'button',
                                    },
                                },
                                field.pagination_labels.next_button_label,
                            ),
                        );
                    }

                    formNodes.push(
                        createElement(
                            'div',
                            {
                                // Same API as `v-bind:class`, accepting either
                                // a string, object, or array of strings and objects.
                                class: {
                                    formPage: true,
                                    active: this.activePageIndex === this.pages.indexOf(field.id),
                                },
                            },
                            fields,
                        ),
                    );
                } else {
                    formNodes.push(flatten([buildField(field)]));
                }
            });

            // Create Form Buttons
            this.buttons.forEach(buttonSchema => {
                const button = this.components.submitButton;
                const buttonOptions = this.elementOptions(button);
                formNodes.push(createElement(button.component, buttonOptions, buttonSchema.label));
            });
            const formOptions = this.elementOptions(this.components.form, {
                autocomplete: this.autocomplete,
                novalidate: this.novalidate,
                ref: '__form',
            });
            const formOnKey = this.components.form.options.native ? 'on' : 'nativeOn';
            nodes.push(
                createElement(
                    this.components.form.component,
                    {
                        ref: '__form',
                        ...formOptions,
                        [formOnKey]: {
                            submit: e => {
                                e.preventDefault();
                                this.submit(e);
                            },
                        },
                    },
                    formNodes,
                ),
            );
        }
        return createElement('div', nodes);
    },
};
</script>

<style lang="scss">
.formPage {
    display: none;
    &.active {
        display: block;
    }
}
</style>
