<template>
    <div>
        <el-dropdown
            v-if="isPopup"
            :ref="`dropdown-${id}`"
            trigger="click"
            :placement="placement"
            @visible-change="
                val => {
                    clickEventHandler(val);
                    visible = val;
                }
            "
        >
            <button :class="[{ 'is-visible': visible }, `dropdown__button dropdown__button-${id}`]">
                <slot name="button">{{ triggerText }} <span class="dropdown__arrow"></span></slot>
            </button>
            <el-dropdown-menu
                slot="dropdown"
                :class="`dropdown__popup dropdown__popup-${id}`"
                :style="{ width: `${width}px` }"
            >
                <slot name="content" :isAcc="false">
                    <el-dropdown-item
                        v-for="(menuItem, index) in itemList"
                        :key="`${$slugify(triggerText)}-${index}`"
                        class="dropdown__list-el"
                        :class="{ 'has-arrow': hasArrow }"
                    >
                        <slot name="link" :menu-item="menuItem">
                            <custom-link
                                v-if="menuItem.attributes"
                                class="dropdown__link"
                                :to="menuItem.attributes.link.uri"
                                primary
                            >
                                {{ menuItem.attributes.link.title }}
                            </custom-link>
                        </slot>
                    </el-dropdown-item>
                </slot>
            </el-dropdown-menu>
        </el-dropdown>
        <accordion-item v-else :title="$slugify(triggerText)" tag="div" class="dropdown__acc">
            <template slot="acc-header" slot-scope="{ isOpen }">
                <div class="dropdown__button" :class="{ 'is-visible': isOpen }">
                    {{ triggerText }} <span class="dropdown__arrow"></span>
                </div>
            </template>
            <ul slot="acc-content" class="dropdown__acc-list">
                <slot name="content" :isAcc="true">
                    <li
                        v-for="(menuItem, index) in itemList"
                        :key="`${$slugify(triggerText)}-${index}`"
                        class="dropdown__list-el"
                        :class="{ 'has-arrow': hasArrow }"
                    >
                        <slot name="acc-link" :menu-item="menuItem">
                            <custom-link
                                v-if="menuItem.attributes"
                                class="dropdown__link"
                                :to="menuItem.attributes.link.uri"
                                primary
                            >
                                {{ menuItem.attributes.link.title }}
                            </custom-link>
                        </slot>
                    </li>
                </slot>
            </ul>
        </accordion-item>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AccordionItem from '~/components/AccordionItem';
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        AccordionItem,
        CustomLink,
    },

    props: {
        isPopup: {
            type: Boolean,
            default: true,
        },
        triggerText: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        placement: {
            type: String,
            default: 'bottom-end',
        },
        itemList: {
            type: Array,
            default() {
                return [];
            },
        },
        hasArrow: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 300,
        },
        clickEventHandler: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            visible: false,
            menuFocusedElement: null,
        };
    },

    computed: {
        ...mapState({
            menu: state => state.menu.menu,
        }),
    },

    mounted() {
        window.addEventListener('keydown', this.changeState);
    },

    methods: {
        changeState(e) {
            const keyCode = e.keyCode ? e.keyCode : false;
            if (document.querySelectorAll(`.dropdown__button-${this.id}`).length > 0) {
                const menuClasses = document.querySelectorAll(`.dropdown__button-${this.id}`)[0].className;

                if (keyCode === 9 && this.id) {
                    try {
                        if (menuClasses === `is-visible dropdown__button dropdown__button-${this.id}`) {
                            this.showNextElement(e.shiftKey);
                            this.$refs[`dropdown-${this.id}`].show();
                            e.preventDefault();
                        } else this.menuFocusedElement = null;
                    } catch (e) {
                        // No need of log here
                    }
                }
            }
        },
        showNextElement(isWithShitKey) {
            const menuElements = document.querySelectorAll(
                `.dropdown__popup-${this.id} button, .dropdown__popup-${this.id} a, .dropdown__popup-${this.id} [tabindex='1']`,
            );
            const elLength = menuElements.length;

            if (this.menuFocusedElement === null) this.menuFocusedElement = 0;
            else if (isWithShitKey) this.menuFocusedElement -= 1;
            else this.menuFocusedElement += 1;

            let elToFocus = menuElements[this.menuFocusedElement];

            if (this.menuFocusedElement >= elLength || this.menuFocusedElement < 0) {
                elToFocus = this.closeMenu();
            }
            elToFocus.focus();
        },
        closeMenu() {
            this.menuFocusedElement = null;
            document.querySelectorAll(`.dropdown__button-${this.id}`)[0].classList.remove('is-visible');
            this.$refs[`dropdown-${this.id}`].hide();
            return document.querySelectorAll(`.dropdown__button-${this.id}`)[0];
        },
    },
};
</script>
<style lang="scss" scoped>
.dropdown {
    &__popup {
        padding: rem(40px);
    }

    &__button {
        background-color: transparent;
        color: $--color-black;
        border: none;
        width: 100%;
        font-size: rem(13px);
        font-weight: 500;
        cursor: pointer;
        text-align: left;
        padding: 10px;
        padding-right: 0;

        &.el-button {
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        @include media(md) {
            padding: 0;
            padding-left: 10px;
        }
    }
}
</style>

<style lang="scss">
.dropdown {
    &__arrow {
        position: relative;
        top: rem(-1px);
        margin-left: rem(4px);
        display: inline-block;
        width: rem(9px);
        height: rem(6px);
        background-image: url('~assets/img/gray-arrow.svg');
        transition: 0.5s all;
        .is-visible & {
            transform: rotate(180deg);
        }
    }
    &__acc .dropdown__acc-list {
        padding: 0 rem(10px);
    }

    &__list-el {
        display: flex;
        align-items: flex-end;
        padding: 0;

        &:hover,
        &:focus {
            background-color: transparent !important;

            a,
            button {
                cursor: pointer;
                text-decoration: underline !important;
            }
        }

        &:not(:first-child) {
            .link__internal-container a,
            .link__internal-container button,
            .link__external {
                padding-top: 15px !important;
            }

            a::after {
                top: calc(50% + 7px) !important;
            }
        }

        &:not(:last-of-type) {
            .link__internal-container a,
            .link__internal-container button,
            .link__external {
                border-bottom: 1px solid #e3e3e3;
                padding-bottom: 15px !important;
            }

            a::after {
                padding-bottom: 15px;
            }
        }

        &.has-arrow {
            a {
                &::after {
                    content: '';
                    height: 9px;
                    width: 6px;
                    background-image: url('~assets/img/arrow.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.5s all;
                }

                &:hover {
                    &:after {
                        transform: translateY(-50%) translateX(-3px);
                    }
                }
            }
        }

        .link__internal-container,
        .link__internal-container a,
        .link__internal-container button,
        .link__external {
            display: block;
            text-align: left;
            padding: 0;
            width: 100%;

            a,
            button {
                font-weight: 600;
                font-size: rem(13);
                line-height: 1;
            }
        }

        .link__external {
            font-weight: 600;
            font-size: rem(13);
            line-height: 1;
        }

        .link__internal,
        .link__external {
            text-decoration: none !important;
        }
    }

    &__link {
        width: 100%;
    }
}
</style>
