import BaseResource from '../BaseResource';
import ByPathRequestFactory from '../ByPathRequestFactory';

class ServiceByHubService extends BaseResource {
    constructor(ctx) {
        super();
        this.resourcePath = '/services-by-hubservice';
        this.app = ctx.app;
    }

    getIndividual(serviceTypes) {
        let serviceTypesUrl = '';

        serviceTypes.forEach((el, index) => {
            serviceTypesUrl += `service_type[]=${el.drupal_internal__tid}`;
            if (serviceTypes.length > index + 1) serviceTypesUrl += '&';
        });

        const uri = `${this.resourcePath}?${serviceTypesUrl}`;

        const result = ByPathRequestFactory(uri);
        result._type = null; // important
        return result;
    }

    getCollection() {
        throw new Error('Method not supported');
    }
}

export default ServiceByHubService;
