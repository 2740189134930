<template>
    <list-with-filter
        class="service-list-page"
        taxonomy-type="service_type"
        :title="$t('services.title')"
        :facets="facets"
        :filter-values="filterValues"
        :search-bar-placeholder="$t('services.findService')"
        :total-count="totalCount"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        hide-filter-header
        :intro="pageIntro"
        @filterUpdate="filterUpdateHandler"
    >
        <template>
            <loader-wrapper :loading="isUpdating">
                <section v-if="Object.keys(services).length > 0" class="service__section">
                    <div v-for="(service, section) in services" :key="section">
                        <div class="service__section__header">
                            {{ section }}
                        </div>
                        <article v-for="n in service" :key="n.entity_id" class="service__section__content">
                            <custom-link class="service__section__title" :to="n.entity_alias" primary>
                                {{ n.entity_title | decodehtml }}
                            </custom-link>
                            <p class="service__section__excerpt">
                                {{ n.summary | striphtml }}
                            </p>
                        </article>
                    </div>
                </section>
                <section v-else class="service__section">
                    {{ $t('filter.noResult') }}
                </section>
            </loader-wrapper>
        </template>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter.vue';
import LoaderWrapper from '~/components/LoaderWrapper';

const parseServicesFromResponseResults = results => {
    const organizedArray = {};
    results.forEach(service => {
        service.service_groups.forEach(t => {
            if (!organizedArray[t]) {
                organizedArray[t] = [];
            }
            organizedArray[t].push(service);
        });
    });
    return organizedArray;
};

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },

    components: {
        CustomLink,
        ListWithFilter,
        LoaderWrapper,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            services: initData.services,
            filterValues: initData.filterValues,
            facets: initData.facets,
            allFacetsBySlug: initData.allFacetsBySlug,
            itemsPerPage: initData.itemsPerPage,
            totalCount: initData.totalCount,
            page: parseInt(this.$route.query.page) || 1,
            isUpdating: false,
            searchFilter: '',
        };
    },

    computed: {
        pageIntro() {
            return this.initData.item.body != null ? this.initData.item.body.processed : null;
        },
    },
    watch: {
        '$route.query.service'(to, from) {
            if (to !== from) {
                this.filterValues.byType = [this.allFacetsBySlug[to]];
                this.filterValues = cloneDeep(this.filterValues);
                this.updateServices();
            }
        },
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        page() {
            this.updateServices(true);
        },
    },

    async asyncData({ app, query, store, params, error, env }, item, routeInfo) {
        // Parsed services by facet
        let services = {};
        const facets = [];
        let totalCount = 0;
        let itemsPerPage = 50;

        // All facets with slug name, used to set facets from query parameter
        const allFacetsBySlug = {};

        // Applied filters to be sent down to FilterForm.
        const filterValues = {
            byTitle: '',
            byDate: 'default',
            byDateRange: [],
            byType: [],
        };

        // Set SOLR search URL
        const path =
            process.env.CMS_API_ENDPOINT +
            '/' +
            app.i18n.locale +
            `/api/search-services?search=&page=${query.page && query.page > 0 ? query.page - 1 : 0}&items_per_page=50`;
        try {
            // First, get all content to get all Facets
            const allResponse = await app.$client.execute('solrSearch', 'getDatas', {
                uri: path,
            });

            // If there are facets in the dataset
            if (allResponse.data.facets.types_service && allResponse.data.facets.types_service.length > 0) {
                // Populate all the facets, node value + label
                allResponse.data.facets.types_service.forEach(type => {
                    const title = app.$slugify(type.values.label);
                    allFacetsBySlug[title] = type.values.value;
                });

                // Get updated results if query parameter is present and exists
                if (query.service && allFacetsBySlug[query.service]) {
                    const parameters = {
                        facets: [allFacetsBySlug[query.service]],
                    };
                    const pathWithQuery = app.$solrUrlBuilder.buildUri(path, parameters);

                    const response = await app.$client.execute('solrSearch', 'getDatas', {
                        uri: pathWithQuery,
                    });
                    allResponse.data = response.data;
                    allResponse.pager = response.pager;
                    filterValues.byType = [allFacetsBySlug[query.service]];
                }
            }

            // Wrap services under facets (for presentation);
            services = parseServicesFromResponseResults(allResponse.data.results);
            if (allResponse.data.facets) {
                for (const [facetId, filters] of Object.entries(allResponse.data.facets)) {
                    const facet = { facetId, filters: [] };
                    for (const [key, f] of Object.entries(filters)) {
                        facet.filters.push({
                            id: key,
                            value: f.values.value,
                            name: f.values.label,
                            active: f.values.active ? f.values.active === 'true' : false,
                        });
                    }
                    facets.push(facet);
                }
            }
            totalCount = parseInt(allResponse.data.pager.count);
            itemsPerPage = parseInt(allResponse.data.pager.items_per_page);
        } catch (e) {
            app.$externalLog.captureException(e);
        }
        return {
            item,
            services,
            allFacetsBySlug,
            facets,
            filterValues,
            totalCount,
            itemsPerPage,
        };
    },

    methods: {
        async updateServices(pageOnly = false) {
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;

            const path = process.env.CMS_API_ENDPOINT + '/' + this.$i18n.locale + `/api/search-services?search=`;

            const parameters = {
                search: this.filterValues.byTitle + `&page=${this.page > 0 ? this.page - 1 : 0}&items_per_page=50`,
                facets: this.filterValues.byType,
            };

            const response = await this.$client.execute('solrSearch', 'getDatas', {
                uri: this.$solrUrlBuilder.buildUri(path, parameters),
            });

            this.services = parseServicesFromResponseResults(response.data.results);
            this.totalCount = parseInt(response.data.pager.count);
            this.itemsPerPage = parseInt(response.data.pager.items_per_page);
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updateServices();
        },
    },
};
</script>

<style lang="scss">
.service-list-page {
    margin-bottom: 0px;

    @include media(sm) {
        margin-bottom: 68px;
    }

    .filter-accordion-header {
        @include media(sm) {
            margin-bottom: 0;
        }
    }

    .card__image-container {
        max-width: 320px;
    }

    .filterButton .el-button {
        margin-left: 0px;
    }

    .title__htag--h1 {
        font-size: rem(25px);
        margin-top: 48px;
        margin-bottom: 24px;

        @include media(sm) {
            font-size: rem(42px);
            margin-top: 27px;
            margin-bottom: 27px;
        }
    }

    .list-with-filter__content > .loader-wraper {
        margin-left: 8px;
    }
}

.service {
    &__section {
        &__item {
            margin-bottom: 2em;
            &__title {
            }
            &__body {
            }
        }

        &__title {
            font-size: rem(16px);
            font-weight: 500;
            color: $--color-primary;

            @include media(sm) {
                font-size: rem(18px);
                font-weight: 700;
            }

            a.link__txt {
                font-weight: 700 !important;
            }
        }

        &__excerpt {
            font-size: rem(13px);
            font-weight: 400;
            margin-top: 2px;
            line-height: 1.6em;

            @include media(sm) {
                font-size: rem(16px);
            }
        }

        &__header {
            font-size: rem(18px);
            color: $--color-info;
            font-weight: 700;
            margin-bottom: 17px;

            @include media(sm) {
                font-size: rem(24px);
                margin-bottom: 15px;
            }
        }

        &__content {
            margin-bottom: 28px;

            @include media(sm) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 48px;
            }
        }
    }

    &__title {
        margin-bottom: 10px;

        a {
            font-size: rem(18px);
            font-weight: 600;
        }
    }
}
</style>
