<template>
    <div :class="{ 'is-active': isOpen, disabled: isDesktop }">
        <div v-if="isDesktop" :class="{ 'hide-desktop': hideFilterHeader }">
            <h2 class="filter-accordion-header">{{ $t('filter.filter') }}</h2>
        </div>

        <button
            v-else
            class="filter-accordion-header"
            :aria-expanded="isOpen.toString()"
            :aria-controls="`accPanel-${id}`"
            @click="toggleVisibility"
        >
            <slot name="title">
                <span>{{ $t('filter.filter') }}</span>
                <span class="filter-accordion-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9">
                        <g>
                            <g>
                                <path
                                    fill="#595959"
                                    d="M1.333 9c-.345 0-.689-.123-.944-.38a1.266 1.266 0 0 1 0-1.828l2.378-2.293L.389 2.206a1.266 1.266 0 0 1 0-1.829 1.382 1.382 0 0 1 1.897 0l3.321 3.212a1.253 1.253 0 0 1 0 1.82L2.275 8.621c-.264.256-.609.38-.942.38z"
                                />
                            </g>
                        </g>
                    </svg>
                </span>
            </slot>
        </button>

        <div v-show="showPanel" :id="`accPanel-${id}`" class="filter-accordion-content-wrapper" role="region">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: Number,
            default: 0,
        },
        hideFilterHeader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isDesktop: true,
        };
    },
    computed: {
        showPanel() {
            if (this.isDesktop) {
                return true;
            } else {
                return this.isOpen;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        toggleVisibility() {
            this.isOpen = !this.isOpen;
        },
        handleResize() {
            if (window.innerWidth >= 768) {
                this.isDesktop = true;
            } else {
                this.isDesktop = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-accordion-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    color: $--color-black;
    font-weight: 700;
    font-size: rem(18px);
    letter-spacing: rem(1.8px);
    text-transform: uppercase;
    padding: rem(16px) rem(24px);
    border-radius: rem(4px);
    line-height: 1.5em;
    background-color: $--color-white;
    border: none;
    box-shadow: $--block-shadow;
    cursor: pointer;

    @include media(sm) {
        cursor: initial;
        font-size: rem(18px);
        color: $--color-green;
    }

    .disabled & {
        box-shadow: none;
        box-sizing: border-box;
        max-width: 284px;
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-bottom: rem(2px) solid $--color-gray;
        padding-bottom: rem(16px);
        margin-top: rem(24px);
        padding-left: 0;
    }

    .filter-accordion-arrow {
        transition: all 0.2s;
        transform: rotate(90deg);

        .is-active & {
            transform: rotate(-90deg);
        }
    }
}

.filter-accordion-content-wrapper {
    padding: rem(24px) 0 0 0;
    border: 0px solid transparent;
    background: none;

    @include media(sm) {
        padding: rem(32px);
        margin-top: 15px;
    }

    .disabled & {
        padding: 0;
        margin: 0;
    }
}

.hide-desktop {
    @include media(sm) {
        display: none;
    }
}
</style>
