<template>
    <list-with-filter
        class="opportunity-jobs"
        :title="$t('jobs.opportunities.title')"
        :search-bar="false"
        :facets="facets"
        :filter-values="filterValues"
        :total-count="parseInt(opportunities.pager.count)"
        :items-per-page="parseInt(opportunities.pager.items_per_page)"
        :page.sync="page"
        has-top-slot
        has-box
        hide-filter-header
        :intro="pageIntro"
        @filterUpdate="filterUpdateHandler"
    >
        <!-- Info box desktop -->
        <Container v-if="!isMobileSize" slot="filterBox" class="filterbox">
            <div class="filter__info">
                <div>
                    <div class="filter__text">
                        {{ $t('jobs.opportunities.info') }}
                    </div>
                    <custom-link :to="$t('jobs.jobs.url')" class="filter__link" underline primary>
                        {{ $t('jobs.opportunities.txt') }}
                    </custom-link>
                </div>
            </div>
        </Container>

        <!-- Header -->
        <Container slot="top" max-width="974px" class="career__top">
            <div class="career__form">
                <span class="career__form__text"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dui diam, semper sit amet vestibulum
                    finibus, lobortis a libero.</span
                >
                <div class="career__form__link">
                    <!-- ajouter Lien formualire emploi -->
                    <custom-link to="https://google.ca" class="career__form__link-file" external white>
                        {{ $t('jobs.form') }}
                    </custom-link>
                </div>
            </div>
        </Container>

        <!-- Opportunities -->
        <Container no-margin class="career__container">
            <loader-wrapper :loading="isUpdating">
                <section v-if="opportunities.results.length > 0" class="career__section">
                    <article
                        v-for="job in opportunities.results"
                        :key="job.entity_id"
                        class="career__section__opportunity"
                    >
                        <div class="opportunity__title">
                            {{ job.entity_title | decodehtml }}
                        </div>
                        <div v-html="job.body" />
                    </article>
                </section>
                <section v-else class="career__section">
                    {{ $t('filter.noResult') }}
                </section>
            </loader-wrapper>
        </Container>

        <!-- Info box MOBILE -->
        <Container v-if="isMobileSize" class="filterbox">
            <div class="filter__info filter__info__mobile">
                <div>
                    <div class="filter__text">
                        {{ $t('jobs.opportunities.info') }}
                    </div>
                    <custom-link :to="$t('jobs.jobs.url')" class="filter__link" underline primary>
                        {{ $t('jobs.opportunities.txt') }}
                    </custom-link>
                </div>
            </div>
        </Container>
    </list-with-filter>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Container from '~/components/Container.vue';
import CustomLink from '~/components/CustomLink';
import ListWithFilter from '~/components/layout/ListWithFilter';
import LoaderWrapper from '~/components/LoaderWrapper';

export default {
    head() {
        return this.$metaTags.head(this.metaTags);
    },
    components: {
        Container,
        CustomLink,
        ListWithFilter,
        LoaderWrapper,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            type: Array,
            default: () => [],
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            opportunities: initData.opportunities,
            facets: initData.facets,
            filterValues: {
                byTitle: '',
                byDate: 'default',
                byDateRange: [],
                byType: [],
            },
            isMobileSize: false,
            isUpdating: false,
            page: parseInt(this.$route.query.page) || 1,
        };
    },

    computed: {
        pageIntro() {
            return this.initData.item.body != null ? this.initData.item.body.processed : null;
        },
    },
    watch: {
        '$route.query.page'(page) {
            this.page = parseInt(page) || 1;
        },
        page() {
            this.updateJobs(true);
        },
    },

    async asyncData({ app, query, store }, item) {
        const facets = [];

        const path =
            process.env.CMS_API_ENDPOINT +
            '/' +
            app.i18n.locale +
            `/api/search-jobs?job_type=career_opportunity&page=${
                query.page && query.page > 0 ? query.page - 1 : 0
            }&items_per_page=10`;
        const response = await app.$client.execute('solrSearch', 'getDatas', {
            uri: path,
        });

        if (response.data.facets) {
            for (const [facetId, filters] of Object.entries(response.data.facets)) {
                const facet = { facetId, filters: [] };
                for (const [key, f] of Object.entries(filters)) {
                    facet.filters.push({
                        id: key,
                        value: f.values.value,
                        name: f.values.label,
                        active: f.values.active ? f.values.active === 'true' : false,
                    });
                }
                facets.push(facet);
            }
        }

        return {
            item,
            opportunities: response.data,
            facets,
        };
    },

    mounted() {
        this.getWindowSize();
        window.addEventListener('resize', this.getWindowSize);
    },

    methods: {
        getWindowSize() {
            const windowWidth = window.innerWidth;
            windowWidth <= 768 ? (this.isMobileSize = true) : (this.isMobileSize = false);
        },
        async updateJobs(pageOnly = false) {
            if (!pageOnly) this.page = 0;
            this.isUpdating = true;
            const path =
                process.env.CMS_API_ENDPOINT +
                '/' +
                this.$i18n.locale +
                `/api/search-jobs?job_type=career_opportunity&page=${
                    this.page > 0 ? this.page - 1 : 0
                }&items_per_page=10`;

            const parameters = {
                facets: this.filterValues.byType,
            };

            const uri = this.$solrUrlBuilder.buildUri(path, parameters);

            const response = await this.$client.execute('solrSearch', 'getDatas', {
                uri,
            });

            if (response.data.facets) {
                const facets = [];
                for (const [facetId, filters] of Object.entries(response.data.facets)) {
                    const facet = { facetId, filters: [] };
                    for (const [key, f] of Object.entries(filters)) {
                        facet.filters.push({
                            id: key,
                            value: f.values.value,
                            name: f.values.label,
                            active: f.values.active ? f.values.active === 'true' : false,
                        });
                    }
                    facets.push(facet);
                }
                this.facets = facets;
            }

            this.opportunities = response.data;
            this.isUpdating = false;
        },
        filterUpdateHandler(filters) {
            this.filterValues = filters;
            this.updateJobs();
        },
    },
};
</script>

<style lang="scss" scoped>
.filter {
    &__info {
        display: flex;
        width: 100%;
        max-width: 284px;
        height: 203px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        font-size: rem(16px);
        padding: 40px 29px 40px 29px;
        text-align: center;
        align-items: center;
        flex-flow: row;
        margin-top: 8px;
        margin-bottom: 46px;

        @include media(sm) {
            padding: 40px;
        }

        &__mobile {
            max-height: 176px;
            margin-top: 36px;
            margin-bottom: 20px;
        }
    }

    &__link {
        font-size: rem(16px);
        font-weight: 400;
        line-height: 1.5em;

        @include media(sm) {
            font-size: rem(18px);
        }
    }

    &__text {
        font-size: rem(14px);
        margin-bottom: 23px;
        line-height: 1.8em;

        @include media(sm) {
            font-size: rem(16px);
            line-height: 1.5em;
        }
    }
}
.career {
    &__top {
        margin-left: 0;

        .container {
            margin-left: 0;
        }
    }

    &__container {
        @include media(sm) {
            margin-right: 100px;
            padding-left: 17px;
        }
    }

    &__form {
        display: flex;
        width: 100%;
        max-width: 974px;
        box-shadow: 0 0 32px rgba(0, 0, 0, 0.16);
        text-align: center;
        align-items: center;
        flex-flow: column;

        @include media(sm) {
            flex-flow: row;
            height: 108px;
        }

        &__text {
            max-width: 660px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 40px;
            text-align: left;
            font-weight: 400;
            font-size: rem(14px);
            line-height: 1.3em;

            @include media(sm) {
                padding: 10px 30px 10px 30px;
                display: flex;
                align-items: center;
                font-size: rem(18px);
                line-height: 1.8em;
            }
        }

        &__link {
            height: 100%;
            width: 100%;
            float: right;
            background-color: $--color-footer;
            box-sizing: border-box;
            padding: 20px;
            font-size: rem(18px);

            @include media(sm) {
                width: 314px;
            }

            &-icone {
                margin-left: 16px;
            }
        }
    }

    &__section {
        align-items: flex-start;

        &__opportunity {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 84px;
            }
        }

        .opportunity {
            &__title {
                color: $--color-info;
                font-size: rem(16px);
                font-weight: 700;
                margin-bottom: 26px;
                display: flex;
                flex-flow: column;

                @include media(sm) {
                    margin-bottom: 42px;
                    white-space: nowrap;
                    flex-flow: none;
                    font-size: rem(18px);
                }

                .link {
                    width: 100%;
                }

                &-pdf {
                    margin-top: 18px;
                    @include media(sm) {
                        float: right;
                        margin-top: 0;
                    }
                }
            }

            &__property {
                margin-bottom: 10px;
            }
        }
    }
}

.bold {
    font-weight: 600;
}
</style>

<style lang="scss">
.el-container {
    .el-main {
        .opportunity-jobs > div > div {
            margin-left: 0;

            @include media(lg) {
                margin-left: -20px;
            }
        }
    }
}
.el-main {
    .opportunity-jobs {
        margin-bottom: 18px;

        .title__htag--h1 {
            font-size: rem(25px);
            margin-top: 48px;
            margin-bottom: 10px;

            @include media(sm) {
                font-size: rem(42px);
                margin-top: 27px;
                margin-bottom: 21px;
            }
        }

        .el-collapse-item__header-desktop {
            margin-top: 0;
        }

        .filters {
            margin-top: 20px;
            margin-bottom: 44px;

            @include media(sm) {
                margin-top: 0;
                margin-bottom: 22px;
            }
        }

        .filter-accordion-header {
            margin-bottom: 12px;
        }

        .filterButton .el-button {
            margin-left: 0px;
        }
    }
}
.opportunity-jobs {
    .career__form {
        .link__external {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .list-with-filter {
        &__first-row {
            // max-width: 973px;
            margin-bottom: 10px;

            @include media(sm) {
                margin-bottom: 70px;
            }
        }
        &__content {
            margin-bottom: 0px;

            @include media(sm) {
                margin-bottom: 100px;
            }
        }
    }
    .career__section__opportunity {
        div p {
            margin-block-start: 5px;
            margin-block-end: 5px;
            font-size: rem(14px);

            @include media(sm) {
                font-size: rem(16px);
            }
        }
    }
}
</style>
