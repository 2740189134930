const initResetSearchstate = () => ({
    resetSearch: false,
});

export const state = initResetSearchstate;

export const mutations = {
    toggleReset(state) {
        state.resetSearch = !state.resetSearch;
    },
};

export const actions = {
    changeResults({ commit }) {
        commit('toggleReset');
    },
};
