<template>
    <div class="c-block-video">
        <ui-lazyimage
            class="c-block-video_cover"
            :image-src="coverImageSrc"
            :image-width="coverImageWidth"
            :image-height="coverImageHeight"
            :image-alt="coverImageAlt"
            :class="{ 'is-active': !isVideoPlaying }"
        />
        <div :class="{ 'is-active': isVideoPlaying }" class="c-block-video_wrapper">
            <ui-video
                :src="videoSrc"
                :loop="true"
                :muted="true"
                :autoplay="true"
                preload="none"
                @onPlay="isVideoPlaying = true"
            ></ui-video>
        </div>
    </div>
</template>
<script>
import UiLazyimage from '~/components/locomotive/ui-lazyimage.vue';
import UiVideo from '~/components/locomotive/ui-video.vue';

export default {
    components: {
        UiVideo,
        UiLazyimage,
    },
    props: {
        coverImageSrc: {
            type: String,
            required: true,
        },
        coverImageWidth: {
            type: Number,
            default: 2,
        },
        coverImageHeight: {
            type: Number,
            default: 1,
        },
        coverImageAlt: {
            type: String,
            default: '',
        },
        videoSrc: {
            type: String,
            default: '',
        },
        videoWidth: {
            type: Number,
            default: 16,
        },
        videoHeight: {
            type: Number,
            default: 9,
        },
    },
    data: () => {
        return {
            isVideoPlaying: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.c-block-video {
    width: 100%;
    height: 100%;

    .c-ui-video {
        width: 100%;
        height: 100%;
    }
}

.c-block-video_wrapper {
    width: 100%;
    height: 100%;
}

.c-block-video_cover {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.c-block-video_cover,
.c-block-video_wrapper {
    opacity: 0;
    visibility: hidden;
    transition: opacity $loco-speed $loco-easing, visibility $loco-speed $loco-easing;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }
}
</style>
