<template>
    <div v-if="Array.isArray(items) && items.length > 0" class="items-list">
        <Row class="items-list__header" align="center">
            <el-col :span="24" :sm="16" :md="18">
                <h2
                    class="items-list__title"
                    :class="{
                        titleSize: titleSize.length,
                        'items-list__title--trait': trait,
                    }"
                >
                    {{ title }}
                </h2>
                <!-- <div class="items-list__trait"></div> -->
            </el-col>

            <el-col v-if="moreLink" class="items-list__more" :span="24" :sm="8" :md="6">
                <custom-link :to="`/${$i18n.locale}/${moreLink.url}`" primary underline>{{ moreLink.txt }}</custom-link>
            </el-col>
        </Row>

        <Row v-if="items" class="items-list__content" :custom-gutter="customGutter">
            <el-col
                v-for="item in items.slice(0, 3)"
                :key="item.id"
                class="items-list__item"
                :span="24"
                :sm="12"
                :md="items.length === 2 ? 12 : 8"
            >
                <Card
                    :type="type"
                    :shadow="false"
                    :show-summary="showSummary"
                    :show-created="showCreated"
                    v-bind="item"
                    size="front_large_21_9"
                    vertical
                ></Card>
            </el-col>
        </Row>

        <div v-if="!items" class="items-list__no-content">
            <p>
                {{ $t('events.noLinkEvents') }}
                <b>{{ title }}</b>
            </p>
        </div>

        <div class="items-list__mobile-link">
            <span class="separator"></span>
            <custom-link class="guide__link" :to="moreLink.url" underline primary>{{ moreLink.txt }}</custom-link>
        </div>
    </div>
</template>

<script>
import Card from '~/components/Card';
import Row from '~/components/Row';

import CustomLink from '~/components/CustomLink';

export default {
    components: {
        Card,
        Row,
        CustomLink,
    },

    props: {
        type: {
            type: String,
            enum: ['event', 'news'],
            default: 'news',
        },
        items: {
            default: null,
            type: Array,
            required: true,
        },
        title: {
            default: () => this.$t('events.event.publishedAt'),
            type: String,
            required: true,
        },
        // Has key: url & txt
        moreLink: {
            default: null,
            type: Object,
            required: false,
        },
        titleSize: {
            default: '',
            type: String,
            required: false,
        },
        showSummary: {
            default: true,
            type: Boolean,
            required: false,
        },
        showCreated: {
            type: Boolean,
            default: true,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        trait: {
            type: Boolean,
            default: false,
        },
        customGutter: {
            type: Number,
            default: 54,
        },
    },
};
</script>

<style lang="scss">
.items-list {
    .el-card {
        background-color: transparent !important;
        margin-bottom: 40px;
        overflow: visible;
    }

    .card {
        &__date-container {
            margin-bottom: 11px !important;

            @include media(xs) {
                margin-bottom: 9px !important;
            }
        }

        &__date {
            font-size: rem(15);
        }

        &__image-container {
            @include media(xs) {
                margin-bottom: 18px !important;
            }
        }

        &__summary {
            font-weight: 400;

            @include media(xs) {
                margin-bottom: 10px;
                font-size: rem(13) !important;
            }
        }
    }

    .media__image,
    .card__text {
        @include media(xs) {
            width: 100%;
            max-width: 100%;
        }
    }

    .media__image,
    .uploaded-image {
        min-width: 100% !important;

        @include media(xs) {
            min-height: 172px;
            height: 37vw;
            width: calc(100% + 18px) !important;
            max-width: initial;
            margin-left: -9px;
            margin-right: -9px;
        }

        @include media(sm) {
            height: 183px;
        }
    }

    &__header {
        margin-bottom: 23px;

        @include media(sm) {
            margin-bottom: 45px;
        }
    }

    &__title {
        color: $--color-dark-black;
        text-transform: uppercase;
        letter-spacing: 0.56px;

        @include media(sm) {
            letter-spacing: 0.6px;
        }

        &--trait {
            @include trait();
        }

        @include media(xs) {
            margin-bottom: 2px;

            &:after {
                display: none;
            }
        }

        &.big {
            font-size: rem(30);
        }
    }

    &__no-content {
        margin-bottom: 2rem;
    }

    &__more {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;

        @include media(xs) {
            display: none;
        }
    }

    &__thumbnail {
        border: 1px solid $--color-gray;
        height: 160px;
        width: 284px;
    }

    &__summary {
        max-width: 284px;
        font-size: rem(16);
        font-weight: 400;
    }

    &__date {
        color: $--color-gray;
        font-size: rem(15);
    }

    &__mobile-link {
        display: flex;
        margin-top: -10px;
        margin-bottom: 40px;

        a {
            font-size: rem(13px);
            font-weight: 400;
        }

        @include media(sm) {
            display: none;
        }

        .separator {
            display: inline-block;
            height: 2px;
            width: calc(100% - 178px);
            background: lightgray;
            margin-right: 13px;
            transform: translateY(9px);
        }
    }
}
</style>
