<template>
    <div v-if="title" class="title" :class="{ padding: padding, 'inside-img': insideImg }">
        <Breadcrumb />

        <h1
            v-if="tag == 'h1'"
            class="title__htag title__htag--h1"
            :class="{ 'type-tags': typeTags && typeTags.length }"
        >
            {{ title }}
        </h1>
        <h2 v-if="tag == 'h2'" class="title__htag title__htag--h2">
            {{ title }}
        </h2>
        <h3 v-if="tag == 'h3'" class="title__htag title__htag--h3">
            {{ title }}
        </h3>
        <h4 v-if="tag == 'h4'" class="title__htag title__htag--h4">
            {{ title }}
        </h4>
        <h5 v-if="tag == 'h5'" class="title__htag title__htag--h5">
            {{ title }}
        </h5>

        <!-- are tags still link ? -->
        <ul v-if="typeTags.length" class="title__sub">
            <li v-for="(category, key) in typeTags" :key="key">
                <CustomLink :to="category.to" class="title__sub-tags" :no-link="category.to === '#'" black>
                    {{ category.name }}
                    <span v-if="typeTags.length - 1 !== key">•&nbsp;</span>
                </CustomLink>
            </li>
        </ul>

        <div v-if="moreLink" class="title__link">
            <img class="title__link-icone" src="~assets/img/fleche_2.svg" alt="arrow" />
            <customLink :to="`/${$i18n.locale}/${moreLink.url}`" primary underline class="title__link-text">
                {{ moreLink.txt }}
            </customLink>
            <div class="title__link-separator"></div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '~/components/Breadcrumb.vue';
import CustomLink from '~/components/CustomLink.vue';

export default {
    components: {
        CustomLink,
        Breadcrumb,
    },

    props: {
        title: {
            type: String,
            default: null,
        },
        typeTags: {
            type: Array,
            default: () => [],
        },
        tag: {
            type: String,
            default: 'h1',
        },
        padding: {
            type: Boolean,
            default: false,
        },
        insideImg: {
            type: Boolean,
            default: false,
        },
        // Has key: url & txt
        moreLink: {
            default: null,
            type: Object,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.title {
    padding-top: 30px;

    @include media(sm) {
        padding-top: $title-padding;
    }

    &__link {
        display: flex;
        width: 100%;
        margin-bottom: 22px;

        @include media(sm) {
            margin-bottom: 20px;
        }

        &-icone {
            margin-right: 5px;
        }

        &-separator {
            @include trait(false, 100%, false);
            width: calc(100% + 20px);
            margin-right: -20px;

            @include media(sm) {
                margin-right: initial;
                width: 100%;
            }
        }

        &-text {
            font-size: rem(14px);
            font-weight: 400;
            width: auto;
            white-space: nowrap;
        }
    }

    &.padding {
        position: relative;
        background: white;
        @include padding-content();
        width: 700px;

        @include media(xs) {
            width: 100%;
        }
    }

    &.inside-img {
        @include media(lg) {
            margin-top: -86px;
        }
    }

    &__htag {
        &--h1 {
            font-size: rem(42px);
            font-weight: 700;
            color: $--color-dark-black;
            margin-top: rem(49px);
            margin-bottom: rem(40px);

            @include media(sm) {
                margin-top: rem(32px);
            }

            &.type-tags {
                margin-bottom: 0;
            }
        }
    }

    &__sub {
        margin: 0;
        padding: 0;
        margin-bottom: -3px;
        margin-top: rem(14px);

        @include media(sm) {
            margin-top: rem(5px);
            margin-bottom: rem(0px);
        }

        & > * {
            list-style: none;
            display: inline-block;
            margin-bottom: 1.25em;
            // margin-right: 0.75em;

            // & + * {
            //     margin-right: 0.75em;
            // }
        }
    }
}
.title {
    &__sub {
        &-tags {
            .link__internal {
                padding: 0;
            }

            .link__txt {
                font-size: rem(16px);
                font-weight: 400;

                @include media(sm) {
                    font-size: rem(20px);
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
