<template>
    <content-details
        :title="item.title"
        :type-tags="item.typeTags"
        :media-image="item.media_image ? item.media_image : null"
        :class="'guide'"
        size="front_large_21_9"
    >
        <template>
            <!-- TEXT -->
            <container huge-padding max-width="649px">
                <span class="guide__body" v-html="item.body.processed" />
            </container>

            <!-- NEWS -->
            <container v-if="news.length > 0" class="guide__container guide__news" huge-padding max-width="649px">
                <div class="guide__container-title">{{ linkedNews }}</div>
                <template v-for="n in news">
                    <transition :key="n.id" name="fade">
                        <Card
                            type="news"
                            class="guide__container__card"
                            v-bind="n"
                            :media-image="n.media_image"
                            size="front_large_21_9"
                            :url="`/${n.pathLangcode}${n.pathAlias}`"
                            :shadow="false"
                            show-summary
                        />
                    </transition>
                </template>
                <div class="link__section">
                    <span class="separator"></span>
                    <custom-link class="guide__link" :to="newsRoute" underline primary>
                        {{ this.$t('news.seeAllNews') }}
                    </custom-link>
                </div>
            </container>

            <!-- EVENTS -->
            <container v-if="events.length > 0" class="guide__container guide__events" max-width="649px">
                <div class="guide__container-title">{{ linkedEvents }}</div>
                <template v-for="event in events">
                    <transition :key="event.id" name="fade">
                        <Card
                            type="event"
                            class="guide__container__card"
                            v-bind="event"
                            :media-image="event.media_image"
                            size="front_large_21_9"
                            :url="`/${event.pathLangcode}${event.pathAlias}`"
                            :shadow="false"
                            fit="cover"
                            show-summary
                        />
                    </transition>
                </template>
                <div class="link__section">
                    <span class="separator"></span>
                    <custom-link class="guide__link" :to="eventsRoute" underline primary>
                        {{ this.$t('events.seeAllEvents') }}
                    </custom-link>
                </div>
            </container>

            <!-- SERVICES -->
            <container v-if="services.length > 0" class="guide__container" huge-padding max-width="649px">
                <div class="guide__container-title-service">SERVICES</div>
                <Row :custom-gutter="80">
                    <el-col v-for="service in services" :key="service.id" class="guide__service" :span="24" :sm="12">
                        <custom-link
                            v-if="service.path"
                            :to="`/${service.path.langcode}${service.path.alias}`"
                            class="guide__service-box"
                            primary
                        >
                            {{ service.title }}
                            <img class="guide__service-icone" src="~assets/img/fleche.svg" alt />
                        </custom-link>
                        <div class="separator-service"></div>
                    </el-col>
                </Row>
            </container>

            <container v-if="item.link" slot="map" full class="service__map">
                <transition name="fade">
                    <interactive-map :uri="item.link.uri" :height="'450px'" />
                </transition>
            </container>

            <container
                slot="bottom"
                class="custom_content guide__container guide__paragraph"
                max-width="649px"
                huge-padding
            >
                <paragraphs :item="item"></paragraphs>
            </container>
        </template>
    </content-details>
</template>

<script>
import { cloneDeep } from 'lodash';

import ScrollMixin from '~/mixins/scroll-mixin.js';

import Card from '~/components/Card';
import Container from '~/components/Container';
import ContentDetails from '~/components/layout/ContentDetails';
import CustomLink from '~/components/CustomLink';
import InteractiveMap from '~/components/InteractiveMap';
import Paragraphs from '~/components/Paragraphs';
import Row from '~/components/Row.vue';

export default {
    components: {
        Card,
        Container,
        ContentDetails,
        CustomLink,
        InteractiveMap,
        Paragraphs,
        Row,
    },

    mixins: [ScrollMixin],

    props: {
        metaTags: {
            default: null,
            type: Array,
        },
        initData: {
            default: null,
            type: Object,
        },
    },

    data() {
        const initData = cloneDeep(this.initData);
        return {
            item: initData.item,
            events: initData.events,
            services: initData.services,
            news: initData.news,
        };
    },

    computed: {
        newsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('news.url');
        },
        eventsRoute() {
            return '/' + this.$i18n.locale + '/' + this.$t('events.url');
        },
        linkedNews() {
            return this.$t('news.linkedNews').toUpperCase();
        },
        linkedEvents() {
            return this.$t('events.linkedEvents').toUpperCase();
        },
    },

    asyncData({ app, query, store, params, error, env }, item) {
        const events = app.$drupalComponentsAdapter.normalizeItems(item.related_events);
        const services = item.related_services;
        const news = app.$drupalComponentsAdapter.normalizeItems(item.related_news);
        return { item, events, services, news };
    },
};
</script>

<style lang="scss" scoped>
.guide {
    &__container {
        margin-bottom: 50px;
    }

    &__news {
        margin-top: 49px;
    }

    &__events {
        margin-bottom: 105px;
    }

    &__body p {
        font-size: rem(20px);
        margin-top: 0;
    }

    &__service {
        height: auto;
        margin-bottom: 40px;

        &-title {
            margin-bottom: 48px;
        }

        &-box {
            font-weight: 500;
            margin-bottom: 36px;
        }

        &-icone {
            width: 20px;
            margin-right: -7px;
            float: right;
        }
    }

    .link__section {
        display: flex;
        margin-top: -20px;
        margin-bottom: -5px;
        white-space: nowrap;

        custom-link {
            display: inline-block;
            text-decoration: none;
        }
    }

    .separator {
        display: inline-block;
        height: 2px;
        width: 430px;
        background: lightgray;
        margin-right: 42px;
        transform: translateY(12px);
    }

    .separator-service {
        position: relative;
        width: 100%;
        height: 2px;
        background: lightgray;
        margin-top: 36px;
    }

    .custom_content {
        margin-top: 20px;
        margin-bottom: 120px;
    }
}

.guide__container-title {
    font-size: rem(15px);
    letter-spacing: 0.6px;
    font-weight: 500;
    margin-bottom: 27px;

    &-service {
        margin-bottom: 38px;
        font-weight: 500;
    }
}

.guide__container {
    span.guide__service-box {
        margin-bottom: 36px;
        width: 100%;
        max-width: 284px;
    }

    &__card {
        &:not(:last-child) {
            margin-bottom: 53px;
        }
    }
}
</style>

<style lang="scss">
div.guide {
    .title.padding.inside-img {
        padding-top: 92px;
        max-width: 655px !important;

        @include media(xs) {
            padding-top: 15px;
        }
    }

    h1.title__htag.title__htag--h1 {
        margin-top: 15px;
        margin-bottom: 0px;
        padding-bottom: 16px;
    }

    .card {
        margin-left: 0;
        max-width: 700px;

        @include media(lg) {
            max-width: auto;
            margin-left: auto;
        }
    }

    .card__image-container {
        width: auto;

        .card__thumbnail,
        .media__image {
            @include media(sm) {
                max-width: 320px !important;
                width: 320px;
                height: 180px;
            }
        }
    }

    &__body p {
        font-size: rem(20px);
        margin-top: 0;
    }

    &__events,
    &__news {
        .el-card__body {
            padding: 0;
        }
    }

    &__map {
        @include media(lg) {
            transform: translateX(96px);
        }
    }

    &__link {
        font-size: rem(15px);
        font-weight: 400;
        text-decoration: underline;
    }

    .content__header {
        &-img {
            margin: 0;
            max-width: none;
            transform: unset;

            @include media(sm) {
                height: 448px;
            }
        }
    }

    .container.no-margin {
        margin-left: auto;
    }

    .title.inside-img {
        width: 100%;

        @include media(lg) {
            transform: translateX(-96px);
            margin-top: -174px;
            padding-top: 91px;
        }
        @include huge-padding-content();
    }

    .title .title__htag--h1 {
        @include media(sm) {
            font-size: rem(64px);
        }

        @include media(lg) {
            margin-top: 25px;
            margin-bottom: 23px;
        }
    }

    .link {
        &__internal-container {
            width: 100%;
        }
    }

    .container {
        .separation {
            letter-spacing: 0.6px;
            font-size: rem(15px);
        }
    }
}
</style>
