import { cloneDeep, difference } from 'lodash';

const initAlerts = () => ({
    alerts: [],
    seenAlerts: [],
    closedMainAlerts: [],
});

export const state = initAlerts;

export const mutations = {
    setAlerts(state, alerts) {
        state.alerts = alerts;
    },
    setClosedMainAlerts(state, closedMainAlerts) {
        state.closedMainAlerts = closedMainAlerts;
    },

    setSeenAlerts(state, seenAlerts) {
        state.seenAlerts = seenAlerts;
    },

    reset() {
        const initState = initAlerts();
        Object.keys(initState).forEach(key => {
            state[key] = cloneDeep(initState[key]);
        });
    },
};

export const actions = {
    async fetchAlertsWithinDate({ commit }, date) {
        const response = await this.$client.execute('alerts', 'getCollection', {
            sort: '-order',
            include: ['zip_code'],
            filters: {
                dateRange: {
                    group: {
                        conjunction: 'AND',
                    },
                },
                dateFrom: {
                    condition: {
                        path: `date.value`,
                        operator: '<=',
                        value: date,
                        memberOf: 'dateRange',
                    },
                },
                dateTo: {
                    condition: {
                        path: `date.end_value`,
                        operator: '>=',
                        value: date,
                        memberOf: 'dateRange',
                    },
                },
            },
        });
        commit('setAlerts', response.data);
    },
    addSeenAlerts({ state, commit }, alertIds) {
        const newAlertIds = difference(alertIds, state.seenAlerts);
        commit('setSeenAlerts', [...state.seenAlerts, ...newAlertIds]);
    },
    addClosedMainAlert({ state, commit }, alertId) {
        if (!state.closedMainAlerts.includes(alertId)) {
            commit('setClosedMainAlerts', [...state.closedMainAlerts, alertId]);
        }
    },
};

export const getters = {
    hasUnseenAlerts: (state, getters) => {
        return (
            difference(
                getters.alertsForUserPostalCode.map(a => a.id),
                state.seenAlerts,
            ).length > 0
        );
    },
    topAlerts: (state, getters) => {
        return getters.alertsForUserPostalCode.filter(alert => {
            return alert.order === 1 && !state.closedMainAlerts.includes(alert.id);
        });
    },
    alertsForUserPostalCode: (state, getters, rootState, rootGetters) => {
        const userPosalCode = rootGetters['authentication/getPostalCodeObject'];
        return state.alerts.filter(alert => {
            if (alert.zip_code.length === 0) return true; // Keep alerts that are for everybody
            if (userPosalCode === null) return false; // Reject other alerts if we don't know the user postal code

            return (
                alert.zip_code.filter(c => {
                    if (c.ldu === null) {
                        return c.fsa === userPosalCode.fsa;
                    } else {
                        return c.fsa === userPosalCode.fsa && c.ldu === userPosalCode.ldu;
                    }
                }).length > 0
            );
        });
    },
};
