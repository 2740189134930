<template>
    <div class="c-block-socials">
        <nav :aria-label="$t('a11y.socialMedia')">
            <ul class="c-block-socials_list">
                <li v-for="(link, index) in links" :key="index" class="c-block-socials_list_item">
                    <ui-cta :url="link.url" :title="link.label" :icon-type="link.icon" type="button" />
                </li>
            </ul>
        </nav>
    </div>
</template>
<script>
import UiCta from '~/components/locomotive/ui-cta.vue';
export default {
    components: {
        UiCta,
    },
    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-newsletter-push {
    max-width: loco-rem(430px);
}

.c-block-socials_list {
    display: flex;
    align-items: center;

    @media (max-width: $to-tiny) {
        justify-content: space-between;
    }
}

.c-block-socials_list_item {
    & + .c-block-socials_list_item {
        margin-left: loco-rem(20px);
    }
}
</style>
