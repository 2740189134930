<template>
    <div>
        <article class="item__item">
            <custom-link v-if="alias" class="item__link" :to="alias" primary>
                <h3 class="item__title">{{ title | decodehtml }}</h3>
            </custom-link>

            <div class="item__row" v-html="excerpt"></div>

            <custom-link v-if="alias" class="item__link" :to="alias" primary underline>
                <span v-html="domain + alias"></span>
            </custom-link>

            <div class="item__row item__date">{{ this.$t('search.lastUpdate') }}: {{ changed }}</div>
        </article>
    </div>
</template>

<script>
import CustomLink from '~/components/CustomLink';

export default {
    components: {
        CustomLink,
    },
    props: {
        changed: {
            type: String,
            default: '',
        },
        excerpt: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        alias: {
            type: String,
            default: '',
        },
        domain: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    &__item {
        margin-bottom: 49px;

        @include media(sm) {
            margin-bottom: 20px;
        }
    }

    &__row {
        font-size: rem(13px);
        margin-bottom: 27px;
        font-weight: 400;
        line-height: 1.625em;

        @include media(sm) {
            font-size: rem(16px);
            line-height: 1.5em;
            margin-bottom: 2px;
        }
    }

    &__title {
        margin-bottom: 9px;
        font-size: rem(16px);
        color: $--color-primary;
        font-weight: 500;
        line-height: 1.5em;

        @include media(sm) {
            margin-bottom: 4px;
            font-size: rem(18px);
        }
    }

    &__date {
        font-size: rem(13px);
        font-weight: 400;
        margin-top: 11px;

        @include media(sm) {
            font-size: rem(14px);
            margin-top: 8px;
        }
    }

    &__link {
        font-size: rem(13px);
        font-weight: 400;

        @include media(sm) {
            font-size: rem(14px);
        }
    }
}
</style>
