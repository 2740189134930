const initAlertes = () => ({
    alertes: [],
});

/*
    Ce code est utile, car si l'API Drupal voit qu'il n'y a pas de résultat,
    il retourne une structure vide. 

    structure "{"content":"[]","headers":{"Content-Type":"application\/json; type="}}"

    au lieu de []
*/
function getArraySiVide(resultatsJson) {
    if (resultatsJson && Array.isArray(resultatsJson)) {
        return resultatsJson;
    }
    // TODO ML : Sinon, nous avons reçu la structure de Drupal avec la

    return [];
}

export const state = initAlertes;

export const mutations = {
    setAlertes(state, valeur) {
        state.alertes = valeur;
    },
};

export const actions = {
    async fetchAlertes({ commit }, app) {
        // Check context (If this(front) or app from back)
        const lang = app.i18n ? app.i18n.locale : app.$i18n.locale;

        try {
            const response = await fetch(process.env.CMS_API_ENDPOINT + '/' + lang + '/api/alertes');
            const alertes = await response.json();
            commit('setAlertes', getArraySiVide(alertes));
        } catch (e) {
            app.$externalLog.captureException(e);
        }
    },
};
