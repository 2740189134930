var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{
        'full-width': _vm.full,
        'big-padding': _vm.bigPadding,
        'huge-padding': _vm.hugePadding,
        padding: _vm.padding,
        'is-horizontal': _vm.horizontal,
        'no-margin': _vm.noMargin,
        'max-width-md': _vm.maxWidthMdOnly,
    },style:(_vm.cssProps)},[_c('Row',{attrs:{"justify":_vm.justify ? _vm.justify : _vm.offset ? 'end' : 'start'}},[_c('el-col',{attrs:{"span":24,"offset":_vm.pageWidth > 1395 && _vm.offset ? _vm.offsetSize : 0}},[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }