// https://gist.github.com/jonathantneal/d3a259ebeb46de7ab0de

export function nearestNormalAspectRatio(width, height, side) {
    const ratio = (width * 100) / (height * 100);
    const maxW = 3 in arguments ? arguments[2] : 16;
    const maxH = 4 in arguments ? arguments[3] : 16;
    const ratiosW = new Array(maxW).join(',').split(',');
    const ratiosH = new Array(maxH).join(',').split(',');
    const ratiosT = {};
    const ratios = {};
    let match;
    let key;
    let ratioX;

    ratiosW.forEach(function(empty, ratioW) {
        ++ratioW;

        ratiosH.forEach(function(empty, ratioH) {
            ++ratioH;

            ratioX = (ratioW * 100) / (ratioH * 100);

            if (!ratiosT[ratioX]) {
                ratiosT[ratioX] = true;

                ratios[ratioW + ':' + ratioH] = ratioX;
            }
        });
    });

    for (key in ratios) {
        if (
            !match ||
            (!side && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
            (side < 0 && ratios[key] <= ratio && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
            (side > 0 && ratios[key] >= ratio && Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match]))
        ) {
            match = key;
        }
    }

    return match;
}
