import BaseResource from '../BaseResource';

class Places extends BaseResource {
    constructor() {
        super();
        this.resourcePath = '/places';
    }
}

export default Places;
