import moment from 'moment-timezone';

export default (ctx, inject) => {
    const drupalComponentsAdapter = {
        normalizeItem: item => {
            const normalizeItem = {};
            try {
                normalizeItem.title = item.title_short ? item.title_short : item.title;
                normalizeItem.created = moment.tz(item.created, 'America/New_York');
                normalizeItem.pathLangcode = item.langcode;
                normalizeItem.pathAlias = item.path.alias;
                normalizeItem.type = item.node_type.name;
                normalizeItem.url = '/' + item.langcode + normalizeItem.pathAlias;

                // supprimer imageUrl
                normalizeItem.mediaImage = item.media_image
                    ? item.media_image
                    : item.place && item.place.media_image
                    ? item.place.media_image
                    : null;
                normalizeItem.imageUrl =
                    item.image && item.image.uri
                        ? item.image.uri.url
                        : normalizeItem.mediaImage
                        ? null
                        : '/placeholder_284x190.svg';
                normalizeItem.id = item.id;

                normalizeItem.summary = null;
                normalizeItem.content = null;

                // For related news
                if (item.details && item.details.processed) normalizeItem.content = item.details.processed;
                if (item.details && item.details.summary) normalizeItem.summary = item.details.summary;

                if (item.details && !item.details.summary.length) {
                    const newSUmmary = item.details.processed.replace(/<\/?[^>]+(>|$)/g, '');
                    normalizeItem.summary = newSUmmary.substr(0, 60) + (newSUmmary.length > 60 ? '...' : '');
                }

                // For related events
                if (item.body && item.body.value) normalizeItem.content = item.body.value;
                if (item.body) normalizeItem.summary = item.body.summary;

                if (item.body && !item.body.summary.length) {
                    const newSUmmary = item.body.value.replace(/<\/?[^>]+(>|$)/g, '');
                    normalizeItem.summary = newSUmmary.substr(0, 60) + (newSUmmary.length > 60 ? '...' : '');
                }

                // normalizeItem.event_types = item.event_type.map(item => item.id);

                if (item.place && item.place !== null) {
                    normalizeItem.place = item.place.title;
                }
                if (item.recurring_dates && item.recurring_dates !== null) {
                    normalizeItem.dateStart = moment.tz(item.recurring_dates.value, 'America/New_York');
                    normalizeItem.dateEnd = moment.tz(item.recurring_dates.end_value, 'America/New_York');
                } else if (item.date && item.date.length > 0) {
                    normalizeItem.dateStart = moment.tz(item.date[0].value, 'America/New_York');
                    normalizeItem.dateEnd = moment.tz(item.date[item.date.length - 1].end_value, 'America/New_York');
                }
            } catch (e) {
                ctx.$externalLog.captureException(e);
            }

            return normalizeItem;
        },
        normalizeItems: items => {
            const normalizeItems = [];
            try {
                items.forEach((item, key) => {
                    normalizeItems[key] = drupalComponentsAdapter.normalizeItem(item);
                });
            } catch (e) {
                ctx.$externalLog.captureException(e);
            }

            // Clean uncomplete data and return result
            return normalizeItems.filter(item => !!item.pathAlias);
        },
        normalizeEvents: events => {
            const normalizedEvents = [];

            try {
                events.map(ev => {
                    const event = {
                        mediaImage: {
                            meta: {
                                links: {
                                    front_large_21_9: '/placeholder_284x190.svg',
                                },
                            },
                        },
                    };

                    event.title = ev.entity_title;
                    event.url = ev.entity_alias;
                    event.id = ev.entity_id;
                    event.summary = ev.summary;
                    if (ev.summary.length > 60) {
                        const newSUmmary = ev.summary.replace(/<\/?[^>]+(>|$)/g, '');
                        event.summary = newSUmmary.substr(0, 60) + (newSUmmary.length > 60 ? '...' : '');
                    }

                    event.type = ev.content_type;
                    if (ev.image) event.mediaImage.meta.links.front_large_21_9 = ev.image;
                    event.dates = ev.event_dates;
                    event.place = ev.place;
                    if (ev.recurring_dates && ev.recurring_dates !== '') {
                        event.dateStart = JSON.parse(ev.recurring_dates).value;
                        event.dateEnd = JSON.parse(ev.recurring_dates).end_value;
                    }
                    normalizedEvents.push(event);
                });
            } catch (e) {
                ctx.$externalLog.captureException(e);
            }
            return normalizedEvents;
        },
    };

    inject('drupalComponentsAdapter', drupalComponentsAdapter);
};
