<template>
    <div :class="[{ '-wide': isWide }, { '-alt': alt }]" class="c-ui-carousel-navigation">
        <ui-cta
            class="c-ui-carousel-navigation_button"
            :size="getSize"
            type="button"
            :title="$t('a11y.carousel.prevSlide')"
            icon-type="chevronLeft"
            @click.native="$emit('onPrev')"
        />
        <ui-cta
            class="c-ui-carousel-navigation_button"
            :size="getSize"
            type="button"
            :title="$t('a11y.carousel.nextSlide')"
            icon-type="chevronRight"
            @click.native="$emit('onNext')"
        />
    </div>
</template>

<script>
import UiCta from '~/components/locomotive/ui-cta.vue';

export default {
    components: {
        UiCta,
    },
    props: {
        isWide: {
            type: Boolean,
            default: false,
        },
        alt: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {};
    },
    computed: {
        getSize() {
            if (this.isWide) {
                return 'wide';
            } else {
                return 'medium';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-ui-carousel-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: loco-rem(56px);

    &.-wide {
        max-width: loco-rem($loco-margin-wide);
    }
}

.c-ui-carousel-navigation_button {
    .c-ui-carousel-navigation.-alt & {
        transform: rotate(-90deg);
    }
}
</style>
