/**
 * @param uri
 * @param headers
 * @param params
 * @param waitFor
 * @returns RequestObject
 * @constructor
 */
const TranslatePathRequestFactory = (uri, headers = {}, params = {}, waitFor = []) => {
    /**
     * @type {RequestObject}
     */
    const request = {};

    request._id = null;
    request._type = 'TranslatePathRequest';
    request._uri = uri;
    request._action = 'view';
    request._headers = headers;
    request._params = params;
    request._waitFor = waitFor;

    return request;
};

export default TranslatePathRequestFactory;
