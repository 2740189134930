<template>
    <div class="c-block-hero">
        <block-video
            v-if="image && video"
            :cover-image-src="image.src"
            :cover-image-width="image.width"
            :cover-image-height="image.height"
            :cover-image-alt="image.alt"
            :video-src="video.src"
            :video-width="video.width"
            :video-height="video.height"
            class="c-block-hero_video"
        />
        <div class="c-block-hero_content">
            <div class="o-container">
                <div ref="inner" class="c-block-hero_content_inner">
                    <h1 class="c-block-hero_title || c-heading -h3" :class="{ 'is-active': showTitle }">
                        {{ title }}
                    </h1>
                    <block-news-carousel v-if="news.length" :items="news" class="c-block-hero_carousel" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

import BlockNewsCarousel from '~/components/locomotive/block-news-carousel.vue';
import BlockVideo from '~/components/locomotive/block-video.vue';

export default {
    components: {
        BlockVideo,
        BlockNewsCarousel,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        news: {
            type: Array,
            default: () => [],
        },
        image: {
            type: Object,
            default: () => {},
        },
        video: {
            type: Object,
            default: () => {},
        },
    },
    data: () => {
        return {
            showTitle: false,
        };
    },
    computed: {
        ...mapState('locomotive-loading', ['isFontsLoaded']),
    },
    watch: {
        isFontsLoaded: 'onFontsLoaded',
    },
    mounted() {
        if (this.isFontsLoaded) {
            this.componentsFontsLoaded = true;
            this.init();
        }
    },
    methods: {
        init() {
            setTimeout(() => {
                this.$nextTick(() => {
                    this.showTitle = true;
                });
            }, 1000);
        },
        onFontsLoaded() {
            if (!this.componentsFontsLoaded) {
                this.componentsFontsLoaded = true;
                this.init();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-hero {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #00102d;
    overflow: hidden;

    @media (max-width: $to-small) {
        min-height: calc((var(--vh, 1vh) * 50) - #{var(--header-height)});
    }

    @media (min-width: $from-small) {
        min-height: calc((var(--vh, 1vh) * 85) - #{var(--header-height)});
    }
}

.c-block-hero_video {
    @include u-stretch();
    &:before {
        content: '';
        @include u-stretch();
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.31) 0.78%, rgba(0, 0, 0, 0) 25.26%),
            linear-gradient(180deg, rgba(74, 74, 73, 0.09) 62.5%, rgba(74, 74, 73, 0.3) 100%);
        z-index: 1;
    }

    video {
        opacity: 0.8;
    }
}

.c-block-hero_title {
    display: block;
    width: 100%;
    max-width: loco-rem(570px);
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transform: translate3d(0, 40px, 0);

    &.is-active {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity $loco-speed linear, transform $loco-speed $loco-easing;
    }

    @media (max-width: $to-medium) {
        text-align: center;
        font-size: calc(clamp(#{loco-rem(32px)}, 6.4vw, #{loco-rem(64px)})) !important;
    }

    @media (min-width: $from-medium) {
        flex: 1;
        padding-right: loco-rem($loco-gutter-small);
    }
}

.c-block-hero_content {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: loco-rem($loco-unit);
    padding-bottom: loco-rem($loco-unit);
    color: $loco-light;
    z-index: 1;
}

.c-block-hero_content_inner {
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: $to-small) {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: calc((var(--vh, 1vh) * 50) - #{var(--header-height)} - #{loco-rem($loco-unit)});
    }

    @media (min-width: $from-small) {
        min-height: calc((var(--vh, 1vh) * 85) - #{var(--header-height)} - #{loco-rem($loco-unit)});
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    }

    @media (min-width: $from-medium) {
        align-items: flex-end;
        justify-content: space-between;
    }
}

.c-block-hero_carousel {
    @media (max-width: $to-medium) {
        margin-top: loco-rem($loco-unit);
    }
}
</style>
