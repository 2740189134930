export default (ctx, inject) => {
    const metaTagsPlugin = {
        setFromResponse: response => {
            let result = null;
            try {
                result = response.data.metatag_normalized.filter(metatag => metatag.tag === 'meta');
            } catch {
                console.warn('Meta tags cannot automaticaly set. Set it in an other way.');
            }
            return result;
        },
        set: metatagNormalized => {
            let result = null;
            try {
                result = metatagNormalized.filter(metatag => metatag.tag === 'meta');
            } catch {
                console.warn('Meta tags cannot automaticaly set. Set it in an other way.');
            }
            return result;
        },
        title: metatags => {
            let result = null;
            try {
                result = metatags.filter(metatag => metatag.attributes.name === 'title')[0].attributes.content;
            } catch {
                console.warn('Title tags cannot automaticaly set or not exist. Set it in an other way.');
                return 'Longueuil';
            }
            return result;
        },
        meta: metatags => {
            let result = null;
            try {
                result = metatags
                    .filter(metatag => metatag.attributes.name !== 'title')
                    .map(metatag => {
                        return {
                            hid: metatag.attributes.name || metatag.attributes.property,
                            name: metatag.attributes.name || metatag.attributes.property,
                            content: metatag.attributes.content,
                        };
                    });
            } catch {
                console.warn('Meta tags cannot automaticaly set or not exist. Set it in an other way.');
                return [];
            }
            return result;
        },

        head: metaTags => {
            return {
                title: metaTagsPlugin.title(metaTags),
                meta: metaTagsPlugin.meta(metaTags),
            };
        },
    };

    inject('metaTags', metaTagsPlugin);
};
