<template>
    <div class="c-block-carousel-alerts">
        <div
            class="c-block-carousel-alerts_inner || u-color-light"
            :class="{
                'u-bgcolor-alert-1': items[activeIndex].severite === '1',
                'u-bgcolor-alert-2': items[activeIndex].severite === '2',
                'u-bgcolor-alert-3': items[activeIndex].severite === '3',
            }"
        >
            <div class="c-block-carousel-alerts_container || o-container">
                <div class="c-block-carousel-alerts_top">
                    <ul ref="itemsContainer" class="c-block-carousel-alerts_list">
                        <li
                            v-for="(item, index) in items"
                            ref="item"
                            :key="index"
                            :class="{ 'is-active': index === activeIndex }"
                            class="c-block-carousel-alerts_list_item"
                        >
                            <p class="c-block-carousel-alerts_category || c-text -overline || js-alert-category">
                                <ui-icon v-if="item.iconType" :type="item.iconType" class="u-mr-5" />
                                <span class="c-block-carousel-alerts_category_text">
                                    {{ item.category }}
                                </span>
                            </p>
                            <div class="c-block-carousel-alerts_message || js-alert-message">
                                <div class="c-block-carousel-alerts_message_inner">
                                    <p class="c-block-carousel-alerts_title">
                                        <span v-html="item.title"></span>
                                    </p>
                                    <ui-cta
                                        v-if="item.linkUrl && item.linkLabel"
                                        :url="item.linkUrl"
                                        :label="item.linkLabel"
                                        :title="item.linkLabel"
                                        type="link"
                                        color="light"
                                        size="large"
                                        class="c-block-carousel-alerts_message_cta"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="c-block-carousel-alerts_bottom">
                    <ui-bullets
                        :length="items.length"
                        :active-index="activeIndex"
                        :alt="true"
                        @onClick="onBulletsClick"
                    />
                    <ui-carousel-navigation class="u-ml-10" :alt="true" @onPrev="onPrev" @onNext="onNext" />
                    <ui-cta
                        title="Close alert bar"
                        type="button"
                        color="light"
                        size="large"
                        icon-type="close"
                        class="c-block-carousel-alerts_close || u-ml-10"
                        @click.native="onClose"
                    />
                </div>
            </div>
        </div>
        <div class="c-block-carousel-alerts_overlay" @click="onClose"></div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { mapState } from 'vuex';

import Raf from 'quark-raf';
import UiBullets from '~/components/locomotive/ui-bullets.vue';
import UiCarouselNavigation from '~/components/locomotive/ui-carousel-navigation.vue';
import UiCta from '~/components/locomotive/ui-cta.vue';
import UiIcon from '~/components/locomotive/ui-icon.vue';

export default {
    components: {
        UiCta,
        UiIcon,
        UiBullets,
        UiCarouselNavigation,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        loopTime: {
            type: Number,
            default: 8000,
        },
        autoPlay: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            activeIndex: 0,
            incrementIndex: 0,
        };
    },
    computed: {
        ...mapState('locomotive-global', ['metrics']),
    },
    watch: {
        metrics: 'onMetricsChange',
    },
    created() {
        this.lastActiveIndex = 0;

        this.isAnimating = false;

        this.isPlaying = false;
        this.baseTime = Date.now();
    },
    mounted() {
        this.onVisibilityChangeBind = this.onVisibilityChange.bind(this);
        this.incrementSlideBind = this.incrementSlide.bind(this);

        this.setStyles();
        this.computeContainer();

        if (this.autoPlay) {
            this.setLoop();
        }
        this.bindEvents();
    },
    beforeDestroy() {
        this.resetLoop();
        this.unbindEvents();
    },
    methods: {
        bindEvents() {
            document.addEventListener('visibilitychange', this.onVisibilityChangeBind);
        },
        unbindEvents() {
            document.removeEventListener('visibilitychange', this.onVisibilityChangeBind);
        },
        playTimeline(disappearItemIndex, appearItemIndex) {
            const $items = this.$refs.item;

            const $currentItem = $items[disappearItemIndex];
            const $nextItem = $items[appearItemIndex];

            const $nextCategory = $nextItem.querySelector('.js-alert-category');
            const $nextMessage = $nextItem.querySelector('.js-alert-message');

            const tl = gsap.timeline({
                onStart: () => {
                    this.isAnimating = true;
                },
                onComplete: () => {
                    this.isAnimating = false;
                },
            });

            tl.to(
                $currentItem,
                {
                    duration: 0.3,
                    autoAlpha: 0,
                },
                0,
            );

            if ($nextCategory) {
                tl.set($nextCategory, { autoAlpha: 0, y: '40px' });
            }
            if ($nextMessage) {
                tl.set($nextMessage, { autoAlpha: 0, y: '40px' });
            }

            tl.to(
                $nextItem,
                {
                    duration: 0.3,
                    autoAlpha: 1,
                },
                0.6,
            );

            if ($nextCategory) {
                tl.to($nextCategory, { duration: 0.3, autoAlpha: 1, y: '0px' }, 0.7);
            }

            if ($nextMessage) {
                tl.to($nextMessage, { duration: 0.3, autoAlpha: 1, y: '0px' }, 0.8);
            }
        },
        setStyles() {
            const $items = this.$refs.item;
            for (let index = 0; index < $items.length; index++) {
                const $currentItem = $items[index];
                // show first items
                if (index !== this.activeIndex) {
                    gsap.set($currentItem, { opacity: 0 });
                }
            }
        },
        setLoop() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdate);
        },
        resetLoop() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdate);
        },
        incrementSlide() {
            if (this.isAnimating) return;
            this.lastActiveIndex = this.activeIndex;
            this.activeIndex = (this.activeIndex + 1) % this.items.length;
            this.baseTime = Date.now();
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
        decrementSlide() {
            if (this.isAnimating) return;
            this.lastActiveIndex = this.activeIndex;
            this.activeIndex =
                this.activeIndex === 0 ? this.items.length - 1 : (this.activeIndex - 1) % this.items.length;
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
        onPrev() {
            this.resetLoop();
            this.decrementSlide();
            if (this.autoPlay) {
                this.setLoop();
            }
        },
        onNext() {
            this.resetLoop();
            this.incrementSlide();
            if (this.autoPlay) {
                this.setLoop();
            }
        },
        onVisibilityChange() {
            if (document.hidden) {
                this.resetLoop();
            } else if (this.autoPlay) {
                this.setLoop();
            }
        },
        onClose() {
            this.$emit('onClose');
            this.resetLoop();
        },
        onMetricsChange() {
            this.computeContainer();
        },
        onUpdate() {
            const dateNow = Date.now();
            const elapsedTime = dateNow - this.baseTime;

            // If minimum duration passed
            if (elapsedTime > this.loopTime) {
                this.incrementSlideBind();
            }
        },
        computeContainer() {
            const $container = this.$refs.itemsContainer;
            const $items = this.$refs.item;
            const heights = [];
            for (let index = 0; index < $items.length; index++) {
                const $item = $items[index];
                heights.push($item.offsetHeight);
            }
            const maxHeight = Math.max(...heights);
            $container.style.height = maxHeight + 'px';
        },
        onBulletsClick(index) {
            const normalizedIndex = index - 1;

            if (normalizedIndex === this.activeIndex) return;

            this.lastActiveIndex = this.activeIndex;
            this.activeIndex = normalizedIndex;
            this.baseTime = Date.now();
            this.playTimeline(this.lastActiveIndex, this.activeIndex);
        },
    },
};
</script>

<style lang="scss" scoped>
.c-block-carousel-alerts {
    opacity: 0;

    html.is-ready & {
        opacity: 1;
        transition: opacity 0.2s linear;
    }
}

.c-block-carousel-alerts_inner {
    position: relative;
    z-index: 2;
    transform: translateZ(0);
    transition: background-color $loco-speed $loco-easing;

    @media (max-width: $to-small) {
        padding-top: loco-rem(16px);
        padding-bottom: loco-rem(16px);
    }

    @media (min-width: $from-small) and (max-width: $to-header) {
        padding-top: loco-rem(20px);
        padding-bottom: loco-rem(16px);
    }

    @media (min-width: $from-header) {
        padding-top: loco-rem(16px);
        padding-bottom: loco-rem(16px);
    }
}

.c-block-carousel-alerts_container {
    position: relative;
    overflow: hidden;

    @media (min-width: $from-small) {
        display: flex;
        justify-content: center;
    }

    @media (min-width: $from-small) and (max-width: $to-header) {
        align-items: flex-end;
    }

    @media (min-width: $from-header) {
        align-items: center;
    }
}

.c-block-carousel-alerts_overlay {
    cursor: pointer;

    @media (max-width: $to-header) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1;
    }

    @media (min-width: $from-header) {
        display: none;
    }
}

.c-block-carousel-alerts_top {
    width: 100%;
}

.c-block-carousel-alerts_bottom {
    display: flex;

    @media (max-width: $to-tiny) {
        justify-content: space-between;
        width: 100%;
    }

    @media (min-width: $from-tiny) {
        justify-content: center;
        width: loco-rem(212px);
    }

    @media (max-width: $to-small) {
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        margin-top: loco-rem(36px);
    }

    @media (min-width: $from-small) {
        align-items: center;
    }
}

.c-block-carousel-alerts_message {
    @media (max-width: $to-small) {
        margin-top: loco-rem(24px);
    }

    @media (min-width: $from-header) {
        flex: 1;
        padding-right: loco-rem($loco-padding);
        text-align: center;
        margin: 0 auto;
    }
}
.c-block-carousel-alerts_message_inner {
    display: inline-block;
    text-align: left;

    @media (max-width: $to-header) {
        padding-right: loco-rem(30px);
    }
}

.c-block-carousel-alerts_category {
    display: flex;
    align-items: center;

    @media (min-width: $from-large) {
        width: loco-rem(212px);
    }

    .c-icon {
        width: 20px;
        height: 20px;
    }
}

.c-block-carousel-alerts_message_cta {
    @media (min-width: $from-header) {
        display: inline-block;
        vertical-align: baseline;
        margin-left: loco-rem(24px);
    }
}

.c-block-carousel-alerts_title {
    @include fontFamily($loco-font, $loco-font-family-fallback);

    @media (min-width: $from-small) and (max-width: $to-header) {
        margin-top: loco-rem(8px);
        margin-bottom: loco-rem(8px);
    }

    @media (max-width: $to-small) {
        font-size: loco-rem(21px);
        line-height: 1;
        margin-bottom: rem(20px);
    }

    @media (min-width: $from-small) {
        display: block;
        font-size: loco-rem(16px);
        line-height: 1.4;
    }

    @media (min-width: $from-header) {
        display: inline-block;
        vertical-align: baseline;
        padding-left: loco-rem(24px);
    }
}

.c-block-carousel-alerts_category_text {
    margin-top: loco-rem(3px);
}

.c-block-carousel-alerts_list {
    position: relative;
}

.c-block-carousel-alerts_list_item {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate3d(-50%, -50%, 0);

    @media (min-width: $from-small) and (max-width: $to-header) {
        flex-direction: column;
    }

    @media (min-width: $from-small) {
        display: flex;
    }

    @media (min-width: $from-header) {
        align-items: center;
        justify-content: center;
    }

    &.is-active {
        pointer-events: auto;
    }
}
</style>
